import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchDocente = async (id) => {
  const res = await axios.get(`${API_HOST}/profiles/profiles/${id}`);
  return res.data;
};

export const fetchDocumentacaoDocente = async (id) => {
  const res = await axios.get(`${API_HOST}/profiles/profiles/${id}/documents`);
  return res.data;
};

export const fetchTeacherDocuments = async () => {
  const res = await axios.get(`${API_HOST}/documents/type/teacher`);
  return res.data;
};

export const createProfileDocument = async (id, params) => {
  const res = await axios.post(
    `${API_HOST}/profiles/profiles/${id}/documents`,
    params
  );
  return res.data;
};

export const deleteDocumentFile = async (id, keys) => {
  const params = { keys: keys.map((key) => ({ key })) };
  const res = await axios.post(
    `${API_HOST}/profiles/profiles/${id}/documents/delete_file`,
    params
  );
  return res.data;
};

export const updateProfileDocument = async (id, documentId, params) => {
  const res = await axios.patch(
    `${API_HOST}/profiles/profiles/${id}/documents/${documentId}`,
    params
  );
  return res.data;
};

export const fetchEmpresa = async (id) => {
  const res = await axios.get(
    `${API_HOST}/profiles/profiles/${id}/legal_person`
  );
  return res.data[0];
};

export const fetchDocumentacaoEmpresa = async (id) => {
  const res = await axios.get(
    `${API_HOST}/profiles/profiles/${id}/legal_person_documents`
  );
  return res.data;
};

export const fetchLegalPersonDocuments = async () => {
  const res = await axios.get(`${API_HOST}/documents/type/legal_person`);
  return res.data;
};

export const createProfileEmpresaDocument = async (id, params) => {
  const res = await axios.post(
    `${API_HOST}/profiles/profiles/${id}/legal_person_documents`,
    params
  );
  return res.data;
};

export const deleteDocumentEmpresaFile = async (id, keys) => {
  const params = { keys: keys.map((key) => ({ key })) };
  const res = await axios.post(
    `${API_HOST}/profiles/profiles/${id}/legal_person_documents/delete_file`,
    params
  );
  return res.data;
};

export const updateProfileEmpresaDocument = async (id, documentId, params) => {
  const res = await axios.patch(
    `${API_HOST}/profiles/profiles/${id}/legal_person_documents/${documentId}`,
    params
  );
  return res.data;
};

export const fetchProfessor = async (params) => {
  const res = await axios.get(
    `${API_HOST}/academico/professores/list_docentes`,
    { params }
  );
  return res.data;
};

export const fetchDocenteContratosId = async (params) => {
  const res = await axios.get(
    `${API_HOST}/contratos/teacher_contract/${params}`
  );
  return res.data;
};

export const fetchDocenteContratos = async (params) => {
  const res = await axios.get(`${API_HOST}/contratos/teacher_contract`, {
    params,
  });
  return res.data;
};
export const fetchDocenteContratosInput = async (params) => {
  const res = await axios.get(`${API_HOST}/contratos/teacher_contract`, {
    params,
  });
  return res.data.results;
};

export const fetchModeloContratos = async (params) => {
  const res = await axios.get(`${API_HOST}/contratos/contract_layout`, {
    params,
  });
  return res.data;
};

export const fetchAsinatauraContratos = async (params) => {
  const res = await axios.get(`${API_HOST}/contratos/contract_signature`, {
    params,
  });
  return res.data;
};

export const fetchAulasNovoEventoAulas = async (params) => {
  const res = await axios.get(`${API_HOST}/academico/aulas/paginated`, {
    params,
  });
  return res.data;
};

export const fetchDocenteEvento = async (params) => {
  const res = await axios.get(`${API_HOST}/financeiro/evento/${params}`);
  return res.data;
};

export const fetchDocenteEventoFilter = async (params) => {
  const res = await axios.get(`${API_HOST}/financeiro/evento`, {
    params,
  });
  return res.data;
};

export const fetchDocenteEventoPagamento = async (params) => {
  const res = await axios.get(`${API_HOST}/financeiro/evento/pagamento`, {
    params,
  });
  return res.data;
};

export const fetchTipoEvento = async (params) => {
  const res = await axios.get(`${API_HOST}/financeiro/evento_tipo`, {
    params,
  });
  return res.data;
};

export const fetchOrdemPagamento = async (params) => {
  const res = await axios.get(`${API_HOST}/financeiro/pagamento_evento`, {
    params,
  });
  return res.data;
};
