import PropTypes from 'prop-types';
import React from 'react';
import InputMask from './InputMask';

const InputCurrency = ({ decimalPlaces, ...rest }) => {
  const currencyMask = (rawValue) => {
    const reais = 'R';
    const dollar = '$';
    const space = ' ';
    const separator = ',';
    const digits = rawValue.replace(/\D/g, '');
    const decimals = [...digits.slice(-2)].map(() => /\d/);
    const number = [...digits.substring(0, digits.length - 2)].map(() => /\d/);
    return [reais, dollar, space, ...number, separator, ...decimals];
  };

  return <InputMask {...rest} mask={currencyMask} />;
};

InputCurrency.propTypes = {
  decimalPlaces: PropTypes.number,
};

InputCurrency.defaultProps = {
  decimalPlaces: 2,
};

export default InputCurrency;
