import { get } from 'lodash';

export const isDisabled = (metaData, fieldName) =>
  get(metaData, `${fieldName}.disabled`);
export const isRequired = (metaData, fieldName) =>
  get(metaData, `${fieldName}.required`);
export const isVisible = (metaData, fieldName) =>
  get(metaData, `${fieldName}.visible`);

export const toDate = (date) => date && date.toISOString().split('T')[0];
export const toTime = (time) =>
  time ? `${time.getHours()}:${time.getMinutes()}` : null;
export const fillString = (str, width) =>
  ('0'.repeat(width) + str).slice(-width);
export const toPresentableDate = (date) => {
  try {
    let dateObj;
    if (date instanceof Date) {
      dateObj = date;
    } else if (date) {
      dateObj = new Date(date);
    } else {
      return null;
    }
    const day = fillString(dateObj.getDate(), 2);
    const month = fillString(dateObj.getMonth() + 1, 2);
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  } catch (_) {
    return null;
  }
};

export const convertToFloat = (string) => {
  if (string) {
    const newString = string.replace(',', '.');
    return parseFloat(newString);
  }
  return string;
};

export const tableTextFilterMethod = (filter, row) => {
  const { id, value } = filter;
  const rowValue = get(row, id) || '';
  const text = lowerCaseStringAndRemoveAccent(rowValue.toString());
  const search = lowerCaseStringAndRemoveAccent(value);
  return text.includes(search);
};

export const enumeration = (array) => {
  /* Recebe uma lista e retorna uma str com os n - 1 primeiros elementos
   * separados por "," e o último separado por "e"
   * EX:
   * > enumeration(['a', 'b', 'c'])
   * > 'a, b e c'
   */
  const a = [...array];
  if (a.length === 1) {
    return a[0];
  }
  const last = a.pop();
  const commaSeparatedString = a.join(', ');
  return `${commaSeparatedString} e ${last}`;
};

export const paginateList = (array, pageNumber, pageSize) =>
  array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);

export const lowerCaseStringAndRemoveAccent = (value) =>
  value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const verifyIfStringHasDigits = (value) => /^\d+$/.test(value);

export const renderListFilter = (array, fields, value) => {
  if (array) {
    if (value) {
      return array.filter((el) =>
        fields.some((field) =>
          lowerCaseStringAndRemoveAccent(get(el, field, '')).includes(
            lowerCaseStringAndRemoveAccent(value)
          )
        )
      );
    }
    return array;
  }
  return [];
};

export const addThousandSeparator = (number, separator = '.') =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const toCurrency = (currency) =>
  addThousandSeparator(parseFloat(currency).toFixed(2).replace('.', ','));

export const getCurrentDate = () =>
  new Date().toLocaleString('pt-br', {
    timeZone: 'America/Sao_Paulo',
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

export const getFilenameFromContentDisposition = (contentDisposition) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return null;
};

export const generateFileName = (name) => {
  const d = new Date();
  const day = `0${d.getDay()}`;
  const month = `0${d.getMonth()}`;
  const dformat = `${day.slice(-2)}_${month.slice(-2)}_${d.getFullYear()}`;
  return `${name}_${dformat}.xlsx`;
};

export const cleanToNumeric = (value) => {
  if (typeof value === 'string') {
    return value.replace(/\D/g, '');
  }
  return '';
};

export const parseDateMask = (date) => {
  // Parses dd/mm/aaaa to aaaa-mm-ddd
  if (!date) {
    return undefined;
  }

  // This regex validates if date is valid. It even checks leap years!
  const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  if (!date.match(regex)) {
    return undefined;
  }

  const [day, month, year] = date.split('/');
  return `${year}-${month}-${day}`;
};

export const ptBRAlphaNumericMask = (rawValue) => {
  const filteredValue = rawValue.replace(/[^\wçáéíóúâêôàèìòù\s]/g, '');
  const maskedValue = [...filteredValue].map(() => /[\wçáéíóúâêôàèìòù\s]/g);
  return maskedValue;
};

export const titleCase = (string) => {
  if (!string) return string;
  const formattedString = string.trim().toLowerCase();
  const stringArray = formattedString.split(' ');
  const resultString = stringArray.map(
    (word) => word[0].toUpperCase() + word.slice(1)
  );
  return resultString.join(' ');
};
