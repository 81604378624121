import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TabsNav from './TabsNav';
import TabsNavButton from './TabsNavButton';
import ResolutionForm from './ResolutionForm';
import ForwardingForm from './ForwardingForm';
import ResponsabilityForm from './ResponsabilityForm';
import { PermissionContext } from 'hoc/withPermission';
import Button from './common/buttons/Button';

const ActiveTabs = {
  RESOLUTION: 'resolution',
  FORWARDING: 'forwarding',
  RESPONSABILITY: 'responsability',
};

const DEFAULT_ACTIVE_TAB = ActiveTabs.RESOLUTION;

const TicketActionForm = ({
  children,
  onSubmit,
  onCancel,
  submitting,
  ticket,
  statuses,
  onFileUpload,
}) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const resolve = get(ticket, 'permissions.resolve');
  const forward = get(ticket, 'permissions.forward');
  const responsability = get(ticket, 'permissions.responsability');
  const email = get(ticket, 'permissions.able_send_email');
  const resolutionFile = get(ticket, 'permissions.able_upload_resolution_file');
  const resolutionFileRequired =
    get(ticket, 'category.file_requirement_ticket_resolution') == 2;

  const renderForm = () => {
    switch (activeTab) {
      case ActiveTabs.RESOLUTION:
        return (
          resolve && (
            <ResolutionForm
              onCancel={onCancel}
              onSubmit={() => onSubmit(ActiveTabs.RESOLUTION)}
              submitting={submitting}
              statuses={statuses}
              sendEmail={email}
              uploadResolutionFile={resolutionFile}
              onFileUpload={onFileUpload}
              resolutionFileRequired={resolutionFileRequired}
            >
              {children}
            </ResolutionForm>
          )
        );
      case ActiveTabs.FORWARDING:
        return (
          forward && (
            <ForwardingForm
              onSubmit={() => onSubmit(ActiveTabs.FORWARDING)}
              onCancel={onCancel}
              submitting={submitting}
            />
          )
        );
      case ActiveTabs.RESPONSABILITY:
        return (
          responsability && (
            <ResponsabilityForm
              onSubmit={() => onSubmit(ActiveTabs.RESPONSABILITY)}
              onCancel={onCancel}
              submitting={submitting}
            />
          )
        );
      default:
        return null;
    }
  };

  const renderTabs = () => {
    if (!resolve && !forward && !responsability) {
      return null;
    }

    return (
      <TabsNav className="pl-0 mt-2 mb-0" style={{ justifyContent: 'left' }}>
        {resolve && (
          <TabsNavButton
            active={activeTab === ActiveTabs.RESOLUTION}
            onClick={() => setActiveTab(ActiveTabs.RESOLUTION)}
          >
            Resolver
          </TabsNavButton>
        )}
        {forward && (
          <TabsNavButton
            active={activeTab === ActiveTabs.FORWARDING}
            onClick={() => setActiveTab(ActiveTabs.FORWARDING)}
          >
            Encaminhar
          </TabsNavButton>
        )}
        {responsability && (
          <TabsNavButton
            active={activeTab === ActiveTabs.RESPONSABILITY}
            onClick={() => setActiveTab(ActiveTabs.RESPONSABILITY)}
          >
            <i style={{ color: '#039f97' }}>
              <b>Alterar responsabilidade</b>
            </i>
          </TabsNavButton>
        )}
      </TabsNav>
    );
  };

  return (
    <>
      {renderTabs()}
      {renderForm()}
    </>
  );
};

TicketActionForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  onFileUpload: PropTypes.func,
  ticket: PropTypes.shape({
    permissions: PropTypes.object,
  }).isRequired,
};

TicketActionForm.defaultProps = {
  statuses: [
    { value: 1, label: 'Deferido' },
    { value: 2, label: 'Indeferido' },
  ],
  children: null,
  submitting: false,
  onFileUpload: () => undefined,
};

export default TicketActionForm;
