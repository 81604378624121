import axios from 'axios';
import { API_HOST } from '../consts';

const prefix = 'core';

class InstitutionApi {
  constructor() {
    this.endpoint = 'institution';
  }

  list = async (params) => {
    const response = await axios.get(`${API_HOST}/${prefix}/${this.endpoint}`, {
      params,
    });
    return response.data;
  };
}

export {
  // eslint-disable-next-line import/prefer-default-export
  InstitutionApi,
};

export const fetchParceiro = (id) =>
  axios.get(`${API_HOST}/core/partners/${id}`).then((res) => res.data);
