import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchGenderOptions from '../../../services/gender_services';

const GenderInputSelect = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchGenderOptions}>
    {({ options, isLoading }) => (
      <InputSelect
        name="gender"
        label="Sexo"
        placeholder="Selecionar"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);
export default GenderInputSelect;
