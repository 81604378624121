import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { API_HOST } from '../../consts';
import DiplomaRecord from '../../components/curricula/DiplomaRecord';
import logo from '../../assets/img/logo.svg';
import { useLocation } from 'react-router-dom';
import { REACT_APP_LABEL_DIPLOMA } from '../../consts';

const DiplomaRecordPage = (props) => {
  const { className } = props;
  const [userIdentification, setUserIdentification] = useState('');
  const [diplomas, setDiplomas] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cameFromQR = queryParams.get('qr');
  const handleUserIdentificationChange = (event) => {
    setUserIdentification(event.target.value);
  };
  const fetchDiploma = useCallback(
    (identification = userIdentification) => {
      axios
        .get(
          `${API_HOST}/curricula/diploma_record?user_identification=${identification}`
        )
        .then((response) => {
          if (response.data.results.length === 0) {
            toast.error(`Diploma não encontrado`);
          }
          setDiplomas(response.data.results);
        })
        .catch(() => {
          toast.error(`Diploma não encontrado`);
        });
    },
    [userIdentification]
  );

  useEffect(() => {
    const identification = queryParams.get('id');
    if (!identification) return;
    fetchDiploma(identification);
    setUserIdentification(identification);
  }, [fetchDiploma, queryParams]);

  const handleSubmit = (event) => {
    fetchDiploma();
    event.preventDefault();
  };

  return (
    <div className={className}>
      <nav className="main-header">
        <ul>
          <li className="logo">
            <Link to="/">
              <img
                width={200}
                src={logo}
                className="navigation-logo"
                alt="CMMG"
              />
            </Link>
          </li>
        </ul>
      </nav>
      <div className="body-container">
        <div className="content-container">
          {!cameFromQR && (
            <form onSubmit={handleSubmit}>
              <div className="input-label">
                Utilize o campo abaixo para pesquisar alunos{' '}
                {REACT_APP_LABEL_DIPLOMA.toLowerCase()}s pela instituição
              </div>
              <input
                className="input-userid"
                placeholder="Digite o CPF ou Nome Completo"
                type="text"
                value={userIdentification}
                onChange={handleUserIdentificationChange}
              />
              <button className="search-button" type="submit">
                <FontAwesomeIcon icon="search" />
              </button>
            </form>
          )}
          {diplomas &&
            diplomas.map((diploma) => (
              <DiplomaRecord
                key={diploma.person_cpf}
                diploma={diploma}
                hasQR={!cameFromQR}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

DiplomaRecordPage.propTypes = {
  className: PropTypes.string,
};

DiplomaRecordPage.defaultProps = {
  className: '',
};

export default styled(DiplomaRecordPage)`
.input-label {
  margin-bottom: 10px
  text-align: center;
}

.search-button {
  background-color: #039f97;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: -45px;
}

.search-button:hover {
  -webkit-box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
}

.content-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input-userid {
  width:83%;
  height: 30px;
  margin-left: 35px;
}
.body-container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

}
.main-header {
  top: 0;
  left: 0;
  z-index: 10;
  color: white;
  width: 100%;
  height: 6rem;
  background: #039f97;
  padding: 0;
  position: block;
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    justify-content: space-between;
    -webkit-box-pack: justify;
    margin: auto;
    padding: 15px 25px;

    .logo {
      align-self: center;
    }

    li {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
