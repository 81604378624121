import React from 'react';
import PropTypes from 'prop-types';

const CircleImage = ({ alt, className, ...props }) => (
  <div className={`img-user mobile-img-user ${className}`}>
    <img className="zoom-img" width="50px" height="50px" alt={alt} {...props} />
  </div>
);

CircleImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CircleImage.defaultProps = {
  className: '',
};

export default CircleImage;
