import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchRelationshipOptions from '../../../services/relationship_services';

const RelationshipInputSelect = ({ isRequired, submitOnChange, ...props }) => {
  const { setFieldValue, submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions fetchOptions={fetchRelationshipOptions}>
      {({ options, isLoading }) => (
        <InputSelect
          name="relationship"
          label="Parentesco"
          placeholder="Selecionar"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          isRequired={isRequired}
          onChange={(value) => {
            if (value) {
              setFieldValue('relationship', '');
            }
            if (submitOnChange) {
              submitForm();
            }
          }}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

RelationshipInputSelect.propTypes = {
  isRequired: PropTypes.bool,
  submitOnChange: PropTypes.bool,
};

RelationshipInputSelect.defaultProps = {
  isRequired: false,
  submitOnChange: false,
};

export default RelationshipInputSelect;
