import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import { fetchSchoolType } from '../../../services/censo_services';

const SchoolTypeInputSelect = ({ submitOnChange, ...props }) => {
  const { submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions fetchOptions={fetchSchoolType}>
      {({ options, isLoading }) => (
        <InputSelect
          name="school_type"
          label="Tipo de Escola"
          placeholder="Selecione"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.nome}
          onChange={() => submitOnChange && submitForm()}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

SchoolTypeInputSelect.propTypes = {
  submitOnChange: PropTypes.bool,
};

SchoolTypeInputSelect.defaultProps = {
  submitOnChange: false,
};

export default SchoolTypeInputSelect;
