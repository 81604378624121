import React from 'react';
import axios from 'axios';
import { API_HOST } from '../consts';
import debounce from 'debounce-promise';
import StatusIndicator from '../components/StatusIndicator';

export const fetchTeachers = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/professores`, { params })
      .then((response) =>
        response.data.results.map((d) => ({
          value: d.id,
          label: d.nome,
        }))
      ),
  500
);

export const fetchMethodologies = debounce(
  (params) =>
    axios.get(`${API_HOST}/aulas/methodologies`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    ),
  500
);

export const fetchRooms = debounce(
  (params) =>
    axios.get(`${API_HOST}/rooms/rooms`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    ),
  500
);

export const fetchBuildings = debounce(
  (params) =>
    axios.get(`${API_HOST}/rooms/buildings`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    ),
  500
);

export const fetchAlunos = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/students`, { params })
      .then((response) => response.data),
  500
);

export const fetchTypes = debounce(
  (params) =>
    axios.get(`${API_HOST}/aulas/types`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    ),
  500
);

export const fetchLocal = debounce(
  (params) =>
    axios.get(`${API_HOST}/rooms/rooms`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: `${d.floor.building.name} - ${d.name}`,
      }))
    ),
  500
);

export const fetchStudents = debounce(
  (params) =>
    axios.get(`${API_HOST}/academico/students`, { params }).then((response) =>
      response.data.results.map((d) => ({
        ...d,
        value: d.id,
        label: (
          <>
            {d.user.last_name}
            <span className="ml-1 mr-1">•</span>
            {d.user.username}
          </>
        ),
      }))
    ),
  500
);

export const fetchStudentGrades = ({ id, ...params }) =>
  axios
    .get(`${API_HOST}/activities/grades/student/${id}`, { params })
    .then((response) =>
      response.data
        .filter((d) => d.grade)
        .map((d) => ({
          value: d.grade.id,
          label: d.name,
          grade: d.grade.value,
        }))
    );

export const fetchFrequencies = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/agendamento/periodicidades`, { params })
      .then((response) =>
        response.data.map((d) => ({
          value: d.id,
          label: d.nome,
        }))
      ),
  500
);

export const fetchClasses = debounce(
  (params) =>
    axios.get(`${API_HOST}/academico/turmas`, { params }).then((response) =>
      response.data.results.map((d) => ({
        disciplines: d.disciplinas.map((f) => ({
          value: f.id,
          label: f.nome,
        })),
        value: d.id,
        label: d.nome,
      }))
    ),
  500
);

export const fetchStudyMaterialOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/study_materials/publications/options`, { params })
      .then((response) =>
        response.data.results.map((d) => ({
          value: d.id,
          label: d.material.title,
        }))
      ),
  500
);

export const fetchClassDisciplines = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/class_disciplines`, { params })
      .then((response) =>
        response.data.results.map((d) => ({
          value: d.id,
          label: `${d.discipline} • ${d.clss}`,
          clss: d.clss,
          discipline: d.discipline,
        }))
      ),
  500
);

export const fetchDisciplines = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/disciplinas`, { params })
      .then((response) =>
        response.data.results.map((d) => ({
          value: d.id,
          label: d.nome,
        }))
      ),
  500
);

export const fetchCourses = debounce(
  (params) =>
    axios.get(`${API_HOST}/academico/cursos`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.nome,
      }))
    ),
  500
);

export const fetchAllCourses = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/cursos/list_all`, { params })
      .then((response) =>
        response.data.map((d) => ({
          value: d.id,
          label: d.nome,
        }))
      ),
  500
);

export const fetchCoursess = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/cursos`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchCleanCourses = debounce(async (params) => {
  const res = await axios.get(`${API_HOST}/academico/cursos`, { params });
  return res.data.results;
}, 500);

export const fetchLessons = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/aulas/paginated`, { params })
      .then((response) =>
        response.data.results.map((d) => ({
          value: d.id,
          label: d.data_formatada,
        }))
      ),
  500
);

export const fetchCronogramaStatus = debounce(
  (params) =>
    axios.get(`${API_HOST}/schedules/status`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    ),
  500
);

export const fetchDepartments = (params) =>
  axios
    .get(`${API_HOST}/declarations/departments`, {
      params: {
        page_size: 1000,
        ...params,
      },
    })
    .then((response) => response.data.results);

export const fetchDays = () =>
  new Promise((resolve) =>
    resolve([
      { value: 0, label: 'Segunda' },
      { value: 1, label: 'Terça' },
      { value: 2, label: 'Quarta' },
      { value: 3, label: 'Quinta' },
      { value: 4, label: 'Sexta' },
      { value: 5, label: 'Sabádo' },
      { value: 6, label: 'Domingo' },
    ])
  );

export const fetchActivityStatuses = () =>
  axios.get(`${API_HOST}/activities/statuses`).then((response) =>
    response.data.map((d) => ({
      value: d.id,
      label: <StatusIndicator color={d.color}>{d.name}</StatusIndicator>,
    }))
  );

export const fetchSchools = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/escolas`, { params })
      .then((response) => response.data),
  500
);

export const fetchCoordinatorOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/login/users`, {
        params: { ...params, group: 'Coordenador de curso' },
      })
      .then((response) => response.data.results),
  500
);

export const fetchCourseOfferTypeOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/offer_type`, { params })
      .then((response) => response.data),
  500
);

export const fetchCalendarOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/calendario/calendarios`, { params })
      .then((response) => response.data),
  500
);

export const fetchAcademicDegrees = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/academic_degrees`, { params })
      .then((response) => response.data),
  500
);

export const fetchEducationLevelOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/educational_stage`, { params })
      .then((response) => response.data),
  500
);

export const fetchModalidadeOfertaOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/modalidades_oferta`, { params })
      .then((response) => response.data),
  500
);

export const fetchPeriodicidadeOfertaOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/periodicidades_oferta`, { params })
      .then((response) => response.data),
  500
);

export const fetchEmploymentRelationshipsOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/core/employment-relationships`, { params })
      .then((response) => response.data),
  500
);

export const fetchEmploymentTypesOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/core/employment-types`, { params })
      .then((response) => response.data),
  500
);

export const fetchGrids = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/curricula/grids`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchGridOptions = debounce(
  (params) =>
    axios.get(`${API_HOST}/curricula/grids`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.description,
      }))
    ),
  500
);

export const fetchClassCategories = debounce(async (params) => {
  const response = await axios.get(`${API_HOST}/academico/categoria_turma`, {
    params,
  });
  return response.data;
}, 500);

export const fetchProfessorOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/professores`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchTermsYears = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/term_years`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchClassesOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/turmas`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchTeacherType = debounce(async (params) => {
  const response = await axios.get(
    `${API_HOST}/academico/tipo_turma_disciplina_professor`,
    { params }
  );
  return response.data;
}, 500);

export const fetchPersonOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/persons/persons`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchPersons = debounce(
  (params) =>
    axios.get(`${API_HOST}/persons/persons`, { params }).then((response) =>
      response.data.results.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    ),
  500
);

export const fetchRemessaSerasaTypes = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/serasa/tipos-remessa`, { params })
      .then((response) => response.data),
  500
);

export const fetchRemessaSerasaStatus = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/serasa/remessa-status`, { params })
      .then((response) => response.data),
  500
);

export const fetchLegalPerson = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/persons/legal_persons`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchParceiros = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/core/partners`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchTemplateTypes = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/declarations/template_types`, { params })
      .then((res) =>
        res.data.map((d) => ({
          ...d,
          value: d.id,
          label: d.name,
        }))
      ),
  500
);

export const fetchTemplate = debounce(
  (params) =>
    axios.get(`${API_HOST}/declarations/templates`, { params }).then((res) =>
      res.data.results.map((d) => ({
        ...d,
        value: d.id,
        label: d.title,
      }))
    ),
  500
);

export const fetchFilterCourses = (params) => {
  axios.get(`${API_HOST}/academico/cursos`, { params }).then((response) => {
    return response.data.results;
  });
};

export const fetchTerms = ({ ...params }) =>
  axios.get(`${API_HOST}/academico/term`, { params }).then((response) =>
    response.data.map((d) => ({
      value: d.id,
      label: d.name,
    }))
  );
export const fetchCourseOffers = async (params) => {
  const res = await axios.get(`${API_HOST}/academico/course_offer`, { params });
  return res.data;
};

export const fetchShifts = async (params) => {
  const res = await axios.get(`${API_HOST}/academico/shift`, { params });
  return res.data;
};

export const fetchTermOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/turnos`, { params })
    .then((response) => response.data);

export const fetchSupplementaryHours = (params) =>
  axios
    .get(`${API_HOST}/academico/supplementary_hours`, { params })
    .then((response) => response.data);

export const createSupplementaryHours = (data) =>
  axios
    .post(`${API_HOST}/academico/supplementary_hours`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response.data.non_field_errors[0];
    });

export const updateSupplementaryHours = (data, id) =>
  axios
    .patch(`${API_HOST}/academico/supplementary_hours/${id}`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response.data.non_field_errors[0];
    });

export const deleteSupplementaryHours = (id) =>
  axios
    .delete(`${API_HOST}/academico/supplementary_hours/${id}`)
    .then((response) => response.data)
    .catch(() => {
      throw 'Erro ao deletar';
    });
