import React from 'react';
import { PropTypes } from 'prop-types';
import LoadingIndicator from '../LoadingIndicator';
import Button from './buttons/Button';

const ComparisonItem = ({
  children,
  list,
  loading,
  title,
  feedbackText,
  className,
  onClick,
  buttonLabel,
  disabledActionButton,
  actionButtonClassName,
}) => {
  const listContent = () => {
    if (loading) {
      return (
        <div className="list-comparison-height">
          <div className="d-flex align-items-center h-100 justify-center">
            <LoadingIndicator />
          </div>
        </div>
      );
    }
    if (list.length === 0) {
      return (
        <div className="list-comparison-height">
          <p className="d-flex align-items-center text-align-center justify-center h-100">
            {feedbackText}
          </p>
        </div>
      );
    }
    return children;
  };

  return (
    <div className={`list-comparison ${className}`}>
      <div className="position-relative">
        <h3 className="list-comparison-title">{title}</h3>
        <div className="d-flex justify-flex-end">
          {buttonLabel && (
            <Button
              type="button"
              disabled={disabledActionButton || loading}
              onClick={onClick}
              className={`btn btn-link ${actionButtonClassName}`}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
      {listContent()}
    </div>
  );
};

ComparisonItem.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  feedbackText: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonLabel: PropTypes.string,
  disabledActionButton: PropTypes.bool,
  actionButtonClassName: PropTypes.string,
};

ComparisonItem.defaultProps = {
  children: null,
  loading: false,
  feedbackText: '',
  title: '',
  className: '',
  onClick: () => {},
  buttonLabel: '',
  disabledActionButton: false,
  actionButtonClassName: '',
};

export default ComparisonItem;
