import { Scope, withFormState } from 'informed';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from './common/buttons/Button';
import InputCheckbox from './InputCheckbox';
import InputSelectInformed from './InputSelectInformed';
import InputTextAreaInformed from './InputTextAreaInformed';
import InputFile from './data-entry/InputFile';

const ResolutionForm = ({
  statuses,
  children,
  onSubmit,
  submitting,
  formState,
  sendEmail,
  onFileUpload,
  uploadResolutionFile,
  resolutionFileRequired,
}) => {
  const status = get(formState.values, 'resolution.status');
  const message = get(formState.values, 'resolution.message');

  const [resolutionFileUploaded, setResolutionFileUploaded] = useState(false);
  const handleFileUpload = (value) => {
    onFileUpload(value);
    setResolutionFileUploaded(!!value);
  };

  const submitButtonIsDisabled =
    submitting ||
    !status ||
    !message ||
    (resolutionFileRequired && !resolutionFileUploaded);
  return (
    <>
      <div className="mb-3">
        <Scope scope="resolution">
          <InputSelectInformed
            placeholder="Qual o seu parecer em relação ao protocolo?"
            field="status"
            options={statuses}
            className="mb-2"
          />
          <div className="mt-3">
            <InputTextAreaInformed
              field="message"
              placeholder="Justifique o seu parecer"
            />
          </div>
          <div className="mt-3">
            <InputTextAreaInformed
              field="private_message"
              placeholder="Observação ( visível apenas para administrativo )"
            />
          </div>
        </Scope>
      </div>
      <div className="mt-3">{children}</div>
      {
        <Scope scope="resolution">
          {sendEmail && (
            <div className="mt-3">
              <InputCheckbox
                field="send_email"
                label="Enviar email para o requerente"
              />
            </div>
          )}
          {uploadResolutionFile && (
            <div className="d-flex align-items-center justify-space-between">
              <InputFile
                module="protocolos"
                textFuncaoInput="Anexar arquivos para o requerente"
                onUploadComplete={handleFileUpload}
              />
            </div>
          )}
        </Scope>
      }
      <div className="mt-3">
        <Button
          disabled={submitButtonIsDisabled}
          action="primary"
          onClick={onSubmit}
        >
          RESOLVER
        </Button>
      </div>
    </>
  );
};

ResolutionForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  formState: PropTypes.shape({
    values: PropTypes.object,
  }).isRequired,
  onFileUpload: PropTypes.func,
  sendEmail: PropTypes.string.isRequired,
  uploadResolutionFile: PropTypes.bool,
  resolutionFileRequired: PropTypes.bool,
};

ResolutionForm.defaultProps = {
  statuses: [],
  children: null,
  submitting: false,
  onFileUpload: () => {},
  uploadResolutionFile: false,
  resolutionFileRequired: false,
};

export default withFormState(ResolutionForm);
