import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchEmailKind from '../../../services/email_kind_services';

const EmailKindInputSelect = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchEmailKind}>
    {({ options, isLoading }) => (
      <InputSelect
        name="email_kind"
        label="Tipo de E-mail"
        placeholder="Selecione"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);

export default EmailKindInputSelect;
