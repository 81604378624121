import { ActionDropdown } from 'common';
import { AcordoContext } from 'contexts/lancamentos/AcordoState';
import { PermissionContext } from 'hoc/withPermission';
import React, { useContext } from 'react';

type AcordoActionsProps = {
  onChange: (actionName: string) => void;
};

const AcordoActions = ({ onChange }: AcordoActionsProps) => {
  const { hasPermission } = useContext(PermissionContext);
  const { acordo } = useContext(AcordoContext);
  const actions: any = [];

  if (hasPermission('financeiro.add_cancelamentoacordo')) {
    actions.push({
      value: 'cancelar',
      label: 'Cancelar',
      icon: 'ban',
      disabled: !!acordo?.cancelamento,
    });
  }

  return <ActionDropdown onClick={onChange} actions={actions} />;
};

export default AcordoActions;
