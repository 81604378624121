import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';

const Onboarding = lazy(() => retry(() => import('./Onboarding')));
const ExamRedirectPage = lazy(() => retry(() => import('./ExamsRedirectPage')));

export default () => (
  <Switch>
    <PrivateRoute exact path="/renovacao-matricula" component={Onboarding} />
    <PrivateRoute exact path="/exams-redirect" component={ExamRedirectPage} />
  </Switch>
);
