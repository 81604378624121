import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatusIndicator = ({ className, color, children, icon, tooltip }) => (
  <>
    <ReactTooltip />
    <span>
      <FontAwesomeIcon
        data-tip={tooltip}
        icon={icon || 'circle'}
        className={`mr-1 ${className}`}
        style={color ? { color } : {}}
      />
      {children}
    </span>
  </>
);

StatusIndicator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
};

StatusIndicator.defaultProps = {
  className: '',
  children: null,
  color: null,
  icon: 'circle',
  tooltip: '',
};

export default StatusIndicator;
