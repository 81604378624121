import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchFiles } from '../s3';
import ChatLine from './ChatLine';
import Files from './common/Files';

const TicketResolution = ({
  children,
  created_by,
  created_at,
  message,
  private_message,
  isAuthor,
  files,
  admissionMethod,
  canSeePrivate,
}) => {
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [resolutionFiles, setResolutionFiles] = useState(null);

  const getFiles = useCallback(async () => {
    setLoadingFiles(true);
    try {
      const res = await fetchFiles(files);
      setResolutionFiles(res);
      setLoadingFiles(false);
    } catch {
      setLoadingFiles(false);
    }
  }, [files]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return (
    <>
      <ChatLine
        timestamp={created_at}
        author={created_by}
        message={message}
        privateMessage={private_message}
        isAuthor={isAuthor}
        admissionMethod={admissionMethod}
        canSeePrivate={canSeePrivate}
      >
        <Files loading={loadingFiles} files={resolutionFiles} />
        {children}
      </ChatLine>
    </>
  );
};

TicketResolution.propTypes = {
  status: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  created_at: PropTypes.string,
  created_by: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.element,
  isAuthor: PropTypes.bool.isRequired,
  admissionMethod: PropTypes.shape({
    charge_ticket: PropTypes.bool,
    name: PropTypes.string,
  }),
  loadingFile: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

TicketResolution.defaultProps = {
  status: {
    name: '',
    color: 'black',
  },
  created_at: '',
  created_by: '',
  message: '',
  children: null,
  admissionMethod: {
    charge_ticket: true,
  },
  files: [],
  loadingFiles: true,
};

export default TicketResolution;
