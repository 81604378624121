import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';
import AcordoPage from './AcordoPage';
import NewPagamentoPage from './NewPagamentoPage';

const FichasFinanceirasPage = lazy(() =>
  retry(() => import('./FichasFinanceirasPage'))
);
const FichaFinanceiraPage = lazy(() =>
  retry(() => import('./FichaFinanceiraPage'))
);
const BoletoPage = lazy(() => retry(() => import('./BoletoPage')));
const NewLancamentoPage = lazy(() =>
  retry(() => import('./NewLancamentoPage'))
);
const NewAcordoPage = lazy(() => retry(() => import('./NewAcordoPage')));
const AcordoListPage = lazy(() => retry(() => import('./AcordoListPage')));
const IRPFPrintPage = lazy(() =>
  retry(() => import('components/ficha-financeira/IRPFPrintPage'))
);
const EditLancamentoPage = lazy(() =>
  retry(() => import('./EditLancamentoPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/irpf"
      permission="financeiro.view_conta"
      component={IRPFPrintPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras"
      permission="financeiro.view_any_conta"
      component={FichasFinanceirasPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:id"
      permission="financeiro.view_conta"
      component={FichaFinanceiraPage}
      sizingWidth="w-90"
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:contaId/lancamentos/new"
      permission="financeiro.add_lancamento"
      component={NewLancamentoPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:contaId/lancamentos/:lancamentoId/pagamentos/new"
      permission="financeiro.add_pagamento"
      component={NewPagamentoPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:contaId/acordos/new"
      permission="financeiro.add_acordo"
      component={NewAcordoPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:contaId/acordos"
      permission="financeiro.view_acordo"
      component={AcordoListPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:contaId/acordos/:acordoId/lancamentos-(em-acordo|criados)"
      permission="financeiro.view_acordo"
      component={AcordoPage}
    />
    <PrivateRoute
      exact
      path="/fichas-financeiras/:contaId/acordos/:acordoId"
      permission="financeiro.view_acordo"
      component={({ location }) => (
        <Redirect to={location.pathname + '/lancamentos-criados'} />
      )}
    />
    <PrivateRoute
      exact
      path="/ver_boleto/:boleto_id"
      permission="financeiro.view_conta"
      component={BoletoPage}
    />
    <PrivateRoute
      exact
      path="/editar-lancamentos"
      permission="financeiro.view_conta"
      component={EditLancamentoPage}
    />
  </Switch>
);
