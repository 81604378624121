import React from 'react';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import CircleImage from './CircleImage';
import defaultUserLogo from '../../assets/img/user.png';

class UserIcon extends React.Component {
  state = {
    forceDefaultPhoto: false,
  };

  setForceDefaultPhoto = (forceDefaultPhoto) => {
    this.setState({ forceDefaultPhoto });
  };

  render() {
    const { src, alt, popoverOnClick, ...rest } = this.props;
    const { forceDefaultPhoto } = this.state;

    return (
      <>
        <CircleImage
          {...rest}
          alt={alt}
          src={forceDefaultPhoto || !src ? defaultUserLogo : src}
          onClick={(e) => this.op && this.op.toggle(e)}
          onError={() => this.setForceDefaultPhoto(true)}
        />
        {popoverOnClick && (
          <OverlayPanel
            ref={(el) => {
              this.op = el;
            }}
          >
            <img alt={alt} src={src || defaultUserLogo} />
          </OverlayPanel>
        )}
      </>
    );
  }
}

UserIcon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  popoverOnClick: PropTypes.bool,
};

UserIcon.defaultProps = {
  src: null,
  popoverOnClick: false,
};

export default UserIcon;
