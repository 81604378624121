import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchCursoDetails = async (id) => {
  const res = await axios.get(`${API_HOST}/academico/cursos/${id}/details`);
  return res.data;
};

export const createCurso = async (params) => {
  const res = await axios.post(`${API_HOST}/academico/cursos`, params);
  return res.data;
};

export const updateCurso = async (id, params) => {
  const res = await axios.put(`${API_HOST}/academico/cursos/${id}`, params);
  return res.data;
};

export const deleteCurso = async (id) => {
  const res = await axios.delete(`${API_HOST}/academico/cursos/${id}`);
  return res.data;
};

export const fetchCoursesDetailList = (params) =>
  axios
    .get(`${API_HOST}/academico/cursos/detail_list`, { params })
    .then((response) => response.data);
