const CursoReducerV2 = (state, action) => {
  switch (action.type) {
    case 'SET_CURSO':
      return {
        ...state,
        curso: action.payload,
      };
    case 'SET_PARCEIRO':
      return {
        ...state,
        parceiro: action.payload,
      };
    case 'SET_CURRICULO':
      return {
        ...state,
        curriculo: action.payload,
      };
    case 'SET_TURMA':
      return {
        ...state,
        turma: action.payload,
      };
    default:
      return state;
  }
};

export default CursoReducerV2;
