/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const Input = ({ label, className, onChange, required, tooltip, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  return (
    <div className={`input-type-style ${className}`}>
      <div className={meta.error ? 'input-error red' : 'input-style'}>
        <input
          {...field}
          {...props}
          onChange={(event) => {
            setFieldValue(field.name, event.target.value);
            if (onChange) {
              onChange(event.target.value);
            }
          }}
        />
        <ReactTooltip className="input-tooltip" multiline place="right" />
        <label
          htmlFor={field.name}
          className={`
            input-label-style
            ${meta.error ? 'red' : 'dim'}
            ${required ? 'input-required' : ''}
          `}
        >
          {label}
          {tooltip && (
            <span className="ml-1" data-tip={tooltip}>
              <FontAwesomeIcon icon="question-circle" />
            </span>
          )}
        </label>
      </div>
      {meta.error && <span className="input-helper red">{meta.error}</span>}
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  onChange: null,
  label: '',
  className: '',
  tooltip: '',
  required: false,
};

export default Input;
