/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

type InputSelectProps = {
  className?: string;
  error?: string;
  label?: string;
  name: string;
  onChange: (val: any) => void;
  required?: boolean;
  placeholder?: string;
};

const InputSelect = ({
  className,
  error,
  label,
  onChange,
  required,
  ...props
}: InputSelectProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className={`input-type-style ${className}`}>
      <Select
        {...field}
        className={error ? 'input-error' : 'dropdown-style'}
        classNamePrefix="dropdown"
        isClearable
        placeholder="Buscar..."
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        cacheOptions={false}
        onChange={(val) => {
          setFieldValue(field.name, val);
          onChange(val);
        }}
        {...props}
      />
      <label
        htmlFor={field.name}
        className={`
          input-label-style
          ${meta.error && meta.touched ? 'red' : 'dim'}
          ${required ? 'input-required' : ''}
        `}
      >
        {label}
      </label>
      {meta.error && meta.touched && (
        <span className="input-helper red">{meta.error}</span>
      )}
    </div>
  );
};

InputSelect.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputSelect.defaultProps = {
  className: 'w-100',
  error: '',
  label: '',
  onChange: () => {},
  required: false,
};

export default InputSelect;
