import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HeaderIcon = ({ onClick, icon }) => (
  <li className="navigation-notification clickable" onClick={onClick}>
    <FontAwesomeIcon icon={icon} />
  </li>
);

export default HeaderIcon;
