import { SUITE_SHARE_ID } from 'consts';
import { useEffect, useState } from 'react';

const useSuiteShare = () => {
  const [suiteShareEnabled, handlesuiteShareEnabled] = useState(false);

  const suiteShareScripAlreadyCreated = () => {
    return document.getElementById('suiteshare');
  };

  const suiteShareInit = () => {
    if (!suiteShareEnabled && !suiteShareScripAlreadyCreated()) {
      var share = document.createElement('script');
      share.async = true;
      share.id = 'suiteshare';
      share.src = 'https://static.suiteshare.com/widgets.js';
      share.setAttribute('init', SUITE_SHARE_ID);
      document.head.appendChild(share);
      handlesuiteShareEnabled(true);
    }
  };

  useEffect(() => {
    if (SUITE_SHARE_ID) {
      suiteShareInit();
    }
    // eslint-disable-next-line
  }, [suiteShareEnabled]);

  suiteShareInit();
};

export default useSuiteShare;
