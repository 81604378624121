import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';
import RemessaDetailsPage from './RemessaDetailsPage';

const GenerateRemessaPage = lazy(() =>
  retry(() => import('./GenerateRemessaPage'))
);
const RemessasPage = lazy(() => retry(() => import('./RemessasPage')));

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/remessas/new"
      permission="financeiro.gerar_remessa"
      component={GenerateRemessaPage}
    />
    <PrivateRoute
      exact
      path="/remessas"
      permission="financeiro.view_any_lancamento"
      component={RemessasPage}
    />
    <PrivateRoute
      exact
      path="/remessas/:id"
      permission="financeiro.view_any_lancamento"
      component={RemessaDetailsPage}
    />
  </Switch>
);
