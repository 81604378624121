import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../assets/img/logo.svg';
import Button from '../../components/common/buttons/Button';
import SidebarItem from './SidebarItem';
import SidebarItemOption from './SidebarItemOption';

class Sidebar extends React.Component {
  state = {
    collapsed: false,
    isSidebarOpen: false,
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  updateDimensions = () => {
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;

    this.setState({
      collapsed: width <= mediumSizeScreen,
    });
  };

  setWrappedRef = (node) => {
    this.wrappedRef = node;
  };

  collapseSidebar = () => {
    const { collapsed } = this.state;
    const { onCollapse } = this.props;

    this.setState({
      collapsed: !collapsed,
    });

    onCollapse();
  };

  handleClickOutside = (event) => {
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;

    if (
      this.wrappedRef &&
      !this.wrappedRef.contains(event.target) &&
      width < mediumSizeScreen
    ) {
      this.setState({
        collapsed: true,
      });
    }
  };

  logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  goToHomepage = () => {
    this.props.history.push('/');
    this.close();
  };

  close = () => {
    this.setState({ isSidebarOpen: false, collapsed: true });
  };

  shouldForceSidebarItemOpening(section) {
    const {
      location: { pathname },
    } = this.props;
    return !!section.options.find((d) => d.url === pathname);
  }

  render() {
    const { menu } = this.props;
    const { collapsed, isSidebarOpen } = this.state;
    const width = window.innerWidth;
    const smallSizeScreen = 768;

    if (width > smallSizeScreen) {
      return (
        <aside
          ref={this.setWrappedRef}
          className={`sidebar-base ${collapsed ? 'collapsed' : ''}`}
        >
          <ul className="menu-list">
            {menu.sections.map((section) => (
              <SidebarItem
                key={section.id}
                label={section.name}
                icon={section.icon}
                collapsed={collapsed}
                forceOpening={this.shouldForceSidebarItemOpening(section)}
              >
                {section.options.map((option) => (
                  <SidebarItemOption key={option.id} to={option.url || ''}>
                    {option.name}
                  </SidebarItemOption>
                ))}
              </SidebarItem>
            ))}
          </ul>
          <button
            type="button"
            className="btn-none sidebar-action"
            onClick={this.collapseSidebar}
          >
            <FontAwesomeIcon
              icon={collapsed ? faAngleDoubleRight : faAngleDoubleLeft}
              className="icon-sidebar"
            />
            {!collapsed && <span className="sidebar-text">Ocultar Menu</span>}
          </button>
        </aside>
      );
    }
    return (
      <nav className="nav-mobile">
        <button
          type="button"
          className="btn-none"
          onClick={() =>
            this.setState({ isSidebarOpen: true, collapsed: false })
          }
        >
          <FontAwesomeIcon icon={faBars} className="font-22 white" />
        </button>
        <div className="logo">
          <Link to="/">
            <img
              width={120}
              src={logo}
              className="navigation-logo"
              alt="CMMG"
            />
          </Link>
        </div>
        {isSidebarOpen && (
          <ul className={`menu-list ${isSidebarOpen ? 'open' : null}`}>
            <div className="menu-elements mobile-elements">
              {menu.sections.map((section) => (
                <SidebarItem
                  key={section.id}
                  label={section.name}
                  icon={section.icon}
                  collapsed={collapsed}
                >
                  {section.options.map((option) => (
                    <SidebarItemOption
                      key={option.id}
                      to={option.url || ''}
                      onClick={this.close}
                    >
                      {option.name}
                    </SidebarItemOption>
                  ))}
                </SidebarItem>
              ))}
              <div className="d-flex flex-direction-column align-items-flex-start">
                <Button
                  onClick={this.goToHomepage}
                  action="none"
                  style={{ marginLeft: -2 }} // Align home icon with sign out icon
                >
                  <FontAwesomeIcon icon="home" className="primary mr-2" />
                  <b>Início</b>
                </Button>
                <Button onClick={this.logout} action="none">
                  <FontAwesomeIcon
                    icon="sign-out-alt"
                    className="primary mr-2"
                  />
                  <b>Sair</b>
                </Button>
              </div>
            </div>
            <button
              type="button"
              className="btn-none p-2 close-sidebar"
              onClick={this.close}
            >
              <FontAwesomeIcon icon="times" />
              <span className="ml-2">Fechar</span>
            </button>
          </ul>
        )}
      </nav>
    );
  }
}

Sidebar.propTypes = {
  menu: PropTypes.shape({
    name: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        icon: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Sidebar);
