import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BOLETOS_SERVICE_HOST } from '../../consts';
import RemessaContext from '../../contexts/Remessa/RemessaContext';
import DownloadCardButton from '../common/buttons/DownloadCardButton';

const RemessaDownloadButton = () => {
  const { remessa, fetchingRemessa } = useContext(RemessaContext);
  const [url, setUrl] = useState(null);
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    if (remessa.s3_key) {
      axios
        .get(`${BOLETOS_SERVICE_HOST}/conciliation/signed-url`, {
          params: {
            key: remessa.s3_key,
            method: 'get',
            download: remessa.titulo || remessa.s3_key,
          },
        })
        .then((response) => {
          setAvailable(true);
          setUrl(response.data.url);
        })
        .catch(() => {
          setAvailable(false);
        });
    }
  }, [remessa]);

  if (fetchingRemessa) {
    return <Skeleton width={250} height={50} />;
  }

  if (!available) {
    return <i>Arquivo indisponível.</i>;
  }

  return (
    <DownloadCardButton link={url} disabled={!remessa.s3_key || !url}>
      {remessa.titulo}
    </DownloadCardButton>
  );
};

RemessaDownloadButton.propTypes = {
  remessa: PropTypes.shape({
    titulo: PropTypes.string,
    key: PropTypes.string,
  }),
};

RemessaDownloadButton.defaultProps = {
  remessa: {},
};

export default RemessaDownloadButton;
