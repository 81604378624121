import React, { useEffect, useReducer, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Card from '../../components/Card';
import ComparisonItem from '../../components/common/ComparisonItem';
import ExamineGraduationProcessReducer from '../../reducers/ExamineGraduationProcessReducer';
import {
  fetchApplications,
  retryApplication,
  undoApplication,
  fetchGraduationProcess,
} from '../../services/graduation-process-services';
import { DropdownMenuButton, DropdownMenuItem } from 'common/dropdown-menu';
import ContentModal from '../../components/common/ContentModal';
import Input from '../../components/Input';
import { renderListFilter } from '../../utils';
import PendencyList from './PendencyList';

const ExamineGraduationProcess = ({ match, history }) => {
  const { id } = match.params;
  const initialState = {
    loadingApplications: true,
    isPendenciesModalOpen: false,
    applications: [],
    application: {},
    graduationProcess: {},
    search: null,
  };

  const [state, dispatch] = useReducer(
    ExamineGraduationProcessReducer,
    initialState
  );
  const inProgressStatus = { id: 1, name: 'Em progresso', color: '' };
  const iscancelledStatus = { id: 4, name: 'Cancelado', color: '' };

  const {
    applications,
    application,
    isPendenciesModalOpen,
    loadingApplications,
    graduationProcess,
    search,
  } = state;

  const getApplications = useCallback(() => {
    dispatch({ type: 'SET_LOADING_APPLICATIONS', payload: true });
    fetchApplications(id)
      .then((data) => {
        dispatch({ type: 'SET_APPlICATIONS', payload: data });
      })
      .catch(() => {
        toast.error('Ocorreu um erro. Por favor tente novamente.');
        dispatch({ type: 'SET_LOADING_APPLICATIONS', payload: false });
      });
  }, [id]);

  const getGraduationProcess = useCallback(() => {
    fetchGraduationProcess(id).then((data) => {
      dispatch({ type: 'SET_GRADUATION_PROCESS', payload: data });
    });
  }, [id]);

  useEffect(() => {
    getApplications();
    getGraduationProcess();
  }, [getApplications, getGraduationProcess]);

  const handleRefresh = () => {
    getApplications();
    getGraduationProcess();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchApplications(id).then((data) => {
        dispatch({ type: 'SET_APPlICATIONS', payload: data });
      });
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);

  const handleRetryApplication = (application_id) => {
    const applicationsCopy = [...applications];
    const applicationsStatusChanged = applications.map((app) =>
      app.id === application_id ? { ...app, status: inProgressStatus } : app
    );
    dispatch({
      type: 'SET_APPlICATIONS',
      payload: applicationsStatusChanged,
    });
    retryApplication(id, application_id)
      .then(() => {})
      .catch(() =>
        dispatch({
          type: 'SET_APPlICATIONS',
          payload: applicationsCopy,
        })
      );
  };

  const handleUndoApplication = (application_id) => {
    const applicationsCopy = [...applications];
    const applicationsStatusChanged = applications.map((app) =>
      app.id === application_id
        ? {
            ...app,
            status: iscancelledStatus,
          }
        : app
    );
    dispatch({ type: 'SET_APPlICATIONS', payload: applicationsStatusChanged });
    undoApplication(id, application_id)
      .then(() => {})
      .catch(() =>
        dispatch({
          type: 'SET_APPlICATIONS',
          payload: applicationsCopy,
        })
      );
  };

  const showPendenciesModal = (application_id) => {
    const selectedApplication = applications.find(
      (app) => app.id === application_id
    );
    dispatch({
      type: 'SHOW_PENDENCIES_MODAL',
      payload: selectedApplication,
    });
  };

  const getPendenciesLength = (app) =>
    size(app.pendencies) + size(app.warnings);

  return (
    <>
      <Card title={`Processo de formação ${id}`}>
        <div className="d-flex justify-space-between">
          <div>
            <p className="font-16 font-weight-600">
              <span className="text-black">
                {get(graduationProcess, 'curriculum.name', '-')}
              </span>
            </p>
            <p className="brown">
              Data de conclusão:
              <span className="text-black ml-1">
                {get(graduationProcess, 'conclusion_date', '-')}
              </span>
            </p>
            <p className="brown">
              Data da colação de grau:
              <span className="text-black ml-1">
                {get(graduationProcess, 'graduation_cerimony_date', '-')}
              </span>
            </p>
            <p className="brown">
              Data de expedição do diploma:
              <span className="text-black ml-1">
                {get(graduationProcess, 'degree_issue_date', '-')}
              </span>
            </p>
          </div>
        </div>
        <ComparisonItem
          actionButtonClassName="white is-icon"
          list={applications}
          loading={loadingApplications}
          title={`Alunos selecionados (${
            loadingApplications ? '-' : applications.length
          })`}
          feedbackText="Ocorreu um erro, por favor recarregue a página."
          onClick={handleRefresh}
          buttonLabel={
            <FontAwesomeIcon icon="redo" spin={loadingApplications} />
          }
          disabledActionButton={loadingApplications}
        >
          <div className="px-2">
            <Input
              name="search"
              icon="search"
              placeholder="Buscar aluno..."
              onChange={(e) =>
                dispatch({ type: 'SET_SEARCH_VALUE', payload: e.target.value })
              }
            />
          </div>
          <div className="list-comparison-container">
            {renderListFilter(
              applications,
              [
                'student_curriculum.student.ra',
                'student_curriculum.student.name',
              ],
              search
            ).map((app) => (
              <div key={app.id} className="list-comparison-view">
                <p className="m-0">
                  {get(app, 'student_curriculum.student.ra')}
                  <span className="font-weight-600 ml-2">
                    {get(app, 'student_curriculum.student.name')}
                  </span>
                </p>
                <div className="d-flex align-items-center position-relative">
                  <span
                    style={{ color: get(app, 'status.name') }}
                    className="mr-2 font-weight-600"
                  >
                    {get(app, 'status.name')}
                  </span>
                  <DropdownMenuButton>
                    {get(app, 'permissions.retry') && (
                      <DropdownMenuItem
                        onClick={() => handleRetryApplication(app.id)}
                      >
                        Tentar Novamente
                      </DropdownMenuItem>
                    )}
                    {get(app, 'permissions.undo') && (
                      <DropdownMenuItem
                        onClick={() => handleUndoApplication(app.id)}
                      >
                        Cancelar formação
                      </DropdownMenuItem>
                    )}
                    {getPendenciesLength(app) > 0 && (
                      <DropdownMenuItem
                        onClick={() => showPendenciesModal(app.id)}
                      >
                        Ver pendências ({getPendenciesLength(app)})
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem
                      onClick={() =>
                        history.push(
                          `/historico-escolar/por-curriculo-aluno/${app.student_curriculum.id}?new=true`
                        )
                      }
                    >
                      Ver histórico
                    </DropdownMenuItem>
                  </DropdownMenuButton>
                </div>
              </div>
            ))}
          </div>
        </ComparisonItem>
        <div className="mt-3 d-flex justify-flex-end">
          <Link to="/graduacao/processos" className="btn btn-link brown">
            Voltar
          </Link>
        </div>
      </Card>
      <ContentModal
        header={`Lista de pendências do(a) aluno(a) ${get(
          application,
          'student_curriculum.student.name',
          '...'
        )}`}
        visible={isPendenciesModalOpen}
        onHide={() => dispatch({ type: 'HIDE_PENDENCIES_MODAL' })}
      >
        <>
          <PendencyList
            pendencies={application.pendencies}
            title="Impeditivas"
          />
          <div className="mb-2" />
          <PendencyList
            pendencies={application.warnings}
            title="Não impeditivas"
          />
        </>
      </ContentModal>
    </>
  );
};

ExamineGraduationProcess.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(ExamineGraduationProcess);
