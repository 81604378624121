import axios from 'axios';
import { API_HOST } from '../../consts';

export const fetchRemessasSerasaList = (params) =>
  axios
    .get(`${API_HOST}/serasa/remessas`, { params })
    .then((response) => response.data.results);

export const fetchRemessaSerasaLancamentos = (params) =>
  axios
    .get(
      `${API_HOST}/serasa/lancamentos${
        params.remessa_type ? '/' + params.remessa_type : ''
      }`,
      { params }
    )
    .then((response) => response.data);

export const fetchRemessasSerasa = (params) =>
  axios
    .get(`${API_HOST}/serasa/remessas`, { params })
    .then((response) => response.data);

export const getExcelSerasaReport = (params) =>
  axios
    .get(`${API_HOST}/serasa/lancamentos/excel`, { params })
    .then((response) => response);

export const fetchRemessaSerasaDetail = (id) =>
  axios
    .get(`${API_HOST}/serasa/remessas/${id}`)
    .then((response) => response.data);

export const generateRemessaSerasa = (params) =>
  axios
    .post(`${API_HOST}/serasa/remessas`, params)
    .then((response) => response.data);

export const fetchRetornoSerasaList = (params) =>
  axios
    .get(`${API_HOST}/serasa/retornos`, { params })
    .then((response) => response.data);
