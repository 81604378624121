/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const InputCheckboxWithValue = ({
  label,
  className,
  disabled,
  onChange,
  value,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <label
      className={`container-check-custom ${className} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {`${label}`}
      <input
        type="checkbox"
        {...props}
        {...field}
        checked={value ? field.value === value : field.value}
        onChange={(event) => {
          if (value) {
            setFieldValue(field.name, event.target.checked ? value : null);
          } else {
            setFieldValue(field.name, event.target.checked);
          }
          if (onChange) {
            onChange(event.target.checked);
          }
        }}
      />
      <span className="checkmark" />
      {meta.error && <span className="input-helper red">{meta.error}</span>}
    </label>
  );
};

InputCheckboxWithValue.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

InputCheckboxWithValue.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  onChange: () => {},
  value: null,
};

export default InputCheckboxWithValue;
