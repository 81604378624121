import React from 'react';
import PropTypes from 'prop-types';
import { size, map } from 'lodash';

const PendencyList = ({ pendencies, title }) => {
  const length = size(pendencies);

  return (
    length > 0 && (
      <>
        <h4 className="mb-2 semi-bold">
          {title} ({length})
        </h4>
        <div className="list-pendencies">
          {map(pendencies, (pendency) => (
            <p key={pendency} className="list-pendencies-item">
              {pendency}
            </p>
          ))}
        </div>
      </>
    )
  );
};

PendencyList.propTypes = {
  pendencies: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

PendencyList.defaultProps = {
  pendencies: [],
};

export default PendencyList;
