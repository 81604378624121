import axios from 'axios';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

const withDownloadButton = (WrappedComponent) =>
  class Hoc extends Component {
    state = {
      downloading: false,
    };

    handleDownload = ({ api, params, filename }) => {
      this.setState({ downloading: true });
      axios
        .get(api, {
          params,
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao fazer o download do arquivo. Por favor, tente novamente.'
          );
        })
        .then(() => {
          this.setState({ downloading: false });
        });
    };

    render() {
      const { downloading } = this.state;
      return (
        <WrappedComponent
          onDownload={this.handleDownload}
          downloading={downloading}
          {...this.props}
        />
      );
    }
  };

export default withDownloadButton;
