import React from 'react';
import { Scope, withFormState } from 'informed';
import { get } from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import InputTextAreaInformed from './InputTextAreaInformed';
import Button from './common/buttons/Button';
import { API_HOST } from '../consts';
import InputAsyncInformed from './InputAsyncInformed';
import DepartmentInput from './DepartmentInput';

const ForwardingForm = ({ onSubmit, submitting, formState }) => {
  const department = get(formState.values, 'forwarding.department');
  const message = get(formState.values, 'forwarding.message');
  const forwarded_to = get(formState.values, 'forwarding.forwarded_to.id');

  const fetchDepartments = (search) => {
    if (department) {
      return axios
        .get(`${API_HOST}/declarations/departments/${department.id}/users`, {
          params: {
            search,
            permission: 'tickets-get_forwarding',
            is_active: true,
            page_size: 100,
            ordering: 'last_name',
          },
        })
        .then((response) => response.data.results);
    }
    return Promise.resolve([]);
  };

  const submitButtonIsDisabled =
    submitting || !department || !message || !forwarded_to;

  return (
    <>
      <div className="mb-3">
        <Scope scope="forwarding">
          <div className="mb-3">
            <DepartmentInput />
            <div className="mt-1" />
            {department && (
              <InputAsyncInformed
                // Key is required to trigger loadOptions after a department change
                key={department.id}
                defaultOptions
                field="forwarded_to"
                placeholder="Destinatário"
                loadOptions={fetchDepartments}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.id}
              />
            )}
          </div>
          <InputTextAreaInformed
            field="message"
            placeholder="Justifique o encaminhamento"
          />
          <div className="mt-3">
            <InputTextAreaInformed
              field="private_message"
              placeholder="Observação ( visível apenas para administrativo )"
            />
          </div>
        </Scope>
      </div>
      <div className="mt-3" />
      <Button
        disabled={submitButtonIsDisabled}
        action="primary"
        onClick={onSubmit}
      >
        ENCAMINHAR
      </Button>
    </>
  );
};

ForwardingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  formState: PropTypes.shape({
    values: PropTypes.object,
  }).isRequired,
};

ForwardingForm.defaultProps = {
  submitting: false,
};

export default withFormState(ForwardingForm);
