import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchCityState from '../../../services/city_services';

const CityInputSelect = ({ params, submitOnChange, ...props }) => {
  const { submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions params={params} fetchOptions={fetchCityState}>
      {({ options, isLoading }) => (
        <InputSelect
          name="city"
          label="Cidade"
          placeholder="Selecionar"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          onChange={() => submitOnChange && submitForm()}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

CityInputSelect.propTypes = {
  params: PropTypes.objectOf(PropTypes.node),
  submitOnChange: PropTypes.bool,
};

CityInputSelect.defaultProps = {
  params: {},
  submitOnChange: false,
};

export default CityInputSelect;
