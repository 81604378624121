import React from 'react';
import PropTypes from 'prop-types';

const ChatBubble = ({ children, isAuthor }) => (
  <div className={`chat-bubble ${isAuthor ? 'is-author' : ''}`}>{children}</div>
);

ChatBubble.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthor: PropTypes.bool.isRequired,
};

export default ChatBubble;
