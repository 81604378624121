import React from 'react';
import LoadingIndicator from './LoadingIndicator';
import PrivateRouteWrapper from './PrivateRouteWrapper';

const SuspenseFallback = () => (
  <PrivateRouteWrapper>
    <div className="position-relative">
      <div className="centered">
        <LoadingIndicator />
      </div>
    </div>
  </PrivateRouteWrapper>
);

export default SuspenseFallback;
