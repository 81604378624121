import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../ConfirmationModal';
import Button from './Button';

const ButtonWithConfirmation = ({
  yesText,
  noText,
  onClick,
  confirmationText,
  children,
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const handleConfirm = () => {
    setConfirming(true);
    onClick().then(() => {
      setConfirming(false);
      setModalOpen(false);
    });
  };

  return (
    <>
      <Button {...rest} onClick={() => setModalOpen(true)}>
        {children}
      </Button>
      <ConfirmationModal
        visible={modalOpen}
        loading={confirming}
        onHide={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        confirmText={yesText}
        hideText={noText}
      >
        {confirmationText}
      </ConfirmationModal>
    </>
  );
};

ButtonWithConfirmation.propTypes = {
  yesText: PropTypes.node,
  noText: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  confirmationText: PropTypes.node,
  children: PropTypes.node.isRequired,
};

ButtonWithConfirmation.defaultProps = {
  yesText: 'sim',
  noText: 'não',
  confirmationText: 'Confirmar?',
};

export default ButtonWithConfirmation;
