import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Talk from 'talkjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CHAT_ID } from '../consts';
import useChatSupportUser from '../hooks/useChatSupportUser';

const PopupChat = (props) => {
  const [numeroNotificacoes, setNumeroNotificacoes] = useState(0);
  const [hideChatButton, setHideChatButton] = useState(false);
  const [signature, supportUser] = useChatSupportUser();
  const buttonRef = useRef(null);
  const sessionRef = useRef(null);
  const popupRef = useRef(null);

  const { currentUser } = props;

  const loadChat = useCallback(() => {
    Talk.ready.then(() => {
      const me = new Talk.User({
        id: currentUser.id,
        name: `${currentUser.name} (${currentUser.username})`,
        role: 'User',
      });
      sessionRef.current = new Talk.Session({
        appId: CHAT_ID,
        me,
        signature,
      });
      const other = new Talk.User(supportUser);
      const users = [];
      users.push(supportUser.id);
      users.push(currentUser.id);
      const conversationId = users.sort().join('_');
      const conversation = sessionRef.current.getOrCreateConversation(
        conversationId
      );
      conversation.setParticipant(me);
      conversation.setParticipant(other);

      popupRef.current = sessionRef.current.createPopup(conversation, {
        keepOpen: false,
        showCloseInHeader: true,
        launcher: 'never',
      });

      popupRef.current.mount({ show: false });
      popupRef.current.on('close', () => setHideChatButton(false));

      sessionRef.current.unreads.on('change', (conversationIds) => {
        const counter = conversationIds.length;
        setNumeroNotificacoes(counter);
      });
    });

    if (buttonRef.current) {
      buttonRef.current.addEventListener('click', (e) => {
        e.preventDefault();
        setHideChatButton(true);
        popupRef.current.show();
      });
    }

    return () => {
      if (popupRef.current) {
        popupRef.current.destroy();
      }
    };
  }, [
    currentUser.id,
    currentUser.username,
    currentUser.name,
    signature,
    supportUser,
  ]);

  useEffect(() => {
    if (signature && supportUser) {
      loadChat();
    }
  }, [signature, supportUser, loadChat]);

  return (
    <>
      <div
        ref={buttonRef}
        className="chat-popup-button"
        style={{ display: hideChatButton ? 'none' : 'initial' }}
      >
        <FontAwesomeIcon icon="comment" />
        <span>CHAT</span>
        {numeroNotificacoes !== 0 && (
          <p className="chat-popup-counter">{numeroNotificacoes}</p>
        )}
      </div>
    </>
  );
};

export default PopupChat;

PopupChat.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
};

PopupChat.defaultProps = {
  currentUser: { id: null, name: '', username: '' },
};
