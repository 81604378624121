import React from 'react';
import PropTypes from 'prop-types';
import ContentModal from '../common/ContentModal';
import ContaCorrenteTable from './ContaCorrenteTable';

const ContaCorrenteModal = ({ showContaCorrenteModal, onHide }) => (
  <ContentModal
    header="Conta Corrente do Aluno:"
    visible={showContaCorrenteModal}
    onHide={onHide}
  >
    <ContaCorrenteTable />
  </ContentModal>
);

ContaCorrenteModal.propTypes = {
  showContaCorrenteModal: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

ContaCorrenteModal.defaultProps = {
  showContaCorrenteModal: false,
};

export default ContaCorrenteModal;
