import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import PopUps from '../components/layout/PopUps';
import { API_HOST } from '../consts';
import { fetchNotices } from '../services/list_services';

const withWarnings = (WrappedComponent) =>
  class HOC extends Component {
    state = {
      warnings: [],
    };

    componentDidMount() {
      this.fetchWarnings();
    }

    handleConfirm = async (id) => {
      const popUpId = id;
      const { warnings } = this.state;
      try {
        await axios.post(`${API_HOST}/notices/${popUpId}/confirm`);
        const popUpWarnings = await warnings.filter((cp) => cp.id !== popUpId);
        this.setState({
          warnings: popUpWarnings,
        });
      } catch {
        toast.error('Ocorreu um erro ao confirmar leitura do aviso.');
      }
    };

    fetchWarnings = async () => {
      const res = await fetchNotices({
        popup: true,
        page_size: 10,
        read: false,
      });
      const popUpWarnings = res.results;
      this.setState({
        warnings: popUpWarnings,
      });
    };

    render() {
      const { warnings } = this.state;

      return (
        <Fragment>
          <PopUps
            popUps={warnings}
            title="Avisos importantes"
            confirmText="Confirmar leitura"
            onConfirm={this.handleConfirm}
          />
          <WrappedComponent {...this.props} />
        </Fragment>
      );
    }
  };

export default withWarnings;
