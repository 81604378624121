import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '../Card';
import Button from '../common/buttons/Button';
import DataForm from '../formik_inputs/DataForm';
import InputCurrency from '../formik_inputs/InputCurrency';
import InputDate from '../formik_inputs/InputDate';
import TextArea from '../formik_inputs/TextArea';
import MetodoPagamentoInput from '../formik_inputs/custom-inputs/MetodoPagamentoInput';
import ContaBancariaInput from '../formik_inputs/custom-inputs/ContaBancariaInput';
import InputFile from '../formik_inputs/InputFile';
import FichaFinanceiraDetails from './FichaFinanceiraDetails';
import ContaState from '../../contexts/conta/ContaState';
import LancamentoDetails from './LancamentoDetails';

const NewPagamento = ({ onSubmit, contaId, cancelUrl }) => (
  <Card title="Adicionar pagamento (baixa manual)">
    <ContaState contaId={contaId}>
      <FichaFinanceiraDetails />
    </ContaState>
    <hr />
    <div>
      <LancamentoDetails />
    </div>
    <h3 className="brown font-weight-600 mb-2">Dados do pagamento</h3>
    <DataForm autoSetErrors onSubmit={onSubmit}>
      {(formik) => (
        <>
          <div className="form-grid-1">
            <InputDate
              name="pago_em"
              label="Data de pagamento"
              className="w-25 w-100-mobile"
            />
            <InputCurrency
              name="valor_pago"
              label="Valor pago"
              className="w-25 w-100-mobile"
            />
            <TextArea name="justificativa" label="Justificativa" />
            <MetodoPagamentoInput className="w-50 w-100-mobile" />
            <ContaBancariaInput className="w-50 w-100-mobile" />
            <InputFile
              name="comprovante_key"
              label="Comprovante"
              module="comprovantes"
              showDeleteConfirmation={false}
            />
          </div>
          <div className="mt-3 text-right">
            <Link className="btn btn-link" to={cancelUrl}>
              Cancelar
            </Link>
            <Button
              action="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Adicionar
            </Button>
          </div>
        </>
      )}
    </DataForm>
  </Card>
);

NewPagamento.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  contaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  cancelUrl: PropTypes.string.isRequired,
};

export default NewPagamento;
