import axios from 'axios';
import { API_HOST } from '../consts';

const prefix = 'academico';

class TermYearsApi {
  constructor() {
    this.endpoint = 'term_years';
  }

  list = async (params) => {
    const response = await axios.get(`${API_HOST}/${prefix}/${this.endpoint}`, {
      params,
    });
    return response.data;
  };
}

class AcademicDegreeApi {
  constructor() {
    this.endpoint = 'academic_degrees';
  }

  list = async (params) => {
    const response = await axios.get(`${API_HOST}/${prefix}/${this.endpoint}`, {
      params,
    });
    return response.data;
  };
}

export { TermYearsApi, AcademicDegreeApi };

export const fetchAttendedPeriods = () =>
  axios
    .get(`${API_HOST}/academico/students/me/attended-periods`)
    .then((response) => response.data);

export const fetchStudentClassesOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/student_classes`, { params })
    .then((response) => response.data);

export const fetchAreaOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/areas`, { params })
    .then((response) => response.data);

export const fetchGrauOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/graus`, { params })
    .then((response) => response.data);

export const fetchCursoStatusOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/curso_status`, { params })
    .then((response) => response.data);

export const fetchCursoVinculadoOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/cursos_vinculados`, { params })
    .then((response) => response.data);

export const fetchTurnoOptions = (params) =>
  axios
    .get(`${API_HOST}/academico/turnos`, { params })
    .then((response) => response.data);

export const fetchTurmaDisciplinaAlunoSupplementary = (params) =>
  axios
    .get(`${API_HOST}/academico/turma_disciplina_aluno/supplementary_data`, {
      params,
    })
    .then((response) => response.data.results);

export const fetchTurmaDisciplina = (params) =>
  axios
    .get(`${API_HOST}/academico/turma_disciplina`, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const fitStudents = (data) =>
  axios
    .post(`${API_HOST}/academico/turma_disciplina_aluno/fit_students`, data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
