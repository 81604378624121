import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingIndicator = ({ center }) => {
  const renderLoadingIndicator = () => (
    <FontAwesomeIcon spin className="loading-indicator" icon="circle-notch" />
  );
  if (center) {
    return (
      <div className="d-flex justify-center">{renderLoadingIndicator()}</div>
    );
  }
  return renderLoadingIndicator();
};

LoadingIndicator.propTypes = {
  center: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  center: false,
};

export default LoadingIndicator;
