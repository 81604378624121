import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BooleanIcon = ({ value }) => (
  <FontAwesomeIcon
    className={value ? 'success' : 'danger'}
    icon={value ? 'check-circle' : 'times-circle'}
  />
);

BooleanIcon.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default BooleanIcon;
