import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import LoadingIndicator from '../LoadingIndicator';
import Lancamento from './Lancamento';
import DefinitionList from '../DefinitionList';
import lancamentoContext from '../../contexts/lancamento/LancamentoContext';
import HistoryIcon from '../../common/data-display/HistoryIcon';
import { PermissionContext } from '../../hoc/withPermission';

const LancamentoDetails = ({ onDeleteDesconto }) => {
  const {
    lancamento,
    lancamentoLoading: loading,
    logs,
    discountLogs,
  } = useContext(lancamentoContext);
  const pago = !!lancamento.pago_em;
  const competencia = `${lancamento.mes_competencia}/${lancamento.ano_competencia}`;
  const responsavel = get(lancamento, 'responsavel.name', '-');

  return (
    <PermissionContext.Consumer>
      {({ hasPermission }) => (
        <div>
          {loading && <LoadingIndicator />}
          {!loading && (
            <div>
              <div>
                {lancamento.classificacao && (
                  <DefinitionList
                    title="Classificação"
                    text={lancamento.classificacao.nome}
                  />
                )}
                {lancamento.justificativa && (
                  <DefinitionList
                    title="Justificativa"
                    text={lancamento.justificativa}
                    tag="pre"
                  />
                )}
              </div>
              <div className="lancamento-details">
                <Lancamento
                  operacao="D"
                  valor={lancamento.valor_bruto}
                  descricao={`${lancamento.tipo.descricao} (${competencia})`}
                />
                <span className="gray-dark font-12">
                  <strong>Responsável:</strong> {responsavel}
                </span>
                {lancamento.descontos.map((desconto) => (
                  <Lancamento
                    operacao="C"
                    valor={desconto.valor}
                    descricao={desconto.tipo.descricao}
                    canExclude={hasPermission('financeiro.delete_desconto')}
                    onExclude={() => onDeleteDesconto(desconto.id)}
                  />
                ))}
                {lancamento.valor_desconto_pontualidade !== 'R$ 0,00' && (
                  <Lancamento
                    operacao="C"
                    valor={lancamento.valor_desconto_pontualidade}
                    descricao="Desconto por pontualidade"
                  />
                )}
                {lancamento.valor_acrescimo !== 'R$ 0,00' && (
                  <Lancamento
                    operacao="D"
                    valor={lancamento.valor_acrescimo}
                    descricao="Multa e juros"
                  />
                )}
                <hr />
                <Lancamento
                  operacao={pago ? 'C' : '*'}
                  valor={
                    pago ? lancamento.valor_pago : lancamento.valor_liquido
                  }
                  descricao={pago ? 'Liquidação' : ''}
                />
              </div>
              <hr />
              <h4 className="semi-bold font-16">Histórico</h4>
              {logs.map((log) => (
                <div className="mb-1" key={log.history_id}>
                  <p className="mb-0">
                    {`
                      Alterado para "${get(log, 'status.nome')}"
                      em ${log.history_date}
                      ${
                        get(log, 'history_user.nome')
                          ? `por ${get(log, 'history_user.nome')}`
                          : ''
                      }
                    `}
                  </p>
                  <span className="gray-dark font-12">
                    <pre>
                      <strong>Motivo:</strong> {log.history_change_reason}
                    </pre>
                  </span>
                </div>
              ))}
              {logs.length === 0 && <p>Nenhuma alteração</p>}
              <h4 className="semi-bold font-16">Histórico de descontos</h4>
              {discountLogs.map((discountLog) => (
                <div
                  className="mb-1 d-flex justify-space-between mt-1"
                  key={discountLog.history_id}
                >
                  <div>
                    <span className="brown">Ação:</span>{' '}
                    <HistoryIcon type={discountLog.history_type} />
                  </div>
                  <div>
                    <span className="brown">Valor:</span> {discountLog.valor}
                  </div>
                  <div>
                    <span className="brown">Tipo:</span>{' '}
                    {get(discountLog, 'tipo.descricao', '-')}
                  </div>
                  <div>
                    <span className="brown">Usuário:</span>{' '}
                    {get(discountLog, 'history_user.nome', '-')}
                  </div>
                  <div>
                    <span className="brown">Data:</span>{' '}
                    {discountLog.history_date}
                  </div>
                </div>
              ))}
              {discountLogs.length === 0 && (
                <p>Nenhuma alteração nos descontos</p>
              )}
            </div>
          )}
        </div>
      )}
    </PermissionContext.Consumer>
  );
};

LancamentoDetails.propTypes = {
  onDeleteDesconto: PropTypes.func.isRequired,
};

export default LancamentoDetails;
