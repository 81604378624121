import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import React from 'react';
import Button from './common/buttons/Button';

const ConfirmationModal = ({
  children,
  visible,
  loading,
  onHide,
  onConfirm,
  confirmText,
  hideText,
  header,
  footer,
  ...rest
}) => (
  <Dialog
    dismissableMask
    modal
    header={header}
    visible={visible}
    onHide={onHide}
    footer={
      footer && (
        <>
          {hideText && (
            <Button action="default" onClick={onHide}>
              {hideText}
            </Button>
          )}
          <Button disabled={loading} onClick={onConfirm}>
            {confirmText}
          </Button>
        </>
      )
    }
    {...rest}
  >
    {children}
  </Dialog>
);

ConfirmationModal.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  footer: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  hideText: PropTypes.string,
  header: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  children: null,
  visible: false,
  loading: false,
  hideText: 'Não',
  confirmText: 'Sim',
  header: 'Confirmação',
  footer: true,
};

export default ConfirmationModal;
