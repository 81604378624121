import React from 'react';
import { fetchAllCourses } from '../services/options_services';
import InputAsyncInformed from './InputAsyncInformed';

const CourseInputAsync = ({ placeholderOption, params, ...props }) => (
  <InputAsyncInformed
    defaultOptions
    placeholder="Curso"
    loadOptions={(search) =>
      fetchAllCourses({ search, ...params }).then((data) =>
        placeholderOption ? [placeholderOption, ...data] : data
      )
    }
    {...props}
  />
);

export default CourseInputAsync;
