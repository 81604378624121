const ExamineGraduationProcessReducer = (state, action) => {
  switch (action.type) {
    case 'SET_APPlICATIONS':
      return {
        ...state,
        applications: action.payload,
        loadingApplications: false,
      };

    case 'SHOW_PENDENCIES_MODAL':
      return {
        ...state,
        isPendenciesModalOpen: true,
        application: action.payload,
      };

    case 'HIDE_PENDENCIES_MODAL':
      return {
        ...state,
        isPendenciesModalOpen: false,
        application: {},
      };

    case 'SET_GRADUATION_PROCESS':
      return {
        ...state,
        graduationProcess: action.payload,
      };

    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        search: action.payload,
      };
    case 'SET_LOADING_APPLICATIONS':
      return {
        ...state,
        loadingApplications: action.payload,
      };
    default:
      return state;
  }
};

export default ExamineGraduationProcessReducer;
