import React from 'react';
import PropTypes from 'prop-types';
import TabsNav from './TabsNav';
import TabsMenuLink from './TabsMenuLink';
import Skeleton from 'react-loading-skeleton';

const TabsMenu = ({ links, isLoading = false, ...rest }) => (
  <TabsNav {...rest}>
    {!isLoading &&
      links.map((link) => <TabsMenuLink key={link.to} {...link} />)}
    {isLoading && (
      <li>
        <Skeleton width={200} height={20} />
      </li>
    )}
  </TabsNav>
);

TabsMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TabsMenu;
