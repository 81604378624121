import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {
  DropdownMenuButton,
  DropdownMenuItem,
} from '../../common/dropdown-menu';
import BooleanIcon from '../common/BooleanIcon';

const PromotionProcessCard = ({
  className,
  promotionProcess,
  downloadPromotedExcel,
  downloadRetainedExcel,
  onProcessPromotion,
}) => (
  <div className={`${className} card-100 card-body`}>
    <div className="card-head-action-button d-flex justify-flex-end w-25 mt-1">
      <DropdownMenuButton>
        <DropdownMenuItem
          onClick={() => downloadPromotedExcel(promotionProcess.id)}
        >
          Excel alunos promoção
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => downloadRetainedExcel(promotionProcess.id)}
        >
          Excel alunos retidos
        </DropdownMenuItem>
        {promotionProcess.is_performed || (
          <DropdownMenuItem
            onClick={() => onProcessPromotion(promotionProcess.id)}
          >
            Efetivar promoção sugerida
          </DropdownMenuItem>
        )}
      </DropdownMenuButton>
    </div>
    <Link to={`/promocao/${promotionProcess.id}`}>
      <p className="semi-bold brown text-underline">
        {promotionProcess.curriculum?.name}
      </p>
    </Link>
    <div className="form-grid-2 pb-2">
      <span className="mr-4">
        <span className="semi-bold">Início:</span> {promotionProcess.start_at}
      </span>
      <span className="mr-4">
        <span className="semi-bold">Término:</span> {promotionProcess.end_at}
      </span>
      <span className="mr-4">
        <span className="semi-bold">Criado por:</span>{' '}
        {get(promotionProcess, 'user.nome')}
      </span>
      <span className="mr-4">
        <span className="semi-bold">Efetivado:</span>{' '}
        <BooleanIcon value={promotionProcess.is_performed} />
      </span>
    </div>
  </div>
);

PromotionProcessCard.propTypes = {
  className: PropTypes.string,
  promotionProcess: PropTypes.shape({
    id: PropTypes.number,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    is_performed: PropTypes.bool,
    curriculum: PropTypes.shape({
      name: PropTypes.string,
    }),
    user: PropTypes.shape({
      nome: PropTypes.string,
    }),
  }).isRequired,
  downloadPromotedExcel: PropTypes.func,
  downloadRetainedExcel: PropTypes.func,
  onProcessPromotion: PropTypes.func,
};

PromotionProcessCard.defaultProps = {
  className: '',
  downloadPromotedExcel: () => {},
  downloadRetainedExcel: () => {},
  onProcessPromotion: () => {},
};

export default PromotionProcessCard;
