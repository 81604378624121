import React from 'react';
import PropTypes from 'prop-types';

const Circle = ({ color, invisible, children }) => (
  <div
    style={{
      backgroundColor: invisible ? 'transparent' : color,
      color: invisible ? '#333' : '#fff',
      fontWeight: invisible ? 'none' : 'bold',
      borderRadius: '50%',
      width: '2em',
      height: '2em',
      lineHeight: '2em',
      padding: 0,
      textAlign: 'center',
    }}
  >
    {children}
  </div>
);

Circle.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  invisible: PropTypes.bool,
};

Circle.defaultProps = {
  color: '#ccc',
  children: null,
  invisible: false,
};

export default Circle;
