import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ActionDropdownAction from './ActionDropdownAction';
import ActionDropdownButton from './ActionDropdownButton';

const ActionDropdown = ({
  label = 'Ações',
  actions,
  onClick,
  className = '',
}) => {
  const [open, setOpen] = useState(false);
  const icon = open ? 'chevron-up' : 'chevron-down';

  const ref = useRef(null);
  // Closes menu on outside click
  useOutsideAlerter(ref, () => {
    setOpen(false);
  });
  if (actions.length === 0) {
    return null;
  }
  return (
    <div ref={ref} className={className}>
      <ActionDropdownButton type="button" onClick={() => setOpen(!open)}>
        <div className="d-flex justify-space-between">
          <span className="mr-1 text-capitalize font-weight-600 gray-dark">
            {label}
          </span>
          <FontAwesomeIcon icon={icon} />
        </div>
      </ActionDropdownButton>
      {open && (
        <div className="position-absolute z-index-1 basic-shadow bg-white">
          {actions.map((action) => (
            <ActionDropdownAction
              key={action.value}
              {...action}
              onClick={(value) => {
                onClick && onClick(value);
                setOpen(false);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

ActionDropdown.propTypes = {
  label: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ActionDropdown.defaultProps = {
  label: 'Ações',
  actions: [],
  className: '',
};

export default styled(ActionDropdown)`
  position: relative;
  display: inline-block;
`;
