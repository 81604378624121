import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { createPagamento } from '../../services/lancamentos/lancamentos_services';
import { toDate } from '../../utils';
import LancamentoState from '../../contexts/lancamento/LancamentoState';
import NewPagamento from '../../components/ficha-financeira/NewPagamento';

class NewPagamentoPage extends React.Component {
  get contaId() {
    const { match } = this.props;
    const { contaId } = match.params;
    return +contaId;
  }

  get lancamentoId() {
    const { match } = this.props;
    const { lancamentoId } = match.params;
    return +lancamentoId;
  }

  handleSubmit = async (values) => {
    const { history } = this.props;
    const data = {
      ...values,
      lancamento: this.lancamentoId,
      pago_em: toDate(values.pago_em),
      creditado_em: toDate(values.creditado_em),
      metodo_pagamento: get(values, 'metodo_pagamento.id'),
      conta_bancaria: get(values, 'conta_bancaria.id'),
    };
    await createPagamento({ data });
    history.push(
      `/fichas-financeiras/${this.contaId}?new_lancamento=${this.lancamentoId}`
    );
  };

  render() {
    return (
      <LancamentoState id={this.lancamentoId}>
        <NewPagamento
          onSubmit={this.handleSubmit}
          cancelUrl={`/fichas-financeiras/${this.contaId}`}
          contaId={this.contaId}
        />
      </LancamentoState>
    );
  }
}

NewPagamentoPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      contaId: PropTypes.string,
    }),
  }).isRequired,
};

export default NewPagamentoPage;
