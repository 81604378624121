import React from 'react';
import { fetchDepartments } from '../services/options_services';
import FetchInputSelectOptions from './FetchInputSelectOptions';
import InputSelectInformed from './InputSelectInformed';

const DepartmentInput = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchDepartments}>
    {({ options, isLoading }) => (
      <InputSelectInformed
        field="department"
        placeholder="Departamento"
        options={options}
        isLoading={isLoading}
        getOptionLabel={(d) => d.name}
        getOptionValue={(d) => d.id}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);

export default DepartmentInput;
