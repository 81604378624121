import { SET_CONTA, SET_CONTA_LOADING } from './types';

const ContaReducer = (state, action) => {
  switch (action.type) {
    case SET_CONTA:
      return {
        ...state,
        conta: action.payload,
      };

    case SET_CONTA_LOADING:
      return {
        ...state,
        contaLoading: action.payload,
      };

    default:
      return state;
  }
};

export default ContaReducer;
