import React from 'react';
import { Switch } from 'react-router';
import PrivateRoute from '../../components/PrivateRoute.tsx';
import SupportPage from './SupportPage';

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/suporte-chat"
      permission="login.can_answer_chat"
      component={SupportPage}
    />
  </Switch>
);
