import React from 'react';
import PropTypes from 'prop-types';

class ToCapitalizeName extends React.Component {
  renderName = (word) => {
    if (word.length > 2) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }

    return word;
  };

  render() {
    const { fullName } = this.props;
    const name = fullName.toLowerCase();
    const words = name.split(' ');
    return <span className="name">{words.map(this.renderName).join(' ')}</span>;
  }
}

ToCapitalizeName.propTypes = {
  fullName: PropTypes.string,
};

ToCapitalizeName.defaultProps = {
  fullName: '',
};

export default ToCapitalizeName;
