/* eslint-disable jsx-a11y/label-has-associated-control */
import classnames from 'classnames';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Textarea from 'react-textarea-autosize';

const TextArea = ({ className, onChange, label, help, required, ...rest }) => {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();

  return (
    <div className={`input-type-style ${className}`}>
      {label && (
        <label
          className={classnames('input-label-style font-12', {
            'input-required': required,
            red: meta.error && meta.touched,
            dim: !meta.error,
          })}
        >
          {label}
        </label>
      )}
      <div className="input-style">
        <Textarea
          minRows={2}
          onChange={(e) => {
            setFieldValue(field.name, e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          {...rest}
          {...field}
        />
      </div>
      {meta.error && meta.touched && (
        <span className="input-helper red">{meta.error}</span>
      )}
    </div>
  );
};

TextArea.propTypes = {
  error: PropTypes.string,
  help: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  className: '',
  error: '',
  help: '',
  label: '',
  onChange: null,
  required: false,
};

export default TextArea;
