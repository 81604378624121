import { get } from 'lodash';

export default class InformedDiscAppicationSerializer {
  constructor(ticketInfo) {
    this.ticketInfo = ticketInfo;
  }

  getDetails() {
    return [
      {
        key: 'Disciplina escolhida para dispensa',
        value: get(this.ticketInfo, 'discipline.name'),
      },
      { key: 'Nome', value: get(this.ticketInfo, 'completed_discipline_name') },
      { key: 'Instituição', value: get(this.ticketInfo, 'institution_name') },
      { key: 'Nota', value: get(this.ticketInfo, 'grade') },
      { key: 'Carga horária', value: get(this.ticketInfo, 'hours') },
      {
        key: 'Ano de conclusão',
        value: get(this.ticketInfo, 'completed_discipline_year'),
      },
    ];
  }
}
