import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PermissionContext } from '../../hoc/withPermission';
import ChatLine from '../ChatLine';
import CollapsedHelpText from '../common/buttons/CollapsedHelpText';
import CopyToClipboardButton from '../common/buttons/CopyToClipboardButton';
import DownloadCardButton from '../common/buttons/DownloadCardButton';
import BoletoStatus from './BoletoStatus';
import CreateBoletoButton from './CreateBoletoButton';

const BOLETO = gql`
  query Boleto($id: ID!) {
    boleto(id: $id) {
      id
      pdfUrl
      status
      formattedTypableLine
      bankAnswerDate
      logs {
        detailedMessage
      }
    }
  }
`;

const BoletoChatLine = ({ id, ticketId, onCreateBoleto }) => {
  const { loading, error, data } = useQuery(BOLETO, {
    variables: { id },
    errorPolicy: 'ignore',
  });
  const boleto = get(data, 'boleto') || {};
  const renderCreateBoletoButton = () => (
    <span className="ml-1">
      <PermissionContext.Consumer>
        {({ hasPermission }) =>
          hasPermission('tickets.add_ticket_boleto') && (
            <CreateBoletoButton
              ticketId={ticketId}
              onCreateBoleto={onCreateBoleto}
            />
          )
        }
      </PermissionContext.Consumer>
    </span>
  );

  if (get(boleto, 'logs', []).length > 0) {
    return (
      <ChatLine message={<strong>Boleto</strong>}>
        <div className="d-flex align-items-center">
          <div>
            {boleto.logs.map((log) => (
              <p key={log.id}>{log.detailedMessage}.</p>
            ))}
          </div>
          {renderCreateBoletoButton()}
        </div>
      </ChatLine>
    );
  }

  if (error) {
    return (
      <ChatLine message={<strong>Boleto</strong>}>
        <div className="d-flex align-items-center">
          <div>Ocorreu um erro ao carregar o boleto.</div>
          {renderCreateBoletoButton()}
        </div>
      </ChatLine>
    );
  }

  if (!id) {
    return (
      <ChatLine message={<strong>Boleto</strong>}>
        <div className="d-flex align-items-center">
          <div>
            O boleto será enviado por email. Por favor, aguarde.
            <br />
            Após o envio, ele também ficará disponível para consulta aqui.
          </div>
          {renderCreateBoletoButton()}
        </div>
      </ChatLine>
    );
  }

  if (error || loading || boleto.status === 'UNAVAILABLE') {
    return (
      <ChatLine message={<strong>Boleto</strong>}>
        <div className="d-flex align-items-center">
          <span>
            Por favor, aguarde alguns instantes até que o boleto seja gerado.
          </span>
          {renderCreateBoletoButton()}
        </div>
      </ChatLine>
    );
  }

  return (
    <>
      <div className="d-flex flex-wrap align-items-center">
        <ChatLine message={<strong>Boleto</strong>}>
          <div className="d-flex align-items-center">
            <DownloadCardButton link={boleto.pdfUrl}>
              boleto.pdf
            </DownloadCardButton>
            <span className="ml-2">
              <CopyToClipboardButton
                tooltip={boleto.formattedTypableLine}
                content={boleto.formattedTypableLine}
              />
            </span>
            {renderCreateBoletoButton()}
          </div>
        </ChatLine>
        <div className="ml-2 ml-0--is-mobile">
          <div className="mb-1">
            <BoletoStatus status={boleto.status} />
          </div>
          {boleto.status === 'PENDING' && (
            <CollapsedHelpText text="Já paguei o boleto">
              O boleto pode levar até 3 dias úteis para ser compensado. Caso
              conste como pendente, após este prazo, entre em contato com a
              Central de Relacionamento.
            </CollapsedHelpText>
          )}
        </div>
      </div>
    </>
  );
};

BoletoChatLine.propTypes = {
  id: PropTypes.number,
  ticketId: PropTypes.number.isRequired,
  onCreateBoleto: PropTypes.func.isRequired,
};

BoletoChatLine.defaultProps = {
  id: null,
};

export default BoletoChatLine;
