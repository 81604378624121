import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';
import AbsenceNoticeTicketPage from './AbsenceNoticeTicketPage';

const ExpiredAttendanceTicketPage = lazy(() =>
  retry(() => import('./ExpiredAttendanceTicketPage'))
);
const StudentAttendanceReportPage = lazy(() =>
  retry(() => import('./student-attendance-report/StudentAttendanceReportPage'))
);
const ExpiredAttendancesRedirectPage = lazy(() =>
  retry(() => import('./ExpiredAttendancesRedirectPage'))
);
const TeacherExpiredAttendances = lazy(() =>
  retry(() => import('./TeacherExpiredAttendances'))
);
const AcademicDepartmentExpiredAttendances = lazy(() =>
  retry(() => import('./AcademicDepartmentExpiredAttendances'))
);
const StudentsAttendancePage = lazy(() =>
  retry(() => import('./StudentsAttendancePage'))
);
const AttendanceReviewPage = lazy(() =>
  retry(() => import('./AttendanceReviewPage'))
);
const AcademicDepartmentAttendanceReviewFormPage = lazy(() =>
  retry(() => import('./AcademicDepartmentAttendanceReviewFormPage'))
);
const TeacherAttendanceReviewFormPage = lazy(() =>
  retry(() => import('./TeacherAttendanceReviewFormPage'))
);
const StudentAttendanceReviewFormPage = lazy(() =>
  retry(() => import('./StudentAttendanceReviewFormPage'))
);
const AttendanceReviewFormRedirectPage = lazy(() =>
  retry(() => import('./AttendanceReviewFormRedirectPage'))
);
const TeacherAbsenceNotice = lazy(() =>
  retry(() => import('./TeacherAbsenceNotice'))
);
const AcademicAbsenceNotice = lazy(() =>
  retry(() => import('./AcademicAbsenceNotice'))
);

const AbsenceNoticeRedirectPage = lazy(() =>
  retry(() => import('./AbsenceNoticeRedirectPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/extrato-frequencias"
      component={StudentAttendanceReportPage}
    />
    <PrivateRoute
      exact
      path="/extrato-frequencias/:disciplineValidity(disciplinas-anteriores|disciplinas-atuais)"
      component={() => <Redirect to="/extrato-frequencias" />}
    />
    <PrivateRoute
      exact
      path="/frequencias"
      permission="academico.view_frequenciadiaria"
      component={StudentsAttendancePage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-frequencia/new"
      permission="attendances.add_review"
      component={AttendanceReviewFormRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-frequencia/new/controle-academico"
      permission={[
        'attendances.add_review',
        'tickets.add_ticket_as_academic_department',
      ]}
      component={AcademicDepartmentAttendanceReviewFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-frequencia/new/professor"
      permission={['attendances.add_review', 'tickets.add_ticket_as_teacher']}
      component={TeacherAttendanceReviewFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-frequencia/new/aluno"
      permission={['attendances.add_review', 'tickets.add_ticket_as_student']}
      component={StudentAttendanceReviewFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-frequencia/:id"
      permission="attendances.view_review"
      component={AttendanceReviewPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/frequencia-fora-do-prazo/new"
      component={ExpiredAttendancesRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/frequencia-fora-do-prazo/new/professor"
      permission="tickets.add_ticket_as_teacher"
      component={TeacherExpiredAttendances}
    />
    <PrivateRoute
      exact
      path="/protocolos/frequencia-fora-do-prazo/new/controle-academico"
      permission="tickets.add_ticket_as_academic_department"
      component={AcademicDepartmentExpiredAttendances}
    />
    <PrivateRoute
      exact
      path="/protocolos/comunicado-ausencia-atividades-docentes/new"
      component={AbsenceNoticeRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/comunicado-ausencia-atividades-docentes/new/professor"
      permission="tickets.add_ticket_as_teacher"
      component={TeacherAbsenceNotice}
    />
    <PrivateRoute
      exact
      path="/protocolos/comunicado-ausencia-atividades-docentes/new/controle-academico"
      permission="tickets.add_ticket_as_academic_department"
      component={AcademicAbsenceNotice}
    />
    <PrivateRoute
      exact
      path="/protocolos/comunicado-ausencia-atividades-docentes/:id"
      permission="attendances.view_absencenoticeticket"
      component={AbsenceNoticeTicketPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/frequencia-fora-do-prazo/:id"
      permission="attendances.view_expiredattendanceticket"
      component={ExpiredAttendanceTicketPage}
    />
  </Switch>
);
