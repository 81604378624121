import axios from 'axios';
import API_HOST from 'consts';
import React, { useReducer, createContext } from 'react';
import { fetchCursoDetails } from 'services/curso-services';
import { fetchParceiro } from 'services/core';
import CursoReducerV2 from '../../reducers/cursos/CursoReducerV2';
import { fetchCurriculoFromCourse } from 'services/curricula-services';
import { fetchClassWithDisciplines } from 'services/class-services';

const initialState = {
  curso: {},
  parceiro: {},
  curriculo: { disciplines: [] },
  turma: {},
};

export const CursoStateV2Context = createContext(initialState);

const CursoStateV2 = ({ children }) => {
  const [state, dispatch] = useReducer(CursoReducerV2, initialState);
  const { curso, parceiro, curriculo, turma } = state;

  const clearStates = () => {
    dispatch({ type: 'SET_CURSO', payload: {} });
    dispatch({ type: 'SET_PARCEIRO', payload: {} });
    dispatch({ type: 'SET_CURRICULO', payload: {} });
    dispatch({ type: 'SET_TURMA', payload: {} });
  };

  const fetchPreviewCodigoCurso = async () => {
    return axios
      .get(`${API_HOST}/academico/cursos/preview_codigo`)
      .then((res) => res.data);
  };

  const fetchPreviewCodigoParceiro = async () => {
    return axios
      .get(`${API_HOST}/core/partners/preview_codigo`)
      .then((res) => res.data);
  };

  const updateCurso = async (cursoId, payload) => {
    return axios
      .patch(`${API_HOST}/academico/cursos/${cursoId}/update_v2`, payload)
      .then((res) => {
        dispatch({ type: 'SET_CURSO', payload: res.data });
        return res.data;
      });
  };

  const saveCurso = async (data) => {
    if (!curso.id) {
      return axios
        .post(`${API_HOST}/academico/cursos/create_v2`, data)
        .then((res) => {
          dispatch({ type: 'SET_CURSO', payload: res.data });
          return res.data;
        });
    }
    return axios
      .put(`${API_HOST}/academico/cursos/${curso.id}/update_v2`, data)
      .then((res) => {
        dispatch({ type: 'SET_CURSO', payload: res.data });
        return res.data;
      });
  };

  const setCurso = async (id) => {
    fetchCursoDetails(id).then((data) => {
      dispatch({ type: 'SET_CURSO', payload: data });
      if (data.partner) {
        setParceiro(data.partner.id);
      }
    });
  };

  const saveParceiro = async (data) => {
    if (!parceiro.id) {
      return axios.post(`${API_HOST}/core/partners`, data).then((res) => {
        dispatch({ type: 'SET_PARCEIRO', payload: res.data });
        return res.data;
      });
    }

    return axios
      .patch(`${API_HOST}/core/partners/${parceiro.id}`, data)
      .then((res) => {
        dispatch({ type: 'SET_PARCEIRO', payload: res.data });
        return res.data;
      });
  };

  const setParceiro = async (id) => {
    fetchParceiro(id).then((data) =>
      dispatch({
        type: 'SET_PARCEIRO',
        payload: {
          ...data,
          vigencia_contrato:
            data.vigencia_contrato && new Date(data.vigencia_contrato),
        },
      })
    );
  };

  const saveCurriculo = async (data) => {
    if (!curriculo.id) {
      return axios
        .post(`${API_HOST}/curricula/curricula`, data)
        .then((res) => dispatch({ type: 'SET_CURRICULO', payload: res.data }));
    }
    return axios
      .put(`${API_HOST}/curricula/curricula/${curriculo.id}`, data)
      .then((res) => dispatch({ type: 'SET_CURRICULO', payload: res.data }));
  };

  const setCurriculo = (course_id) => {
    fetchCurriculoFromCourse(course_id).then((data) =>
      dispatch({ type: 'SET_CURRICULO', payload: data })
    );
  };

  const saveTurma = async (data) => {
    if (!turma.id) {
      return axios
        .post(`${API_HOST}/academico/turmas/createV2`, data)
        .then((res) => dispatch({ type: 'SET_TURMA', payload: res.data }));
    }
    return axios
      .put(`${API_HOST}/academico/turmas/${turma.id}/updateV2`, data)
      .then((res) => dispatch({ type: 'SET_TURMA', payload: res.data }));
  };
  const setTurma = (id) => {
    fetchClassWithDisciplines(id).then((res) =>
      dispatch({
        type: 'SET_TURMA',
        payload: {
          ...res.data,
          validity_start_date:
            res.data.validity_start_date &&
            new Date(res.data.validity_start_date),
          validity_end_date:
            res.data.validity_end_date && new Date(res.data.validity_end_date),
        },
      })
    );
  };

  return (
    <CursoStateV2Context.Provider
      value={{
        curso,
        parceiro,
        curriculo,
        turma,
        saveCurso,
        saveParceiro,
        saveCurriculo,
        setTurma,
        setCurso,
        setParceiro,
        setCurriculo,
        updateCurso,
        saveTurma,
        clearStates,
        fetchPreviewCodigoCurso,
        fetchPreviewCodigoParceiro,
      }}
    >
      {children}
    </CursoStateV2Context.Provider>
  );
};

export default CursoStateV2;
