import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const ClassRelocationFormRedirectPage = lazy(() =>
  retry(() => import('./ClassRelocationFormRedirectPage'))
);
const AcademicDepartmentClassRelocationFormPage = lazy(() =>
  retry(() => import('./AcademicDepartmentClassRelocationFormPage'))
);
const ClassRelocationPage = lazy(() =>
  retry(() => import('./ClassRelocationPage'))
);
const StudentClassRelocationFormPage = lazy(() =>
  retry(() => import('./StudentClassRelocationFormPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/protocolos/troca-turma/new"
      component={ClassRelocationFormRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/troca-turma/new/controle-academico"
      component={AcademicDepartmentClassRelocationFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/troca-turma/new/aluno"
      component={StudentClassRelocationFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/troca-turma/:id"
      permission="attendances.view_review"
      component={ClassRelocationPage}
    />
  </Switch>
);
