import React from 'react';
import AsyncSelect from 'react-select/lib/Async';

const InputAsync = (props) => {
  const { error, help } = props;
  return (
    <>
      <AsyncSelect
        isClearable
        placeholder="Buscar..."
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        cacheOptions={false}
        {...props}
      />
      {error && <span className="input-helper red">{error}</span>}
      {error && help && <br />}
      {help && <span className="input-helper">{help}</span>}
    </>
  );
};

export default InputAsync;
