import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const CronogramaAulasEditPage = lazy(() =>
  retry(() => import('./CronogramaAulasEditPage'))
);
const CronogramaAulasViewPage = lazy(() =>
  retry(() => import('./CronogramaAulasViewPage'))
);
const CronogramaDisciplinasPage = lazy(() =>
  retry(() => import('./CronogramaDisciplinasPage'))
);
const CronogramaTurmasPage = lazy(() =>
  retry(() => import('./CronogramaTurmasPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/cronograma"
      permission="schedules.view_schedule"
      component={CronogramaDisciplinasPage}
    />
    <PrivateRoute
      exact
      path="/cronograma/:cronogramaId/turma-disciplinas"
      permission="schedules.view_schedule"
      component={CronogramaTurmasPage}
    />
    <PrivateRoute
      exact
      path="/cronograma/:cronogramaId/turma-disciplinas/:classDisciplineId/edit"
      permission="schedules.change_schedule"
      component={CronogramaAulasEditPage}
    />
    <PrivateRoute
      exact
      path="/cronograma/:cronogramaId/turma-disciplinas/:classDisciplineId"
      permission="schedules.view_schedule"
      component={CronogramaAulasViewPage}
    />
  </Switch>
);
