import { get } from 'lodash';

export default class ExpiredAtendanceSerializer {
  constructor(ticketInfo) {
    this.ticketInfo = ticketInfo;
  }

  getLessons(lessons) {
    return lessons.map((l) => ({
      key: l.time,
      value: '',
    }));
  }

  getDetails() {
    const { clss, discipline, course, teacher } = this.ticketInfo;
    const lessons = get(this.ticketInfo, 'lessons');
    const details = [
      { key: 'Frequência fora do prazo', value: '' },
      {
        key: `${clss || '-'} - ${discipline || '-'} - ${course || '-'}`,
        value: '',
      },
      { key: 'Professor', value: teacher || '-' },
    ];
    if (lessons) {
      return [...details, ...this.getLessons(lessons)];
    }
    return details;
  }
}
