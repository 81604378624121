import React from 'react';
import PropTypes from 'prop-types';

const DropdownMenuItem = ({ children, onClick, className }) => (
  <li className={`dropdown-menu-item ${className}`}>
    <button type="button" onClick={onClick}>
      {children}
    </button>
  </li>
);

DropdownMenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

DropdownMenuItem.defaultProps = {
  className: '',
  children: null,
  onClick: () => {},
};

export default DropdownMenuItem;
