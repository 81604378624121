import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { Props } from 'react-select/lib/components';

interface InputSelectProps<T> extends Props<T> {
  className?: string;
  error?: string;
  label?: string;
  required?: boolean;
  name?: string;
  placeholder?: string;
}

const InputSelect = <T extends object>({
  className,
  error,
  label,
  name,
  required,
  ...rest
}: InputSelectProps<T>) => (
  <div className={`input-type-style ${className}`}>
    <Select
      className={error ? 'input-error' : 'dropdown-style'}
      classNamePrefix="dropdown"
      isClearable
      placeholder="Buscar..."
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Nenhum resultado encontrado'}
      cacheOptions={false}
      name={name}
      {...rest}
    />
    {label && (
      <label
        htmlFor={name}
        className={classnames('input-label-style', {
          'input-required': required,
        })}
      >
        {label}
      </label>
    )}
    {error && <span className="input-helper">{error}</span>}
  </div>
);

InputSelect.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

InputSelect.defaultProps = {
  className: 'w-100',
  error: '',
  label: '',
  name: '',
  required: false,
};

export default InputSelect;
