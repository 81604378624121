import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../assets/img/logo-green.svg';
import InputText from '../../components/data-entry/InputText';
import LoadingIndicator from '../../components/LoadingIndicator';
import { API_HOST, MOODLE_HOST } from '../../consts';

const MoodleLoginPage = ({ location, history }) => {
  const formRef = useRef(null);
  const error = false;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { errorcode } = queryString.parse(location.search);
    switch (errorcode) {
      case '3':
        toast.error('Login e/ou senha incorretos.');
        break;
      case undefined:
        break;
      default:
        toast.error('Ocorreu um erro ao autenticar com o moodle.');
    }
  }, [location]);

  useEffect(() => {
    const { errorcode } = queryString.parse(location.search);

    Axios.get(`${API_HOST}/services/moodle/users/me`)
      .then((r) => {
        if (errorcode === undefined) {
          formRef.current.username.value = r.data.username;
          formRef.current.password.value = r.data.password;
          formRef.current.submit();
        } else {
          setLoading(false);
        }
      })
      .catch(() => history.push('/not-found'));
  }, [formRef, location, history]);

  return (
    <>
      {loading && (
        <div className="center-spinner">
          <LoadingIndicator />
        </div>
      )}
      <form
        className="login-card shadow"
        style={{ display: loading ? 'none' : 'block' }}
        ref={formRef}
        action={`${MOODLE_HOST}/login/index.php`}
        method="post"
        name="form"
        id="form"
      >
        <>
          <div className="login-background">
            <div className="login-card shadow">
              {error && (
                <div className="login-error">
                  <FontAwesomeIcon icon="times-circle" />
                  <span className="ml-2">{error}</span>
                </div>
              )}
              <div className="login-spacing">
                <figure className="text-center mt-2 mb-3">
                  <img src={logo} className="login-logo" alt="notification" />
                  <h2>Acesso ao Moodle</h2>
                </figure>
                <div className="mb-3 mt-4">
                  <InputText
                    label="Login"
                    type="text"
                    field="username"
                    name="username"
                    required
                  />
                </div>
                <div className="mb-3">
                  <InputText
                    label="Senha"
                    type="password"
                    field="password"
                    name="password"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary p-2 w-100"
                  name="Submit"
                  value="Login"
                >
                  Entrar
                </button>
              </div>
            </div>
          </div>
        </>
      </form>
    </>
  );
};

MoodleLoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(MoodleLoginPage);
