import React, { createContext, Component } from 'react';
import axios from 'axios';
import { API_HOST } from '../consts';
import { withRouter } from 'react-router-dom';

export const UserContext = createContext({
  id: '',
  name: '',
  username: '',
  email: '',
  photo: '',
  is_staff: false,
});

const withUser = (WrappedComponent) => {
  class Hoc extends Component {
    state = {
      id: '',
      username: '',
      email: '',
      name: '',
      photo: '',
      is_staff: false,
    };

    componentDidMount() {
      this.fetchUser();
    }

    fetchUser = () => {
      axios.get(`${API_HOST}/login/users/me`).then((response) => {
        if (
          response.data.require_password_change &&
          this.props.location.pathname !== '/alterar-senha-obrigatorio'
        ) {
          this.props.history.push('/alterar-senha-obrigatorio');
        }

        this.setState({
          id: response.data.id,
          username: response.data.username,
          email: response.data.email,
          name: response.data.nome,
          photo: response.data.photo_url,
          isStaff: response.data.is_staff,
        });
      });
    };

    render() {
      return (
        <UserContext.Provider value={this.state}>
          <WrappedComponent user={this.state} />
        </UserContext.Provider>
      );
    }
  }

  Hoc.propTypes = Component.propTypes;

  return withRouter(Hoc);
};

export default withUser;
