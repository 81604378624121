import PropTypes from 'prop-types';
import React from 'react';
import { toCurrency } from '../../utils';

const CurrencyLabel = ({ currency }) => {
  if (currency >= 0) {
    return <span>{`R$ ${toCurrency(currency)}`}</span>;
  }

  return <span>-</span>;
};

CurrencyLabel.propTypes = {
  currency: PropTypes.number,
};

CurrencyLabel.defaultProps = {
  currency: null,
};

export default CurrencyLabel;
