import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_HOST } from '../../consts';
import imgLogo from '../../assets/img/logo-green.svg';
import InputInformed from '../../components/InputInformed';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', submit: true };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submit: false });

    const data = new FormData();
    data.append('username', this.state.value);
    axios
      .post(`${API_HOST}/password-reset/`, data)
      .then(() => {
        const { history } = this.props;
        toast.success(
          'Um email contendo os passos para a redefinição de senha foi enviado.'
        );
        history.push('/login');
      })
      .catch(() => {
        const { history } = this.props;
        toast.error(
          'Ocorreu um erro e não foi possível enviar o email no momento.'
        );
        history.push('/login');
        this.setState({ submit: true });
      });
  }

  render() {
    const { submit } = this.state;
    return (
      <div className="login-background">
        <div className="login-card shadow">
          <div className="login-spacing">
            <figure className="text-center mt-2">
              <img src={imgLogo} className="login-logo" alt="notification" />
            </figure>
            <div className="group-input mt-3">
              <h4>
                Insira o seu login e enviaremos um link de redefinição de senha
                para seu email.
              </h4>
              <form onSubmit={this.handleSubmit}>
                <InputInformed
                  className="form-control login-mail"
                  type="text"
                  value={this.state.value}
                  onChange={this.handleChange}
                  placeholder="Login"
                />
                <div className="action-login mt-3">
                  <button
                    className="btn btn-primary p-2 w-100"
                    type="submit"
                    disabled={this.state.value.length === 0 || submit === false}
                  >
                    {submit ? 'REDEFINIR SENHA' : 'ENVIANDO...'}
                  </button>
                </div>
              </form>
            </div>
            <div className="action-login mt-2">
              <Link
                to="/login"
                className="btn btn-link"
                style={{ paddingLeft: 0 }}
              >
                CANCELAR
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
