import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchRaceOptions from '../../../services/race_services';

const RaceInputSelect = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchRaceOptions}>
    {({ options, isLoading }) => (
      <InputSelect
        name="race"
        label="Cor/Raça"
        placeholder="Selecionar"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);
export default RaceInputSelect;
