import React from 'react';
import InputMask from '../InputMask';

const CPFInputMask = ({ ...props }) => (
  <InputMask
    label="CPF"
    name="cpf"
    mask={[
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ]}
    {...props}
  />
);

export default CPFInputMask;
