import React from 'react';
import PropTypes from 'prop-types';

const TabsNavButton = ({ className, active, children, ...rest }) => (
  <li className={className}>
    <a className={active ? 'tab--active' : ''} {...rest}>
      {children}
    </a>
  </li>
);

TabsNavButton.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
};

TabsNavButton.defaultProps = {
  className: '',
  active: false,
  children: null,
};

export default TabsNavButton;
