import React from 'react';
import { Link } from 'react-router-dom';
import SVGS from '../../svg_files';

class Forbidden extends React.Component {
  render() {
    return (
      <div className="bg-errors">
        <div className="w-100 text-center">
          <h1 className="white">SINTO MUITO</h1>
          <p className="white">
            VOCÊ NÃO TEM PERMISSÃO PARA ACESSAR ESTA PÁGINA.
          </p>
          <div className="mb-3 mt-3">
            <SVGS name="forbidden" />
          </div>
          <Link className="btn btn-primary" to="/">
            VOLTAR À PÁGINA INICIAL
          </Link>
        </div>
      </div>
    );
  }
}

export default Forbidden;
