/* eslint-disable react/no-array-index-key */
import { ApolloProvider } from '@apollo/react-hooks';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import client from './apollo_client';
import SuspenseFallback from './components/SuspenseFallback';
import withMenu from './hoc/withMenu';
import withPermission from './hoc/withPermission';
import withTracker from './hoc/withTracker';
import withUser from './hoc/withUser';
import withWarnings from './hoc/withWarnings';
import NotFound from './pages/errors/NotFound';
import MoodleLoginPage from './pages/misc/MoodleLoginPage';
import * as routers from './pages/routers';

class Main extends React.Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common.Authorization = `JWT ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }

    let lastOffHourToastId = null;
    const { history } = props;
    const onSuccess = (response) => response;
    const onError = (e) => {
      const status = e.response && e.response.status;
      if (status === 403 && e.response.data.off_hour_message) {
        if (!toast.isActive(lastOffHourToastId)) {
          localStorage.removeItem('token');
          history.push('/login');
          lastOffHourToastId = toast.error(e.response.data.off_hour_message);
        }
      }
      return Promise.reject(e);
    };
    axios.interceptors.response.use(onSuccess, onError);
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <Suspense fallback={<SuspenseFallback />}>
          <Route exact path="/not-found" component={NotFound} />
          <Route exact path="/moodle-login" component={MoodleLoginPage} />
          {Object.values(routers).map((Router, i) => (
            <Router key={i} />
          ))}
        </Suspense>
      </ApolloProvider>
    );
  }
}

Main.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withWarnings(
  withTracker(withMenu(withPermission(withUser(withRouter(Main)))))
);
