import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DropdownMenuLink = ({ to, children, className }) => (
  <li className={`dropdown-menu-item ${className}`}>
    <Link to={to}>{children}</Link>
  </li>
);

DropdownMenuLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

DropdownMenuLink.defaultProps = {
  className: '',
};

export default DropdownMenuLink;
