import React, { useEffect, useReducer, useCallback } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import CurriculaContentContext from './CurriculaContentContext';
import CurriculaContentReducer from 'reducers/curricula/CurriculaContentReducer';
import { toast } from 'react-toastify';
import { Actions } from 'reducers/curricula/actions';
import {
  fetchCurriculoContent,
  createCurriculum,
} from 'services/curricula-services';
import { fetchDisciplinaDetails } from 'services/discipline-services';
import {
  createGrid,
  createGridDiscipline,
  deleteGridDiscipline,
  updateGriDisciplines,
} from 'services/class-services';
import { fetchGrids, fetchTerms } from 'services/options_services';

const CurriculaContentState = ({ children, match }) => {
  const history = useHistory();
  const { id } = match.params;
  const initialState = {
    terms: [],
    grids: [],
    termId: null,
    disciplines: [],
    curriculaContent: {},
    curriculaContentLoading: false,
    curriculumFilteredDisciplines: [],
  };

  const [state, dispatch] = useReducer(CurriculaContentReducer, initialState);

  const onSelectTerm = useCallback(async (data) => {
    try {
      let params = {};
      if (data) {
        dispatch({ type: Actions.SET_CURRICULA_DISCIPLINES, payload: true });
        params.term = data.value;
        params.curriculum = id;
        const data = await fetchDisciplinaDetails(params);
        dispatch({ type: Actions.SET_CURRICULA_DISCIPLINES, payload: data });
        dispatch({ type: Actions.SET_TERM_ID, payload: params.term });
      } else {
        dispatch({ type: Actions.SET_CURRICULA_DISCIPLINES, payload: false });
      }
    } catch (err) {
      toast.error('Não foi possivel carregar as disciplinas dessa etapa.');
    }
  }, []);

  const getCurriculaContent = useCallback(async () => {
    dispatch({ type: Actions.SET_CURRICULA_CONTENT_LOADING, payload: true });
    try {
      const data = await fetchCurriculoContent(id);
      const termsData = await fetchTerms({ curriculum: id });
      dispatch({ type: Actions.SET_CURRICULA_CONTENT, payload: data });
      dispatch({ type: Actions.SET_TERMS, payload: termsData });
      dispatch({ type: Actions.SET_CURRICULA_CONTENT_LOADING, payload: false });
      // If there is only one term, auto select it
      if (termsData.length === 1) {
        onSelectTerm(termsData[0].id);
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao carregar a página.');
      dispatch({ type: Actions.SET_CURRICULA_CONTENT_LOADING, payload: false });
    }
  }, [id]);

  const handleUpdateGridAndDiscipline = useCallback(
    async (gridDiscId, data) => {
      await updateGriDisciplines(gridDiscId, data);
      dispatch({ type: Actions.SET_CURRICULA_DISCIPLINES, payload: [] });
      await onSelectTerm({ id: termId });
    }
  );

  const handleCreateCurriculum = useCallback(async (data) => {
    const params = { ...data };
    let curriculum;
    curriculum = await createCurriculum(params);
    dispatch({ type: Actions.SET_CURRICULUM_ID, payload: curriculum.id });
    history.push(`/curriculo-grades/${curriculum.id}`);
  });

  const getGrids = useCallback(async (curriculum) => {
    const data = await fetchGrids({ curriculum });
    dispatch({ type: Actions.SET_GRIDS, payload: data });
  });

  const handleCreateGrid = useCallback(async (curriculumId, data) => {
    const params = { ...data, curriculum: curriculumId };
    await createGrid(params);
  });

  const handleCreateGridDiscipline = useCallback(async (data) => {
    await createGridDiscipline(data);
  });

  const handleDeleteGridDiscipline = useCallback(async (gridDiscId) => {
    await deleteGridDiscipline(gridDiscId);
  });

  useEffect(() => {
    if (!isNaN(id)) {
      getCurriculaContent();
    }
  }, [id, getCurriculaContent]);

  const {
    grids,
    terms,
    termId,
    curriculaContent,
    curriculumFilteredDisciplines,
  } = state;

  return (
    <CurriculaContentContext.Provider
      value={{
        id,
        grids,
        terms,
        getGrids,
        onSelectTerm,
        curriculaContent,
        handleCreateCurriculum,
        handleCreateGrid,
        getCurriculaContent,
        handleDeleteGridDiscipline,
        handleCreateGridDiscipline,
        handleUpdateGridAndDiscipline,
        curriculumFilteredDisciplines,
      }}
    >
      {children}
    </CurriculaContentContext.Provider>
  );
};

export default withRouter(CurriculaContentState);
