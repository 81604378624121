import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputMask from '../InputMask';

const PhoneInputMask = ({ ...props }) => {
  const phoneMask = createNumberMask({
    prefix: '',
    integerLimit: 13,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  });

  return (
    <InputMask name="phone" label="Telefone" mask={phoneMask} {...props} />
  );
};

export default PhoneInputMask;
