import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchSchoolType = async (params) => {
  const res = await axios.get(`${API_HOST}/censo/tipo_escola_ensino_medio`, {
    params,
  });
  return res.data;
};

export const fetchDeficiency = (params) =>
  axios.get(`${API_HOST}/censo/deficiencia`, { params }).then((response) =>
    response.data.map((d) => ({
      value: d.id,
      label: d.nome,
    }))
  );

export const fetchAdmissionMethod = async (params) => {
  const res = await axios.get(`${API_HOST}/censo/forma_ingresso`, { params });
  return res.data;
};

export const fetchTurno = async (params) => {
  const res = await axios.get(`${API_HOST}/censo/turno`, { params });
  return res.data;
};

export const fetchCampus = async (params) => {
  const res = await axios.get(`${API_HOST}/censo/campus`, { params });
  return res.data;
};
