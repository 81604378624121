import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  type,
  action,
  disabled = false,
  children,
  icon,
  className,
  size = 'medium',
  ...rest
}) => (
  // eslint-disable-next-line react/button-has-type
  <button
    type={type}
    className={`btn btn-${action} ${className} btn-${size}`}
    disabled={disabled}
    {...rest}
  >
    {icon && <FontAwesomeIcon icon={icon} />}
    <span>{children}</span>
  </button>
);

Button.propTypes = {
  action: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Button.defaultProps = {
  action: 'primary',
  children: '',
  type: 'button',
  className: '',
  icon: null,
  disabled: false,
  size: 'medium',
};

export default Button;
