import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_HOST } from '../../consts';
import imgLogo from '../../assets/img/logo-green.svg';
import InputInformed from '../../components/InputInformed';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', submit: true };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submit: false });

    const token = get(this.props, 'match.params.token');
    let data = new FormData();
    data.append('password', this.state.value);
    data.append('token', token);

    axios
      .post(`${API_HOST}/password-reset/confirm/`, data)
      .then(() => {
        const { history } = this.props;
        toast.success('Sua senha foi alterada com sucesso.');
        history.push('/login');
      })
      .catch((error) => {
        const isBadRequest = error.response && error.response.status === 404;
        const { history } = this.props;
        if (isBadRequest) {
          toast.error('Desculpe, esse link já foi utilizado ou expirou.');
          history.push('/forgot-password');
        } else {
          toast.error(
            'Ocorreu um erro e não foi possível redefinir sua senha no momento.'
          );
          history.push('/login');
        }

        this.setState({ submit: true });
      });
  }

  render() {
    const { submit } = this.state;
    return (
      <div className="login-background">
        <div className="login-card shadow">
          <div className="login-spacing">
            <figure className="text-center mt-2">
              <img src={imgLogo} className="login-logo" alt="notification" />
            </figure>
            <div className="group-input mt-3">
              <h4>Insira sua nova senha de acesso ao sistema</h4>
              <form onSubmit={this.handleSubmit}>
                <InputInformed
                  className="form-control login-mail"
                  type="password"
                  value={this.state.value}
                  onChange={this.handleChange}
                  placeholder="Nova senha"
                />
                <div className="action-login mt-3">
                  <button
                    className="btn btn-primary p-2 w-100"
                    type="submit"
                    disabled={this.state.value.length === 0 || submit === false}
                  >
                    {submit ? 'REDEFINIR SENHA' : 'REDEFININDO SENHA...'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ChangePassword);
