import { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'collapse':
      return { ...state, collapsed: true, arrow: 'right' };
    case 'uncollapse':
      return { ...state, collapsed: false, arrow: 'down' };
    default:
      throw new Error();
  }
};

function useCollapse(collapsedDefault) {
  const [state, dispatch] = useReducer(reducer, {
    collapsed: collapsedDefault,
    arrow: collapsedDefault ? 'right' : 'down',
  });

  const setCollapsed = () =>
    dispatch({ type: state.collapsed ? 'uncollapse' : 'collapse' });

  return [state.collapsed, state.arrow, setCollapsed];
}

export default useCollapse;
