import React, { Component, createContext } from 'react';
import { get } from 'lodash';
import debounce from 'debounce-promise';
import { toast } from 'react-toastify';

const sortedIds = {
  data: 'data',
  datetime: 'data',
  teacher: 'professor',
  course: 'curso',
  discipline: 'disciplina',
  class: 'turma',
  students: 'nr_alunos',
  status: 'status',
  id: 'id',
};

export const TableContext = createContext({
  page: 0,
  pageSize: 10,
  sorted: [],
  pages: 0,
  count: '-',
});

const withTable = (fetch, initialState = {}, mapFieldId = true) => (
  WrappedComponent
) =>
  class Table extends Component {
    // Uses debounce because of api calls that should start with filters
    // This allows onFilterChange to be called before fetchData
    fetchData = debounce(({ loading = true } = {}) => {
      if (loading) {
        this.setState({ loading: true });
      }

      const { page, pageSize, sorted, filters } = this.state;

      const { pk } = this.props;
      let response = null;

      if (pk) {
        response = fetch(pk, {
          page: page + 1,
          page_size: pageSize,
          ordering: sorted
            .map((field) => `${field.desc ? '-' : ''}${this.getFieldId(field)}`)
            .join(','),
          ...filters,
        });
      } else {
        response = fetch({
          page: page + 1,
          page_size: pageSize,
          ordering: sorted
            .map((field) => `${field.desc ? '-' : ''}${this.getFieldId(field)}`)
            .join(','),
          ...filters,
        });
      }
      response
        .then((data) => {
          this.setState({
            pages: data.total_pages,
            data: data.results,
            count: data.count,
            loading: false,
          });
        })
        .catch((error) => {
          const status = get(error, 'response.status', '-');
          const errors = get(error, 'response.data', '-');
          this.setState({
            loading: false,
          });
          switch (status) {
            case 400:
              Object.keys(errors).forEach((field) => {
                if (field === 'non_field_errors') {
                  toast.error(
                    <div>
                      {errors.non_field_errors.map((d) => (
                        <>
                          {d}
                          <br />
                        </>
                      ))}
                    </div>,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: false,
                    }
                  );
                } else {
                  try {
                    toast.error(errors[field][0]);
                  } catch (_) {
                    // DO NOTHING
                  }
                }
              });

              break;
            case 401:
              window.location.href = '/login';

              break;
            case 403:
              window.location.href = '/forbidden';
              errors.forEach((e) => toast.error(e));
              break;
            default:
              toast.error('Ocorreu um erro. Por favor, tente novamente.');

              break;
          }
        });
    }, 300);

    constructor(props) {
      super(props);
      this.state = {
        page: 0,
        pageSize: 10,
        sorted: [],
        pages: 0,
        filters: {},
        ...initialState,
      };
    }

    componentDidMount() {
      this.fetchData();
    }

    getFieldId = (field) => (mapFieldId ? sortedIds[field.id] : field.id);

    handlePageChange = (pageIndex) => {
      this.setState({ page: pageIndex }, this.fetchData);
    };

    handlePageSizeChange = (pageSize) => {
      this.setState({ pageSize, page: 0 }, this.fetchData);
    };

    handleSortedChange = (newSorted) => {
      this.setState({ sorted: newSorted }, this.fetchData);
    };

    handleFilterChange = (filters) => {
      this.setState({ filters, page: 0 }, this.fetchData);
    };

    render() {
      return (
        <TableContext.Provider
          value={{
            onPageChange: this.handlePageChange,
            onPageSizeChange: this.handlePageSizeChange,
            onSortedChange: this.handleSortedChange,
            page: this.state.page,
            pageSize: this.state.pageSize,
            sorted: this.state.sorted,
            pages: this.state.pages,
            data: this.state.data,
            loading: this.state.loading,
            count: this.state.count,
            filters: this.state.filters,
            manual: true,
            resizable: false,
          }}
        >
          <WrappedComponent
            {...this.props}
            fetchData={this.fetchData}
            onFilterChange={this.handleFilterChange}
          />
        </TableContext.Provider>
      );
    }
  };

export default withTable;
