import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReviewExplanation from '../ReviewExplanation';
import TicketActionForm from '../TicketActionForm';

const ExpiredAttendanceTicket = ({
  ticket,
  ticketInfo,
  onSubmit,
  onCancel,
  submitting,
  renderForwardings,
}) => {
  const statuses = [
    { value: 1, label: 'Deferido' },
    { value: 2, label: 'Indeferido' },
  ];

  const lessons = get(ticketInfo, 'lessons') || [];

  return (
    <>
      <ReviewExplanation shouldGetFiles ticket={ticket} ticketInfo={ticketInfo}>
        <strong className="secondary">
          Comunicação de ausência em atividades docentes
        </strong>
        <div>
          {get(ticketInfo, 'clss') && (
            <p>
              <em>
                {get(ticketInfo, 'clss')} - {get(ticketInfo, 'discipline')} -{' '}
                {get(ticketInfo, 'course')}
              </em>
            </p>
          )}
          <p className="m-0">
            {get(ticketInfo, 'lesson_group.data_formatada')}
          </p>
          {lessons.map((lesson) => (
            <p key={lesson.id} className="mt-0">
              {lesson.time}
            </p>
          ))}
        </div>
        <small>
          <strong>Professor:</strong> {get(ticketInfo, 'teacher')}
        </small>
      </ReviewExplanation>
      {renderForwardings()}
      {get(ticketInfo, 'permissions.resolve') && (
        <TicketActionForm
          statuses={statuses}
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitting={submitting}
          ticket={ticket}
        />
      )}
    </>
  );
};

ExpiredAttendanceTicket.propTypes = {
  submitting: PropTypes.bool,
  ticket: PropTypes.shape({
    permissions: PropTypes.shape({
      resolve: PropTypes.bool,
    }),
  }).isRequired,
  ticketInfo: PropTypes.shape({
    clss: PropTypes.string,
    course: PropTypes.string,
    discipline: PropTypes.string,
    id: PropTypes.number,
    lesson_group: PropTypes.shape({
      id: PropTypes.number,
      data_inicio: PropTypes.string,
      data_termino: PropTypes.string,
    }),
    permissions: PropTypes.shape({
      resolve: PropTypes.bool,
    }),
    teacher: PropTypes.string,
    ticket: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ExpiredAttendanceTicket.defaultProps = {
  submitting: false,
  ticketInfo: {},
};

export default ExpiredAttendanceTicket;
