import React from 'react';
import moment from 'moment';
import Card from 'components/Card';
import LancamentoCard from '../../components/ficha-financeira/LancamentoCard';
import Button from 'components/common/buttons/Button';

const RemessaSerasaDetails = ({ remessa, lancamentos }) => {
  return (
    <>
      <Card title={`Remessa ${remessa.identificador}`}>
        <div style={{ fontSize: '16px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className="semi-bold">Responsável: </span>
              <span>
                {remessa.responsible_user ? remessa.responsible_user.name : '-'}
              </span>
            </div>
            <div>
              <span className="semi-bold">Status: </span>
              <span>{remessa.status ? remessa.status.nome : ''}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <div>
              <span className="semi-bold">Descrição: </span>
              <span>{remessa.descricao}</span>
            </div>
            <div>
              <span className="semi-bold">Data da Remessa: </span>
              <span>{moment(remessa.created_at).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <hr />
          {remessa.url ? (
            <Button>
              <a
                style={{ textDecoration: 'inherit', color: 'inherit' }}
                href={remessa.url}
              >
                Baixar Arquivo de Remessa
              </a>
            </Button>
          ) : (
            'Arquivo de Remessa Indisponível'
          )}
          <hr />
          {/*remessa.erros?
              algum component q mostra os erros da remessa
          <hr /> */}
        </div>
        <div className="mt-2">
          <strong>Lançamentos na Remessa:</strong>
        </div>
        {lancamentos.map((lancamento) => (
          <LancamentoCard
            key={lancamento.id}
            lancamento={lancamento}
            shouldShowStatusSerasa={true}
            maxWidth="100%"
          />
        ))}
      </Card>
    </>
  );
};

export default RemessaSerasaDetails;
