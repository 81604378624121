import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchLessonTime = (id) =>
  axios
    .get(`${API_HOST}/academico/aulas/${id}/time`)
    .then((response) => response.data);

export const fetchActivity = (id) =>
  axios
    .get(`${API_HOST}/activities/activities/${id}`)
    .then((response) => response.data);

export const fetchMenu = () =>
  axios.get(`${API_HOST}/menu/menu/user`).then((response) => response.data);

export const fetchLesson = (id) =>
  axios.get(`${API_HOST}/academico/aulas/${id}`).then(({ data }) => ({
    ...data,
    id: data.id,
    start_time: data.hora_inicio,
    end_time: data.hora_final,
    datetime: `${data.data_formatada} ${data.hora_inicio} - ${data.hora_final}`,
    date: data.data_formatada,
  }));

export const fetchTicket = (id) =>
  axios
    .get(`${API_HOST}/tickets/tickets/${id}`)
    .then((response) => response.data);

export const fetchAttendanceReview = (id) =>
  axios
    .get(`${API_HOST}/attendances/reviews/ticket/${id}`)
    .then((response) => response.data);

export const fetchExpiredAttendances = (id) =>
  axios
    .get(`${API_HOST}/attendances/expired_attendance_ticket/ticket/${id}`)
    .then((response) => response.data);

export const fetchAbsenceNoticeTicket = (id) =>
  axios
    .get(`${API_HOST}/attendances/absence_notice_ticket/ticket/${id}`)
    .then((response) => response.data);

export const fetchGradeReview = (id) =>
  axios
    .get(`${API_HOST}/activities/reviews/ticket/${id}`)
    .then((response) => response.data);

export const fetchClassRelocation = (id) =>
  axios
    .get(`${API_HOST}/class_relocations/class_relocation/ticket/${id}`)
    .then((response) => response.data);

export const fetchClassDiscipline = (id) =>
  axios
    .get(`${API_HOST}/academico/class_disciplines/${id}`)
    .then((response) => response.data);

export const fetchDailyAttendances = ({ id, ...params }) =>
  axios
    .get(`${API_HOST}/attendances/daily_attendances/student/${id}`, { params })
    .then((response) => response.data);

export const fetchParam = (param) =>
  axios
    .get(`${API_HOST}/core/params/${param}`)
    .then((response) => response.data);

export const fetchRoom = (id) =>
  axios.get(`${API_HOST}/rooms/rooms/${id}`).then((response) => response.data);

export const fetchProgramContent = (id) =>
  axios
    .get(`${API_HOST}/teaching_plans/teaching_plans/${id}/program_content`)
    .then((response) => response.data);
