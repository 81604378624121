import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const InputMask = ({
  value,
  onChange,
  mask,
  className,
  error,
  label,
  forwardedRef,
  required,
  ...rest
}) => (
  <>
    <div className={`input-type-style ${className}`}>
      <div className="input-style">
        <MaskedInput mask={mask} value={value} onChange={onChange} {...rest} />
      </div>
      <label
        className={classnames('input-label-style', {
          red: error,
          dim: !error,
          'input-required': required,
        })}
      >
        {label}
      </label>
      {error && <span className="input-helper red">{error}</span>}
    </div>
  </>
);

InputMask.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
};

InputMask.defaultProps = {
  className: '',
  value: '',
  error: null,
  required: false,
};

export default InputMask;
