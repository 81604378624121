/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import contaContext from '../../contexts/conta/ContaContext';
import { TableContext } from '../../hoc/withTable';
import Table from '../Table';
import { fetchContaCorrente } from '../../services/financeiros/financeiros_services';
import withTable from '../../hoc/withTable';

const ContaCorrenteTable = ({ onFilterChange }) => {
  const { conta } = useContext(contaContext);
  useEffect(() => {
    onFilterChange({ conta: conta.id });
  }, [onFilterChange, conta]);

  const columns = [
    {
      Header: 'Contrato',
      accessor: 'contrato_id',
      sortable: true,
    },
    {
      Header: 'Data do Registro',
      accessor: 'created_at',
      sortable: true,
    },
    {
      Header: 'Valor Pago no Lançamento',
      accessor: 'lancamento_valor_pago',
      className: 'text-right',
    },
    {
      Header: 'Descrição da Operação',
      accessor: 'descricao_operacao',
      sortable: true,
      className: 'text-right',
    },
    {
      Header: 'Saldo em Contrato',
      accessor: 'saldo_atual',
      className: 'text-right',
    },
  ];

  return (
    <div data-testid="ficha-financeira-table">
      <TableContext.Consumer>
        {(props) => <Table {...props} columns={columns} isResponsive />}
      </TableContext.Consumer>
    </div>
  );
};

ContaCorrenteTable.propTypes = {
  fetchData: PropTypes.func.isRequired,
};

export default withTable(
  fetchContaCorrente,
  { sorted: [{ id: 'created_at', desc: true }], pageSize: 20 },
  false
)(ContaCorrenteTable);
