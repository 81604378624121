import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import StudentRemessaCard from './StudentRemessaCard';

const LancamentosCardDeck = ({ lancamentos, loading }) => {
  if (loading) {
    return (
      <span>
        <Skeleton count={5} height={160} />
      </span>
    );
  }

  if (lancamentos.length === 0) {
    return (
      <p className="d-flex align-items-center justify-center">
        Nenhum lançamento.
      </p>
    );
  }

  return (
    <div className="cards-selector-container">
      {lancamentos.map((lancamento) => (
        <div className="card-selector" key={lancamento.id}>
          <StudentRemessaCard student={lancamento} />
        </div>
      ))}
    </div>
  );
};

LancamentosCardDeck.propTypes = {
  lancamentos: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

LancamentosCardDeck.defaultProps = {
  lancamentos: [],
  loading: false,
};

export default LancamentosCardDeck;
