import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import React from 'react';

const ContentModal = ({
  children,
  visible,
  onHide,
  header,
  className,
  footer,
  ...rest
}) => (
  <Dialog
    dismissableMask
    modal
    visible={visible}
    header={header}
    onHide={onHide}
    className={`modal-base modal-content ${className}`}
    footer={footer}
    {...rest}
  >
    {children}
  </Dialog>
);

ContentModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.string,
  footer: PropTypes.node,
};

ContentModal.defaultProps = {
  className: '',
  header: '',
  footer: null,
  visible: false,
  children: null,
};

export default ContentModal;
