import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';

const TRACK_ID = process.env.REACT_APP_TRACK_ID;

if (TRACK_ID) {
  GoogleAnalytics.initialize(TRACK_ID);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      if (TRACK_ID) {
        // eslint-disable-next-line
        const page = this.props.location.pathname + this.props.location.search;
        trackPage(page);
      }
    }

    componentDidUpdate(prevProps) {
      if (TRACK_ID) {
        const currentPage =
          prevProps.location.pathname + prevProps.location.search;
        const nextPage =
          this.props.location.pathname + this.props.location.search;

        if (currentPage !== nextPage) {
          trackPage(nextPage);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
