import useFeature from 'hooks/useFeature';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import defaultPhoto from '../assets/img/user.png';
import CircleImage from '../common/data-display/CircleImage';
import ToCapitalizeName from '../common/data-display/ToCapitalizeNames';
import { PermissionContext } from '../hoc/withPermission';
import HeaderIcon from './HeaderIcon';
import PopupChat from './PopupChat';
import { API_HOST } from 'consts';

const Header = (props) => {
  // const [showNotification, setShowNotification] = useState(false);
  const { photo, name, username, isStaff } = props;

  // const toggleNotification = () => {
  //   setShowNotification(!showNotification);
  // };

  const logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const goToAdmin = () => {
    window.location.href = `${API_HOST}/admin`;
  };

  const [chatEnable] = useFeature('login__chat');

  return (
    <>
      <nav className="navigation-student">
        <ul>
          <li className="logo">
            <Link to="/">
              <img
                width={200}
                src={logo}
                className="navigation-logo"
                alt="CMMG"
              />
            </Link>
          </li>
          <li className="user-info">
            <ToCapitalizeName fullName={name} />
            <span className="cpf">
              <span className="destaque">{username}</span>
            </span>
          </li>
          <li>
            <CircleImage alt="Foto de perfil" src={photo || defaultPhoto} />
          </li>
          {/* <HeaderIcon onClick={toggleNotification} icon="bell" /> */}
          <PermissionContext.Consumer>
            {({ hasPermission }) => {
              return (
                hasPermission('login.can_view_chat') &&
                chatEnable && <PopupChat currentUser={props} />
              );
            }}
          </PermissionContext.Consumer>
          {isStaff && <HeaderIcon onClick={goToAdmin} icon="cog" />}
          <HeaderIcon onClick={logout} icon="sign-out-alt" />
        </ul>
      </nav>
      <div className="navigation-space" />
    </>
  );
};

Header.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
  isStaff: PropTypes.bool,
};

Header.defaultProps = {
  photo: defaultPhoto,
  name: '',
  username: '',
  isStaff: false,
};

export default Header;
