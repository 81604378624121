export const white = '#fff';
export const black = '#111';
export const fontColor = '#313131';
export const gray = 'rgba($black, 0.1)';
export const shadow = 'rgba($black, 0.1)';
export const dim = 'rgba($black, 0.6)';
export const blue = 'rgb(32, 143, 220)';
export const greenLight = '#c6efeb';
export const inputPlaceholderColor = '#808080';
export const green = '#039f97';
export const greenDark = '#077772';
export const success = '#74b777';
export const danger = '#b77474';
export const red = 'rgb(255, 80, 70)';
export const yellow = '#e2d7a6';
export const creme = '#fdf1d0';
export const cremeDark = '#efebd8';
export const brown = 'rgb(167, 152, 95)';
export const blueGrade = 'linear-gradient(90deg, $blue 0, rgb(0, 144, 206))';
export const redGrad = 'linear-gradient(90deg, $red 0, rgb(249, 131, 140))';
export const yellowGrad =
  'linear-gradient(90deg, $yellow 0, rgb(255, 137, 28))';
export const greenGrad = 'linear-gradient(90deg, rgb(0, 163, 151) 0, $green)';
export const brownGrad = 'linear-gradient(90deg, rgb(125, 108, 47) 0, $brown)';
export const grayLighter = '#eaeaea';
export const whiteDarker = '#fafafa';
export const grayLight = '#f5f5f5';
export const grayDark = '#585858';
export const silver = '#f8f8f8';
export const silverLight = '#aaaaaa';
export const silverDark = '#5f5f5f';
export const borderColor = '#dddddd';
export const lightGreen = '#d1ded2';
export const redDim = 'rgb(230, 100, 110)';
export const yellowDim = 'rgb(255, 255, 200)';
export const info = '#efebd8';
export const textBlack = '#707070';
export const gold = '#e2d489';
export const pinkyRed = '#cb9292';
export const borderGray = '#d0d0d0';
export const strongRed = '#d42333';
export const strongGreen = '#03af56';
export const ultraLightGray = '#d9d9d9';
export const cancelText = '#a9a9a9';
export const brownDark = '#A39161';
