import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const DefaultTicketFormPage = lazy(() =>
  retry(() => import('./DefaultTicketFormPage'))
);
const DefaultTicketPage = lazy(() =>
  retry(() => import('./DefaultTicketPage'))
);
const TicketsAddressedToMePage = lazy(() =>
  retry(() => import('./TicketsAddressedToMePage'))
);
const AllTicketsPage = lazy(() => retry(() => import('./AllTicketsPage')));
const TicketsRequestedByMePage = lazy(() =>
  retry(() => import('./TicketsRequestedByMePage'))
);
const FinishedTicketsPage = lazy(() =>
  retry(() => import('./FinishedTicketsPage'))
);
const TicketsWaitingActionPage = lazy(() =>
  retry(() => import('./TicketsWaitingActionPage'))
);
const TicketsPage = lazy(() => retry(() => import('./TicketsPage')));
const TicketsListMenuRedirectPage = lazy(() =>
  retry(() => import('./TicketsListMenuRedirectPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/protocolos/new/:ticketId"
      permission="tickets.add_ticket"
      component={DefaultTicketFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/destinados-a-mim"
      permission="tickets.get_forwarding"
      component={TicketsAddressedToMePage}
    />
    <PrivateRoute
      exact
      path="/protocolos/todos"
      permission="tickets.view_any_ticket"
      component={AllTicketsPage}
    />
    <PrivateRoute
      exact
      path="/protocolos"
      permission="tickets.view_ticket"
      component={TicketsListMenuRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/abertos-por-mim"
      permission="tickets.add_ticket"
      component={TicketsRequestedByMePage}
    />
    <PrivateRoute
      exact
      path="/protocolos/concluidos"
      permission="tickets.view_waiting_action_ticket"
      component={FinishedTicketsPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/aguardando-analise"
      permission="tickets.view_waiting_action_ticket"
      component={TicketsWaitingActionPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/new"
      permission="tickets.add_ticket"
      component={TicketsPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/:id(\d+)"
      permission="tickets.view_ticket"
      component={DefaultTicketPage}
    />
  </Switch>
);
