import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from 'primereact/progressbar';
import { downloadFile } from '../s3';

const FileDisplay = ({
  file,
  onDeleteConfirmation,
  showDownloadButton,
  onPreview,
  withPreview,
  loading,
}) => (
  <div key={file.key} className="file-name ">
    <p className="font-12 d-flex justify-space-between align-items-center">
      {withPreview ? (
        <button type="button" className="btn-none" onClick={onPreview}>
          <span className="mr-2 ml-2">
            {loading ? (
              <FontAwesomeIcon color="#7d9bd4" icon="spinner" spin />
            ) : (
              <FontAwesomeIcon color="#039F97" icon="check-circle" />
            )}{' '}
            {file.name}
          </span>
        </button>
      ) : (
        <span className="mr-2 ml-2">
          {loading ? (
            <FontAwesomeIcon color="#7d9bd4" icon="spinner" spin />
          ) : (
            <FontAwesomeIcon color="#039F97" icon="check-circle" />
          )}{' '}
          {file.name}
        </span>
      )}
      {showDownloadButton && (
        <button
          type="button"
          onClick={() => downloadFile(file.key)}
          className="clickable download-file no-border mr-1"
        >
          <FontAwesomeIcon icon="download" />
        </button>
      )}
      {onDeleteConfirmation && (
        <button
          type="button"
          onClick={() => onDeleteConfirmation(file.key)}
          className="clickable delete-file no-border"
        >
          <FontAwesomeIcon icon="trash-alt" />
        </button>
      )}
    </p>
    <ProgressBar
      className="input-file-progress"
      showValue={false}
      value={file.progress}
    />
  </div>
);

FileDisplay.propTypes = {
  file: PropTypes.shape({
    uploading: PropTypes.bool,
    name: PropTypes.string,
    key: PropTypes.string,
    progress: PropTypes.number,
  }),
  onDeleteConfirmation: PropTypes.func,
  showDownloadButton: PropTypes.bool,
  withPreview: PropTypes.bool,
  loading: PropTypes.bool,
  onPreview: PropTypes.func,
};

FileDisplay.defaultProps = {
  file: {},
  onDeleteConfirmation: null,
  showDownloadButton: false,
  withPreview: false,
  loading: false,
  onPreview: () => {},
};

export default FileDisplay;
