import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchVaccineTypeOptions = async (params) => {
  const res = await axios.get(`${API_HOST}/health/vaccine_type`, { params });
  return res.data;
};

export const fetchVaccines = async (params) => {
  const res = await axios.get(`${API_HOST}/health/vaccine`, { params });
  return res.data;
};

export const updateVaccine = async (params) => {
  const res = await axios.put(`${API_HOST}/health/vaccine/batch`, params);
  return res.data;
};

export const createVaccine = async (params) => {
  const res = await axios.post(`${API_HOST}/health/vaccine`, { params });
  return res.data;
};

export const updateVaccineFile = async (id, params) => {
  const res = await axios.patch(`${API_HOST}/health/vaccine/${id}`, params);
  return res.data;
};

export const deleteVaccineFile = async (id) => {
  const res = await axios.post(`${API_HOST}/health/vaccine/${id}`);
  return res.data;
};
