import React from 'react';
import PropTypes from 'prop-types';

const TabsNav = ({ children, className, position, style, ...rest }) => (
  <ul
    className={`tabs-nav ${className}`}
    style={{
      justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
      paddingLeft: position === 'left' ? 0 : undefined,
      ...style,
    }}
    {...rest}
  >
    {children}
  </ul>
);

TabsNav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
};

TabsNav.defaultProps = {
  children: null,
  className: '',
  position: 'right',
};

export default TabsNav;
