import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StudentPromotionProcessCard from './StudentPromotionProcessCard';
import { CardContext } from '../../hoc/withCards';
import { fetchStudentProcessResults } from '../../services/promotion_services';
import ContentModal from '../common/ContentModal';
import BooleanIcon from '../common/BooleanIcon';
import LoadingIndicator from '../LoadingIndicator';

const StudentPromotionProcessList = ({ renderLoading }) => {
  const { id } = useParams();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [detailsData, setDetails] = useState([]);
  const [selectedStudentProcess, setSelectedStudentProcess] = useState([]);

  const handleCardClick = async (studentProcess) => {
    setDetailLoading(true);
    setShowDetailModal(true);
    setSelectedStudentProcess(studentProcess);
    const params = {
      student_process: studentProcess.id,
      page_size: 100,
    };
    try {
      const data = await fetchStudentProcessResults(params);
      setDetailLoading(false);
      setDetails(data);
    } catch {
      setDetailLoading(false);
      setSelectedStudentProcess({});
      setDetails([]);
      setShowDetailModal(false);
      toast.error('Ocorreu um erro ao carregar os detalhes do aluno.');
    }
  };

  return (
    <>
      <CardContext.Consumer>
        {({ cards }) => (
          <div>
            {renderLoading() || (
              <div className="card-deck">
                {cards.map((card) => (
                  <StudentPromotionProcessCard
                    key={card.id}
                    classInfo={card}
                    processId={id}
                    onCardClick={() => handleCardClick(card)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </CardContext.Consumer>
      <ContentModal
        visible={showDetailModal}
        onHide={() => {
          setShowDetailModal(false);
          setSelectedStudentProcess({});
          setDetails([]);
        }}
        footer={null}
        header={`Detalhes aluno ${selectedStudentProcess.student_curriculum?.student?.name}`}
      >
        {detailLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="h-100 d-flex justify-space-between flex-direction-column">
            {detailsData.map((result) => (
              <div className="card-100 card-body">
                <div className="d-flex">
                  <p>
                    <BooleanIcon value={!result.block_promotion} />
                  </p>
                  <p className="semi-bold ml-1">{result.note}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </ContentModal>
    </>
  );
};

StudentPromotionProcessList.propTypes = {
  renderLoading: PropTypes.func.isRequired,
};

export default StudentPromotionProcessList;
