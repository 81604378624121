import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ChatLine from './ChatLine';

const Forwardings = ({ forwardings, ticket }) => {
  const lastForwarding =
    forwardings.length > 0 && forwardings[forwardings.length - 1];
  const shouldRenderNotAnsweredIndicator =
    lastForwarding && ticket.resolution === null;

  return (
    <>
      {forwardings.map((forwarding) => (
        <div className="mt-2" key={forwarding.id}>
          <ChatLine
            timestamp={forwarding.created_at}
            author={forwarding.created_by}
            isAuthor={forwarding.request_user_is_author}
            message={forwarding.message}
            privateMessage={forwarding.private_message}
            admissionMethod={get(ticket, 'admission_method')}
            canSeePrivate={get(ticket, 'permissions.can_see_fowarding_private')}
          />
        </div>
      ))}
      {shouldRenderNotAnsweredIndicator && (
        <div className="mt-2">
          <ChatLine
            timestamp={<i>Ainda não respondeu</i>}
            author={lastForwarding.forwarded_to}
            admissionMethod={get(ticket, 'admission_method')}
          />
        </div>
      )}
    </>
  );
};

Forwardings.propTypes = {
  forwardings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      created_at: PropTypes.string,
      created_by: PropTypes.string,
      request_user_is_author: PropTypes.bool,
      message: PropTypes.string,
      forwarded_to: PropTypes.string,
    })
  ).isRequired,
  ticket: PropTypes.shape({
    permissions: PropTypes.object,
  }).isRequired,
};

export default Forwardings;
