import { get } from 'lodash';

export default class ClassRelocationSerializer {
  constructor(ticketInfo) {
    this.ticketInfo = ticketInfo;
  }

  getDetails() {
    const course = this.ticketInfo.course || '-';
    const fromClass = get(this.ticketInfo, 'from_class_discipline.clss', '-');
    const fromClassDiscipline = get(
      this.ticketInfo,
      'from_class_discipline.discipline',
      '-'
    );
    const toClass = get(this.ticketInfo, 'to_class_discipline.clss', '-');
    const details = [
      {
        key: `${course} - ${fromClass} - ${fromClassDiscipline}`,
        value: '',
      },
      { key: 'Aluno', value: get(this.ticketInfo, 'student.name', '-') },
      {
        key: 'Turma a ser remanejada',
        value: `De: ${fromClass} -> Para: ${toClass}`,
      },
    ];
    return details;
  }
}
