import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingText from '../LoadingText';

const Files = ({ loading, files }) => {
  if (loading) {
    return <LoadingText text="Carregando arquivos" />;
  }
  if (get(files, 'length') > 0) {
    return files.map((file) => (
      <a download key={file.link} href={file.link} className="message-file">
        <FontAwesomeIcon icon="file-alt" className="mr-2 ml-2" /> {file.name}
      </a>
    ));
  }
  return null;
};

Files.propTypes = {
  loading: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

Files.defaultProps = {
  loading: false,
  files: [],
};

export default Files;
