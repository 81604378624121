import React, { useState } from 'react';
import { Form } from 'informed';
import axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../../consts';
import Card from '../../components/Card';
import CourseInputAsync from '../../components/CourseInputAsync';
import Button from '../../components/common/buttons/Button';

const PromotionCheckPage = () => {
  const [formState, setFormState] = useState();
  const [checks, setChecks] = useState([]);
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState(null);

  const checkPromotion = () => {
    const courseId = get(formState, 'values.course.value');

    if (!courseId) {
      return;
    }

    setChecking(true);
    axios
      .get(`${API_HOST}/curricula/promotion_check/${courseId}`)
      .then((response) => {
        setChecks(response.data);
        setChecking(false);
      })
      .catch(() => {
        setChecking(false);
        setChecks([]);
        setError(true);
      });
  };

  return (
    <Card title="Verificar promoções de aluno">
      <p>
        Selecione um curso para visualizar os alunos que <strong>não</strong>{' '}
        devem ser promovidos para a próxima etapa.
      </p>
      <Form onChange={setFormState}>
        <CourseInputAsync field="course" />
      </Form>
      <Button onClick={checkPromotion} disabled={checking} className="mt-2">
        Buscar
      </Button>
      {error && <p className="danger">Ocorreu um erro.</p>}
      <p>{checks.length} alunos encontrados</p>
      {checks.map((check) => (
        <div key={check.student_name}>
          <p>
            <strong>{check.student_name}</strong> ({check.term_name})
            <span className="ml-2">
              {check.show_admission_method && check.admission_method_name}
            </span>
          </p>
          <ul>
            {check.errors.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
          <hr />
        </div>
      ))}
    </Card>
  );
};

export default PromotionCheckPage;
