import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_HOST } from '../consts';

function useFeature(feature) {
  const [hasFeature, setHasFeature] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_HOST}/v2/features/global/${feature}`)
      .then((response) => setHasFeature(response.data.value));
  }, [feature]);

  return [hasFeature];
}

export default useFeature;
