import axios from 'axios';
import debounce from 'debounce-promise';
import { API_HOST } from '../consts';

export const fetchClassesOptions = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/turmas`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchClasses = (params) =>
  axios
    .get(`${API_HOST}/academico/turmas/detail_list`, { params })
    .then((response) => response.data);

export const fetchClassDisciplines = (params) =>
  axios
    .get(`${API_HOST}/academico/class_disciplines/detail_list`, { params })
    .then((response) => response.data.results);

export const fetchGridDisciplines = (params) =>
  axios
    .get(`${API_HOST}/curricula/grid_disciplines`, { params })
    .then((response) => response.data.results);

export const deleteGridDiscipline = (id) =>
  axios
    .delete(`${API_HOST}/curricula/grid_disciplines/${id}`)
    .then((response) => response.data);

export const updateGriDisciplines = (id, params) =>
  axios
    .patch(`${API_HOST}/curricula/grid_disciplines/${id}`, { ...params })
    .then((response) => response.data);

export const createGridDiscipline = (data) =>
  axios
    .post(`${API_HOST}/curricula/grid_disciplines`, { ...data })
    .then((response) => response.data);

export const deleteClassDiscipline = async (id) => {
  const res = await axios.delete(
    `${API_HOST}/academico/class_disciplines/${id}`
  );
  return res.data;
};

export const addClassDiscipline = async (params) => {
  const res = await axios.post(
    `${API_HOST}/academico/class_disciplines/create_from_grid`,
    params
  );
  return res.data;
};

export const fetchClass = async (id) => {
  const res = await axios.get(`${API_HOST}/academico/turmas/${id}/details`);
  return res.data;
};

export const deleteClass = async (id) => {
  const res = await axios.delete(`${API_HOST}/academico/turmas/${id}`);
  return res.data;
};

export const createClass = async (params) => {
  const res = await axios.post(`${API_HOST}/academico/turmas`, params);
  return res.data;
};

export const updateClass = async (id, params) => {
  const res = await axios.put(`${API_HOST}/academico/turmas/${id}`, params);
  return res.data;
};

export const fetchClassWithDisciplines = (course) =>
  axios.get(`${API_HOST}/academico/turmas/from_course`, { params: { course } });

export const createGrid = async (params) => {
  const res = await axios.post(`${API_HOST}/curricula/grids`, params);
  return res.data;
};
