import classnames from 'classnames';
import { asField } from 'informed';
import React from 'react';
import InputAsync from './InputAsync';

export default asField(
  ({
    fieldApi,
    onChange,
    fieldState,
    className,
    label,
    required,
    ...props
  }) => (
    <div className={`input-type-style ${className}`}>
      <InputAsync
        className={fieldState.error ? 'input-error red' : 'dropdown-style'}
        classNamePrefix="dropdown"
        value={fieldState.value || ''}
        error={fieldState.error}
        onChange={(value) => {
          fieldApi.setError(null);
          fieldApi.setValue(value);
          if (onChange) {
            onChange(value);
          }
        }}
        {...props}
      />
      {label && (
        <label
          className={classnames('input-label-style', {
            'input-required': required,
            red: fieldState.error,
            dim: !fieldState.error,
          })}
        >
          {label}
        </label>
      )}
    </div>
  )
);
