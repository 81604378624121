/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { useField, useFormikContext } from 'formik';

const InputSelectAsync = ({
  label,
  required,
  className,
  instructions,
  onChange,
  placeholder,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className={`input-type-style ${className}`}>
      <AsyncSelect
        cacheOptions={false}
        {...field}
        {...props}
        isClearable
        placeholder={placeholder}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        className={meta.error ? 'input-error red' : 'dropdown-style'}
        classNamePrefix="dropdown"
        onChange={(val) => {
          setFieldValue(field.name, val);
          if (onChange) {
            onChange(val);
          }
        }}
      />
      {label && (
        <label
          htmlFor={field.name}
          className={`
          input-label-style
          ${meta.error ? 'red' : 'dim'}
          ${required ? 'input-required' : ''}
        `}
        >
          {label}
        </label>
      )}
      {meta.error && <span className="input-helper red">{meta.error}</span>}
      {instructions && <span className="input-helper">{instructions}</span>}
    </div>
  );
};

InputSelectAsync.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  instructions: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

InputSelectAsync.defaultProps = {
  label: null,
  className: '',
  required: false,
  instructions: '',
  onChange: null,
  placeholder: 'Buscar...',
};

export default InputSelectAsync;
