import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import { fetchContasBancarias } from '../../../services/banco/banco_services';

const ContaBancariaInput = (props) => (
  <FetchInputSelectOptions
    fetchOptions={() => fetchContasBancarias().then((data) => data.results)}
  >
    {({ options, isLoading }) => (
      <InputSelect
        name="conta_bancaria"
        label="Conta bancária"
        placeholder="Selecione"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) =>
          `${d.banco.nome} ${d.agencia} ${d.conta_corrente}`
        }
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);

export default ContaBancariaInput;
