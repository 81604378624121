import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const ChangePasswordPage = lazy(() =>
  retry(() => import('./ChangePasswordPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/alterar-senha"
      permission=""
      component={ChangePasswordPage}
    />
    <PrivateRoute
      exact
      path="/alterar-senha-obrigatorio"
      permission=""
      sidebar={false}
      component={ChangePasswordPage}
    />
  </Switch>
);
