import React from 'react';
import InputMask from '../InputMask';

const cepMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const CEPInputMask = ({ ...props }) => (
  <InputMask label="CEP" name="cep" mask={cepMask} {...props} />
);

export default CEPInputMask;
