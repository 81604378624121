import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const StudyMaterialPage = lazy(() =>
  retry(() => import('./StudyMaterialPage'))
);
const StudyMaterialPublicationsPage = lazy(() =>
  retry(() => import('./StudyMaterialPublicationsPage'))
);
const AddStudyMaterialPage = lazy(() =>
  retry(() => import('./AddStudyMaterialPage'))
);
const StudentMaterialPublicationClassDisciplineSelect = lazy(() =>
  retry(() => import('./StudentMaterialPublicationClassDisciplineSelect'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/materiais-didaticos"
      permission="study_materials.view_material"
      component={StudyMaterialPage}
    />
    <PrivateRoute
      exact
      path="/materiais-didaticos/new"
      permission="study_materials.add_material"
      component={AddStudyMaterialPage}
    />
    <PrivateRoute
      exact
      path="/materiais-didaticos/turma-disciplinas"
      permission="study_materials.view_student_publication"
      component={StudentMaterialPublicationClassDisciplineSelect}
    />
    <PrivateRoute
      exact
      path="/materiais-didaticos/turma-disciplinas/:id"
      permission="study_materials.view_student_publication"
      component={StudyMaterialPublicationsPage}
    />
    <PrivateRoute
      exact
      path="/materiais-didaticos/:id"
      permission="study_materials.change_material"
      component={AddStudyMaterialPage}
    />
  </Switch>
);
