import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const ChatLineInfo = ({ author, timestamp, admissionMethod }) => (
  <div>
    <h4 className="secondary mb-0">
      <strong>{author || '--'}</strong>
    </h4>
    {!get(admissionMethod, 'charge_ticket') && (
      <small className="silver-dark d-block">
        {get(admissionMethod, 'name')}
      </small>
    )}
    <small className="silver-dark">{timestamp}</small>
  </div>
);

ChatLineInfo.propTypes = {
  author: PropTypes.string.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  admissionMethod: PropTypes.shape({
    charge_ticket: PropTypes.bool,
    name: PropTypes.string,
  }),
};

ChatLineInfo.defaultProps = {
  timestamp: null,
  admissionMethod: {
    charge_ticket: true,
  },
};

export default ChatLineInfo;
