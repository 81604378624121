const NovoDocenteReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DADOS_PESSOAIS':
      return {
        ...state,
        dadosPessoais: action.payload,
      };
    case 'SET_DOCUMENTACAO_DOCENTE':
      return {
        ...state,
        documentacaoDocente: action.payload,
      };
    case 'SET_DADOS_EMPRESA':
      return {
        ...state,
        empresa: action.payload,
      };
    case 'SET_DOCUMENTACO_EMPRESA':
      return {
        ...state,
        documentacaoEmpresa: action.payload,
      };
    case 'SET_DOCUMENTACAO_META':
      return {
        ...state,
        documentacaoMeta: action.payload,
      };
    case 'SET_PROFESSOR_PERSON_META':
      return {
        ...state,
        professorPerson: action.payload,
      };
    case 'SET_CONTRATO_DOCENTE':
      return {
        ...state,
        contratoDocente: action.payload,
      };
    case 'SET_ASSINATURA_CONTRATO':
      return {
        ...state,
        assinaturaContrato: action.payload,
      };
    case 'SET_LISTA_EVENTOS':
      return {
        ...state,
        evento: action.payload,
      };
    case 'SET_PAGAMENTO_EVENTOS':
      return {
        ...state,
        evento: action.payload,
      };
    default:
      return state;
  }
};

export default NovoDocenteReducer;
