import { isFunction } from 'lodash';
import React, { createContext, ReactNode } from 'react';
import { useQuery } from 'react-query';
import { Acordo, fetchAcordo } from 'services/financeiros/acordos';
import { fetchLancamentos, Lancamento } from 'services/financeiros/lancamentos';

interface IAcordoState {
  acordo: Acordo | undefined;
  lancamentosCriados: Lancamento[];
  lancamentosEmAcordo: Lancamento[];
  acordoId: number | undefined;
  contaId: number | undefined;
  refetch: () => void;
}

const initialState: IAcordoState = {
  acordo: undefined,
  lancamentosCriados: [],
  lancamentosEmAcordo: [],
  acordoId: undefined,
  contaId: undefined,
  refetch: () => {},
};

export const AcordoContext = createContext(initialState);

type NewAcordoStateProps = {
  children: ReactNode;
  acordoId: number;
  contaId: number;
};

const AcordoState = ({ acordoId, contaId, children }: NewAcordoStateProps) => {
  const { data: acordo, refetch: refetchAcordo } = useQuery(
    ['acordo', acordoId],
    (key: string, acordoId: number) => fetchAcordo(acordoId)
  );
  const {
    data: lancamentosCriadosList,
    refetch: refetchLancamentosCriados,
  } = useQuery(
    ['lancamentosCriados', acordoId],
    (key: string, acordoId: number) => fetchLancamentos({ acordo: acordoId })
  );
  const {
    data: lancamentosEmAcordoList,
    refetch: refetchLancamentosEmAcordo,
  } = useQuery(
    ['lancamentosEmAcordo', acordoId],
    (key: string, acordoId: number) =>
      fetchLancamentos({ escolhido_em_acordo_id: acordoId })
  );

  const refetch = () => {
    refetchAcordo();
    refetchLancamentosCriados();
    refetchLancamentosEmAcordo();
  };

  const state = {
    acordo,
    acordoId,
    contaId,
    lancamentosCriados: lancamentosCriadosList?.results || [],
    lancamentosEmAcordo: lancamentosEmAcordoList?.results || [],
    refetch,
  };

  return (
    <AcordoContext.Provider value={state}>
      {isFunction(children) ? children(state) : children}
    </AcordoContext.Provider>
  );
};

export default AcordoState;
