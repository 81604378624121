import axios from 'axios';
import API_HOST from 'consts';
import React, { useReducer, createContext } from 'react';
import { fetchRespostasCPA } from 'services/pesquisaCpa_services';
import PesquisaCpaRedunce from 'reducers/pesquisaCpa/PesquisaCpaRedunce';

const initialState = {
  pesquisaCpa: {},
};

export const PequisaCpaContext = createContext(initialState);

const PequisaCpaState = ({ children }) => {
  const [state, dispatch] = useReducer(PesquisaCpaRedunce, initialState);

  const { pesquisaCpa } = state;

  const clearStates = () => {
    dispatch({ type: 'SET_PESQUISA_CPA', payload: {} });
  };

  const savePesquisaCpa = async (quizId, data) => {
    if (!quizId) {
      return axios
        .post(`${API_HOST}/academico/answer_quiz`, data)
        .then((res) => {
          dispatch({ type: 'SET_PESQUISA_CPA', payload: res.data });
          return res.data;
        });
    }
    return axios
      .put(`${API_HOST}/academico/answer_quiz/${quizId}`, data)
      .then((res) => {
        dispatch({ type: 'SET_PESQUISA_CPA', payload: res.data });
        return res.data;
      });
  };

  const setPesquisaCpaFilter = async (id) => {
    await fetchRespostasCPA(id).then((data) => {
      dispatch({ type: 'SET_PESQUISA_CPA', payload: data });
    });
  };

  return (
    <PequisaCpaContext.Provider
      value={{
        clearStates,
        pesquisaCpa,
        setPesquisaCpaFilter,
        savePesquisaCpa,
      }}
    >
      {children}
    </PequisaCpaContext.Provider>
  );
};

export default PequisaCpaState;
