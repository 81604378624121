import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import RawInputCurrency from '../InputCurrency';

const InputCurrency = ({ className, onChange, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className={`input-style ${className} ${meta.error ? 'red' : ''}`}>
      <RawInputCurrency
        onChange={(event) => {
          setFieldValue(field.name, event.target.value);
          if (onChange) {
            onChange(event.target.value);
          }
          console.log('onChange', onChange);
        }}
        error={meta.error}
        {...field}
        {...props}
      />
    </div>
  );
};

InputCurrency.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

InputCurrency.defaultProps = {
  className: '',
  onChange: () => {},
};

export default InputCurrency;
