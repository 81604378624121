/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const InputMask = ({ label, className, mask, required, ...props }) => {
  const [field, meta] = useField(props);
  const error = meta.error || props.errors;
  return (
    <div className={`input-type-style ${className}`}>
      <div className={error ? 'input-error red' : 'input-style'}>
        <MaskedInput mask={mask} {...field} {...props} />
        <label
          className={`
            input-label-style
            ${error ? 'red' : 'dim'}
            ${required ? 'input-required' : ''}
          `}
        >
          {label}
        </label>
      </div>
      {error && <span className="input-helper red">{error}</span>}
    </div>
  );
};

InputMask.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  required: PropTypes.bool,
};

InputMask.defaultProps = {
  label: '',
  className: '',
  required: false,
};

export default InputMask;
