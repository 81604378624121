import React, { createContext, Component } from 'react';
import { fetchMenu } from '../services/retrieve_services';

export const MenuContext = createContext({
  sections: [],
});

const withMenu = (WrappedComponent) =>
  class Hoc extends Component {
    state = {
      sections: [],
    };

    componentDidMount() {
      this.fetchMenu();
    }

    fetchMenu = () => {
      fetchMenu().then((menu) => this.setState({ ...menu }));
    };

    render() {
      const { menu } = this.state;
      return (
        <MenuContext.Provider value={this.state}>
          <WrappedComponent menu={menu} />
        </MenuContext.Provider>
      );
    }
  };

export default withMenu;
