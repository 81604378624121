import { API_HOST } from 'consts';
import axios from 'axios';

export interface Lancamento {
  id: number;
  mes_competencia: number;
  ano_competencia: number;
  tipo: {
    id: number;
    descricao: string;
  };
  data_vencimento: string;
  valor_liquido: string;
  valor_desconto: string;
  valor_bruto: string;
  status: {
    id: number;
    nome: string;
    cor: string;
  };
  responsavel: {
    name: string;
  };
  serasa_status?: {
    id: number;
    nome: string;
    cor: string;
  };
}

export interface LancamentoList {
  results: Lancamento[];
}

export interface LancamentoListParams {
  acordo?: number;
  escolhido_em_acordo_id?: number;
}

export const fetchLancamentos = (params: LancamentoListParams) => {
  return axios
    .get<LancamentoList>(
      `${API_HOST}/financeiro/lancamentos/ficha_financeira`,
      { params }
    )
    .then((response) => response.data);
};
