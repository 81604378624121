import React from 'react';
import { Link } from 'react-router-dom';
import SVGS from '../../svg_files';

class NotFound extends React.Component {
  render() {
    return (
      <div className="bg-errors">
        <div className="w-100 text-center">
          <h1 className="white">404</h1>
          <p className="white">PÁGINA SOLICITADA NÃO PODE SER ENCONTRADA.</p>
          <div className="mb-3">
            <SVGS name="not-found" />
          </div>
          <Link className="btn btn-primary" to="/">
            VOLTAR À PÁGINA INICIAL
          </Link>
        </div>
      </div>
    );
  }
}

export default NotFound;
