import axios from 'axios';
import { API_HOST } from 'consts';

export interface Acordo {
  id: number;
  data_acordo: string;
  valor_refinanciado: string;
  valor_entrada: string | null;
  valor_desconto: string | null;
  data_primeiro_venc: string;
  data_venc_entrada: string | null;
  tipo_desconto: {
    id: number;
    descricao: string;
  } | null;
  justificativa: string;
  pct_juros: string;
  pct_multa: string;
  conta_id: number;
  status: string;
  total_pago: number;
  cancelamento: {
    id: number;
    created_at: string;
    created_by: {
      id: number;
      nome: string;
    };
    saldo_id: number | null;
    justificativa: string;
  } | null;
  saldo_contrato: string;
  usa_saldo_em_conta: boolean;
  valor_saldo_entrada: string;
}

export interface AcordoList {
  results: Acordo[];
}

export const fetchAcordos = (params: any) => {
  return axios
    .get<AcordoList>(`${API_HOST}/financeiro/acordos`, { params })
    .then((response) => response.data);
};

export const fetchAcordo = (acordoId: number) => {
  return axios
    .get<Acordo>(`${API_HOST}/financeiro/acordos/${acordoId}`)
    .then((response) => response.data);
};

export interface CancelamentoAcordo {
  justificativa: string;
  acordo: number;
}

export const cancelarAcordo = (values: CancelamentoAcordo) => {
  return axios
    .post(`${API_HOST}/financeiro/cancelamentos_acordo`, values)
    .then((response) => response.data);
};
