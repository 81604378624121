import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const StudentSchedulePage = lazy(() =>
  retry(() => import('./StudentSchedulePage'))
);
const StudentWeeklyHoursSheetsPage = lazy(() =>
  retry(() => import('./StudentWeeklyHoursSheetsPage'))
);
const LessonAttendancePage = lazy(() =>
  retry(() => import('./LessonAttendancePage'))
);
const LessonListPage = lazy(() => retry(() => import('./LessonListPage')));
const LessonHoursListPage = lazy(() =>
  retry(() => import('./lesson-hours-list/LessonHoursListPage'))
);
const LessonHoursPage = lazy(() =>
  retry(() => import('./new-lesson-hours/LessonHoursPage'))
);
const LessonHoursViewPage = lazy(() =>
  retry(() => import('./LessonHoursViewPage'))
);
const ChangeTeacherFromLessonsInRangePage = lazy(() =>
  retry(() => import('./ChangeTeacherFromLessonsInRangePage'))
);
const ChangeRoomFromLessonsInRangePage = lazy(() =>
  retry(() => import('./ChangeRoomFromLessonsInRangePage'))
);
const PunchCardPage = lazy(() => retry(() => import('./PunchCardPage')));
const PunchCardPrintPage = lazy(() =>
  retry(() => import('./PunchCardPrintPage'))
);
const PunchCardDetailPrintPage = lazy(() =>
  retry(() => import('./PunchCardDetailPrintPage'))
);
const LessonPlanEditPage = lazy(() =>
  retry(() => import('./LessonPlanEditPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/aulas/:id/frequencias"
      permission="academico.view_frequenciadiaria"
      component={LessonAttendancePage}
    />
    <PrivateRoute
      exact
      path="/aulas"
      permission="academico.view_aula"
      component={LessonListPage}
    />
    <PrivateRoute
      exact
      path="/aulas/horarios"
      permission="aulas.view_agendamento"
      component={LessonHoursListPage}
    />
    <PrivateRoute
      exact
      path="/pontos"
      permission="academico.view_punch_cards"
      component={PunchCardPage}
    />
    <PrivateRoute
      exact
      path="/pontos/impressao"
      permission="academico.view_punch_cards"
      component={PunchCardPrintPage}
      navbar={false}
      sidebar={false}
    />
    <PrivateRoute
      exact
      path="/pontos/impressao-detalhada"
      permission="academico.view_punch_cards"
      component={PunchCardDetailPrintPage}
      navbar={false}
      sidebar={false}
    />
    <PrivateRoute
      exact
      path="/aulas/horarios/new"
      permission="aulas.add_agendamento"
      component={LessonHoursPage}
    />
    <PrivateRoute
      exact
      path="/aulas/horarios/:id"
      permission="aulas.view_agendamento"
      component={LessonHoursViewPage}
    />
    <PrivateRoute
      exact
      path="/aulas/horarios/:id/alterar-professor"
      permission="aulas.change_agendamento"
      component={ChangeTeacherFromLessonsInRangePage}
    />
    <PrivateRoute
      exact
      path="/aulas/horarios/:id/alterar-sala"
      permission="aulas.change_agendamento"
      component={ChangeRoomFromLessonsInRangePage}
    />
    <PrivateRoute
      exact
      path="/horarios"
      permission="agendamento.view_horarioprogramado"
      component={StudentWeeklyHoursSheetsPage}
    />
    <PrivateRoute
      exact
      path="/cronograma/aluno"
      // permission="agendamento.view_horarioprogramado"
      component={StudentSchedulePage}
    />
    <PrivateRoute
      exact
      path="/aulas/:id/plano-aula"
      permission="academico.change_aula"
      component={LessonPlanEditPage}
    />
  </Switch>
);
