import React, { useState, useEffect, useRef, useCallback } from 'react';
import Talk from 'talkjs';
import { CHAT_ID } from '../../consts';
import LoadingIndicator from '../../components/LoadingIndicator';
import useChatSupportUser from '../../hooks/useChatSupportUser';

const SupportPage = () => {
  const [loading, setLoading] = useState(true);
  const inboxRef = useRef(null);
  const [signature, currentUser] = useChatSupportUser();

  const loadChat = useCallback(() => {
    Talk.ready.then(async () => {
      const me = new Talk.User(currentUser);
      const talkSession = new Talk.Session({
        appId: CHAT_ID,
        me,
        signature,
      });
      const inbox = talkSession.createInbox();
      await inbox.mount(inboxRef.current);
      setLoading(false);
    });
  }, [signature, currentUser]);

  useEffect(() => {
    if (signature && currentUser) {
      loadChat();
    }
  }, [signature, currentUser, loadChat]);

  return (
    <>
      <div className="container">
        <section className="one-page-only">
          <div className="title-button-page mb-5">
            <h2 style={{ fontSize: '1.35rem' }}>Suporte</h2>
          </div>
          {loading && <LoadingIndicator center />}
          <div
            ref={inboxRef}
            style={{ width: '90%', margin: 30, height: 500 }}
          />
        </section>
      </div>
    </>
  );
};

export default SupportPage;
