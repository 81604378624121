import React from 'react';
import { useField, useFormikContext } from 'formik';
import RawInputFile from '../data-entry/InputFile';

const InputFile = (props) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleUploadComplete = (fileKey) => {
    setFieldValue(field.name, fileKey);
  };

  const handleDeleteFile = () => {
    setFieldValue(field.name, null);
  };

  return (
    <RawInputFile
      onUploadComplete={handleUploadComplete}
      onDeleteFile={handleDeleteFile}
      error={meta.error}
      {...props}
    />
  );
};

export default InputFile;
