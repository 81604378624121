import React from 'react';

import PropTypes from 'prop-types';
import { Input, InputDate } from '..';

const CustomInput = ({ field_type, ...rest }) => {
  switch (field_type) {
    case 'Data':
      return <InputDate {...rest} />;
    case 'Texto':
      return <Input {...rest} />;
    default:
      return null;
  }
};

CustomInput.propTypes = {
  field_type: PropTypes.oneOf(['Texto', 'Data']).isRequired,
};

export default CustomInput;
