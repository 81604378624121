import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

export default function <T>(
  url: string,
  initialState: T,
  params?: any
): [T, boolean, () => void] {
  const [data, setData] = useState<T>(initialState);
  const [loading, setLoading] = useState(true);

  const fetchUrl = useCallback(async () => {
    const response = await axios.get<T>(url, { params });
    const json = response.data;
    setData(json);
    setLoading(false);
  }, [url, params]);

  useEffect(() => {
    fetchUrl();
  }, [fetchUrl]);
  return [data, loading, fetchUrl];
}
