import { Actions } from './actions';
import { CurriculaContentReducerState, Action } from 'utils/types';

const CurriculaContentReducer = (
  state: CurriculaContentReducerState,
  action: Action
) => {
  switch (action.type) {
    case Actions.SET_CURRICULA_CONTENT:
      return {
        ...state,
        curriculaContent: action.payload,
      };
    case Actions.SET_CURRICULA_CONTENT_LOADING:
      return {
        ...state,
        curriculaContentLoading: action.payload,
      };
    case Actions.SET_SELECTED_TERM:
      return {
        ...state,
        selectedTerm: action.payload,
      };
    case Actions.SET_CURRICULA_DISCIPLINES:
      return {
        ...state,
        curriculumFilteredDisciplines: action.payload,
      };
    case Actions.SET_TERM_ID:
      return {
        ...state,
        termId: action.payload,
      };
    case Actions.SET_GRIDS:
      return {
        ...state,
        grids: action.payload,
      };
    case Actions.SET_TERMS:
      return {
        ...state,
        terms: action.payload,
      };
    default:
      return state;
  }
};
export default CurriculaContentReducer;
