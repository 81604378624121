import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const CursoListPage = lazy(() => retry(() => import('./CursoListPage')));
const CadastroCursoPage = lazy(() =>
  retry(() => import('./CadastroCursoPage'))
);

export default () => (
  <Switch>
    <PrivateRoute exact path="/cursos" component={CursoListPage} />
    <PrivateRoute exact path="/cursos/new" component={CadastroCursoPage} />
    <PrivateRoute exact path="/cursos/:id/edit" component={CadastroCursoPage} />
  </Switch>
);
