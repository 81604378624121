import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import CollapsedMenuItem from '../../components/CollapsedMenuItem';

class SidebarItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.forceOpening,
    };
  }

  handleClick = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { label, icon, children, collapsed } = this.props;
    const { isOpen } = this.state;

    if (collapsed) {
      return (
        <CollapsedMenuItem
          icon={icon}
          isOpen={isOpen}
          onClick={this.handleClick}
        />
      );
    }

    return (
      <Fragment>
        <li className="menu-element">
          <button
            type="button"
            onClick={!collapsed ? this.handleClick : null}
            className={`clickable d-flex align-items-center justify-space-between w-100 ${
              isOpen ? 'active-menu' : ''
            }`}
          >
            <div className="d-flex align-items-center">
              <div className="menu-icon-border">
                {icon && (
                  <FontAwesomeIcon
                    className="menu-icon"
                    icon={icon}
                    size="lg"
                  />
                )}
              </div>
              <span className="mr-2">{label}</span>
            </div>
            {!collapsed && (
              <FontAwesomeIcon
                className="chevron"
                icon={`chevron-${isOpen ? 'up' : 'down'}`}
              />
            )}
          </button>
          {isOpen && children && (
            <ul className="menu-list-internal animated fadeIn">{children}</ul>
          )}
        </li>
      </Fragment>
    );
  }
}

SidebarItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  forceOpening: PropTypes.bool,
};

SidebarItem.defaultProps = {
  label: '',
  icon: '',
  children: null,
  collapsed: false,
  forceOpening: false,
};

export default SidebarItem;
