import styled from 'styled-components';
import { grayLight, textBlack } from '../variables';

const Card = styled.div`
  border: ${(props) => (props.borderless ? 'none' : '8px solid')};
  border-color: ${(props) => props.borderColor};
  padding: 20px;
  border-radius: 6px;
  ${(props) =>
    props.hasShadow && 'box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15)'};
  color: ${textBlack};
`;

Card.defaultProps = {
  borderless: false,
  borderColor: grayLight,
  hasShadow: false,
};

export default Card;
