import React from 'react';
import PropTypes from 'prop-types';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchNationalityOptions from '../../../services/nationality_services';

const NationalityInputSelect = ({ isRequired, ...props }) => (
  <FetchInputSelectOptions fetchOptions={fetchNationalityOptions}>
    {({ options, isLoading }) => (
      <InputSelect
        name="nationality"
        label="Nacionalidade"
        placeholder="Selecionar"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        isRequired={isRequired}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);

NationalityInputSelect.propTypes = {
  isRequired: PropTypes.bool,
};

NationalityInputSelect.defaultProps = {
  isRequired: false,
};

export default NationalityInputSelect;
