import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const ClassListPage = lazy(() => retry(() => import('./ClassListPage')));
const ClassCadastroPage = lazy(() =>
  retry(() => import('./ClassCadastroPage'))
);
const ClassDisciplineList = lazy(() =>
  retry(() => import('./ClassDisciplineList'))
);
const DisciplineRegisterPage = lazy(() =>
  retry(() => import('../disciplines/DisciplineRegisterPage'))
);

export default () => (
  <Switch>
    <PrivateRoute exact path="/turmas" component={ClassListPage} />
    <PrivateRoute exact path="/turmas/new" component={ClassCadastroPage} />
    <PrivateRoute exact path="/turmas/:id/edit" component={ClassCadastroPage} />
    <PrivateRoute
      exact
      path="/turmas/:id/disciplinas/edit"
      component={ClassDisciplineList}
    />
    <PrivateRoute
      exact
      path="/turmas/:id/disciplinas/:disciplinaID/edit"
      component={DisciplineRegisterPage}
    />
  </Switch>
);
