import axios from 'axios';
import { API_HOST } from '../../consts';

export const fetchConta = async (id: any, params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/contas/${id}`, {
    params,
  });
  return res.data;
};

export const fetchLancamento = async (id: any, params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/lancamentos/${id}`, {
    params,
  });
  return res.data;
};

export const fetchLancamentoLogs = async (id: any, params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/lancamentos/${id}/logs`, {
    params,
  });
  return res.data;
};

export const fetchDiscountLogs = async (params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/descontos/logs`, {
    params,
  });
  return res.data;
};

export const fetchContas = async (params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/contas`, { params });
  return res.data.results;
};

export const fetchLancamentosFichaFinanceira = async (params: any) => {
  const res = await axios.get(
    `${API_HOST}/financeiro/lancamentos/ficha_financeira`,
    { params }
  );
  return res.data;
};

export const fetchContaCorrente = async (params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/contas_correntes/conta`, {
    params,
  });
  return res.data;
};

export const fetchStatusLancamentos = async (params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/status_lancamento`, {
    params,
  });
  return res.data;
};

export const fetchContasOptions = async (params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/contas`, { params });
  return res.data.results.map((d: any) => ({
    label: d.pessoa.name,
    value: d.id,
  }));
};

export const fetchResponsavel = async (params: any) => {
  const res = await axios.get(`${API_HOST}/financeiro/responsavel`, { params });
  return res.data.results;
};

export const deleteDesconto = async (id: any) => {
  const res = await axios.delete(`${API_HOST}/financeiro/descontos/${id}`);
  return res.data;
};

export const cancelarLancamentos = (data: any, params: any) =>
  axios.post(`${API_HOST}/financeiro/lancamentos/cancelar`, data, params);

export const fetchContratos = async ({ contaId }: any) => {
  const res = await axios.get(
    `${API_HOST}/financeiro/contas/${contaId}/contratos`
  );
  return res.data.results.map((d: any) => ({
    id: d.id,
    name: `${d.descricao} - ${d.tipo}`,
  }));
};

export const fetchStudentExtraDataFromBoleto = async (params: any) => {
  const res = await axios.get(
    `${API_HOST}/financeiro/pagamentos/with_students_data`,
    { params }
  );
  return res.data;
};

export const fetchAcordos = (params: any) => {
  return axios
    .get(`${API_HOST}/financeiro/acordos`, { params })
    .then((response) => response.data);
};

export const fetchAcordo = (acordoId: number) => {
  return axios
    .get(`${API_HOST}/financeiro/acordo/${acordoId}`)
    .then((response) => response.data);
};

export const fetchBoletoUrl = (boletoId: number) => {
  return axios
    .get(`${API_HOST}/financeiro/pagamentos/${boletoId}/boleto_url`)
    .then((response) => response.data);
};
