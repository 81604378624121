const retry = (fn, retriesLeft = 2, interval = 1000) =>
  /* Use this with React.lazy to retry downloading a chunk if download fails.
   * After retrying `retriesLeft` times, page will be reloaded.
   *
   * USAGE:
   * const Component = lazy(() => retry(() => import('./Component')));
   */
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            window.location.reload();
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export default retry;
