import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import TableActionButton from '../../components/TableActionButton';
import DropdownMenu from './DropdownMenu';

class DropdownMenuButton extends Component {
  state = {
    active: false,
  };

  showMenu = () => {
    this.setState({ active: true }, () => {
      document.addEventListener('click', this.closeMenu);
      document.addEventListener('keyup', (e) => {
        const ESC = 27;
        if (e.keyCode === ESC) {
          this.closeMenu();
        }
      });
    });
  };

  closeMenu = () => {
    this.setState({ active: false }, () => {
      document.removeEventListener('click', this.closeMenu);
      document.removeEventListener('keyup', this.closeMenu);
    });
  };

  render() {
    const {
      children,
      icon,
      label,
      className,
      parentClassName,
      button: Button,
      tooltip,
    } = this.props;
    const { active } = this.state;

    return (
      <div className={parentClassName}>
        <ReactTooltip />
        <Button
          className={`dropdown-menu-button ${className} `}
          icon={icon}
          onClick={active ? this.closeMenu : this.showMenu}
          style={{ marginRight: 0 }}
          data-tip={tooltip}
        >
          {label}
        </Button>
        {active && <DropdownMenu>{children}</DropdownMenu>}
      </div>
    );
  }
}

DropdownMenuButton.propTypes = {
  icon: PropTypes.string,
  parentClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  label: PropTypes.node,
  button: PropTypes.func,
};

DropdownMenuButton.defaultProps = {
  className: '',
  parentClassName: '',
  icon: 'ellipsis-v',
  label: null,
  button: TableActionButton,
};

export default DropdownMenuButton;
