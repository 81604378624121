/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const InputCheckbox = ({ label, className, disabled, onChange, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <label
      className={`container-check-custom ${className} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <span className="container-check-custom-label">{label}</span>
      <input
        type="checkbox"
        {...props}
        {...field}
        checked={field.value}
        disabled={disabled}
        onChange={(event) => {
          setFieldValue(field.name, event.target.checked);
          if (onChange) {
            onChange(event.target.checked);
          }
        }}
      />
      <span className="checkmark" />
      {meta.error && <span className="input-helper red">{meta.error}</span>}
    </label>
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

InputCheckbox.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  onChange: () => {},
};

export default InputCheckbox;
