import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const SidebarItemOption = ({ to, children, onClick }) => {
  const externalLink = !to.startsWith('/');

  if (externalLink) {
    return (
      <li>
        <a href={to} className="tooltip-container" rel="noopener noreferrer">
          {children}
          <div className="tooltip-text">{to}</div>
        </a>
      </li>
    );
  }

  return (
    <li>
      <NavLink exact activeClassName="active-link" to={to} onClick={onClick}>
        {children}
      </NavLink>
    </li>
  );
};

SidebarItemOption.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarItemOption.defaultProps = {
  children: null,
  to: '',
  onClick: () => {},
};

export default SidebarItemOption;
