import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchGraduationsProcess = (params) =>
  axios
    .get(`${API_HOST}/graduation/process`, { params })
    .then((response) => response.data);

export const fetchGraduationProcess = (id, params) =>
  axios
    .get(`${API_HOST}/graduation/process/${id}`, { params })
    .then((response) => response.data);

export const sendGraduationProcess = (params) =>
  axios
    .post(`${API_HOST}/graduation/process`, params)
    .then((response) => response.data);

export const fetchApplications = (id) =>
  axios
    .get(`${API_HOST}/graduation/process/${id}/applications`)
    .then((response) => response.data);

export const retryApplication = (graduation_id, application_id) =>
  axios
    .post(
      `${API_HOST}/graduation/process/${graduation_id}/applications/${application_id}/retry`
    )
    .then((response) => response.data);

export const undoApplication = (graduation_id, application_id) =>
  axios
    .post(
      `${API_HOST}/graduation/process/${graduation_id}/applications/${application_id}/undo`
    )
    .then((response) => response.data);
