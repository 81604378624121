import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const SchoolRecordPage = lazy(() => retry(() => import('./SchoolRecordPage')));
const SelectStudentAndCurriculumPage = lazy(() =>
  retry(() => import('./SelectStudentAndCurriculumPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      permission="curricula.view_student"
      path="/historico-escolar/controle-academico/selecionar-curriculo"
      component={SelectStudentAndCurriculumPage}
    />
    <PrivateRoute
      exact
      permission="school_records.view_schoolrecord"
      path="/historico-escolar/por-aluno/:id"
      component={({ location, match }) => {
        const { id } = match.params;
        return (
          <Redirect to={`/historico-escolar/a/${id}/ca/${location.search}`} />
        );
      }}
    />
    <PrivateRoute
      exact
      permission="school_records.view_schoolrecord"
      path="/historico-escolar/meu-historico"
      component={() => <Redirect to="/historico-escolar/a/me/ca/" />}
    />
    <PrivateRoute
      exact
      permission="school_records.view_schoolrecord"
      path="/historico-escolar/a/:studentId(\d+|me)/ca/:studentCurriculumId?"
      component={SchoolRecordPage}
    />
  </Switch>
);
