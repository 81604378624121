import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DownloadCardButton = ({ link, children, onClick, className }) => (
  <a
    onClick={onClick}
    download
    key={link}
    href={link}
    className={`message-file ${className}`}
    target="_blank"
    rel="noreferrer noopener"
  >
    <FontAwesomeIcon icon="file-alt" className="mr-2 ml-2" /> {children}
  </a>
);

DownloadCardButton.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

DownloadCardButton.defaultProps = {
  onClick: () => {},
  className: '',
};

export default DownloadCardButton;
