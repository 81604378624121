import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/common/Card';
import Label from 'components/common/Label';
import React from 'react';
import { Lancamento } from 'services/financeiros/lancamentos';
import styled from 'styled-components';

type LancamentoCardProps = {
  lancamento: Lancamento;
  maxWidth?: string;
  shouldShowStatusSerasa?: boolean;
  className?: string;
};

const LancamentoCard = ({
  lancamento,
  className,
  shouldShowStatusSerasa,
}: LancamentoCardProps) => {
  return (
    <Card className={className}>
      <div>
        <div className="d-flex justify-space-between align-items-center mb-1">
          <div className="d-flex align-items-center">
            <span className="semi-bold font-16 mr-1">
              <span className="brown mr-1">#{lancamento.id}</span>
              {lancamento.tipo.descricao}
            </span>
            ({lancamento.mes_competencia}/{lancamento.ano_competencia})
          </div>
        </div>
        <div className="mb-1">
          {' '}
          <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>{' '}
          <span className="semi-bold">Venc.</span> {lancamento.data_vencimento}
        </div>
        <div className="mb-1">
          {' '}
          <FontAwesomeIcon icon="user"></FontAwesomeIcon>{' '}
          <span className="semi-bold">Resp.</span> {lancamento.responsavel.name}
        </div>
      </div>
      <div>
        <div className="d-flex justify-flex-end">
          {shouldShowStatusSerasa && lancamento.serasa_status ? (
            <Label color={lancamento.serasa_status.cor}>
              {lancamento.serasa_status.nome}
            </Label>
          ) : (
            <Label color={lancamento.status.cor}>
              {lancamento.status.nome}
            </Label>
          )}
        </div>
        <div className="mt-1 d-flex align-items-flex-end flex-direction-column">
          <span className="font-12">{lancamento.valor_bruto} ORIG.</span>
          <span className="font-12">{lancamento.valor_desconto} DESC.</span>
          <span className="font-16 semi-bold">{lancamento.valor_liquido}</span>
        </div>
      </div>
    </Card>
  );
};

export default styled(LancamentoCard)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: ${(props) => props.maxWidth || '600px'};
`;
