import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { REACT_APP_LABEL_DIPLOMA } from '../../consts';

const DiplomaRecord = (props) => {
  const { diploma, className, hasQR } = props;
  return (
    <ul className="flex-container">
      <div className={className}>
        <div className="main-container">
          <div className="title-header">
            <li className="flex-item">
              <h3>ALUNO {REACT_APP_LABEL_DIPLOMA}</h3>
            </li>
            <li className="flex-item">
              <span className="name-header">{diploma.person_name}</span>
            </li>
            <li className="flex-item">
              {diploma.person_cpf && (
                <span className="cpf-header">CPF: {diploma.person_cpf}</span>
              )}
            </li>

            <li className="flex-item">
              {hasQR && (
                <div className="diploma-qr">
                  <QRCode
                    size={128}
                    value={`${window.location.href}?id=${diploma.person_name}&qr=true`}
                  />
                </div>
              )}
            </li>
            <li className="flex-item">
              <p className="diploma-key">Curso</p>
              <p>
                {diploma.curso_emec} - {diploma.curso_name}
              </p>
            </li>

            {diploma.emec_diploma_dispatcher_code && (
              <li className="flex-item">
                <p className="diploma-key">Instituição Expedidora do Diploma</p>
                <p>
                  {diploma.emec_diploma_dispatcher_code} -{' '}
                  {diploma.emec_diploma_dispatcher_name}
                </p>
              </li>
            )}

            {diploma.emec_diploma_register_code && (
              <li className="flex-item">
                <p className="diploma-key">
                  Instituição Registradora do Diploma
                </p>
                <p>
                  {diploma.emec_diploma_register_code} -{' '}
                  {diploma.emec_diploma_register_name}
                </p>
              </li>
            )}

            {diploma.turma && (
              <li className="flex-item">
                <p className="diploma-key">Turma</p>
                <p>{diploma.turma}</p>
              </li>
            )}

            {diploma.ra && (
              <li className="flex-item">
                <p className="diploma-key">Registro Acadêmico</p>
                <p>{diploma.ra}</p>
              </li>
            )}
          </div>

          <ul className="flex-container"></ul>

          <div className="diploma-info">
            {diploma.student_admission_date && (
              <li className="flex-item">
                <p className="diploma-key">Data de Ingresso no Curso</p>
                <p>
                  {moment(diploma.student_admission_date).format('DD/MM/YYYY')}
                </p>
              </li>
            )}

            {diploma.student_conclusion_date && (
              <li className="flex-item">
                <p className="diploma-key">Data de Conclusão no Curso</p>
                <p>
                  {moment(diploma.student_conclusion_date).format('DD/MM/YYYY')}
                </p>
              </li>
            )}

            {diploma.diploma_dispatch_date && (
              <li className="flex-item">
                <p className="diploma-key">Data de Expedição do Diploma</p>
                <p>
                  {moment(diploma.diploma_dispatch_date).format('DD/MM/YYYY')}
                </p>
              </li>
            )}

            {diploma.diploma_register_date && (
              <li className="flex-item">
                <p className="diploma-key">Data de Registro do Diploma</p>
                <p>
                  {moment(diploma.diploma_register_date).format('DD/MM/YYYY')}
                </p>
              </li>
            )}

            {diploma.diploma_register_date && (
              <li className="flex-item">
                <p className="diploma-key">Número da Expedição do Diploma</p>
                <p>{diploma.dispatch_number_id}</p>
              </li>
            )}

            {diploma.register_number_id && (
              <li className="flex-item">
                <p className="diploma-key">Número do Registro do Diploma</p>
                <p>{diploma.register_number_id}</p>
              </li>
            )}

            {diploma.dou_publish_date && (
              <li className="flex-item">
                <p className="diploma-key">Data de Publicação no D.O.U</p>
                <p>{moment(diploma.dou_publish_date).format('DD/MM/YYYY')}</p>
              </li>
            )}

            {diploma.livro && (
              <li className="flex-item">
                <p className="diploma-key">Livro de Registro de Diploma</p>
                <p>{diploma.livro}</p>
              </li>
            )}
            {diploma.pagina && (
              <li className="flex-item">
                <p className="diploma-key">Página do Registro</p>
                <p>{diploma.pagina}</p>
              </li>
            )}
            {diploma.carga_horaria && (
              <li className="flex-item">
                <p className="diploma-key">Carga Horária</p>
                <p>{diploma.carga_horaria} horas</p>
              </li>
            )}
            {diploma.iniciado_em && (
              <li className="flex-item">
                <p className="diploma-key">Início do curso</p>
                <p>{moment(diploma.iniciado_em).format('MM/YYYY')}</p>
              </li>
            )}
            {diploma.finalizado_em && (
              <li className="flex-item">
                <p className="diploma-key">Término do curso</p>
                <p>{moment(diploma.finalizado_em).format('MM/YYYY')}</p>
              </li>
            )}

            {diploma.registrado_em && (
              <li className="flex-item">
                <p className="diploma-key">Data de Registro do Diploma</p>
                <p>{moment(diploma.registrado_em).format('DD/MM/YYYY')}</p>
              </li>
            )}
          </div>
        </div>
      </div>
    </ul>
  );
};

DiplomaRecord.propTypes = {
  className: PropTypes.string,
  diploma: PropTypes.shape({
    person_name: PropTypes.string,
    person_cpf: PropTypes.string,
    curso_emec: PropTypes.string,
    curso_name: PropTypes.string,
    emec_diploma_dispatcher_code: PropTypes.string,
    emec_diploma_dispatcher_name: PropTypes.string,
    emec_diploma_register_code: PropTypes.string,
    emec_diploma_register_name: PropTypes.string,
    student_admission_date: PropTypes.string,
    student_conclusion_date: PropTypes.string,
    diploma_dispatch_date: PropTypes.string,
    diploma_register_date: PropTypes.string,
    dispatch_number_id: PropTypes.string,
    register_number_id: PropTypes.string,
    dou_publish_date: PropTypes.string,
  }),
};

DiplomaRecord.defaultProps = {
  className: '',
  diploma: {},
  hasQR: false,
};

export default styled(DiplomaRecord)`
  padding-top: 10px;
  .flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .flex-item {
    margin: 40px;
    list-style: none;
  }
  .main-container {
    padding: 20px;
    background: rgb(250, 250, 250);
    display: flex;
    flex-flow: row wrap;
  }
  .name-header {
    color: #039f97;
    font-size: 30px;
  }
  .cpf-header {
    padding-left: 10px;
  }
  .title-header {
    padding-top: 20px;
  }
  .top-info {
    padding-top: 10px;
    position: relative;
  }
  .diploma-qr {
    position: relative;
    margin-top: 30px;
    right: 0;
    margin-right: 90px;
  }

  .diploma-info {
    padding-top: 10px;
  }

  .diploma-key {
    color: #039f97;
    font-weight: bold;
  }
`;
