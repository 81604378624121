import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import ReactTable from 'react-table';
import { tableTextFilterMethod } from '../utils';
import CellLabel from './CellLabel';
import LoadingIndicator from './LoadingIndicator';

const translations = {
  previousText: 'anterior',
  nextText: 'próximo',
  loadingText: <LoadingIndicator />,
  noDataText: 'nenhum dado encontrado',
  pageText: 'pág.',
  ofText: 'de',
  rowsText: 'linhas por página',
};

const mapToResponsiveColumn = (column) => {
  const DefaultCell = (row) => <Fragment>{row.value}</Fragment>;
  const Cell = column.Cell || DefaultCell;

  return {
    ...column,
    Cell: (row) => (
      <CellLabel row={row}>
        <Cell {...row} />
      </CellLabel>
    ),
  };
};

const Table = ({ isResponsive, columns, ...props }) => {
  const getColumns = () =>
    isResponsive ? columns.map(mapToResponsiveColumn) : columns;

  return (
    <ReactTable
      defaultFilterMethod={tableTextFilterMethod}
      className={`table-default ${isResponsive ? 'table-responsive' : ''}`}
      columns={getColumns()}
      onSortedChange={(d) => props.onSortedChange(d)}
      {...props}
      {...translations}
    />
  );
};

Table.propTypes = {
  isResponsive: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Table.defaultProps = {
  isResponsive: false,
};

export default Table;
