import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdownActionButton from './ActionDropdownActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionDropdownAction = ({ icon, label, value, onClick, ...rest }) => (
  <div>
    <ActionDropdownActionButton
      type="button"
      onClick={() => onClick(value)}
      {...rest}
    >
      {icon && (
        <span className="mr-1">
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      {label}
    </ActionDropdownActionButton>
  </div>
);

ActionDropdownAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  icon: PropTypes.string,
};

ActionDropdownAction.defaultProps = {
  icon: '',
};

export default ActionDropdownAction;
