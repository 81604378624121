import React, { Fragment, useEffect } from 'react';
import { asField } from 'informed';
import InputSelect from './InputSelect';

export default asField(({ fieldApi, fieldState, onChange, ...props }) => {
  useEffect(() => {
    if ((props.options.length === 1, props?.autoSelectOption)) {
      fieldApi.setValue(props.options[0]);
      if (onChange) {
        onChange(fieldApi.getValue());
      }
    }
  }, [props.options]);
  return (
    <Fragment>
      <InputSelect
        value={fieldState.value}
        onChange={(value) => {
          fieldApi.setError(null);
          fieldApi.setValue(value);
          if (onChange) {
            onChange(value);
          }
        }}
        error={fieldState.error}
        {...props}
      />
    </Fragment>
  );
});
