import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const IssueDeclarationRedirectPage = lazy(() =>
  retry(() => import('./IssueDeclarationRedirectPage'))
);
const IssuedDeclarationsPage = lazy(() =>
  retry(() => import('./IssuedDeclarationsPage'))
);
const AcademicDepartmentIssueDeclarationFormPage = lazy(() =>
  retry(() => import('./AcademicDepartmentIssueDeclarationFormPage'))
);
const StudentIssueDeclarationFormPage = lazy(() =>
  retry(() => import('./StudentIssueDeclarationFormPage'))
);
const DeclarationTemplateFormPage = lazy(() =>
  retry(() => import('./DeclarationTemplateFormPage'))
);
const DeclarationTemplateListPage = lazy(() =>
  retry(() => import('./DeclarationTemplateListPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      permission="declarations.add_template"
      path="/declaracoes/template"
      component={DeclarationTemplateListPage}
    />
    <PrivateRoute
      exact
      permission="declarations.add_template"
      path="/declaracoes/template/:id"
      component={DeclarationTemplateFormPage}
    />
    <PrivateRoute
      exact
      path="/declaracoes/emissao"
      component={IssueDeclarationRedirectPage}
    />
    <PrivateRoute
      exact
      permission="declarations.add_declaration"
      path="/declaracoes/emissao/controle-academico"
      component={AcademicDepartmentIssueDeclarationFormPage}
    />
    <PrivateRoute
      exact
      permission="declarations.add_my_declaration"
      path="/declaracoes/emissao/aluno"
      component={StudentIssueDeclarationFormPage}
    />
    <PrivateRoute
      exact
      path="/declaracoes"
      permission="declarations.view_declaration"
      component={IssuedDeclarationsPage}
    />
  </Switch>
);
