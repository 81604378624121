import styled from 'styled-components';
import { grayDark, grayLight, grayLighter } from '../../components/variables';

export default styled.button`
  padding: 15px;
  background-color: white;
  color: ${grayDark};
  font-size: 12px;
  font-weight: 500;
  border: 1px solid ${grayLight};
  cursor: pointer;
  min-width: 200px;
  max-width: 200px;
  text-align: left;

  &:hover:not(:disabled) {
    background-color: ${grayLighter};
  }

  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
`;
