import PropTypes from 'prop-types';
import React from 'react';
import { PermissionContext } from '../hoc/withPermission';
import Label from './common/Label';
import DownloadFromApiButton from './DownloadFromApiButton';
import LoadingIndicator from './LoadingIndicator';
import TableActionButton from './TableActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import Button from './common/buttons/Button';

const Card = ({
  title,
  children,
  loading,
  statusColor,
  statusName,
  downloadOptions,
  actions,
  cardWidth,
  titleOpiton,
  className,
  backButton,
  providedActionButton,
  providedActionFunction,
}) => {
  const history = useHistory();
  return (
    <div style={{ width: cardWidth }}>
      <div className="content-right">
        <div className={className}>
          {titleOpiton === true && (
            <h3 className="font-18 font-weight-700">{title}</h3>
          )}
          {statusColor && (
            <div className="ml-2">
              <Label color={statusColor}>{statusName}</Label>
            </div>
          )}
          {titleOpiton === true && <hr className="page-title-line mr-2" />}
          {actions &&
            actions.map((action) => (
              <TableActionButton
                key={action.id}
                icon={action.icon}
                onClick={action.action}
                className="ml-2"
              />
            ))}
          {backButton && (
            <div
              onClick={() => history.go(-1)}
              className="btn btn-rounded btn-rounded-white basic-shadow"
            >
              <FontAwesomeIcon
                icon="dollar-sign"
                style={{
                  marginLeft: '1px',
                  cursor: 'pointer',
                  fontSize: '15px',
                }}
              />
            </div>
          )}
          {providedActionButton && (
            <>
              <Button
                onClick={() =>
                  providedActionFunction && providedActionFunction()
                }
              >
                Gerar Relatório
              </Button>
            </>
          )}

          {downloadOptions && downloadOptions.showDownloadButton && (
            <PermissionContext.Consumer>
              {({ hasPermission }) =>
                hasPermission(downloadOptions.permission) && (
                  <div className="d-flex align-items-center justify-flex-end">
                    <DownloadFromApiButton
                      {...downloadOptions}
                      icon="download"
                    />
                  </div>
                )
              }
            </PermissionContext.Consumer>
          )}
        </div>
        <div>
          {loading ? (
            <div className="d-flex align-items-center justify-center">
              <LoadingIndicator />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  loading: PropTypes.bool,
  statusColor: PropTypes.string,
  statusName: PropTypes.string,
  cardWidth: PropTypes.number,
  downloadOptions: PropTypes.shape({
    api: PropTypes.string,
    showDownloadButton: PropTypes.bool,
    dataTip: PropTypes.string,
    permission: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      action: PropTypes.func,
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ),
  titleOpiton: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  title: '-',
  cardWidth: '',
  children: null,
  loading: false,
  statusColor: '',
  statusName: '',
  downloadOptions: {
    api: '',
    showDownloadButton: false,
    dataTip: '',
    permission: '',
  },
  actions: [],
  titleOpiton: true,
  className: 'page-title mb-2 d-flex align-items-center justify-center',
};

export default Card;
