export enum Actions {
  SET_SORT_NAME_DESC = 'SET_SORT_NAME_DESC',
  SET_CLSS_REPORT = 'SET_CLSS_REPORT',
  SET_STUDENT_REPORT = 'SET_STUDENT_REPORT',
  SET_CURRICULA_CONTENT = 'SET_CURRICULA_CONTENT',
  SET_CURRICULA_CONTENT_LOADING = 'SET_CURRICULA_CONTENT_LOADING',
  SET_SELECTED_TERM = 'SET_SELECTED_TERM',
  SET_CURRICULA_DISCIPLINES = 'SET_CURRICULA_DISCIPLINES',
  SET_TERM_ID = 'SET_TERM_ID',
  SET_GRIDS = 'SET_GRIDS',
  SET_TERMS = 'SET_TERMS',
}
