import React from 'react';

class SVGS extends React.Component {
  render() {
    const { name } = this.props;

    return (
      <div>
        {name === 'forbidden' && (
          <svg
            id="e278dd73-391b-4587-bb8e-30776dad1b52"
            data-name="Forbidden"
            viewBox="0 0 367 349.16"
            className="svg-size"
          >
            <defs>
              <linearGradient
                id="b1da634d-805d-4bfc-ad3d-921e53a484b2"
                x1="-106.6"
                y1="584.38"
                x2="-106.6"
                y2="583.38"
                gradientTransform="matrix(16.52, 0, 0, -21.38, 1958.45, 12569.39)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#86ced6" />
                <stop offset="1" stop-color="#5fdddd" />
              </linearGradient>
              <linearGradient
                id="b19c14c3-4c47-4215-babf-e22c7acf267e"
                x1="-106.6"
                y1="584.38"
                x2="-106.6"
                y2="583.38"
                gradientTransform="matrix(16.52, 0, 0, -21.38, 1896.27, 12569.39)"
              />
            </defs>
            <g id="fa9a9ed4-1b5a-4517-bf99-76e833ebe69f" data-name="Group 3317">
              <ellipse
                id="a74dc6f0-c14a-4fda-9817-edb77f79bf5a"
                data-name="Ellipse 877"
                className="ba2b28a5-99b5-481a-a3d6-27eb24280e6e"
                cx="183.5"
                cy="340.66"
                rx="183.5"
                ry="8.5"
              />
              <path
                id="ae2d087e-028b-428b-9826-e216e0c1e129"
                data-name="Path 4308"
                className="e9c1bacc-187d-4d99-a102-5e2203cc1ecf"
                d="M95,77.75c33,26.4-49.95,75.75,73.6,73.18s62.48-77.46,64-80.47,49.72-50.16,9.93-67-58.27,33.62-58.27,33.62-35.52,1.9-48.16,4.2S121.68-3.63,87.83,3.45,62,51.35,95,77.75Z"
                transform="translate(0 0)"
              />
              <path
                id="be69107c-71cf-4ccc-82d6-a1b5eba5bc93"
                data-name="Path 4309"
                className="a4940688-da3f-4b25-8f6b-0b6aaea6e669"
                d="M153,138.33c16.19-22.33,31.3,0,31.3,0"
                transform="translate(0 0)"
              />
              <path
                id="bc70a3e9-6af3-4056-aded-dedd71f26882"
                data-name="Path 4311"
                className="e63b201e-04da-4689-ab5c-c47e65031a99"
                d="M208.35,288.84c-18,44.52,1.63,48.29,1.63,48.29s53.58,11.09,23.51-64.37"
                transform="translate(0 0)"
              />
              <path
                id="f5f65650-e4e8-4be9-b4ad-9b22718f8a91"
                data-name="Path 4312"
                className="e63b201e-04da-4689-ab5c-c47e65031a99"
                d="M149.68,296.57c26.2,35.63,7.61,42.22,7.61,42.22s-50.47,18.67-35.35-52"
                transform="translate(0 0)"
              />
              <ellipse
                id="adad51f0-5d9e-4da4-b5c6-eceb1987d7f5"
                data-name="Ellipse 872"
                className="b613ad63-8c32-4e8b-9d2a-db998bd7653b"
                cx="162.71"
                cy="111.22"
                rx="8.86"
                ry="3.08"
              />
              <path
                id="b64109f3-edc6-4867-a53b-c093b49331fb"
                data-name="Path 4316"
                className="e9c1bacc-187d-4d99-a102-5e2203cc1ecf"
                d="M200.85,146.72s96.54,83.88,72.71,100S200.85,146.72,200.85,146.72Z"
                transform="translate(0 0)"
              />
              <path
                id="bdccf127-d56a-4fe7-ae23-0d3920aec2b9"
                data-name="Path 4317"
                className="e9c1bacc-187d-4d99-a102-5e2203cc1ecf"
                d="M144,148.26s-96.53,83.88-72.7,100S144,148.26,144,148.26Z"
                transform="translate(0 0)"
              />
              <path
                id="e8005af5-4127-4423-9861-16e5510e1f64"
                data-name="Path 4318"
                className="bc8a55b4-314b-4f97-839f-a6d88c5ec426"
                d="M207.6,114.31l20.18-15.14"
                transform="translate(0 0)"
              />
              <path
                id="b516a7a6-7d1e-4880-af93-ab6ca9e2b4e3"
                data-name="Path 4323"
                className="bc8a55b4-314b-4f97-839f-a6d88c5ec426"
                d="M133.31,115.27l-20.18-15.13"
                transform="translate(0 0)"
              />
              <path
                id="e8877935-9877-479b-8b70-77676d314269"
                data-name="Path 4319"
                className="bc8a55b4-314b-4f97-839f-a6d88c5ec426"
                d="M207.44,118.88l22.44-1"
                transform="translate(0 0)"
              />
              <path
                id="e76ca728-410f-4432-813a-3ea22f6b7799"
                data-name="Path 4322"
                className="bc8a55b4-314b-4f97-839f-a6d88c5ec426"
                d="M133.47,119.85,111,118.9"
                transform="translate(0 0)"
              />
              <path
                id="beab6816-0643-4de1-984a-420249ec464f"
                data-name="Path 4320"
                className="bc8a55b4-314b-4f97-839f-a6d88c5ec426"
                d="M207.22,124.82l20.94,12.42"
                transform="translate(0 0)"
              />
              <path
                id="e82d4dd1-d15f-4919-a46a-29bed6ee777e"
                data-name="Path 4321"
                className="bc8a55b4-314b-4f97-839f-a6d88c5ec426"
                d="M133.69,125.79l-20.94,12.43"
                transform="translate(0 0)"
              />
              <ellipse
                id="beff75d9-138d-4159-a095-fd3241e090fa"
                data-name="Ellipse 873"
                className="a8d79614-2f1d-4a58-851b-23c3954672cd"
                cx="221.47"
                cy="34.42"
                rx="17.01"
                ry="8.75"
                transform="translate(84 211.43) rotate(-61)"
              />
              <ellipse
                id="f11f12e6-0ef4-43f2-b786-8678ea5110e5"
                data-name="Ellipse 874"
                className="a8d79614-2f1d-4a58-851b-23c3954672cd"
                cx="98.07"
                cy="35.38"
                rx="8.74"
                ry="17"
                transform="translate(-4.22 55.56) rotate(-31)"
              />
              <ellipse
                id="a1647037-62a3-4c25-8182-22f77eeba3dd"
                data-name="Ellipse 875"
                className="bba96737-43b5-408d-9c89-3e3e0bb11990"
                cx="197.66"
                cy="88.34"
                rx="8.26"
                ry="10.69"
              />
              <ellipse
                id="a4cfae4e-b22d-4955-9048-3c9f8aba5c87"
                data-name="Ellipse 876"
                className="bba96737-43b5-408d-9c89-3e3e0bb11990"
                cx="135.48"
                cy="88.34"
                rx="8.26"
                ry="10.69"
              />
              <path
                id="ae570b2c-f846-4fb3-87a5-89b35339a0d4"
                data-name="Path 4327"
                className="ef26a2b0-8edb-4ef0-b756-68866bf8f4d4"
                d="M189.4,88.34"
                transform="translate(0 0)"
              />
              <path
                id="f24a1403-d03a-4996-8c6e-edbb7e0a7161"
                data-name="Path 4310"
                className="e9c1bacc-187d-4d99-a102-5e2203cc1ecf"
                d="M144.65,151.11S37,302.27,155.1,298.36s116-61.88,47-151.64"
                transform="translate(0 0)"
              />
              <path
                id="aee72d3a-80e8-4b30-ac0c-1ef6c9f3a2b8"
                data-name="Path 4315"
                className="a75657b5-bc3f-42dc-af17-1659c41383d1"
                d="M213,164.1c-54.85,47.92-79.19,0-79.19,0l9.31-13.77,57.76-2.19Z"
                transform="translate(0 0)"
              />
              <g
                id="ec564650-b38c-4cc1-99b9-dc794aa982ff"
                data-name="Group 3291"
              >
                <path
                  id="af47f4c3-276c-4209-b063-e1b60d44e7ab"
                  data-name="Path 4324"
                  className="bcc50b28-125b-49a1-8b0f-f70ce408cca4"
                  d="M213.78,192.85a11.49,11.49,0,0,0-22.25,4,11.5,11.5,0,1,0-19.87,7.86v0l.5.5,0,0,19.32,19.32,19.36-19.35.51-.52a11.49,11.49,0,0,0,2.39-11.9Z"
                  transform="translate(0 0)"
                />
                <path
                  id="a3dfe399-cb04-415b-999d-d2169103a473"
                  data-name="Path 4325"
                  className="b68d1de6-821d-4e1a-b970-02a79faa8c62"
                  d="M213.78,192.85a11.49,11.49,0,0,0-22.25,4,11.16,11.16,0,0,0-.09-1.42v29.06l.09.09,19.35-19.35.52-.52a11.51,11.51,0,0,0,2.38-11.9Z"
                  transform="translate(0 0)"
                />
                <path
                  id="b786f999-5551-40ff-8c8f-51f83cc280f8"
                  data-name="Path 4326"
                  className="e9c1bacc-187d-4d99-a102-5e2203cc1ecf"
                  d="M192.9,212a.22.22,0,0,1-.08,0,.71.71,0,0,1-.57-.43L187.49,200l-4.53,8.2a.72.72,0,0,1-.62.36h-13a.7.7,0,0,1-.71-.7.71.71,0,0,1,.71-.71h12.62l5-9.12a.74.74,0,0,1,.66-.36.7.7,0,0,1,.61.44l4.84,11.8,4.57-6.29a.71.71,0,0,1,1-.17.78.78,0,0,1,.25.32l1.85,4.74,1.45-2.14a.71.71,0,0,1,.58-.31h11.44a.7.7,0,0,1,.7.71.7.7,0,0,1-.7.7H203.12l-2,2.93a.69.69,0,0,1-1,.2.66.66,0,0,1-.27-.33L198,205.44l-4.55,6.26A.67.67,0,0,1,192.9,212Z"
                  transform="translate(0 0)"
                />
              </g>
              <path
                id="b8797c58-68be-4b71-8c9f-d3ea60520e35"
                data-name="Path 4328"
                className="a75657b5-bc3f-42dc-af17-1659c41383d1"
                d="M276.44,245.08,204.1,152.62s41.1,92.68,59.69,94.25"
                transform="translate(0 0)"
              />
              <path
                id="aa62b25d-dc5b-4045-a1ff-2b8fddb9bc5f"
                data-name="Path 4332"
                className="a75657b5-bc3f-42dc-af17-1659c41383d1"
                d="M71.62,248.26c-8.77-12.08,71.52-98.39,71.52-98.39l-35.72,70.4S80.38,260.33,71.62,248.26Z"
                transform="translate(0 0)"
              />
            </g>
          </svg>
        )}
        {name === 'not-found' && (
          <svg
            id="bdbcb489-0f7f-47a4-9f5b-e2f514c374cb"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 367 349.16"
            className="svg-size"
          >
            <defs>
              <linearGradient
                id="ff5dc906-4943-4c29-b1f8-39452318255c"
                x1="-106.6"
                y1="584.38"
                x2="-106.6"
                y2="583.38"
                gradientTransform="matrix(16.52, 0, 0, -21.38, 1958.45, 12569.39)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#86ced6" />
                <stop offset="1" stop-color="#5fdddd" />
              </linearGradient>
              <linearGradient
                id="a703456b-2dd0-4639-b2af-c085aa307c13"
                x1="-106.6"
                y1="584.38"
                x2="-106.6"
                y2="583.38"
                gradientTransform="matrix(16.52, 0, 0, -21.38, 1896.27, 12569.39)"
              />
            </defs>
            <g id="fff010ea-6636-4c7c-8c61-43b6a8cc09f4" data-name="Group 3316">
              <ellipse
                id="fe8af836-309b-46c8-9a2b-bdd51f4dfbff"
                data-name="Ellipse 877"
                className="a8b65191-7ca1-4a66-bfd7-1ed79ee9825e"
                cx="183.5"
                cy="340.66"
                rx="183.5"
                ry="8.5"
              />
              <path
                id="beac1e3e-770b-40de-a38a-ec3fdfa9ccd1"
                data-name="Path 4308"
                className="b290c448-5abf-4563-ae39-99a1b2808895"
                d="M95,77.75c33,26.4-49.95,75.75,73.6,73.18s62.48-77.46,64-80.47,49.72-50.16,9.93-67-58.27,33.62-58.27,33.62-35.52,1.9-48.16,4.2S121.68-3.63,87.83,3.45,62,51.35,95,77.75Z"
                transform="translate(0 0)"
              />
              <path
                id="a832f670-5fc5-49b1-bdd7-4e9d895813c4"
                data-name="Path 4311"
                className="b23dc51d-14cb-4e6c-9f48-20291fa0f0e9"
                d="M208.35,288.84c-18,44.52,1.63,48.29,1.63,48.29s53.58,11.09,23.51-64.38"
                transform="translate(0 0)"
              />
              <path
                id="a189cbc5-e82b-4e51-9f66-45cd0ad88a25"
                data-name="Path 4312"
                className="b23dc51d-14cb-4e6c-9f48-20291fa0f0e9"
                d="M149.68,296.57c26.2,35.63,7.61,42.22,7.61,42.22s-50.47,18.67-35.35-52"
                transform="translate(0 0)"
              />
              <ellipse
                id="a9f7d069-2a88-422b-a81b-77fa9f8c37ce"
                data-name="Ellipse 872"
                className="e381f779-b0d3-4f6a-96f4-9dd593883e14"
                cx="162.71"
                cy="111.22"
                rx="8.86"
                ry="3.08"
              />
              <path
                id="b044dc64-c62b-471b-ae3a-38b147d0fecd"
                data-name="Path 4316"
                className="b290c448-5abf-4563-ae39-99a1b2808895"
                d="M200.85,146.72s96.54,83.88,72.71,100S200.85,146.72,200.85,146.72Z"
                transform="translate(0 0)"
              />
              <path
                id="af0cfe08-b595-4a52-9920-f42be33b5f09"
                data-name="Path 4318"
                className="b6feb140-3c93-45a1-a06d-1f7f8a8a0312"
                d="M207.6,114.31l20.18-15.14"
                transform="translate(0 0)"
              />
              <path
                id="b81b9898-50ed-485e-97cf-fcaaeb67783f"
                data-name="Path 4323"
                className="b6feb140-3c93-45a1-a06d-1f7f8a8a0312"
                d="M133.31,115.27l-20.18-15.13"
                transform="translate(0 0)"
              />
              <path
                id="b809eba1-c60e-4583-9a2e-4db054d6267f"
                data-name="Path 4319"
                className="b6feb140-3c93-45a1-a06d-1f7f8a8a0312"
                d="M207.44,118.88l22.44-1"
                transform="translate(0 0)"
              />
              <path
                id="a58eb21a-51ce-4cb6-bbf5-926ea9d46f7d"
                data-name="Path 4322"
                className="b6feb140-3c93-45a1-a06d-1f7f8a8a0312"
                d="M133.47,119.85,111,118.9"
                transform="translate(0 0)"
              />
              <path
                id="b10f1304-e34c-430e-899f-49e0fd6e154e"
                data-name="Path 4320"
                className="b6feb140-3c93-45a1-a06d-1f7f8a8a0312"
                d="M207.22,124.82l20.94,12.42"
                transform="translate(0 0)"
              />
              <path
                id="a0f2a0fd-366f-47d2-8ba7-93034cbd1e9a"
                data-name="Path 4321"
                className="b6feb140-3c93-45a1-a06d-1f7f8a8a0312"
                d="M133.69,125.79l-20.94,12.43"
                transform="translate(0 0)"
              />
              <ellipse
                id="a3dd7cd5-0e5d-487c-a0ff-294fab7e9a90"
                data-name="Ellipse 873"
                className="b5c4b21f-f4e0-40bb-a465-9e38ec4010d8"
                cx="221.47"
                cy="34.42"
                rx="17.01"
                ry="8.75"
                transform="translate(84 211.43) rotate(-61)"
              />
              <ellipse
                id="a23ae78d-1fd7-48cd-83dd-ab75e8b078c2"
                data-name="Ellipse 874"
                className="b5c4b21f-f4e0-40bb-a465-9e38ec4010d8"
                cx="98.07"
                cy="35.38"
                rx="8.74"
                ry="17"
                transform="translate(-4.21 55.56) rotate(-31)"
              />
              <ellipse
                id="ab1bad8c-8af2-46e7-9e64-c6212f80615a"
                data-name="Ellipse 875"
                className="ee89c055-7b23-4951-bec5-99a30fe95806"
                cx="197.66"
                cy="88.34"
                rx="8.26"
                ry="10.69"
              />
              <ellipse
                id="a7852e76-4f4f-42a7-a8c1-72ddadef672d"
                data-name="Ellipse 876"
                className="ee89c055-7b23-4951-bec5-99a30fe95806"
                cx="135.48"
                cy="88.34"
                rx="8.26"
                ry="10.69"
              />
              <path
                id="f9e6fb4a-61df-4d15-96c2-e1b5d8afdf05"
                data-name="Path 4327"
                className="f45d2b9c-2a05-47cb-9753-8d836a341fe8"
                d="M189.4,88.34"
                transform="translate(0 0)"
              />
              <path
                id="f8fac5ac-d2ee-472c-bcf8-02d4de9a635e"
                data-name="Path 4310"
                className="b290c448-5abf-4563-ae39-99a1b2808895"
                d="M144.65,151.11S37,302.27,155.1,298.36s116-61.88,47-151.64"
                transform="translate(0 0)"
              />
              <path
                id="b3d64d7b-2b53-4de5-9900-6a0e07fbec17"
                data-name="Path 4315"
                className="a11f7ba2-ef7a-4225-9801-2f6ed262a94f"
                d="M213,164.1c-54.85,47.92-79.19,0-79.19,0l9.31-13.77,57.76-2.19Z"
                transform="translate(0 0)"
              />
              <g
                id="e752a86b-0d9b-4ced-8d92-5f3a66a9c694"
                data-name="Group 3291"
              >
                <path
                  id="b8e6fa8d-4889-45e6-b9cb-87236c136dec"
                  data-name="Path 4324"
                  className="b1225692-2c0a-47cb-8cce-29e8342b46cc"
                  d="M213.78,192.85a11.49,11.49,0,0,0-22.25,4,11.5,11.5,0,1,0-19.87,7.86v0l.5.5,0,0,19.32,19.32,19.36-19.35.51-.52a11.49,11.49,0,0,0,2.39-11.9Z"
                  transform="translate(0 0)"
                />
                <path
                  id="a26c7600-ab12-4e92-b1d3-a6a4f5e7c8a5"
                  data-name="Path 4325"
                  className="f2e94514-5050-4aba-b341-477f1d6fe949"
                  d="M213.78,192.85a11.49,11.49,0,0,0-22.25,4,11.16,11.16,0,0,0-.09-1.42v29.06l.09.09,19.35-19.35.52-.52a11.51,11.51,0,0,0,2.38-11.9Z"
                  transform="translate(0 0)"
                />
                <path
                  id="ae366ccf-2d5f-48d3-994b-014b5a7d9a1f"
                  data-name="Path 4326"
                  className="b290c448-5abf-4563-ae39-99a1b2808895"
                  d="M192.5,213.86h-.12a1,1,0,0,1-.78-.76L185,192.51l-6.28,14.54a1,1,0,0,1-.85.64H159.82a1.28,1.28,0,0,1-.94-1.55,1.32,1.32,0,0,1,.94-.94H177.3l7-16.17a1,1,0,0,1,.91-.64,1,1,0,0,1,.85.77l6.71,20.91,6.33-11.15a.9.9,0,0,1,.91-.5,1,1,0,0,1,.78.79l2.56,8.39,2-3.79a1,1,0,0,1,.81-.55H222a1.29,1.29,0,0,1,0,2.5H206.65l-2.76,5.2a.92.92,0,0,1-.91.54,1,1,0,0,1-.8-.79l-2.59-8.47-6.3,11.11A1,1,0,0,1,192.5,213.86Z"
                  transform="translate(0 0)"
                />
              </g>
              <path
                id="acc4cc26-24ca-434a-8ccc-2c1c0b407904"
                data-name="Path 4328"
                className="a11f7ba2-ef7a-4225-9801-2f6ed262a94f"
                d="M276.44,245.08,204.1,152.62s41.1,92.68,59.69,94.25"
                transform="translate(0 0)"
              />
              <path
                id="a7c6d692-40ff-4478-932b-a0aecc447d3e"
                data-name="Path 4377"
                className="f45d2b9c-2a05-47cb-9753-8d836a341fe8"
                d="M161,132"
                transform="translate(0 0)"
              />
              <path
                id="a5c50c56-1c9c-4108-8a27-dbf17d9f21c6"
                data-name="Path 4378"
                className="b5c4b21f-f4e0-40bb-a465-9e38ec4010d8"
                d="M171.88,133.61c6.67-15.19-20.06-15.9-15-1.13,0,0,8.33,16.32,15,1.13"
                transform="translate(0 0)"
              />
              <g
                id="a00fe297-f868-4ba0-8027-2aa4331b9cb3"
                data-name="Group 3298"
              >
                <path
                  id="f8c19f2b-741b-43a9-b0d9-8e766b490ea7"
                  data-name="Path 4379"
                  className="b290c448-5abf-4563-ae39-99a1b2808895"
                  d="M135.36,157.07s-59.24,55.26-28.53,61.5,52.74-73,52.74-73S163.58,126.22,135.36,157.07Z"
                  transform="translate(0 0)"
                />
                <path
                  id="ad44e209-90a9-4aa1-84c6-bf649caa7c75"
                  data-name="Path 4380"
                  className="a11f7ba2-ef7a-4225-9801-2f6ed262a94f"
                  d="M103,217.33s36.31-20.1,55.27-77.48c0,0,7.42-1-14.35,46.17C143.91,186,125.33,227.28,103,217.33Z"
                  transform="translate(0 0)"
                />
              </g>
              <g
                id="a976955f-0df5-4a76-bf33-a017f4f5491c"
                data-name="Group 3300"
              >
                <path
                  id="bbd5f7a0-8bb9-4907-8847-e219e3dbdff8"
                  data-name="Path 4381"
                  className="a41c562b-184a-4691-ad76-fa98a4c45daf"
                  d="M288.88,239.64l-14,3.75a6.33,6.33,0,0,0-4.47,7.74l1,3.84a6.32,6.32,0,0,0,7.74,4.46l14-3.75a6.33,6.33,0,0,0,4.46-7.74l-1-3.84A6.32,6.32,0,0,0,288.88,239.64Zm4.19,9.53A1.59,1.59,0,0,1,292,251.1l-14,3.75a1.57,1.57,0,0,1-1.92-1.11l-1-3.84a1.56,1.56,0,0,1,1.11-1.92l14-3.76a1.59,1.59,0,0,1,1.93,1.11Z"
                  transform="translate(0 0)"
                />
                <rect
                  id="fc79e660-1576-40b0-bb0c-2ba0260df1c7"
                  data-name="Rectangle 3186"
                  className="b290c448-5abf-4563-ae39-99a1b2808895"
                  x="255.17"
                  y="248.73"
                  width="70.22"
                  height="49.46"
                  rx="7.2"
                  transform="translate(-60.92 84.5) rotate(-15.01)"
                />
                <path
                  id="b02dd91f-51fa-46dc-aee3-127ba99bcc7b"
                  data-name="Path 4382"
                  className="a11f7ba2-ef7a-4225-9801-2f6ed262a94f"
                  d="M311.2,242.25,284,249.54l12.81,47.77L324,290a6.82,6.82,0,0,0,4.82-8.36l-9.27-34.58A6.84,6.84,0,0,0,311.2,242.25Z"
                  transform="translate(0 0)"
                />
                <g
                  id="ede1de92-4214-4d67-b66a-7f1769d53d62"
                  data-name="Group 3299"
                >
                  <rect
                    id="f442c645-d4e3-4563-923d-dd499f49dbd1"
                    data-name="Rectangle 3187"
                    className="f2e94514-5050-4aba-b341-477f1d6fe949"
                    x="274.45"
                    y="268.56"
                    width="32.58"
                    height="9.56"
                    transform="translate(-60.87 84.62) rotate(-15.01)"
                  />
                  <rect
                    id="f22fb584-8a10-4f7a-9cc4-d271e6aa7db8"
                    data-name="Rectangle 3188"
                    className="f2e94514-5050-4aba-b341-477f1d6fe949"
                    x="285.97"
                    y="257.05"
                    width="9.56"
                    height="32.58"
                    transform="translate(-60.87 84.62) rotate(-15.01)"
                  />
                </g>
              </g>
            </g>
          </svg>
        )}
      </div>
    );
  }
}

export default SVGS;
