import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';
import ExamineGraduationProcess from './ExamineGraduationProcess';

const GraduationProcessPage = lazy(() =>
  retry(() => import('./GraduationProcessPage'))
);
const NewGraduationProcessPage = lazy(() =>
  retry(() => import('./NewGraduationProcessPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/graduacao/processos"
      component={GraduationProcessPage}
      permission="graduation.view_process"
    />
    <PrivateRoute
      exact
      path="/graduacao/processos/new"
      component={NewGraduationProcessPage}
      permission="graduation.add_process"
    />
    <PrivateRoute
      exact
      path="/graduacao/processos/:id"
      component={ExamineGraduationProcess}
      permission="graduation.view_process"
    />
  </Switch>
);
