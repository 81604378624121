import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import withTracker from './hoc/withTracker';
import Main from './Main';
import Forbidden from './pages/errors/Forbidden';
import ChangePassword from './pages/login/ChangePassword';
import ForgotPassword from './pages/login/ForgotPassword';
import DiplomaRecordPage from './pages/curricula/DiplomaRecordPage';
import LoginPage from './pages/login/LoginPage';
import './register-css';
import './register-fa-icons';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <>
          <ToastContainer
            autoClose={20000}
            transition={Slide}
            closeOnClick
            pauseOnHover
          />
          <Switch>
            <Route path="/diplomas" component={DiplomaRecordPage} />
            <Route path="/forbidden" component={Forbidden} />
            <Route exact path="/login" component={withTracker(LoginPage)} />
            <Route
              path="/forgot-password"
              component={withTracker(ForgotPassword)}
            />
            <Route
              path="/password-reset/:token"
              component={withTracker(ChangePassword)}
            />
            <Main />
          </Switch>
        </>
      </BrowserRouter>
    );
  }
}

export default App;
