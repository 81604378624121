import React from 'react';
import PropTypes from 'prop-types';

const LoadingText = ({ text, className, tag: Tag }) => (
  <Tag className={`saving ${className}`}>
    {text}
    <span> .</span>
    <span>.</span>
    <span>.</span>
  </Tag>
);

LoadingText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.oneOf(['p', 'span']),
};

LoadingText.defaultProps = {
  text: '',
  className: '',
  tag: 'p',
};

export default LoadingText;
