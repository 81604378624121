import {
  SET_LANCAMENTO,
  SET_LANCAMENTO_LOADING,
  SET_LOGS,
  SET_LOGS_LOADING,
  SET_DISCOUT_LOGS_LOADING,
  SET_DISCOUNT_LOGS,
} from './types';

const LancamentoReducer = (state, action) => {
  switch (action.type) {
    case SET_LANCAMENTO:
      return {
        ...state,
        lancamento: action.payload,
        lancamentoLoading: false,
      };

    case SET_LANCAMENTO_LOADING:
      return {
        ...state,
        lancamentoLoading: action.payload,
      };

    case SET_LOGS:
      return {
        ...state,
        logs: action.payload,
        loadingLogs: false,
      };

    case SET_LOGS_LOADING:
      return {
        ...state,
        loadingLogs: true,
      };

    case SET_DISCOUT_LOGS_LOADING:
      return {
        ...state,
        loadingDiscountLogs: true,
      };

    case SET_DISCOUNT_LOGS:
      return {
        ...state,
        loadingDiscountLogs: false,
        discountLogs: action.payload,
      };

    default:
      return state;
  }
};

export default LancamentoReducer;
