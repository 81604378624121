import React, { Component, createContext } from 'react';
import { fetchPermissions } from '../services/list_services';

export const PermissionContext = createContext({
  permissions: {},
  fetchingPermissions: true,
  error: false,
  hasPermission: (_) => false,
});

const withPermission = (WrappedComponent) =>
  class Hoc extends Component {
    state = {
      permissions: {},
      fetchingPermissions: true,
      error: false,
    };

    componentDidMount() {
      this.fetchPermissions();
    }

    fetchPermissions = () => {
      fetchPermissions()
        .then((permissions) =>
          this.setState({ permissions, fetchingPermissions: false })
        )
        .catch(() =>
          this.setState({ fetchingPermissions: false, error: true })
        );
    };

    hasPermission = (permission) => {
      const { permissions } = this.state;
      // eslint-disable-next-line no-underscore-dangle
      return permissions.__all__ === true || permissions[permission] === true;
    };

    render() {
      const { permissions, fetchingPermissions, error } = this.state;

      return (
        <PermissionContext.Provider
          value={{
            error,
            fetchingPermissions,
            permissions,
            hasPermission: this.hasPermission,
          }}
        >
          <WrappedComponent
            {...this.props}
            permissions={permissions}
            hasPermission={this.hasPermission}
          />
        </PermissionContext.Provider>
      );
    }
  };

export default withPermission;
