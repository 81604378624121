import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';
import PromotionCheckPage from './PromotionCheckPage';
import CurriculaContentstate from 'contexts/curricula/CurriculaContentstate';
import CurriculaContentContext from 'contexts/curricula/CurriculaContentContext';

const CompletedDisciplinesPage = lazy(() =>
  retry(() => import('./CompletedDisciplinesPage'))
);
const AggStudentGradesReportPage = lazy(() =>
  retry(() => import('./AggStudentGradesReportPage'))
);
const StudentCurrentStatusPage = lazy(() =>
  retry(() => import('./StudentCurrentStatusPage'))
);
const InformedDiscApplicationFormAsAcadDeptPage = lazy(() =>
  retry(() => import('./InformedDiscApplicationFormAsAcadDeptPage'))
);
const InformedDiscApplicationFormAsStudentPage = lazy(() =>
  retry(() => import('./InformedDiscApplicationFormAsStudentPage'))
);
const InformedDiscApplicationFormRedirectPage = lazy(() =>
  retry(() => import('./InformedDiscApplicationFormRedirectPage'))
);
const InformedDiscApplicationPage = lazy(() =>
  retry(() => import('./InformedDiscApplicationPage'))
);
const CurriculumPage = lazy(() => retry(() => import('./CurriculumPage')));
const ListCurriculumPage = lazy(() =>
  retry(() => import('./ListCurriculumPage'))
);
const CreateCurriculumPage = lazy(() =>
  retry(() => import('./CreateCurriculumPage'))
);
const CurriculumDisciplinePage = lazy(() =>
  retry(() => import('./CurriculumDisciplinePage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      permission="curricula.view_completeddiscipline"
      path="/curriculos/disciplinas-concluidas"
      component={CompletedDisciplinesPage}
    />
    <PrivateRoute
      exact
      path="/curriculos/relatorio-aluno-agg"
      permission="curricula.view_agg_student_report"
      component={AggStudentGradesReportPage}
    />
    <PrivateRoute
      exact
      path="/curriculos/status-atual-aluno"
      permission="curricula.view_agg_student_report"
      component={StudentCurrentStatusPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/disciplina-informada/tipo/:type/new/controle-academico"
      permission="curricula.add_informeddiscapplication_as_academic_department"
      component={InformedDiscApplicationFormAsAcadDeptPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/disciplina-informada/tipo/:type/new"
      component={InformedDiscApplicationFormRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/disciplina-informada/tipo/:type/new/aluno"
      permission="curricula.add_informeddiscapplication_as_student"
      component={InformedDiscApplicationFormAsStudentPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/disciplina-informada/:id"
      component={InformedDiscApplicationPage}
    />
    <PrivateRoute
      exact
      path="/curriculos/promocoes"
      component={PromotionCheckPage}
    />
    <CurriculaContentstate>
      <CurriculaContentContext.Consumer>
        {() => (
          <Switch>
            <PrivateRoute
              exact
              path="/curriculo/:id"
              component={CurriculumPage}
            />
            <PrivateRoute
              exact
              path="/listar-curriculos"
              component={ListCurriculumPage}
            />
            <PrivateRoute
              exact
              path="/novo-curriculo"
              component={CreateCurriculumPage}
            />
            <PrivateRoute
              exact
              path="/curriculo-grades/:id"
              component={CurriculumDisciplinePage}
            />
          </Switch>
        )}
      </CurriculaContentContext.Consumer>
    </CurriculaContentstate>
  </Switch>
);
