import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ACTIVE_CURRICULUM_STATUS } from '../../consts';
import DownloadCardButton from '../common/buttons/DownloadCardButton';
import UserIcon from '../../common/data-display/UserIcon';
import TransacaoError from './TransacaoError';

const statusColor = (status_id = ACTIVE_CURRICULUM_STATUS) =>
  status_id === ACTIVE_CURRICULUM_STATUS ? '#3f928b' : '#b77474';

const StudentRemessaCard = ({ student }) => (
  <div>
    <div className="ml-3 student-remessa-card">
      <div className="student-remessa-content">
        <div className="student-data">
          <div className="d-flex flex-direction-column justify-space-between align-items-center student-remessa-column mr-3">
            <UserIcon
              className="student-remessa-user-img"
              src={get(student, 'aluno.photo')}
              alt={get(student, 'aluno.name')}
            />
            <div
              style={{
                background: statusColor(get(student, 'status_aluno.id')),
              }}
              className="clean-tag green w-100 mt-1"
            >
              {get(student, 'status_aluno.name', '-')}
            </div>
          </div>
          <div>
            <h3 className="font-16 font-weight-600">
              {get(student, 'aluno.name', '-')}
            </h3>
            <div className="d-flex align-items-center font-14 mt-1">
              <FontAwesomeIcon className="text-center" icon="graduation-cap" />
              <p className="m-0 ml-1 font-weight-600">
                {get(student, 'curriculo.course.name', '-')}
              </p>
            </div>
            <div className="d-flex align-items-center font-14">
              <FontAwesomeIcon className="text-center" icon="book" />
              <p className="m-0 ml-1 font-weight-600">
                {get(student, 'curriculo.name', '-')}
              </p>
            </div>
            <p className="student-remessa-paragraph">
              Responsável Fin.:
              <span className="text-black">
                {' '}
                {get(student, 'responsavel_financeiro.name', '-')}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex font-15 font-weight-600">
        <div className="mr-3">
          <p className="brown m-0">
            Competência:
            <span className="text-black">
              {' '}
              {student.mes_competencia || '-'}/{student.ano_competencia || '-'}{' '}
            </span>
          </p>
          <p className="brown m-0 mt-1">
            Categoria:
            <span className="text-black">
              {' '}
              {get(student, 'tipo.descricao', '-')}
            </span>
          </p>
          <p className="brown m-0 mt-1">
            Status:
            <span className="text-black">
              {' '}
              {get(student, 'status.nome', '-')}
            </span>
          </p>
        </div>
        <div>
          <p className="brown m-0">
            Venc:
            <span className="text-black">
              {' '}
              {student.data_vencimento || '-'}
            </span>
          </p>
          <p className="brown m-0 mt-1">
            Valor:
            <span className="text-black"> {student.valor_liquido || '-'}</span>
          </p>
        </div>
      </div>
      {student.link && (
        <div className="d-flex align-items-center">
          <DownloadCardButton
            className="student-card-download-button"
            href={student.link}
          />
        </div>
      )}
    </div>
    <div className="mt-2">
      <TransacaoError>{student.error}</TransacaoError>
    </div>
  </div>
);

StudentRemessaCard.propTypes = {
  student: PropTypes.shape({
    aluno: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    curso: PropTypes.string,
    date: PropTypes.string,
    mes_competencia: PropTypes.number,
    ano_competencia: PropTypes.number,
    tipo: PropTypes.shape({
      descricao: PropTypes.string,
      id: PropTypes.number,
    }),
    data_vencimento: PropTypes.string,
    valor_liquido: PropTypes.string,
    financer: PropTypes.string,
    status: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
};

StudentRemessaCard.defaultProps = {
  student: {},
};

export default StudentRemessaCard;
