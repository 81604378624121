import { get } from 'lodash';
import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import defaultPhoto from '../../assets/img/user.png';
import CircleImage from '../../common/data-display/CircleImage';
import ContaContext from '../../contexts/conta/ContaContext';
import ContaCorrenteModal from './ContaCorrenteModal';
import DefinitionList from '../DefinitionList';
import Button from '../common/buttons/Button';

const FichaFinanceiraDetails = () => {
  const [showContaCorrenteModal, setContaCorrenteModalVisibility] = useState(
    false
  );
  const contaContext = useContext(ContaContext);
  const contaId = get(contaContext, 'conta.id');

  if (get(contaContext, 'contaLoading')) {
    return (
      <div data-testid="ficha-details-loading" className="m-3">
        <Skeleton width="100%" height="100px" />
      </div>
    );
  }

  return (
    <div data-testid="ficha-details" className="ficha-details">
      <div className="ficha-aluno">
        <CircleImage
          src={get(contaContext, 'conta.pessoa.photo_url') || defaultPhoto}
          alt="student-photo"
        />
        <DefinitionList
          title="Aluno"
          text={
            <Link
              className="brown text-underline"
              to={`/fichas-financeiras/${contaId}`}
            >
              {get(contaContext, 'conta.pessoa.name')}
            </Link>
          }
          className="flex-75 ml-2"
        />
        <DefinitionList
          title="CPF"
          text={get(contaContext, 'conta.pessoa.cpf')}
          className="flex-50 ml-2"
        />
        <DefinitionList
          title="Telefone"
          text={get(contaContext, 'conta.pessoa.phone')}
          className="flex-50 ml-2"
        />
        <DefinitionList
          title="E-mail"
          text={get(contaContext, 'conta.pessoa.email')}
          className="flex-50 ml-2"
        />
        <DefinitionList
          title="Curso"
          text={get(contaContext, 'conta.pessoa.curso')}
          className="flex-50 ml-2"
        />
        <DefinitionList
          title="Currículo"
          text={get(contaContext, 'conta.pessoa.curriculo')}
          className="flex-50 ml-2"
        />
      </div>
      <Button onClick={() => setContaCorrenteModalVisibility(true)}>
        <span className="ml-1">Conta Corrente</span>
      </Button>
      <ContaCorrenteModal
        showContaCorrenteModal={showContaCorrenteModal}
        onHide={() => setContaCorrenteModalVisibility(false)}
      />
      {/* {valorSaldoRestante > 0 && (
        <Dt title="Saldo">
          <CurrencyLabel currency={valorSaldoRestante}></CurrencyLabel>
        </Dt>
      )} */}

      <div className="form-grid-3 grid-gap-3">
        {/* <DefinitionList title="Total Pago" text={get(contaContext, 'conta.total_pago')} /> */}
        {/* <DefinitionList className="ficha-devendo" title="Total Devido" text={`R$ ${get(contaContext, 'conta.total_devido')}`} /> */}
        {/* <DefinitionList title="Total Crédito" text={get(contaContext, 'conta.saldo')} /> */}
      </div>
    </div>
  );
};

export default FichaFinanceiraDetails;
