import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_HOST, BOLETOS_SERVICE_HOST } from './consts';

export const fetchFiles = (prefix) => {
  if (prefix) {
    const params = { prefix };
    return axios
      .get(`${API_HOST}/uploads/files-metadata`, { params })
      .then((response) => response.data);
  }
  return Promise.resolve([]);
};

export const uploadFile = async (file, key, module, onUploadProgress) => {
  const params = {
    key,
    method: 'put',
    file_size: file.size,
  };
  let service = API_HOST;
  let url = `${service}/uploads/signed-url`;

  if (module === 'conciliation') {
    service = BOLETOS_SERVICE_HOST;
    url = `${service}/conciliation/signed-url`;
  }

  const response = await axios.get(url, { params }).catch((err) => {
    const fileSizeError = get(err, 'response.data["file_size"][0]', '');
    const nonFieldError = get(err, 'response.data["non_field_errors"][0]', '');
    if (fileSizeError) {
      toast.error(fileSizeError);
      return;
    }
    if (nonFieldError) {
      toast.error(nonFieldError);
      return;
    }
    toast.error(
      'Não foi possível fazer upload do arquivo. Por favor, tente novamente'
    );
  });

  const signedUrl = response.data.url;
  const options = {
    onUploadProgress,
    headers: {
      'Content-Type': file.type,
    },
  };
  const instance = axios.create({
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
  });

  return instance.put(signedUrl, file, options);
};

export const downloadFile = (key, service = 'portal') => {
  const params = {
    key,
    method: 'get',
    service,
  };
  const filename = key.split('/').slice(-1)[0];

  return axios
    .get(`${API_HOST}/uploads/signed-url`, { params })
    .then((response) => {
      const { url } = response.data;
      fetch(url).then((t) =>
        t.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', filename);
          a.click();
        })
      );
    });
};

export const getFileLink = (key, service = 'portal') => {
  const params = {
    key,
    method: 'get',
    service,
  };

  return axios
    .get(`${API_HOST}/uploads/signed-url`, { params })
    .then((response) => response.data);
};
