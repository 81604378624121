import Button from 'components/common/buttons/Button';
import ContentModal from 'components/common/ContentModal';
import TextArea from 'components/formik_inputs/TextArea';
import WarningMessage from 'components/WarningMessage';
import { AcordoContext } from 'contexts/lancamentos/AcordoState';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { cancelarAcordo } from 'services/financeiros/acordos';

type CancelarAcordoModalProps = {
  onHide: () => void;
  visible: boolean;
};

const CancelarAcordoModal = ({ visible, onHide }: CancelarAcordoModalProps) => {
  const { acordo, refetch } = useContext(AcordoContext);
  const cancelarAcordoInitialValues = {
    justificativa: '',
  };

  const cancelarAcordoValidation = (
    values: typeof cancelarAcordoInitialValues
  ) => {
    let errors: { [key: string]: string } = {};

    if (!values.justificativa) {
      errors.justificativa = 'Este campo é obrigatório';
    }

    return errors;
  };

  const handleSubmit = async (values: typeof cancelarAcordoInitialValues) => {
    if (!acordo) {
      return;
    }

    await cancelarAcordo({ ...values, acordo: acordo?.id });
    onHide();
    refetch();
  };

  if (!acordo) {
    return null;
  }

  return (
    <ContentModal
      visible={visible}
      onHide={onHide}
      className=""
      footer={null}
      header={`Cancelar acordo #${acordo?.id}`}
    >
      <Formik
        initialValues={cancelarAcordoInitialValues}
        onSubmit={handleSubmit}
        validate={cancelarAcordoValidation}
      >
        {({ isSubmitting }) => (
          <Form className="h-100">
            <div className="h-100 d-flex justify-space-between flex-direction-column">
              <div>
                {(acordo?.usa_saldo_em_conta || 0) > 0 && (
                  <WarningMessage className="mb-3 semi-bold gray-dark">
                    <span>
                      Após o cancelamento, um saldo de{' '}
                      <strong>{acordo?.valor_saldo_entrada}</strong> será
                      adicionado à ficha financeira.
                    </span>
                  </WarningMessage>
                )}
                <TextArea
                  name="justificativa"
                  label="Justificativa"
                  onChange={null}
                  required
                  help=""
                  className=""
                />
              </div>
              <div className="text-align-right">
                <Button
                  type="submit"
                  action="primary"
                  icon={null}
                  disabled={isSubmitting}
                  className=""
                >
                  Enviar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ContentModal>
  );
};

export default CancelarAcordoModal;
