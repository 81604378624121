import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';
import CursoStateV2, {
  CursoStateV2Context,
} from 'contexts/cursos/CursoStateV2';

const CadastroCursoPage = lazy(() =>
  retry(() => import('./CadastroCursoPage'))
);
const NovoParceiro = lazy(() => retry(() => import('./NovoParceiroPage')));
const NovoCurriculo = lazy(() => retry(() => import('./NovoCurriculoPage')));

const NovaTurma = lazy(() => retry(() => import('./NovaTurmaPage')));
const FinalizarFluxoNovoCurso = lazy(() =>
  retry(() => import('./FinalizarFluxoNovoCursoPage'))
);

export default () => (
  <CursoStateV2>
    <CursoStateV2Context.Consumer>
      {() => (
        <Switch>
          <PrivateRoute exact path="/v2/cursos" component={CadastroCursoPage} />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/edit"
            component={CadastroCursoPage}
          />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/parceiros"
            component={NovoParceiro}
          />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/parceiros/edit"
            component={NovoParceiro}
          />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/curriculos"
            component={NovoCurriculo}
          />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/curriculos"
            component={NovoCurriculo}
          />
          <PrivateRoute exact path="/nova_turma" component={NovaTurma} />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/turmas"
            component={NovaTurma}
          />
          <PrivateRoute
            exact
            path="/v2/cursos/:id/finalizar"
            component={FinalizarFluxoNovoCurso}
          />
        </Switch>
      )}
    </CursoStateV2Context.Consumer>
  </CursoStateV2>
);
