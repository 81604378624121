import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PermissionContext } from '../../hoc/withPermission';
import RemessaContext from '../../contexts/Remessa/RemessaContext';
import { ActionDropdown } from 'common';

const RemessaDetailsActions = ({ className }) => {
  const actions = [];
  const { hasPermission } = useContext(PermissionContext);
  const { dispatch: remessaDispatch } = useContext(RemessaContext);

  if (hasPermission('financeiro.cancelar_transacao')) {
    actions.push({
      value: 'CANCELAMENTO_REMESSA',
      label: 'Cancelar remessa',
    });
  }
  actions.push({
    value: 'ENVIAR_EMAILS',
    label: 'Enviar emails',
  });

  return (
    actions.length > 0 && (
      <div className={className}>
        <ActionDropdown
          actions={actions}
          onClick={(action) => {
            if (action === 'CANCELAMENTO_REMESSA') {
              remessaDispatch({
                type: 'SET_MODAL',
                payload: 'CANCELAMENTO_REMESSA',
              });
            } else if (action === 'ENVIAR_EMAILS') {
              remessaDispatch({
                type: 'SET_MODAL',
                payload: 'ENVIAR_EMAILS',
              });
            }
          }}
        />
      </div>
    )
  );
};

RemessaDetailsActions.propTypes = {
  className: PropTypes.string,
};

RemessaDetailsActions.defaultProps = {
  className: '',
};

export default styled(RemessaDetailsActions)`
  display: flex;
  justify-content: flex-start;
`;
