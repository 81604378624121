import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const TabsMenuLink = ({ to, label, onClick }) => (
  <li>
    <NavLink replace to={to} onClick={onClick} activeClassName="tab--active">
      {label}
    </NavLink>
  </li>
);

TabsMenuLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TabsMenuLink.defaultProps = {
  onClick: () => {},
};

export default TabsMenuLink;
