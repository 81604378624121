import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchProcesses = (params) =>
  axios
    .get(`${API_HOST}/promotion/process`, { params })
    .then((response) => response.data);

export const fetchStudentProcesses = (params) =>
  axios
    .get(`${API_HOST}/promotion/student_process`, { params })
    .then((response) => response.data);

export const fetchStudentProcessResults = (params) =>
  axios
    .get(`${API_HOST}/promotion/student_process_result`, { params })
    .then((response) => response.data.results);
