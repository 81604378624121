import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title, className, children }) => (
  <div className={`page-title mb-2 d-flex align-items-center ${className}`}>
    <h4 className="font-18 font-weight-700 gray-dark">{title}</h4>
    <hr className="page-title-line mr-2" />
    {children}
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = {
  className: '',
  children: '',
};

export default Title;
