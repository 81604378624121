import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withCards from '../../hoc/withCards';
import PromotionProcessList from '../../components/promotion/PromotionProcessList';
import { fetchProcesses } from '../../services/promotion_services';
import Button from '../../components/common/buttons/Button';

const PromotionListPage = ({ renderLoading, fetchData, loading }) => (
  <>
    <div className="mb-3 d-flex justify-space-between">
      <Link className="btn btn-primary" to="/promocao/new">
        CRIAR NOVO PROCESSO
      </Link>
      <Button
        action="none"
        disabled={loading}
        className="btn is-icon"
        onClick={fetchData}
      >
        <FontAwesomeIcon
          icon="redo"
          className="font-18 silver-dark"
          spin={loading}
        />
      </Button>
    </div>
    <PromotionProcessList renderLoading={renderLoading} fetchData={fetchData} />
  </>
);

PromotionListPage.propTypes = {
  renderLoading: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withCards({
  fetch: fetchProcesses,
  title: 'Lista de processos de promoção',
  paginated: true,
  noResultsText: 'Nenhum processo de promoção encontrado.',
})(PromotionListPage);
