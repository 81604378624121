import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import withDownloadButton from '../../hoc/withDownloadButton';
import PromotionProcessCard from './PromotionProcessCard';
import { CardContext } from '../../hoc/withCards';
import ConfirmationModal from '../ConfirmationModal';
import { API_HOST } from '../../consts';

const PromotionProcessList = ({ renderLoading, fetchData, onDownload }) => {
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const handleDownloadPromoted = (processId) => {
    onDownload({
      api: `${API_HOST}/promotion/process/${processId}/promoted_excel`,
      params: {},
      filename: 'alunos_promocao.xlsx',
    });
  };

  const handleDownloadRetained = (processId) => {
    onDownload({
      api: `${API_HOST}/promotion/process/${processId}/retained_excel`,
      params: {},
      filename: 'alunos_retidos.xlsx',
    });
  };

  const handleProcessPromotion = (processId) => {
    setSelectedProcess(processId);
    setConfirmationModal(true);
  };

  const processPromotion = async () => {
    setConfirmationModal(false);
    const data = {
      process: selectedProcess,
    };
    try {
      await axios.post(`${API_HOST}/promotion/peform-promotion`, data);
      toast.success('Promoção está sendo efetivada com sucesso.');
      fetchData();
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar efetivar a promoção sugerida.');
    }
  };

  return (
    <CardContext.Consumer>
      {({ cards }) => (
        <>
          {renderLoading() || (
            <div className="card-deck">
              {cards.map((card) => (
                <PromotionProcessCard
                  key={card.id}
                  promotionProcess={card}
                  downloadPromotedExcel={handleDownloadPromoted}
                  downloadRetainedExcel={handleDownloadRetained}
                  onProcessPromotion={handleProcessPromotion}
                />
              ))}
            </div>
          )}
          <ConfirmationModal
            visible={confirmationModal}
            onHide={() => setConfirmationModal(false)}
            onConfirm={processPromotion}
          >
            Tem certeza que deseja efetivar essa sugestão de promoção?
          </ConfirmationModal>
        </>
      )}
    </CardContext.Consumer>
  );
};

PromotionProcessList.propTypes = {
  renderLoading: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default withDownloadButton(PromotionProcessList);
