import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import useFeature from '../hooks/useFeature';
import { fetchFiles } from '../s3';
import ChatLine from './ChatLine';
import Files from './common/Files';
import StatusIndicator from './StatusIndicator';
import BoletoChatLine from './tickets/BoletoChatLine';

const ReviewExplanation = ({
  children,
  ticket,
  files,
  loadingFiles,
  shouldGetFiles,
}) => {
  const [hasBoletoFeature] = useFeature('tickets__boleto');

  // Used when the user creates a boleto using the redo button.
  const [newBoletoId, setNewBoletoId] = useState(null);

  const initialState = {
    files: [],
    loading: false,
  };

  const filesReducer = (state, action) => {
    switch (action.type) {
      case 'SET_FILES':
        return {
          files: action.payload,
          loading: false,
        };
      case 'SET_LOADING':
        return {
          ...state,
          loading: !state.loading,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(filesReducer, initialState);

  const getFiles = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchFiles(get(ticket, 'files'))
      .then((r) => {
        dispatch({ type: 'SET_FILES', payload: r });
      })
      .catch(() => {
        dispatch({ type: 'SET_LOADING' });
      });
  }, [ticket]);

  useEffect(() => {
    if (shouldGetFiles) {
      getFiles();
    }
  }, [shouldGetFiles, getFiles]);

  const renderFiles = () => {
    if (shouldGetFiles) {
      return <Files loading={state.loading} files={state.files} />;
    }
    return <Files loading={loadingFiles} files={files} />;
  };

  return (
    <>
      <div className="d-flex justify-space-between mb-2">
        <StatusIndicator color={get(ticket, 'status.color')}>
          {get(ticket, 'status.name')}
        </StatusIndicator>
      </div>
      <ChatLine
        timestamp={get(ticket, 'created_at')}
        message={get(ticket, 'message')}
        privateMessage={get(ticket, 'private_message')}
        isAuthor={false}
        author={get(ticket, 'requested_by')}
        admissionMethod={get(ticket, 'admission_method')}
        canSeePrivate={get(ticket, 'permissions.can_see_review_private')}
      >
        <div className="mt-2" />
        {renderFiles()}
        {children}
      </ChatLine>
      {hasBoletoFeature && ticket.category.has_boleto && ticket.show_boleto && (
        <div className="mt-2">
          <BoletoChatLine
            id={newBoletoId || ticket.boleto_id}
            ticketId={ticket.id}
            onCreateBoleto={setNewBoletoId}
          />
        </div>
      )}
    </>
  );
};

ReviewExplanation.propTypes = {
  ticket: PropTypes.shape({
    status: PropTypes.shape({
      id: PropTypes.number,
    }),
    category: PropTypes.shape({
      has_boleto: PropTypes.bool,
    }),
    show_boleto: PropTypes.bool,
    boleto_id: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  shouldGetFiles: PropTypes.bool,
  loadingFiles: PropTypes.bool,
  children: PropTypes.node,
};

ReviewExplanation.defaultProps = {
  files: [],
  loadingFiles: false,
  shouldGetFiles: false,
  children: null,
};

export default ReviewExplanation;
