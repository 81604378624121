import axios from 'axios';
import { API_HOST } from '../consts';

export const fetchCurricula = (params) =>
  axios
    .get(`${API_HOST}/curricula/curricula`, { params })
    .then((response) => response.data);

export const fetchCurriculaOptions = (params) =>
  axios.get(`${API_HOST}/curricula/curricula`, { params }).then((response) =>
    response.data.map((d) => ({
      value: d.id,
      label: d.name,
    }))
  );

export const fetchPaginatedCurricula = (params) =>
  axios
    .get(`${API_HOST}/curricula/curricula/paginated`, { params })
    .then((response) => response.data);

export const fetchPaginatedCurriculaCards = (params) =>
  axios
    .get(`${API_HOST}/curricula/curricula/cards_content`, { params })
    .then((response) => response.data);

export const fetchStudentCurriculum = (params) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum`, { params })
    .then((response) => response.data);

export const fetchStudentCurriculumById = (id) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum/${id}`)
    .then((response) => response.data);

export const fetchStudentCurriculumWithCPF = (params) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum/with_cpf`, { params })
    .then((response) => response.data);

export const fetchStudentCurriculaFichaAluno = (params) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum/ficha_aluno`, { params })
    .then((response) => response.data);

export const fetchStudentCurriculaSupplementaryHours = (params) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum/supplementary_hours`, {
      params,
    })
    .then((response) => response.data);

export const fetchStudentCurriculumStatus = (params) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum_status`, { params })
    .then((response) =>
      response.data.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );

export const fetchStudentDocuments = (id) =>
  axios
    .get(`${API_HOST}/curricula/pending_documents/${id}`)
    .then((response) => response.data)
    .catch((error) => error.status);

export const fetchCertificate = (params) =>
  axios
    .get(`${API_HOST}/academico/check_has_academic_degree`, { params })
    .then((response) => response.data)
    .catch((error) => error.status);

export const retrieveCurricula = (id) =>
  axios
    .get(`${API_HOST}/curricula/curricula/${id}/detailed`)
    .then((response) => response.data);

export const fetchCurriculoFromCourse = (course) =>
  axios
    .get(`${API_HOST}/curricula/curricula/from_course`, { params: { course } })
    .then((response) => response.data);

export const fetchCurriculoContent = async (id) => {
  const response = await axios.get(
    `${API_HOST}/curricula/curricula/${id}/content`
  );
  return response.data;
};

export const createCurriculum = (params) =>
  axios
    .post(`${API_HOST}/curricula/curricula`, { ...params })
    .then((response) => response.data);

export const duplicateCurriculum = (id, params) =>
  axios
    .post(`${API_HOST}/curricula/curricula/${id}/duplicate_curriculum`, {
      ...params,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

export const filterStudentCurriculum = (params) =>
  axios
    .get(`${API_HOST}/curricula/student_curriculum/enturmacao_em_massa`, {
      params,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
