import React from 'react';
import { asField } from 'informed';

export default asField(
  ({
    fieldApi,
    onChange,
    fieldState,
    field,
    label,
    className,
    forwardedRef,
    ...props
  }) => (
    <div className={`input-type-style ${className}`}>
      <div className={fieldState.error ? 'input-error red' : 'input-style'}>
        <input
          value={fieldState.value || ''}
          onChange={(e) => {
            fieldApi.setValue(e.target.value);
            fieldApi.setError(null);
            if (onChange) {
              onChange(e);
            }
          }}
          {...props}
        />
      </div>
      <label
        htmlFor={field}
        className={`input-label-style ${fieldState.error ? 'red' : 'dim'}`}
      >
        {label}
      </label>
      {fieldState.error && (
        <span className="input-helper red">{fieldState.error}</span>
      )}
    </div>
  )
);
