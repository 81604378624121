import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import { fetchVaccineTypeOptions } from '../../../services/vaccine_services';

const VaccineTypeInputSelect = ({ submitOnChange, ...props }) => {
  const { setFieldValue, submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions fetchOptions={fetchVaccineTypeOptions}>
      {({ options, isLoading }) => (
        <InputSelect
          name="vaccine_type"
          label="Tipo de Vacina"
          placeholder="Selecionar"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          onChange={(value) => {
            if (value) {
              setFieldValue('vaccine_type', '');
            }
            if (submitOnChange) {
              submitForm();
            }
          }}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

VaccineTypeInputSelect.propTypes = {
  submitOnChange: PropTypes.bool,
};

VaccineTypeInputSelect.defaultProps = {
  submitOnChange: false,
};

export default VaccineTypeInputSelect;
