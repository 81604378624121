/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-state */
import React, { createContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'debounce-promise';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import Card from '../components/Card';

export const CardContext = createContext({
  page: 0,
});

const withCards = ({
  fetch,
  title,
  noResultsText,
  paginated = true,
  fetchDataOnMount = true,
  checkFirstFormChange = true,
}) => (WrappedComponent) =>
  class CardsDeck extends React.Component {
    state = {
      cards: [],
      pagination: 0,
      page: 0,
      loading: true,
      values: [],
      isMobile: false,
    };

    firstFormChange = true;

    handleChangeForm = debounce((values) => {
      this.setState(
        {
          values,
          page: 0,
        },
        () => {
          // If page filters use informed, this check avoids extra requests
          if (checkFirstFormChange) {
            if (!this.firstFormChange) {
              this.fetchData();
            }
            this.firstFormChange = false;
          } else {
            this.fetchData();
          }
        }
      );
    }, 800);

    componentDidMount() {
      if (fetchDataOnMount) {
        this.fetchData();
      }
      this.updateDimensions();
      window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    handleClick = () => {
      const { collapsed } = this.state;
      this.setState({ collapsed: !collapsed });
    };

    updateDimensions = () => {
      const width = window.innerWidth;
      const mobile = 768;

      this.setState({
        isMobile: width <= mobile,
      });
    };

    handlePageChange = (pageIndex) => {
      this.setState({ page: pageIndex.selected }, this.fetchData);
    };

    fetchData = () => {
      this.setState({ loading: true });

      const { page, values } = this.state;

      return fetch({
        page: page + 1,
        ...values,
      })
        .then((data) => {
          if (paginated) {
            this.setState({
              cards: data.results,
              pages: data.total_pages,
              nextPage: data.next,
              previousPage: data.previous,
              total: data.count,
              loading: false,
            });
          } else {
            this.setState({
              cards: data,
              loading: false,
            });
          }

          return data;
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    };

    renderPrevious() {
      return <FontAwesomeIcon icon="arrow-left" />;
    }

    renderNext() {
      return <FontAwesomeIcon icon="arrow-right" />;
    }

    renderLoading = () => {
      const { loading, cards } = this.state;

      if (loading) {
        return (
          <div className="mt-3">
            <Skeleton width="100%" height="100px" />
          </div>
        );
      }

      if (cards.length === 0) {
        return <div className="mt-3 mb-3">{noResultsText}</div>;
      }

      return null;
    };

    render() {
      const {
        cards,
        pagination,
        pages,
        page,
        loading,
        isMobile,
        total,
      } = this.state;

      return (
        <CardContext.Provider
          value={{
            onPageChange: this.handlePageChange,
            cards,
            pagination,
            page,
            pages,
            loading,
            total,
          }}
        >
          <Card title={title}>
            <WrappedComponent
              {...this.props}
              loading={loading}
              renderLoading={this.renderLoading}
              fetchData={this.fetchData}
              onPageChange={this.handlePageChange}
              onChangeForm={this.handleChangeForm}
              cards={cards}
            />
            {paginated && pages && (
              <ReactPaginate
                previousLabel={this.renderPrevious()}
                nextLabel={this.renderNext()}
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={isMobile ? 1 : 3}
                pageRangeDisplayed={isMobile ? 1 : 5}
                onPageChange={this.handlePageChange}
                containerClassName="pagination"
                subContainerClassName="page"
                activeClassName="active"
              />
            )}
          </Card>
        </CardContext.Provider>
      );
    }
  };

export default withCards;
