import PropTypes from 'prop-types';
import React from 'react';

const Dt = ({ title, children, first = false, DtWidth }) => (
  <p
    className={`mb-1 font-16 student-card-width brown ${
      first ? 'mt-0' : 'mt-1'
    }`}
    style={{ width: DtWidth }}
  >
    {title}:{' '}
    <span className="text-black">{children === 0 ? 0 : children || '-'}</span>
  </p>
);

Dt.propTypes = {
  DtWidth: PropTypes.number,
};
Dt.defaultProps = {
  DtWidth: '',
};

export default Dt;
