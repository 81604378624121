import Dt from 'components/common/Dt';
import { AcordoContext } from 'contexts/lancamentos/AcordoState';
import useModal from 'hooks/useModal';
import React from 'react';
import { Route } from 'react-router';
import styled from 'styled-components';
import AcordoActions from './AcordoActions';
import AcordoSection from './AcordoSection';
import CancelarAcordoModal from './CancelarAcordoModal';
import LancamentosCriados from './LancamentosCriados';
import LancamentosEmAcordo from './LancamentosEmAcordo';
import LancamentosMenu from './LancamentosMenu';

type AcordoProps = {
  className?: string;
};

const Acordo = ({ className }: AcordoProps) => {
  const [
    cancelarModalIsOpen,
    showCancelarModal,
    hideCancelarModal,
  ] = useModal();

  const handleActionChange = (actionName: string) => {
    switch (actionName) {
      case 'cancelar':
        showCancelarModal();
    }
  };

  return (
    <AcordoContext.Consumer>
      {({ acordo, acordoId, contaId, refetch }) => (
        <>
          {console.log(acordo)}
          <div className={className}>
            <pre className="mb-2">{acordo?.justificativa}</pre>
            <div className="d-flex">
              <Dt first title="Data do acordo">
                <strong>{acordo?.data_acordo}</strong>
              </Dt>
              <Dt first title="Valor refinanciado">
                <strong>{acordo?.valor_refinanciado}</strong>
              </Dt>
              <Dt first title="Saldo no Contrato">
                <strong>{acordo?.saldo_contrato}</strong>
              </Dt>
            </div>
            {acordo?.tipo_desconto && (
              <>
                <AcordoSection>Desconto</AcordoSection>
                <div className="d-flex">
                  <Dt first title="Valor">
                    {acordo?.valor_desconto}
                  </Dt>
                  <Dt first title="Tipo">
                    {acordo?.tipo_desconto?.descricao}
                  </Dt>
                </div>
              </>
            )}
            {acordo?.data_venc_entrada && (
              <>
                <AcordoSection>Entrada</AcordoSection>
                <div className="d-flex">
                  <Dt first title="Valor">
                    {acordo?.valor_entrada}
                  </Dt>
                  <Dt first title="Data de vencimento">
                    {acordo?.data_venc_entrada}
                  </Dt>
                </div>
              </>
            )}

            <div className="justify-space-between align-items-center d-flex-responsive">
              {acordoId && contaId && (
                <LancamentosMenu acordoId={acordoId} contaId={contaId} />
              )}
              <AcordoActions onChange={handleActionChange} />
            </div>
            <Route
              path={`/fichas-financeiras/${contaId}/acordos/${acordoId}/lancamentos-em-acordo`}
              component={LancamentosEmAcordo}
            />
            <Route
              path={`/fichas-financeiras/${contaId}/acordos/${acordoId}/lancamentos-criados`}
              component={LancamentosCriados}
            />
            <CancelarAcordoModal
              visible={cancelarModalIsOpen}
              onHide={hideCancelarModal}
            />
          </div>
        </>
      )}
    </AcordoContext.Consumer>
  );
};

export default styled(Acordo)`
  .d-flex > p {
    margin-right: 30px;
  }
`;
