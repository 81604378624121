import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import RemessaContext from '../../contexts/Remessa/RemessaContext';
import RemessaDetailsTitle from './RemessaDetailsTitle';
import RemessaDownloadButton from './RemessaDownloadButton';

const RemessaDetailsFileSection = ({ className }) => {
  const { remessa, fetchingRemessa, remessaFileValidation } = useContext(
    RemessaContext
  );

  if (fetchingRemessa) {
    return <Skeleton width="100%" height={110} />;
  }

  return (
    <section className={className}>
      <RemessaDetailsTitle>Arquivo de remessa</RemessaDetailsTitle>
      <div className="d-flex align-items-center">
        {remessa.tipo && remessa.tipo.id === 2 ? (
          <span>
            Remessas do Bradesco Online geram boletos automaticamente.
          </span>
        ) : (
          <>
            <RemessaDownloadButton remessa={remessa} />
            <span className="ml-2" />
            {remessaFileValidation &&
              remessaFileValidation.status ===
                'ok'(
                  <span className="success semi-bold">
                    <FontAwesomeIcon icon="check" /> Arquivo de remessa OK.
                  </span>
                )}
            {remessaFileValidation &&
              remessaFileValidation.status === 'not-ok' && (
                <span className="danger semi-bold">
                  <FontAwesomeIcon icon="times" /> O arquivo de remessa
                  apresentou problemas.
                </span>
              )}
          </>
        )}
      </div>
    </section>
  );
};

RemessaDetailsFileSection.propTypes = {
  className: PropTypes.string,
};

RemessaDetailsFileSection.defaultProps = {
  className: '',
};

export default styled(RemessaDetailsFileSection)`
  @media (max-width: 480px) {
    > div {
      display: grid;
      row-gap: 0;
    }
  }
`;
