import PropTypes from 'prop-types';
import React from 'react';

const InputText = ({
  className,
  error = undefined,
  label,
  field,
  ...props
}) => (
  <div className={`input-type-style ${className}`}>
    <div className={error ? 'input-error red' : 'input-style'}>
      <input id={field} {...props} />
    </div>
    <label
      htmlFor={field}
      className={`input-label-style ${error ? 'red' : 'dim'}`}
    >
      {label}
    </label>
    {error && <span className="input-helper red">{error}</span>}
  </div>
);

InputText.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  error: PropTypes.string,
};

InputText.defaultProps = {
  className: '',
  error: '',
};

export default InputText;
