import React from 'react';

const TransacaoError = ({ children }) => {
  try {
    return <span className="red semi-bold">{JSON.stringify(children)}</span>;
  } catch (_) {
    return <i>Erro indisponível.</i>;
  }
};

export default TransacaoError;
