import { asField } from 'informed';
import React from 'react';
import Textarea from 'react-textarea-autosize';

const InputTextAreaInformed = asField(
  ({
    fieldState,
    fieldApi,
    onChange,
    onBlur,
    forwardedRef,
    label,
    className,
    ...rest
  }) => (
    <div className={`input-type-style ${className}`}>
      {label && (
        <label
          className={`input-label-style text-area-label font-12 ${
            fieldState.error ? 'red' : 'dim'
          }`}
        >
          {label}
        </label>
      )}
      <div className="input-style">
        <Textarea
          ref={forwardedRef}
          minRows={2}
          onChange={(e) => {
            fieldApi.setValue(e.target.value, e);
            if (onChange) {
              onChange(e);
            }
          }}
          value={fieldState.value || ''}
          onBlur={(e) => {
            fieldApi.setTouched(true);
            if (onBlur) {
              onBlur(e);
            }
          }}
          {...rest}
        />
      </div>
      {fieldState.error && (
        <span className="input-helper red">{fieldState.error}</span>
      )}
    </div>
  )
);

export default InputTextAreaInformed;
