/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import withCards from '../../hoc/withCards';
import StudentPromotionProcessList from '../../components/promotion/StudentPromotionProcessList';
import { fetchStudentProcesses } from '../../services/promotion_services';

const StudentPromotionListPage = ({ onChangeForm, ...props }) => {
  const { id } = useParams();

  useEffect(() => {
    onChangeForm({ process: id });
  }, [id, onChangeForm]);

  return (
    <>
      <StudentPromotionProcessList {...props} />
      <Link to="/promocao" className="btn btn-link mt-3">
        VOLTAR
      </Link>
    </>
  );
};

export default withCards({
  fetch: fetchStudentProcesses,
  title: 'Alunos da promoção',
  paginated: true,
  fetchDataOnMount: false,
  checkFirstFormChange: false,
  noResultsText: 'Nenhum aluno encontrado nesse processo.',
})(StudentPromotionListPage);
