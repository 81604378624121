import PropTypes from 'prop-types';
import React from 'react';

const getStatus = (status) =>
  ({
    open: { color: '#74B777', name: 'Aberta' },
    processed_with_error: { color: '#e6646e', name: 'Processada com erros' },
    generated_with_error: { color: '#e6646e', name: 'Gerada com erros' },
    closed: { color: '#74B777', name: 'Finalizada' },
    cancelled: { color: '#e6646e', name: 'Cancelada' },
  }[status]);

const StatusRemessa = ({ status }) => {
  const { color, name } = getStatus(status) || {};
  return (
    <span className="tag" style={{ background: color }}>
      {name}
    </span>
  );
};

StatusRemessa.propTypes = {
  status: PropTypes.oneOf(['closed']).isRequired,
};

export default StatusRemessa;
