import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const NoticesPage = lazy(() => retry(() => import('./NoticesPage')));

export default () => (
  <Switch>
    <PrivateRoute exact path="/" component={NoticesPage} />
    <PrivateRoute
      exact
      path="/avisos"
      permission="notices.view_notice"
      component={NoticesPage}
    />
  </Switch>
);
