import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';
import RemessaSerasaDetailsPage from './RemessaSerasaDetailsPage';

const GenerateRemessaSerasaPage = lazy(() =>
  retry(() => import('./GenerateRemessaSerasaPage'))
);
const ReportRemessaSerasaPage = lazy(() =>
  retry(() => import('./ReportRemessaSerasaPage'))
);
const RemessasSerasaPage = lazy(() =>
  retry(() => import('./RemessasSerasaPage'))
);
const RetornoSerasPage = lazy(() => retry(() => import('./RetornoSerasPage')));

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/serasa"
      permission="financeiro.view_any_lancamento"
      component={RemessasSerasaPage}
    />
    <PrivateRoute
      exact
      path="/serasa/new"
      permission="financeiro.gerar_remessa"
      component={GenerateRemessaSerasaPage}
    />
    <PrivateRoute
      exact
      path="/serasa/relatorio"
      permission="financeiro.view_any_lancamento"
      component={ReportRemessaSerasaPage}
    />
    <PrivateRoute
      exact
      path="/serasa/retorno"
      permission="financeiro.view_any_lancamento"
      component={RetornoSerasPage}
    />
    <PrivateRoute
      exact
      path="/serasa/:id"
      permission="financeiro.view_any_lancamento"
      component={RemessaSerasaDetailsPage}
    />
  </Switch>
);
