import React from 'react';
import PropTypes from 'prop-types';
import BooleanIcon from '../common/BooleanIcon';

const StudentPromotionProcessCard = ({ className, classInfo, onCardClick }) => (
  <button
    type="button"
    onClick={onCardClick}
    className={`${className} btn-none card-100 card-body`}
  >
    <div className="d-flex justify-space-between">
      <p className="semi-bold brown text-underline">
        {classInfo.student_curriculum?.student?.name}
      </p>
      <p>
        <BooleanIcon value={classInfo.is_promoted} />
      </p>
    </div>
  </button>
);

StudentPromotionProcessCard.propTypes = {
  className: PropTypes.string,
  onCardClick: PropTypes.func,
  classInfo: PropTypes.shape({
    id: PropTypes.number,
    is_promoted: PropTypes.bool,
    student_curriculum: PropTypes.shape({
      student: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
};

StudentPromotionProcessCard.defaultProps = {
  className: '',
  onCardClick: () => {},
};

export default StudentPromotionProcessCard;
