import axios from 'axios';
import { API_HOST, BOLETOS_SERVICE_HOST } from '../../consts';

export const fetchRemessasStudents = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos`, { params })
    .then((response) => response.data);

export const fetchTipoRemessa = (params) =>
  axios
    .get(`${BOLETOS_SERVICE_HOST}/remessa/tipo_remessa`, { params })
    .then((response) => response.data);

export const createRemessas = (params) =>
  axios.post(`${API_HOST}/remessas`, params).then((response) => response.data);

export const fetchStatusRemessa = (params) =>
  axios
    .get(`${API_HOST}/remessas/status`, params)
    .then((response) => response.data);

export const fetchRemessasList = (params) =>
  axios
    .get(`${BOLETOS_SERVICE_HOST}/remessa/remessa-sicoob`, { params })
    .then((response) => response.data);

export const fetchRemessaDetail = (id) =>
  axios
    .get(`${BOLETOS_SERVICE_HOST}/remessa/remessa-sicoob/${id}`)
    .then((response) => response.data);

export const fetchRemessaLancamentos = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos/remessa`, { params })
    .then((response) => response.data);

export const validateRemessaFile = (remessaId) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/remessa/remessa-sicoob/remessa/${remessaId}/validate_file`
    )
    .then((response) => response.data);

export const fetchRemessaTotalValue = (params) =>
  axios
    .get(`${API_HOST}/financeiro/valor-total-remessa`, { params })
    .then((response) => response.data);
