import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';
import PequisaCpaState, {
  PequisaCpaContext,
} from 'contexts/pesquisaCpa/PequisaCpaState';

const ListaDocenteCpa = lazy(() => retry(() => import('./CpaDocenteListPage')));
const PesquisaCpa = lazy(() => retry(() => import('./CpaPage')));
const EditResposta = lazy(() => retry(() => import('./CpaEditRespostaPage')));

export default () => (
  <PequisaCpaState>
    <PequisaCpaContext.Consumer>
      {() => (
        <Switch>
          <PrivateRoute exact path="/docente-cpa" component={ListaDocenteCpa} />
          <PrivateRoute exact path="/resultados-cpa" component={PesquisaCpa} />
          <PrivateRoute
            exact
            path="/resultados-cpa/:id"
            component={PesquisaCpa}
          />
          <PrivateRoute
            exact
            path="/edit-resposta-cpa/:id"
            component={EditResposta}
          />
        </Switch>
      )}
    </PequisaCpaContext.Consumer>
  </PequisaCpaState>
);
