import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import { fetchMetodosPagamento } from '../../../services/lancamentos/lancamentos_services';

const MetodoPagamentoInput = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchMetodosPagamento}>
    {({ options, isLoading }) => (
      <InputSelect
        name="metodo_pagamento"
        label="Método de pagamento"
        placeholder="Selecione"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.nome}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);

export default MetodoPagamentoInput;
