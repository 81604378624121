import axios from 'axios';
import { Form } from 'informed';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import ConfirmationModal from '../components/ConfirmationModal';
import Forwardings from '../components/Forwardings';
import Ticket from '../components/Ticket';
import { API_HOST } from '../consts';
import fetchForwardings from '../services/list/tickets/fetch-forwardings';
import { fetchTicket } from '../services/retrieve_services';
import cancelTicket from '../services/tickets/cancel_ticket';

const withTicket = ({
  fetchTicketInfo = () => {},
  submitUrl,
  forwardingSubmitUrl = () => `${API_HOST}/tickets/forwardings`,
  mapInitialValues = () => {},
  mapSubmitValues = (values, ticketId) => {},
  setInitialValues = false,
  toastSubmitErrors = true,
}) => (WrappedComponent) =>
  class Hoc extends Component {
    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
      }).isRequired,
    };

    state = {
      fetchingTicket: true,
      fetchingTicketInfo: true,
      cancellingTicket: false,
      isAlertModalOpen: false,
      isResponsabilityModalOpen: false,
      ticket: null,
      ticketInfo: null,
      submitting: false,
      forwardings: [],
      submitErrors: [],
    };

    componentDidMount() {
      this.fetchTicket();
      this.fetchForwardings();
    }

    handleSubmit = (action = 'resolution', extraValues) => {
      if (action === 'forwarding') {
        this.handleForwardingSubmit();
      } else if (action === 'responsability') {
        this.handleResponsability();
      } else {
        // This is a workaround for tickets that need
        // data not in informed formState to be passed.
        this.handleResolutionSubmit(extraValues);
      }
    };

    handleResponsability = () => {
      this.setState({ isResponsabilityModalOpen: true });
    };

    handleResponsabilitySubmit = () => {
      this.setState({ submitting: true });
      const { values } = this.formApi.getState();
      const {
        match: {
          params: { id },
        },
      } = this.props;
      return axios
        .post(forwardingSubmitUrl(id), {
          group: get(values, 'responsability.group.id'),
          forwarded_to: get(values, 'responsability.forwarded_to.id'),
          message: get(values, 'responsability.message'),
          ticket: id,
        })
        .then(this.handleSubmitSuccess)
        .catch((error) => {
          const status = get(error, 'response.status');
          if (status === 400) {
            const errors = error.response.data;
            this.formApi.setError(
              'responsability.group',
              get(errors, 'group[0]')
            );
            this.formApi.setError(
              'responsability.message',
              get(errors, 'message[0]')
            );
            this.formApi.setError(
              'responsability.forwarded_to',
              get(errors, 'forwarded_to[0]')
            );
          }
          this.setState({ submitting: false });
        });
    };

    handleHideResponsabilityModal = () => {
      this.setState({
        isResponsabilityModalOpen: false,
      });
    };

    handleForwardingSubmit = () => {
      this.setState({ submitting: true });
      const { values } = this.formApi.getState();
      const {
        match: {
          params: { id },
        },
      } = this.props;
      return axios
        .post(forwardingSubmitUrl(id), {
          group: get(values, 'forwarding.group.id'),
          forwarded_to: get(values, 'forwarding.forwarded_to.id'),
          message: get(values, 'forwarding.message'),
          private_message: get(values, 'forwarding.private_message'),
          ticket: id,
        })
        .then(this.handleSubmitSuccess)
        .catch((error) => {
          const status = get(error, 'response.status');
          if (status === 400) {
            const errors = error.response.data;
            this.formApi.setError('forwarding.group', get(errors, 'group[0]'));
            this.formApi.setError(
              'forwarding.message',
              get(errors, 'message[0]')
            );
            this.formApi.setError(
              'forwarding.forwarded_to',
              get(errors, 'forwarded_to[0]')
            );
          }
          this.setState({ submitting: false });
        });
    };

    handleResolutionSubmit = (extraValues) => {
      this.setState({ submitting: true, submitErrors: {} });
      const { values } = this.formApi.getState();
      const {
        match: {
          params: { id },
        },
      } = this.props;
      const ticketId = id;

      return axios
        .post(submitUrl(ticketId), {
          ...mapSubmitValues(values, ticketId),
          ...extraValues,
          resolution: {
            ...values.resolution,
            status: get(values.resolution, 'status.value'),
          },
        })
        .then(this.handleSubmitSuccess)
        .catch((error) => {
          const status = get(error, 'response.status');
          if (status === 400) {
            const { resolution, ...otherErrors } = error.response.data;
            this.formApi.setError(
              'resolution.message',
              get(resolution, 'message[0]')
            );
            this.formApi.setError(
              'resolution.status',
              get(resolution, 'status[0]')
            );
            Object.entries(otherErrors).forEach((e) => {
              if (this.formApi.fieldExists(e[0])) {
                this.formApi.setError(e[0], e[1]);
              } else if (toastSubmitErrors) {
                // Same as e[1][0]
                toast.error(get(e, '1.0'));
              }
            });

            this.setState({
              submitting: false,
              submitErrors: error.response.data,
            });
          } else {
            this.setState({ submitting: false });
          }
        });
    };

    handleSubmitSuccess = () => {
      this.fetchTicket();
      this.fetchForwardings();
      this.setState({ submitting: false });
      this.setState({ isResponsabilityModalOpen: false });
    };

    handleHideAlertModal = () => {
      this.setState({
        isAlertModalOpen: false,
      });
    };

    handleCancelTicket = () => {
      this.setState({
        cancellingTicket: true,
      });
      const { ticket } = this.state;
      cancelTicket(ticket.id)
        .then((data) => {
          this.handleHideAlertModal();
          toast.success('Protocolo cancelado.');
          this.setState({
            ticket: data,
            cancellingTicket: false,
          });
        })
        .catch((e) => {
          const status = get(e, 'response.status', '-');
          const errors = get(e, 'response.data', '-');
          if (status === 403) {
            toast.error(errors.detail);
            this.handleHideAlertModal();
          } else {
            toast.error('Ocorreu um erro ao cancelar o protocolo.');
          }
          this.setState({
            cancellingTicket: false,
          });
        });
    };

    fetchTicketInfo(ticket) {
      return fetchTicketInfo(ticket.id);
    }

    fetchTicket() {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      fetchTicket(id)
        .then((ticket) => {
          this.setState({ ticket, fetchingTicket: false });
          this.fetchTicketInfo(ticket)
            .then((ticketInfo) => {
              this.setState({ ticketInfo, fetchingTicketInfo: false }, () => {
                if (setInitialValues) {
                  // This is an ugly workaround because values
                  // were not being added to formState.values without this setTimeout.
                  // This hoc needs to be refactored, anyway.
                  setTimeout(() => {
                    Object.entries(mapInitialValues(ticketInfo)).forEach(
                      (entry) => {
                        if (this.formApi.fieldExists(entry[0])) {
                          this.formApi.setValue(entry[0], entry[1]);
                        }
                      }
                    );
                  }, 500);
                }
              });
            })
            .catch(() => {
              this.setState({ fetchingTicketInfo: false });
            });
        })
        .catch(() => {
          this.setState({ fetchingTicket: false, fetchingTicketInfo: false });
        });
    }

    fetchForwardings() {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      fetchForwardings({ ticket: id, page_size: 100 }).then((data) => {
        this.setState({
          forwardings: data.results,
          fetchingForwardings: false,
        });
      });
    }

    render() {
      const {
        ticket,
        fetchingTicket,
        fetchingTicketInfo,
        submitting,
        isAlertModalOpen,
        forwardings,
        cancellingTicket,
        isResponsabilityModalOpen,
      } = this.state;

      if (fetchingTicketInfo) {
        return (
          <div className="mt-3">
            <Skeleton width="100%" height="100px" />
          </div>
        );
      }

      return (
        <Form
          className="w-100"
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
        >
          <Ticket
            forwardings={forwardings}
            submitting={submitting}
            ticket={ticket}
            fetchingTicket={fetchingTicket}
            onCancelTicket={() => this.setState({ isAlertModalOpen: true })}
            onTransferResponsability={() =>
              this.setState({ isResponsabilityModalOpen: true })
            }
          >
            <WrappedComponent
              {...this.state}
              formApi={this.formApi}
              onSubmit={this.handleSubmit}
              renderForwardings={() => (
                <Forwardings forwardings={forwardings} ticket={ticket} />
              )}
            />
          </Ticket>
          <ConfirmationModal
            visible={isAlertModalOpen}
            onHide={this.handleHideAlertModal}
            onConfirm={this.handleCancelTicket}
            loading={cancellingTicket}
          >
            Tem certeza que deseja cancelar este protocolo?
          </ConfirmationModal>
          <ConfirmationModal
            visible={isResponsabilityModalOpen}
            onHide={this.handleHideResponsabilityModal}
            onConfirm={this.handleResponsabilitySubmit}
          >
            Certeza que deseja alterar a responsabilidade?
          </ConfirmationModal>
        </Form>
      );
    }
  };

export default withTicket;
