import TabsMenu from 'components/TabsMenu';
import React from 'react';

type LancamentosMenuProps = {
  acordoId: number;
  contaId: number;
};

const LancamentosMenu = ({ acordoId, contaId }: LancamentosMenuProps) => (
  <TabsMenu
    position="left"
    links={[
      {
        to: `/fichas-financeiras/${contaId}/acordos/${acordoId}/lancamentos-criados`,
        label: 'Lançamentos criados',
      },
      {
        to: `/fichas-financeiras/${contaId}/acordos/${acordoId}/lancamentos-em-acordo`,
        label: 'Lançamentos em acordo',
      },
    ]}
  />
);

export default LancamentosMenu;
