import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo-green.svg';
import InputInformed from './InputInformed';

class Login extends React.Component {
  render() {
    const { onSubmit, error, submitting } = this.props;
    return (
      <Fragment>
        <div className="login-background">
          <div className="login-card shadow">
            {error && (
              <div className="login-error">
                <FontAwesomeIcon icon="times-circle" />
                <span className="ml-2">{error}</span>
              </div>
            )}
            <div className="login-spacing">
              <figure className="text-center mt-2 mb-3">
                <img src={logo} className="login-logo" alt="notification" />
              </figure>
              <div className="mb-3 mt-4">
                <InputInformed
                  label="Login"
                  type="text"
                  field="username"
                  required
                />
              </div>
              <div className="mb-3">
                <InputInformed
                  label="Senha"
                  type="password"
                  field="password"
                  required
                />
              </div>
              <button
                type="submit"
                onClick={onSubmit}
                className="btn btn-primary p-2 w-100"
                disabled={submitting}
              >
                Entrar
              </button>
              <div className="mt-3">
                <Link to="/forgot-password">
                  <p className="semi-bold btn-link">Esqueci minha senha</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

Login.defaultProps = {
  error: '',
};

export default Login;
