import axios from 'axios';
import { API_HOST } from '../../consts';

export const fetchOriginOptions = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos/origins`, { params })
    .then((res) =>
      res.data.map((d) => ({
        value: d.id,
        label: d.description,
      }))
    );

export const fetchCompetenciaOptions = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos/competencias`, { params })
    .then((res) =>
      res.data.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );

export const fetchCategoriaOptions = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos/categorias`, { params })
    .then((res) =>
      res.data.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );

export const fetchResponsaveis = (params) =>
  axios
    .get(`${API_HOST}/financeiro/responsavel`, { params })
    .then((res) => res.data);

export const fetchStatusOptions = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos/status`, { params })
    .then((res) =>
      res.data.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );

export const fetchLancamentos = (params) =>
  axios
    .get(`${API_HOST}/financeiro/lancamentos`, { params })
    .then((res) => res.data);

export const fetchClassificacoes = (params) =>
  axios
    .get(`${API_HOST}/financeiro/classificacao`, { params })
    .then((res) => res.data);

export const fetchMetodosPagamento = (params) =>
  axios
    .get(`${API_HOST}/financeiro/metodos_pagamento`, { params })
    .then((res) => res.data);

export const createLancamento = ({ data = {}, params = {} }) =>
  axios
    .post(`${API_HOST}/financeiro/lancamentos`, data, params)
    .then((res) => res.data);

export const createPagamento = ({ data = {}, params = {} }) =>
  axios
    .post(`${API_HOST}/financeiro/pagamentos`, data, params)
    .then((res) => res.data);
