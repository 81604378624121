import { Form, Formik } from 'formik';
import { get, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';

const DataForm = ({
  children,
  initialValues,
  onSubmit,
  autoSetErrors,
  submitOnChange,
  ...rest
}) => (
  <Formik
    initialValues={initialValues}
    {...rest}
    onSubmit={async (values, { setFieldError, setSubmitting }) => {
      try {
        await onSubmit(values, { setFieldError });
      } catch (err) {
        if (autoSetErrors) {
          const status = get(err, 'response.status', '-');
          const errors = get(err, 'response.data', '-');
          if (status === 400) {
            Object.keys(errors).forEach((field) => {
              if (field === 'non_field_errors') {
                toast.error(
                  <div>
                    {errors.non_field_errors.map((d) => (
                      <>
                        {d}
                        <br />
                      </>
                    ))}
                  </div>,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false,
                  }
                );
              } else {
                try {
                  setFieldError(field, errors[field]);
                } catch (e) {
                  // DO NOTHING
                }
              }
            });
            toast.error(
              'Por favor, corrija os erros encontrados para prosseguir.'
            );
          } else {
            toast.error('Ocorreu um erro. Por favor, tente novamente.');
          }
        }
      }

      setSubmitting(false);
    }}
  >
    {(formik) => (
      <Form onChange={() => submitOnChange && formik.submitForm()}>
        {isFunction(children) ? children(formik) : children}
      </Form>
    )}
  </Formik>
);

DataForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  autoSetErrors: PropTypes.bool,
  submitOnChange: PropTypes.bool,
};

DataForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  autoSetErrors: false,
  submitOnChange: false,
};

export default DataForm;
