import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HistoryIcon = ({ type }) => {
  switch (type) {
    case '+':
      return <FontAwesomeIcon icon="check" className="green" />;
    case '-':
      return <FontAwesomeIcon icon="times" className="red" />;
    case '~':
      return <FontAwesomeIcon icon="edit" className="blue" />;
    default:
      return null;
  }
};

HistoryIcon.propTypes = {
  type: PropTypes.oneOf(['+', '-', '~']).isRequired,
};

export default HistoryIcon;
