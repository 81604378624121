import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';

const ListaAlunosPage = lazy(() => retry(() => import('./ListaAlunosPage')));
const FichaAlunoPage = lazy(() => retry(() => import('./FichaAlunoPage')));
const DocumentoPage = lazy(() => retry(() => import('./DocumentoPage')));
const FichaSaudePage = lazy(() => retry(() => import('./FichaSaudePage')));
const FichaCensoPage = lazy(() => retry(() => import('./FichaCensoPage')));
const FichaDisciplinaPage = lazy(() =>
  retry(() => import('./FichaDisciplinaPage'))
);
const ComplementacaoChPage = lazy(() =>
  retry(() => import('./ComplementacaoChPage'))
);
const BatchFitInPage = lazy(() => retry(() => import('./BatchFitInPage')));

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/ficha-aluno"
      component={ListaAlunosPage}
      permission="persons.change_any_profile"
    />
    <PrivateRoute
      exact
      path="/ficha-aluno/:id/dados-pessoais/:cid"
      component={FichaAlunoPage}
      permission="persons.change_person"
    />
    <PrivateRoute
      exact
      path="/ficha-aluno/:id/documentacao/:cid"
      component={DocumentoPage}
      permission="persons.change_person"
    />
    <PrivateRoute
      exact
      path="/ficha-aluno/:id/ficha-saude/:cid"
      component={FichaSaudePage}
      permission="persons.change_person"
    />
    <PrivateRoute
      exact
      path="/ficha-aluno/:id/ficha-censo/:cid"
      component={FichaCensoPage}
      permission="persons.change_person"
    />
    <PrivateRoute
      exact
      path="/ficha-aluno/:id/disciplinas/:cid"
      component={FichaDisciplinaPage}
    />
    <PrivateRoute
      path="/carga-horaria-suplementar"
      component={ComplementacaoChPage}
      permission="persons.change_person"
    />
    <PrivateRoute
      path="/enturmacao"
      component={BatchFitInPage}
      permission="persons.change_person"
    />
  </Switch>
);
