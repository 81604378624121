import React from 'react';
import { useContext } from 'react';
import { AcordoContext } from 'contexts/lancamentos/AcordoState';
import LancamentoCard from '../LancamentoCard';
import LancamentoList from '../LancamentoList';

const LancamentosEmAcordo = () => {
  const { lancamentosEmAcordo } = useContext(AcordoContext);

  return (
    <LancamentoList>
      {lancamentosEmAcordo.map((lancamento) => (
        <LancamentoCard key={lancamento.id} lancamento={lancamento} />
      ))}
    </LancamentoList>
  );
};

export default LancamentosEmAcordo;
