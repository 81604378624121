import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const ImportsPage = lazy(() => retry(() => import('./ImportsPage')));

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/importacao"
      title
      permission="menu.view_importacao"
      component={ImportsPage}
    />
  </Switch>
);
