import axios from 'axios';
import { get } from 'lodash';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';
import RemessaDetails from '../../components/remessa/RemessaDetails';
import { API_HOST, BOLETOS_SERVICE_HOST } from '../../consts';
import useInterval from '../../hooks/useInterval';
import RemessaDetailsPageReducer from '../../reducers/remessas/RemessaDetailsPageReducer';
import {
  fetchRemessaDetail,
  fetchRemessaLancamentos,
  validateRemessaFile,
} from '../../services/remessas/remessas-services';
import { paginateList, renderListFilter } from '../../utils';
import RemessaContext from '../../contexts/Remessa/RemessaContext';

const RemessaDetailsPage = () => {
  const initialState = {
    lancamentos: [],
    page: 0,
    loadingLancamento: false,
    remessaError: true,
    remessa: {},
    values: {},
    fetchingRemessa: false,
    openModal: null,
    cancelandoRemessa: false,
    cancelarRemessaError: '',
    enviandoEmails: false,
    enviarEmailsError: '',
  };
  const [state, dispatch] = useReducer(RemessaDetailsPageReducer, initialState);
  const { id } = useParams();

  const {
    lancamentos,
    page,
    loadingLancamento,
    remessa,
    values,
    fetchingRemessa,
    openModal,
    cancelandoRemessa,
    enviandoEmails,
  } = state;

  const pageSize = 10;

  const fetchLancamentos = useCallback(
    (remessaObj) => {
      dispatch({ type: 'SET_LOADING_LANCAMENTO', payload: true });
      const params = { remessa_id: id, page_size: 1000 };
      fetchRemessaLancamentos(params)
        .then((data) => {
          let lancamentosData = data.results;
          lancamentosData = lancamentosData.map((lanc) => ({
            ...lanc,
            error: get(
              remessaObj.sicoob240logs_set.find(
                (log) => lanc.id === get(log, 'data.numero_documento')
              ),
              'descricao'
            ),
          }));
          dispatch({ type: 'SET_LANCAMENTOS', payload: lancamentosData });
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao carregar a lista de lançamentos. Por favor tente novamente.'
          );
          dispatch({ type: 'SET_LOADING_LANCAMENTO', payload: false });
        });
    },
    [id]
  );

  const fetchRemessa = useCallback(
    (loading) => {
      if (loading) {
        dispatch({ type: 'FETCH_REMESSA' });
      }
      return fetchRemessaDetail(id)
        .then((data) => {
          dispatch({ type: 'SET_REMESSA', payload: data });
          return data;
        })
        .catch(() => {
          // if (remessaError) {
          //   toast.error('Ocorreu um erro ao carregar a remessa. Por favor tente novamente.');
          //   dispatch({ type: 'SET_REMESSA_ERROR', payload: false });
          // }
        });
    },
    [id]
  );

  const fetchRemessaFileValidation = useCallback(() => {
    dispatch({ type: 'FETCH_REMESSA_FILE_VALIDATION' });
    return validateRemessaFile(id)
      .then((data) => {
        dispatch({
          type: 'FETCH_REMESSA_FILE_VALIDATION_SUCCESS',
          payload: data,
        });
        return data;
      })
      .catch(() => {
        dispatch({ type: 'FETCH_REMESSA_FILE_VALIDATION_ERROR' });
      });
  }, [id]);

  const cancelarRemessa = async () => {
    dispatch({ type: 'CANCELAR_REMESSA' });
    try {
      // Atualiza o status dos lançamentos da remessa
      await axios.post(`${API_HOST}/financeiro/transacoes/cancelar`, {
        remessa_id: remessa.id,
      });
      // Atualiza o status da remessa
      try {
        await axios.patch(
          `${BOLETOS_SERVICE_HOST}/remessa/remessa-sicoob/${remessa.id}`,
          {
            status: 'cancelled',
          }
        );
      } catch (_) {
        // no-empty
      }
      dispatch({ type: 'CANCELAR_REMESSA_SUCCESS' });
      toast.success('Remessa cancelada.');
    } catch (_) {
      dispatch({ type: 'CANCELAR_REMESSA_ERROR' });
      toast.error('Não foi possível cancelar a remessa.');
    }
  };

  const enviarEmails = async () => {
    const lancamentos_ids = lancamentos.map((l) => l.id);
    dispatch({ type: 'ENVIAR_EMAILS' });
    dispatch({ type: 'SET_MODAL', payload: null });
    try {
      await axios.post(`${API_HOST}/financeiro/lancamentos/send_emails`, {
        lancamentos: lancamentos_ids,
      });
      dispatch({ type: 'ENVIAR_EMAILS_SUCCESS' });
      toast.success('Emails enviados.');
    } catch (_) {
      dispatch({ type: 'ENVIAR_EMAILS_ERROR' });
      toast.error('Não foi possível enviar os emails.');
    }
  };

  let lancamentosToShow = [...lancamentos];
  lancamentosToShow = renderListFilter(
    lancamentosToShow,
    ['aluno.name'],
    values.name
  );
  if (values.error === true) {
    lancamentosToShow = lancamentosToShow.filter((d) => d.error);
  }
  lancamentosToShow = paginateList(lancamentosToShow, page, pageSize);
  const pages = lancamentos.length / pageSize;

  useEffect(() => {
    fetchRemessaFileValidation();
  }, [fetchRemessaFileValidation]);

  useEffect(() => {
    fetchRemessa(true).then((remessaObj) => {
      fetchLancamentos(remessaObj);
    });
  }, [fetchRemessa, fetchLancamentos]);

  useInterval(() => {
    if (remessa && remessa.status === 'closed') {
      return;
    }

    fetchRemessa();
  }, 5000);

  const contextValue = {
    ...state,
    dispatch,
  };

  return (
    <RemessaContext.Provider value={contextValue}>
      {enviandoEmails && (
        <span>Os emails estão sendo enviados... Aguarde um momento</span>
      )}
      <RemessaDetails
        id={id}
        remessa={remessa}
        loadingLancamento={loadingLancamento}
        lancamentosToShow={lancamentosToShow}
        allLancamentos={lancamentos}
        fetchingRemessa={fetchingRemessa}
        pages={pages}
        page={page}
        onFilterChange={(payload) => dispatch({ type: 'SET_VALUES', payload })}
        onPageChange={(pageIndex) =>
          dispatch({ type: 'SET_PAGE', payload: pageIndex })
        }
      />
      <ConfirmationModal
        visible={openModal === 'CANCELAMENTO_REMESSA'}
        onHide={() => dispatch({ type: 'SET_MODAL', payload: null })}
        onConfirm={cancelarRemessa}
        header="Cancelar remessa"
        show={openModal === 'CANCELAMENTO_REMESSA'}
        confirmText="CONFIRMAR"
        hideText=""
        loading={cancelandoRemessa}
      >
        <p>
          Os lançamentos voltarão ao status &quotEm aberto&quot e ficarão
          disponíveis para envio em outras remessas.
        </p>
        <p>
          Esta ação <strong>não</strong> cancela boletos gerados, caso o arquivo
          já tenha sido enviado para o banco.
        </p>
      </ConfirmationModal>
      <ConfirmationModal
        visible={openModal === 'ENVIAR_EMAILS'}
        onHide={() => dispatch({ type: 'SET_MODAL', payload: null })}
        onConfirm={enviarEmails}
        header="Enviar emails"
        show={openModal === 'ENVIAR_EMAILS'}
        confirmText="CONFIRMAR"
        hideText=""
        loading={enviandoEmails}
      >
        <p>Enviar emails notificando alunos sobre o boleto</p>
      </ConfirmationModal>
    </RemessaContext.Provider>
  );
};

export default RemessaDetailsPage;
