import useSuiteShare from 'hooks/useSuiteShare';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import PrivateRouteWrapper from './PrivateRouteWrapper';

interface PrivateRouteProps extends RouteProps {
  navbar?: boolean;
  sidebar?: boolean;
  permission: string | string[];
  sizingWidth?: string;
}

const isAuthenticated = () => localStorage.getItem('token');

const PrivateRoute = ({
  component: Component,
  navbar,
  sidebar,
  permission,
  sizingWidth,
  ...rest
}: PrivateRouteProps) => {
  useSuiteShare();

  return (
    <PrivateRouteWrapper
      navbar={navbar}
      sidebar={sidebar}
      permission={permission}
      sizingWidth={sizingWidth}
    >
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated() ? (
            Component && <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </PrivateRouteWrapper>
  );
};

export default PrivateRoute;
