import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

const TableActionButton = ({
  onClick,
  icon,
  children,
  className,
  iconProps,
  disabled,
  dataTip,
  ...rest
}) => (
  <Fragment>
    {dataTip && <ReactTooltip />}
    <button
      disabled={disabled}
      type="button"
      data-tip={dataTip}
      className={classnames('btn btn-rounded btn-rounded-white basic-shadow', {
        className,
        disabled,
      })}
      onClick={onClick}
      {...rest}
    >
      <FontAwesomeIcon icon={icon} {...iconProps} />
      {children}
    </button>
  </Fragment>
);

TableActionButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  iconProps: PropTypes.object,
  disabled: PropTypes.bool,
  dataTip: PropTypes.string,
};

TableActionButton.defaultProps = {
  children: null,
  icon: '',
  className: '',
  iconProps: {},
  disabled: false,
  dataTip: null,
};

export default TableActionButton;
