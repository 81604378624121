import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const DisciplineOffersPage = lazy(() =>
  retry(() => import('./DisciplineOffersPage'))
);
const DisciplineOfferReportListPage = lazy(() =>
  retry(() => import('./DisciplineOfferReportListPage'))
);
const DisciplineOfferReportPage = lazy(() =>
  retry(() => import('./DisciplineOfferReportPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/ofertas-disciplina/relatorios"
      permission="discipline_offers.view_disciplineoffer"
      component={DisciplineOfferReportListPage}
    />
    <PrivateRoute
      exact
      path="/ofertas-disciplina/:id"
      permission="discipline_offers.view_disciplineoffer"
      component={DisciplineOfferReportPage}
    />
    <PrivateRoute
      exact
      path="/ofertas-disciplina-aluno/:optionalDisciplineType"
      component={DisciplineOffersPage}
    />
  </Switch>
);
