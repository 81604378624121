import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import ButtonWithConfirmation from '../common/buttons/ButtonWithConfirmation';
import createBoleto from '../../services/tickets/create_boleto';

const CreateBoletoButton = ({ ticketId, onCreateBoleto }) => (
  <>
    <ReactTooltip />
    <ButtonWithConfirmation
      action="none"
      onClick={() => {
        return createBoleto(ticketId).then(onCreateBoleto);
      }}
      confirmationText="Confirmar a geração de um novo boleto?"
      data-tip="Gerar novo boleto"
    >
      <FontAwesomeIcon icon="redo" className="font-20 silver-dark" />
    </ButtonWithConfirmation>
  </>
);

export default CreateBoletoButton;
