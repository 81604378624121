import axios from 'axios';
import { API_HOST } from '../consts';
import debounce from 'debounce-promise';

export const fetchClassDiscipline = async (id) => {
  const res = await axios.get(
    `${API_HOST}/academico/class_disciplines/${id}/details`
  );
  return res.data;
};

export const updateClassDiscipline = async (id, params) => {
  const res = await axios.put(
    `${API_HOST}/academico/class_disciplines/${id}`,
    params
  );
  return res.data;
};

export const fetchDisciplinaView = debounce((params) =>
  axios.get(`${API_HOST}/academico/disciplinas`, { params }).then((response) =>
    response.data.results.map((d) => ({
      value: d.id,
      label: d.nome,
    }))
  )
);

export const fetchDisciplinaDetails = async (params) => {
  const res = await axios.get(
    `${API_HOST}/academico/disciplinas/detail_by_curriculum`,
    { params }
  );
  return res.data.results;
};
