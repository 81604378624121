import axios from 'axios';
import API_HOST from 'consts';
import React, { useReducer, createContext } from 'react';
import {
  fetchDocente,
  fetchDocumentacaoDocente,
  fetchTeacherDocuments,
  fetchEmpresa,
  fetchDocumentacaoEmpresa,
  fetchLegalPersonDocuments,
  fetchProfessor,
  fetchDocenteContratosId,
  fetchAsinatauraContratos,
  fetchDocenteContratos,
  fetchDocenteEvento,
  fetchDocenteEventoFilter,
} from 'services/docente_services';
import NovoDocenteReducer from 'reducers/fluxo_novo_docente/NovoDocenteReducer';
import { get } from 'lodash';

const initialState = {
  dadosPessoais: {},
  documentacaoDocente: {},
  empresa: {},
  documentacaoEmpresa: {},
  documentacaoMeta: [],
  professorPerson: {},
  contratoDocente: {},
  assinaturaContrato: {},
  evento: {},
  pagamentoEventoDocnte: {},
};

export const NovoDocenteContext = createContext(initialState);

const NovoDocenteState = ({ children }) => {
  const [state, dispatch] = useReducer(NovoDocenteReducer, initialState);

  const {
    dadosPessoais,
    documentacaoDocente,
    empresa,
    documentacaoEmpresa,
    documentacaoMeta,
    professorPerson,
    contratoDocente,
    assinaturaContrato,
    evento,
    pagamentoEventoDocnte,
  } = state;

  const clearStates = () => {
    dispatch({ type: 'SET_DADOS_PESSOAIS', payload: {} });
    dispatch({ type: 'SET_DOCUMENTACAO_DOCENTE', payload: {} });
    dispatch({ type: 'SET_DADOS_EMPRESA', payload: {} });
    dispatch({ type: 'SET_DOCUMENTACO_EMPRESA', payload: {} });
    dispatch({ type: 'SET_DOCUMENTACO_META', payload: [] });
    dispatch({ type: 'SET_PROFESSOR_PERSON_META', payload: {} });
    dispatch({ type: 'SET_CONTRATO_DOCENTE', payload: {} });
    dispatch({ type: 'SET_ASSINATURA_CONTRATO', payload: {} });
    dispatch({ type: 'SET_LISTA_EVENTOS', payload: [] });
    dispatch({ type: 'SET_PAGAMENTO_EVENTOS', payload: [] });
  };

  const saveDadosPessoais = async (data) => {
    if (!dadosPessoais.id) {
      return axios
        .post(`${API_HOST}/profiles/profiles/teacher`, data)
        .then((res) => {
          dispatch({ type: 'SET_DADOS_PESSOAIS', payload: res.data });
          return res.data;
        });
    }
    return axios
      .put(`${API_HOST}/profiles/profiles/${dadosPessoais.id}`, data)
      .then((res) => {
        dispatch({ type: 'SET_DADOS_PESSOAIS', payload: res.data });
        return res.data;
      });
  };

  const setDadosPessoais = async (id) => {
    fetchDocente(id).then((data) => {
      dispatch({ type: 'SET_DADOS_PESSOAIS', payload: data });
    });
  };

  const setDocumentacaoDocente = async (id) => {
    fetchDocumentacaoDocente(id).then((data) => {
      const groupedByType = data.reduce(
        (acc, item) => ({
          [get(item, 'type.id')]: item,
          ...acc,
        }),
        {}
      );
      dispatch({ type: 'SET_DOCUMENTACAO_DOCENTE', payload: groupedByType });
    });
  };

  const fetchDocuments = async () => {
    fetchTeacherDocuments().then((data) => {
      dispatch({ type: 'SET_DOCUMENTACAO_META', payload: [...data] });
    });
  };

  const setDocumento = (tipo_id, values) => {
    const newDocumento = {
      ...documentacaoDocente,
      [tipo_id]: values,
    };
    dispatch({ type: 'SET_DOCUMENTACAO_DOCENTE', payload: newDocumento });
  };

  const saveDadosEmpresa = async (id, data, editarDadosEmpresa) => {
    if (!editarDadosEmpresa) {
      return axios
        .post(`${API_HOST}/profiles/profiles/${id}/legal_person`, data)
        .then((res) => {
          dispatch({ type: 'SET_DADOS_EMPRESA', payload: { ...res } });
        });
    }
    return axios
      .patch(`${API_HOST}/profiles/legal_person/${empresa.id}`, data)
      .then((res) => {
        dispatch({ type: 'SET_DADOS_EMPRESA', payload: res.data });
        return res.data;
      });
  };

  const setDadosEmpresa = async (id) => {
    fetchEmpresa(id).then((data) => {
      dispatch({ type: 'SET_DADOS_EMPRESA', payload: data });
    });
  };

  const setDocumentacaoEmpresa = async (id) => {
    fetchDocumentacaoEmpresa(id).then((data) => {
      const groupedByType = data.reduce(
        (acc, item) => ({
          [get(item, 'type.id')]: item,
          ...acc,
        }),
        {}
      );
      dispatch({ type: 'SET_DOCUMENTACO_EMPRESA', payload: groupedByType });
    });
  };

  const fetchDocumentsEmpresa = async () => {
    fetchLegalPersonDocuments().then((data) => {
      dispatch({ type: 'SET_DOCUMENTACAO_META', payload: [...data] });
    });
  };

  const setDocumentoEmpresa = (tipo_id, values) => {
    const newDocumento = {
      ...documentacaoEmpresa,
      [tipo_id]: values,
    };
    dispatch({ type: 'SET_DOCUMENTACO_EMPRESA', payload: newDocumento });
  };

  const setProfessorPerson = (id) => {
    fetchProfessor(id).then((data) => {
      dispatch({ type: 'SET_PROFESSOR_PERSON_META', payload: data.results });
    });
  };

  const saveContratoDocnete = async (data) => {
    // if (!contratoDocente.id) {
    return axios
      .post(`${API_HOST}/contratos/teacher_contract`, data)
      .then((res) => {
        dispatch({ type: 'SET_CONTRATO_DOCENTE', payload: res.data });
        return res.data;
      });
    // }
    // return axios
    //   .put(`${API_HOST}/contratos/teacher_contract/${contratoDocente.id}`, data)
    //   .then((res) => {
    //     dispatch({ type: 'SET_CONTRATO_DOCENTE', payload: res.data });
    //     return res.data;
    //   });
  };

  const setDocenteContratos = (id) => {
    fetchDocenteContratosId(id).then((data) => {
      dispatch({ type: 'SET_CONTRATO_DOCENTE', payload: data });
    });
  };

  const setDocenteContratosAtivo = (id) => {
    fetchDocenteContratos(id).then((data) => {
      dispatch({ type: 'SET_CONTRATO_DOCENTE', payload: data });
    });
  };

  const setDocenteContratosCard = (id) => {
    fetchDocenteContratos(id).then((data) => {
      dispatch({ type: 'SET_CONTRATO_DOCENTE', payload: data });
    });
  };

  const saveAssinaturaContrato = async (data) => {
    // if (!contratoDocente.id) {
    return axios
      .post(`${API_HOST}/contratos/contract_signature`, data)
      .then((res) => {
        dispatch({ type: 'SET_ASSINATURA_CONTRATO', payload: res.data });
        return res.data;
      });
    // }
    // return axios
    //   .put(`${API_HOST}/contratos/contract_signature/${contratoDocente.id}`, data)
    //   .then((res) => {
    //     dispatch({ type: 'SET_ASSINATURA_CONTRATO', payload: res.data });
    //     return res.data;
    //   });
  };

  const setAssinaturaContratos = (id) => {
    fetchAsinatauraContratos(id).then((data) => {
      dispatch({ type: 'SET_ASSINATURA_CONTRATO', payload: data });
    });
  };

  const saveEvento = async (data) => {
    if (!evento.id) {
      return axios.post(`${API_HOST}/financeiro/evento`, data).then((res) => {
        dispatch({ type: 'SET_LISTA_EVENTOS', payload: res.data });
        return res.data;
      });
    }
    return axios
      .put(`${API_HOST}/financeiro/evento/${evento.id}`, data)
      .then((res) => {
        dispatch({ type: 'SET_LISTA_EVENTOS', payload: res.data });
        return res.data;
      });
  };

  const saveEventoDocument = async (data) => {
    return axios
      .patch(`${API_HOST}/financeiro/evento/${evento.id}/upload_documento`, {
        key: data,
      })
      .then((res) => {
        dispatch({ type: 'SET_LISTA_EVENTOS', payload: res.data });
        return res.data;
      });
  };

  const saveEventoEmail = async (data) => {
    return axios
      .post(`${API_HOST}/financeiro/evento/enviar_email_docente`, {
        evento: data,
      })
      .then((res) => {
        dispatch({ type: 'SET_LISTA_EVENTOS', payload: res.data });
        return res.data;
      });
  };

  const setEventos = (id) => {
    fetchDocenteEvento(id).then((data) => {
      dispatch({ type: 'SET_LISTA_EVENTOS', payload: data });
    });
  };

  const setEventosFilter = (id) => {
    fetchDocenteEventoFilter(id).then((data) => {
      dispatch({ type: 'SET_LISTA_EVENTOS', payload: data });
    });
  };

  const savePagamentoEvento = async (data) => {
    return axios
      .post(`${API_HOST}/financeiro/pagamento_docente`, data)
      .then((res) => {
        dispatch({ type: 'SET_PAGAMENTO_EVENTOS', payload: res.data });
        return res.data;
      });
  };

  return (
    <NovoDocenteContext.Provider
      value={{
        clearStates,
        dadosPessoais,
        saveDadosPessoais,
        setDadosPessoais,

        documentacaoDocente,
        setDocumentacaoDocente,
        setDocumento,
        fetchDocuments,
        documentacaoMeta,

        empresa,
        setDadosEmpresa,
        saveDadosEmpresa,

        documentacaoEmpresa,
        setDocumentacaoEmpresa,
        setDocumentoEmpresa,
        fetchDocumentsEmpresa,

        professorPerson,
        setProfessorPerson,

        contratoDocente,
        saveContratoDocnete,
        setDocenteContratos,
        setDocenteContratosCard,
        setDocenteContratosAtivo,

        assinaturaContrato,
        saveAssinaturaContrato,
        setAssinaturaContratos,

        evento,
        saveEvento,
        setEventos,
        setEventosFilter,
        saveEventoDocument,
        saveEventoEmail,

        pagamentoEventoDocnte,
        savePagamentoEvento,
      }}
    >
      {children}
    </NovoDocenteContext.Provider>
  );
};

export default NovoDocenteState;
