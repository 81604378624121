import { useState, useEffect } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { API_HOST } from '../consts';

function useChatSupportUser() {
  const [signature, setSignature] = useState('');
  const [supportUser, setSupportUser] = useState('');

  useEffect(() => {
    async function fetchSupport() {
      try {
        const response = await axios.get(`${API_HOST}/support-chat`);
        setSupportUser(get(response, 'data.support_user'));
        setSignature(get(response, 'data.signature'));
      } catch {
        // continue regardless of error
      }
    }
    fetchSupport();
  }, []);

  return [signature, supportUser];
}

export default useChatSupportUser;
