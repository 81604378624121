import axios from 'axios';
import debounce from 'debounce-promise';
import { groupBy, orderBy } from 'lodash';
import { API_HOST, BOLETOS_SERVICE_HOST } from '../consts';

const mapFetchLessons = (d) => ({
  id: d.id,
  datetime: `${d.data_formatada} ${d.hora_inicio} - ${d.hora_final}`,
  date: d.data_formatada,
  teacher: d.professor.nome,
  course: d.curso,
  discipline: d.disciplina,
  class: d.turma,
  students: d.nr_alunos,
  status: {
    ...d.status,
    color: d.status.color,
    name: d.status.name,
  },
  duration: d.duration,
  start_time: d.hora_inicio,
  end_time: d.hora_final,
  weekday: d.weekday,
  room: d.room,
  building: d.building_code,
});

const mapFetchLessonHours = (d) => ({
  id: d.id,
  start_date: d.agendamento.data_inicio,
  end_date: d.agendamento.data_termino,
  discipline: d.disciplina.nome,
  class: d.turma.nome,
  teacher: d.professor.nome,
  frequency: d.agendamento.periodicidade.nome,
  weekdays: d.agendamento.dias_semana,
  lessons: d.aulas,
  building_code: d.building_code || 'ND',
  room: d.room || 'ND',
});

const mapLessonScheduling = (d) => ({
  class: d.turma.nome,
  discipline: d.disciplina.nome,
  teacher: d.professor.nome,
  start_date: d.agendamento.data_inicio,
  end_date: d.agendamento.data_termino,
  frequency: d.agendamento.periodicidade.nome,
  weekdays: d.agendamento.dias_semana,
  room: d.room,
  room_id: d.room_id,
  class_discipline: d.turma_disciplina,
  show_teacher: d.show_teacher,
});

export const fetchLessons = (params = {}) =>
  axios
    .get(`${API_HOST}/academico/aulas/paginated`, {
      params,
    })
    .then((response) => ({
      ...response.data,
      results: response.data.results.map(mapFetchLessons),
    }));

export const fetchLessonHours = (params = {}) =>
  axios
    .get(`${API_HOST}/aulas/agendamentos`, {
      params,
    })
    .then((response) => ({
      ...response.data,
      results: response.data.results.map(mapFetchLessonHours),
    }));

export const fetchLessonScheduling = (id) =>
  axios
    .get(`${API_HOST}/aulas/agendamentos/${id}`)
    .then((response) => mapLessonScheduling(response.data));

export const fetchPermissions = async () => {
  const response = await axios.get(`${API_HOST}/login/permissions`);
  if (response) {
    return response.data;
  }
  return null;
};

export const fetchPreferences = async () => {
  const response = await axios.get(`${API_HOST}/v2/features/global`);
  if (response) {
    return response.data;
  }
  return null;
};

export const fetchLessonComplications = (lesson) =>
  axios
    .get(`${API_HOST}/academico/v2/aulas/${lesson}/complications`)
    .then((response) => response.data);

export const fetchActivities = (params) =>
  axios
    .get(`${API_HOST}/activities/activities`, {
      params,
    })
    .then((response) => response.data);

export const fetchActivityStatuses = () =>
  axios
    .get(`${API_HOST}/activities/statuses`)
    .then((response) => response.data);

export const fetchActivityDailyStatus = (params) =>
  axios
    .get(`${API_HOST}/activities/daily_status`, {
      params,
    })
    .then((response) => response.data);

export const fetchActivityGrades = (id) =>
  axios
    .get(`${API_HOST}/activities/activities/${id}/grades`)
    .then((response) => response.data);

export const fetchCriterions = (params) =>
  axios
    .get(`${API_HOST}/activities/criterion_class_disciplines`, {
      params,
    })
    .then((response) => response.data);

export const fetchStudyMaterials = (params) =>
  axios
    .get(`${API_HOST}/study_materials/materials`, {
      params,
    })
    .then((response) => response.data);

export const fetchStudentPublications = (params) =>
  axios
    .get(`${API_HOST}/study_materials/publications/student`, {
      params,
    })
    .then((response) => response.data);

export const fetchStudentAttendances = (params) =>
  axios
    .get(`${API_HOST}/attendances/attendances/student`, {
      params,
    })
    .then((response) => response.data);

export const fetchStudentStudyMaterials = (params) =>
  axios
    .get(`${API_HOST}/study_materials/materials/student`, {
      params,
    })
    .then((response) => response.data);

export const fetchClassDisciplines = (params) =>
  axios
    .get(`${API_HOST}/academico/class_disciplines`, {
      params,
    })
    .then((response) => response.data);

export const fetchDatesWithoutLessons = debounce(
  ({ date, ...params }) =>
    axios
      .get(`${API_HOST}/academico/dates_without_lessons`, {
        params: {
          // API expects 1-indexed month
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          ...params,
        },
      })
      .then((response) =>
        response.data.map((d) => new Date(d.year, d.month - 1, d.day, 0, 0))
      ),
  400
);

export const fetchStudentGrades = ({ id, ...params }) =>
  axios
    .get(`${API_HOST}/activities/grades/student/${id}`, {
      params,
    })
    .then((response) => response.data);

export const fetchStudentGradesGroupedByDiscipline = ({ id, ...params }) =>
  fetchStudentGrades({ id, ...params })
    .then((data) =>
      Object.entries(groupBy(data, 'class_discipline')).map((entry) => ({
        id: entry[0],
        discipline: entry[1][0].discipline,
        clss: entry[1][0].clss,
        activities: entry[1],
      }))
    )
    .then((data) => orderBy(data, ['discipline', 'clss'], ['asc', 'asc']));

export const fetchTickets = (params, action) =>
  axios
    .get(`${API_HOST}/tickets/tickets${action ? `/${action}` : ''}`, { params })
    .then((response) => response.data);

export const fetchClassesDisciplines = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/class_disciplines`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchClasses = debounce(
  (params) =>
    axios
      .get(`${API_HOST}/academico/turmas`, { params })
      .then((response) => response.data.results),
  500
);

export const fetchUserAttendances = (params) =>
  axios
    .get(`${API_HOST}/attendances/attendances/student`, { params })
    .then((response) => response.data);

export const fetchAttendances = (params) =>
  axios
    .get(`${API_HOST}/attendances/attendances`, { params })
    .then((response) => response.data);

export const fetchGrades = (params) =>
  axios
    .get(`${API_HOST}/activities/grades`, { params })
    .then((response) => response.data);

export const fetchAttendanceReport = (id, params) =>
  axios
    .get(`${API_HOST}/attendances/report/consolidate/student/${id}`, { params })
    .then((response) => response.data);

export const fetchNotices = (params) =>
  axios
    .get(`${API_HOST}/notices/notices`, {
      params,
    })
    .then((response) => response.data);

export const fetchFeatures = (params) =>
  axios
    .get(`${API_HOST}/features/`, { params })
    .then((response) => response.data);

export const fetchBuildings = (params) =>
  axios
    .get(`${API_HOST}/rooms/buildings`, { params })
    .then((response) => response.data);

export const fetchFloors = (params) =>
  axios
    .get(`${API_HOST}/rooms/floors`, { params })
    .then((response) => response.data);

export const fetchRooms = (params) =>
  axios
    .get(`${API_HOST}/rooms/rooms`, { params })
    .then((response) => response.data);

export const fetchSchedulingTeachers = (schedulingId) =>
  axios
    .get(`${API_HOST}/aulas/agendamentos/${schedulingId}/teachers`)
    .then((response) => response.data);

export const fetchCourseDcn = (courseId) =>
  axios
    .get(`${API_HOST}/dcn/courses/${courseId}/dcn`)
    .then((response) => response.data);

export const fetchTeachingPlans = (params) =>
  axios
    .get(`${API_HOST}/teaching_plans/teaching_plans/me`, { params })
    .then((response) => response.data);

export const fetchSchedulingRooms = (schedulingId) =>
  axios
    .get(`${API_HOST}/aulas/agendamentos/${schedulingId}/rooms`)
    .then((response) => response.data);

export const fetchCombinedLessons = (params) =>
  axios
    .get(`${API_HOST}/academico/aulas`, { params })
    .then((response) => response.data);

export const fetchDeclarationTemplates = (params) =>
  axios
    .get(`${API_HOST}/declarations/templates`, { params })
    .then((response) => response.data);

export const fetchLessonsDailyStatus = (params) =>
  axios
    .get(`${API_HOST}/academico/aulas_daily_status`, { params })
    .then((response) => response.data);

export const fetchConciliationBanks = (params) =>
  axios
    .get(`${BOLETOS_SERVICE_HOST}/conciliation/banks-return-files`, { params })
    .then((response) =>
      response.data.map((d) => ({
        value: d.id,
        label: `${d.name} - ${d.provider.toUpperCase()}`,
      }))
    );

export const fetchReturnFiles = (params, provider = 'cbr643') => {
  let url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-cbr643`;

  if (provider === 'bradesco_retorno') {
    url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-retorno-bradesco`;
  }

  if (provider === 'sicoob_240') {
    url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-sicoob-240`;
  }

  return axios.get(url, { params }).then((response) => response.data);
};

export const fetchReturnFileDetail = (pk, params = {}, provider = 'cbr643') => {
  let url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-cbr643/${pk}`;

  if (provider === 'bradesco_retorno') {
    url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-retorno-bradesco/${pk}`;
  }

  if (provider === 'sicoob_240') {
    url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-sicoob-240/${pk}`;
  }

  return axios.get(url, { params }).then((response) => response.data);
};

export const fetchReturnFileStatus = (pk, params = {}, provider = 'cbr643') => {
  let url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-cbr643/${pk}/status`;

  if (provider === 'bradesco_retorno') {
    url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-retorno-bradesco/${pk}/status`;
  }
  if (provider === 'sicoob_240') {
    url = `${BOLETOS_SERVICE_HOST}/conciliation/return-files-sicoob-240/${pk}/status`;
  }

  return axios.get(url, { params }).then((response) => response.data);
};

export const fetchRecordsByReturnFileSICOOB = (pk, params = {}) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/conciliation/return-files-sicoob-240/${pk}/records`,
      { params }
    )
    .then((response) => response.data);

export const fetchConciliationByReturnFileSICOOB = (pk, params = {}) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/conciliation/return-files-sicoob-240/${pk}/conciliation`,
      { params }
    )
    .then((response) => response.data);

export const fetchConciliationByReturnFileCBR643 = (pk, params = {}) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/conciliation/return-files-cbr643/${pk}/conciliation`,
      { params }
    )
    .then((response) => response.data);

export const fetchRecordsByReturnFileCBR643 = (pk, params = {}) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/conciliation/return-files-cbr643/${pk}/records`,
      { params }
    )
    .then((response) => response.data);

export const fetchConciliationByReturnFileRetornoBradesco = (pk, params = {}) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/conciliation/return-files-retorno-bradesco/${pk}/conciliation`,
      { params }
    )
    .then((response) => response.data);

export const fetchRecordsByReturnFileRetornoBradesco = (pk, params = {}) =>
  axios
    .get(
      `${BOLETOS_SERVICE_HOST}/conciliation/return-files-retorno-bradesco/${pk}/records`,
      { params }
    )
    .then((response) => response.data);

export default fetchLessons;
