import styled from 'styled-components';
import { fontColor, grayLight, grayLighter } from '../../components/variables';

export default styled.button`
  background-color: ${grayLight};
  border: 1px solid ${grayLight};
  color: ${fontColor};
  border-radius: 5px;
  padding: 10px 15px 10px;
  font-size: 13px;
  font-weight: 700;
  width: 200px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: ${grayLighter};
  }
`;
