import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchAddressKind from '../../../services/address_kind_services';

const AddressKindInputSelect = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchAddressKind}>
    {({ options, isLoading }) => (
      <InputSelect
        name="addresses_kind"
        label="Tipo de Endereço"
        placeholder="Selecione"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);

export default AddressKindInputSelect;
