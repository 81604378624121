import Card from 'components/Card';
import Acordo from 'components/ficha-financeira/acordo/Acordo';
import FichaFinanceiraDetails from 'components/ficha-financeira/FichaFinanceiraDetails';
import ContaState from 'contexts/conta/ContaState';
import AcordoState from 'contexts/lancamentos/AcordoState';
import React from 'react';
import { useParams } from 'react-router';

const AcordoPage = () => {
  const { contaId, acordoId } = useParams();
  const statusColors = {
    Processado: 'green',
    Cancelado: 'darkred',
    'Em processamento': 'orange',
  };

  return (
    <ContaState contaId={contaId}>
      <AcordoState acordoId={acordoId} contaId={contaId}>
        {({ acordo }) => (
          <Card
            title={acordo ? `Acordo #${acordo.id}` : 'Acordo'}
            statusName={acordo ? acordo.status : null}
            statusColor={acordo ? statusColors[acordo.status] : null}
          >
            <FichaFinanceiraDetails />
            <div className="mb-2" />
            <Acordo />
          </Card>
        )}
      </AcordoState>
    </ContaState>
  );
};

export default AcordoPage;
