import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CellLabel = ({ row, children }) => {
  const StyledWrapper = styled.div`
    &:before {
      content: '${row.column.Header}';
    }
  `;

  return (
    <div className="responsive-row d-flex align-items-center">
      <StyledWrapper className="column-label" />
      {children}
    </div>
  );
};

CellLabel.propTypes = {
  row: PropTypes.shape(),
  children: PropTypes.node,
};

CellLabel.defaultProps = {
  row: {},
  children: '',
};

export default CellLabel;
