import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';
import NovoDocenteState, {
  NovoDocenteContext,
} from 'contexts/fluxo_novo_docente/NovoDocenteState';

const ListaDocente = lazy(() => retry(() => import('./ListaDocentePage')));
const NovoDocente = lazy(() => retry(() => import('./DadosPessoaisPage')));
const DocumentacaoDocnete = lazy(() =>
  retry(() => import('./DocumentacaoDocentePage'))
);
const EmpresaDocente = lazy(() => retry(() => import('./EmpresaDocentePage')));
const DocumentacaoEmpresa = lazy(() =>
  retry(() => import('./DocumentacaoEmpresaPage'))
);

const ListaContratoDocente = lazy(() =>
  retry(() => import('./contratos/ListaContratoDocentePage'))
);
const NovoContrato = lazy(() =>
  retry(() => import('./contratos/NovoContratoPage'))
);

const ListaEventoDocente = lazy(() =>
  retry(() => import('./eventos/ListaEventoDocentePage'))
);

const NovoEvento = lazy(() => retry(() => import('./eventos/NovoEventoPage')));

const FichaFinanceiraDocent = lazy(() =>
  retry(() => import('./Financeiro/FichaFinanceiraDocentePage'))
);

const OrdemPagamntoList = lazy(() =>
  retry(() => import('./Financeiro/OrdemPagamentoPage'))
);

export default () => (
  <NovoDocenteState>
    <NovoDocenteContext.Consumer>
      {() => (
        <Switch>
          <PrivateRoute exact path="/docente" component={NovoDocente} />
          <PrivateRoute
            exact
            path="/docente/:id/edit"
            component={NovoDocente}
          />
          <PrivateRoute
            exact
            path="/docente/:id/documentacao"
            component={DocumentacaoDocnete}
          />
          <PrivateRoute
            exact
            path="/docente/:id/empresa"
            component={EmpresaDocente}
          />
          <PrivateRoute
            exact
            path="/docente/:id/documentosEmpresa"
            component={DocumentacaoEmpresa}
          />
          <PrivateRoute
            exact
            path="/lista-contrato-docente/:id"
            component={ListaContratoDocente}
          />

          <PrivateRoute exact path="/lista-docente" component={ListaDocente} />
          <PrivateRoute
            exact
            path="/novo-contrato-docente/:id/contrato/new"
            component={NovoContrato}
          />
          <PrivateRoute
            exact
            path="/novo-contrato-docente/:id/contrato/:contratoId"
            component={NovoContrato}
          />
          <PrivateRoute
            exact
            path="/lista-evento-docente/:id"
            component={ListaEventoDocente}
          />
          <PrivateRoute
            exact
            path="/novo-evento-docente/:id/novo-evento/new"
            component={NovoEvento}
          />
          <PrivateRoute
            exact
            path="/novo-evento-docente/:id/novo-evento/:eventoId/edit"
            component={NovoEvento}
          />
          <PrivateRoute
            exact
            path="/novo-evento-docente/:id/novo-evento/:eventoId/viewer"
            component={NovoEvento}
          />
          <PrivateRoute
            exact
            path="/ficha-financeira-docente/:id"
            component={FichaFinanceiraDocent}
          />
          <PrivateRoute
            exact
            path="/ordem-pagamento/:id"
            component={OrdemPagamntoList}
          />
        </Switch>
      )}
    </NovoDocenteContext.Consumer>
  </NovoDocenteState>
);
