import PropTypes from 'prop-types';
import React from 'react';

const DefinitionList = ({ title, text, className, tag: Tag }) => (
  <>
    <div className={`flex-25 ${className}`}>
      <div className="teaching-plan">
        <div className="teaching-plan-title">
          <p>{title}</p>
        </div>
        <div className="teaching-plan-text">
          <Tag>{text || '-'}</Tag>
        </div>
      </div>
    </div>
  </>
);

DefinitionList.propTypes = {
  title: PropTypes.string,
  text: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.oneOf(['p', 'span', 'pre']),
};

DefinitionList.defaultProps = {
  title: '',
  text: '',
  className: '',
  tag: 'p',
};

export default DefinitionList;
