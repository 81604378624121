import axios from 'axios';
import { API_HOST } from '../consts';

const fetchRelationshipOptions = async (params) => {
  const res = await axios.get(`${API_HOST}/health/contact_relationship`, {
    params,
  });
  return res.data;
};

export default fetchRelationshipOptions;
