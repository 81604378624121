import { get } from 'lodash';
import { TICKET_CATEGORY_ENUMS as TICKET_CATEGORIES } from '../../../consts';
import InformedDiscAppicationSerializer from './InformedDiscApplicationSerializer';
import AttendanceReviewSerializer from './AttendanceReviewSerializer';
import DefaultDetailsSerializer from './DefaultDetailsSerializer';
import ClassRelocationSerializer from './ClassRelocationSerializer';
import ExpiredAtendanceSerializer from './ExpiredAttendanceSerializer';

export default class TicketSerializer {
  constructor(ticket, children) {
    this.categoryId = get(ticket, 'category.id');
    this.ticketInfo = get(children, 'props.ticketInfo');
    this.ticket = ticket;
  }

  getDetailsSerializer() {
    const detailSerializers = {
      [TICKET_CATEGORIES.DISCIPLINA_INFORMADA_TIPO_1]: new InformedDiscAppicationSerializer(
        this.ticketInfo
      ),
      [TICKET_CATEGORIES.RETIFICACAO_FREQUENCIA]: new AttendanceReviewSerializer(
        this.ticket,
        this.ticketInfo
      ),
      [TICKET_CATEGORIES.TROCA_TURMA]: new ClassRelocationSerializer(
        this.ticketInfo
      ),
      [TICKET_CATEGORIES.FREQUENCIA_FORA_DO_PRAZO]: new ExpiredAtendanceSerializer(
        this.ticketInfo
      ),
    };
    return detailSerializers[this.categoryId] || new DefaultDetailsSerializer();
  }

  getResolutionInfo() {
    if (this.ticket.resolution) {
      const { resolution } = this.ticket;
      return {
        message: resolution.message,
        status_name: resolution.status.name,
        created_by: resolution.created_by,
        created_at: resolution.created_at,
      };
    }
    return null;
  }

  getTicketSerializer() {
    const resolution = this.getResolutionInfo() || {};
    return {
      id: this.ticket.id,
      title: `${this.ticket.category.name} #${this.ticket.id}`,
      requested_by: this.ticket.requested_by,
      student:
        get(this.ticket, 'addressed_student_name') ||
        get(this.ticketInfo, 'student.name') ||
        '-',
      course: this.ticket.course,
      requested_date: this.ticket.created_at.split(' ')[0],
      ra: get(this.ticket, 'addressed_student_ra', '-'),
      clss: get(this.ticketInfo, 'student.clss', '-'),
      resolution_created_at: get(resolution, 'created_at', '-'),
      responsible: get(resolution, 'created_by', '-'),
      ticket_message: this.ticket.message,
      final_status:
        get(resolution, 'status_name') || get(this.ticket, 'status.name'),
      resolution,
      details: this.getDetailsSerializer().getDetails(),
    };
  }
}
