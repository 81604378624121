/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

const RadioCheckboxInput = ({
  label,
  className,
  disabled,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <label
      className={`container-check-custom ${className} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {`${label}`}
      <input
        type="checkbox"
        {...props}
        {...field}
        checked={field.value}
        onChange={(event) => {
          setFieldValue(field.name, event.target.checked);
          if (onChange) {
            onChange(event.target.checked);
          }
        }}
      />
      <span className="radio-check" />
      {meta.error && <span className="input-helper red">{meta.error}</span>}
    </label>
  );
};

RadioCheckboxInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

RadioCheckboxInput.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  onChange: () => {},
};

export default RadioCheckboxInput;
