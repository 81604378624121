import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Button from './common/buttons/Button';
import logo from '../assets/img/logo-green.svg';
import Card from './Card';
import TicketResolution from './TicketResolution';
import { API_HOST } from '../consts';
import { fetchFiles } from '../s3';
import TicketPdfSerializer from '../pages/tickets/TicketPdfSerializer/TicketPdfSerializer';

class Ticket extends Component {
  constructor(props) {
    super(props);
    if (props) {
      const { ticket, children } = props;
      this.state = {
        ticketSerializer: new TicketPdfSerializer(
          ticket,
          children
        ).getTicketSerializer(),
        showDownloadButton: true,
      };
    }
  }

  componentDidMount() {
    const { ticketSerializer } = this.state;
    const prefix = get(this.props.ticket, 'files');
    if (prefix) {
      fetchFiles(prefix)
        .then((files) => {
          const filesNames = files.map((file) => ({ filename: file.name }));
          this.setState({
            ticketSerializer: { ...ticketSerializer, files: filesNames },
          });
        })
        .catch(() =>
          this.setState({
            ticketSerializer: { ...ticketSerializer, files: [] },
          })
        );
    } else {
      this.setState({ ticketSerializer: { ...ticketSerializer, files: [] } });
    }
  }

  getPermissions() {
    return ['tickets.export_any_ticket', 'tickets.export_ticket'];
  }

  downloadOptions() {
    const { ticketSerializer } = this.state;
    const data = {
      endpoint: '/api/Tickets/Ticket',
      data: ticketSerializer,
    };
    return {
      showDownloadButton: this.state.showDownloadButton,
      api: `${API_HOST}/core/export_service_gateway`,
      dataTip: 'Download Protocolo',
      method: 'post',
      data,
      filename: `${ticketSerializer.title}.pdf`,
      permission: this.getPermissions(),
    };
  }

  renderTitle() {
    const { ticket } = this.props;
    if (ticket) {
      return `${ticket.category.name} #${ticket.id}`;
    }

    return <Skeleton width="150px" height="100%" />;
  }

  render() {
    const { ticket, children, submitting, onCancelTicket } = this.props;

    return (
      <>
        <div className="w-100 d-flex justify-flex-end">
          <img
            width={150}
            src={logo}
            className="navigation-logo show-on-print"
            alt="CMMG"
          />
        </div>
        <Card
          title={this.renderTitle()}
          downloadOptions={this.downloadOptions()}
        >
          {children}
          {ticket && ticket.resolution && (
            <div className="mt-2">
              <TicketResolution
                {...ticket.resolution}
                isAuthor={!!get(ticket.resolution, 'request_user_is_author')}
                canSeePrivate={get(
                  ticket,
                  'permissions.can_see_review_private'
                )}
              />
            </div>
          )}
          <div className="d-flex justify-space-between mt-3">
            <Link
              disabled={submitting}
              className="btn btn-link brown"
              to="/protocolos"
              style={{ padding: 0 }}
            >
              VOLTAR
            </Link>
            {get(ticket, 'permissions.cancel') && (
              <Button
                action="default cancel-button mt-3"
                onClick={onCancelTicket}
              >
                Cancelar protocolo
              </Button>
            )}
          </div>
        </Card>
      </>
    );
  }
}

Ticket.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number,
    resolution: PropTypes.node,
    category: PropTypes.node,
  }),
  fetchingTicket: PropTypes.bool.isRequired,
  onCancelTicket: PropTypes.func,
  children: PropTypes.node,
  submitting: PropTypes.bool,
  loadingFiles: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Ticket.defaultProps = {
  ticket: {},
  children: null,
  onCancelTicket: () => {},
  submitting: false,
};

export default Ticket;
