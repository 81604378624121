import React from 'react';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchMaritalStatusOptions from '../../../services/marital_status_services';

const MaritalStatusInputSelect = (props) => (
  <FetchInputSelectOptions fetchOptions={fetchMaritalStatusOptions}>
    {({ options, isLoading }) => (
      <InputSelect
        name="marital_status"
        label="Estado Civil"
        placeholder="Selecionar"
        options={options}
        isLoading={isLoading}
        getOptionValue={(d) => d.id}
        getOptionLabel={(d) => d.name}
        {...props}
      />
    )}
  </FetchInputSelectOptions>
);
export default MaritalStatusInputSelect;
