import { Checkbox } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';

class InputCheckbox extends React.Component {
  render() {
    const {
      label,
      value,
      onChange,
      field,
      help,
      children,
      className,
      checked,
      ...rest
    } = this.props;
    return (
      <>
        <label className={`container-check-custom ${className}`}>
          <span className="container-check-custom-label">
            {label && <span className="m-0">{label}</span>}
          </span>
          <span>
            <Checkbox
              value={value}
              onChange={onChange}
              field={field}
              {...rest}
              initialValue={checked}
            />
            <span className="checkmark" />
          </span>
          {help && <small className="form-text text-muted">{help}</small>}
        </label>
        {children && children}
      </>
    );
  }
}

InputCheckbox.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  field: PropTypes.string,
  help: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
};

InputCheckbox.defaultProps = {
  label: '',
  field: '',
  className: '',
  help: null,
  value: '',
  onChange: () => {},
  children: null,
  checked: false,
};

export default InputCheckbox;
