import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import TableActionButton from './TableActionButton';
import { getFilenameFromContentDisposition } from '../utils';

const DownloadFromApiButton = ({
  api,
  params,
  filename,
  useHttpHeaderFilename,
  icon,
  method,
  data,
  ...rest
}) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = () => {
    setDownloading(true);
    axios({
      url: api,
      method,
      data,
      params,
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        let downloadFilename = filename;
        if (useHttpHeaderFilename) {
          downloadFilename =
            downloadFilename ||
            getFilenameFromContentDisposition(
              response.headers['content-disposition']
            );
        }

        link.setAttribute('download', downloadFilename);
        document.body.appendChild(link);
        link.click();
        window.location.reload();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao fazer o download do arquivo. Por favor, tente novamente.'
        );
      })
      .then(() => {
        setDownloading(false);
      });
  };

  return (
    <TableActionButton
      icon={downloading ? 'circle-notch' : icon}
      onClick={handleDownload}
      disabled={downloading}
      iconProps={{ spin: downloading }}
      {...rest}
    />
  );
};

DownloadFromApiButton.propTypes = {
  api: PropTypes.string.isRequired,
  method: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  params: PropTypes.objectOf(PropTypes.any),
  filename: PropTypes.string,
  useHttpHeaderFilename: PropTypes.bool,
  icon: PropTypes.string,
};

DownloadFromApiButton.defaultProps = {
  params: {},
  filename: null,
  useHttpHeaderFilename: true,
  icon: 'download',
  method: 'GET',
  data: {},
};

export default DownloadFromApiButton;
