import React, { useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import useInterval from '../../hooks/useInterval';
import RemessaDetailsPageReducer from '../../reducers/remessas/RemessaDetailsPageReducer';
import { fetchRemessaSerasaDetail } from '../../services/serasa/serasa-services';
import RemessaSerasaDetails from 'components/serasa/RemessaSerasaDetails';

const RemessaSerasaDetailsPage = () => {
  const initialState = {
    lancamentos: [],
    page: 0,
    loadingLancamento: false,
    remessaError: true,
    remessa: {},
    values: {},
    fetchingRemessa: false,
    openModal: null,
    cancelandoRemessa: false,
    cancelarRemessaError: '',
  };
  const [state, dispatch] = useReducer(RemessaDetailsPageReducer, initialState);
  const { id } = useParams();

  const { lancamentos, remessa } = state;

  const fetchRemessa = useCallback(
    (loading) => {
      if (loading) {
        dispatch({ type: 'FETCH_REMESSA' });
      }
      return fetchRemessaSerasaDetail(id)
        .then((data) => {
          dispatch({ type: 'SET_REMESSA', payload: data });
          dispatch({ type: 'SET_LANCAMENTOS', payload: data.lancamentos });
          return data;
        })
        .catch(() => {
          // if (remessaError) {
          //   toast.error('Ocorreu um erro ao carregar a remessa. Por favor tente novamente.');
          //   dispatch({ type: 'SET_REMESSA_ERROR', payload: false });
          // }
        });
    },
    [id]
  );

  useEffect(() => {
    fetchRemessa(true);
  }, [fetchRemessa]);

  useInterval(() => {
    if (remessa && remessa.status === 'closed') {
      return;
    }

    fetchRemessa();
  }, 5000);

  return <RemessaSerasaDetails remessa={remessa} lancamentos={lancamentos} />;
};

export default RemessaSerasaDetailsPage;
