import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCollapse from '../../../hooks/useCollapse';
import Button from './Button';

const CollapsedHelpText = ({ children, text }) => {
  const [collapsed, arrow, setCollapsed] = useCollapse(true);

  return (
    <div>
      <Button onClick={setCollapsed} action="help">
        <FontAwesomeIcon icon={`chevron-${arrow}`} />
        <span className="ml-1">{text}</span>
      </Button>
      <p className="silver-light mt-0 mb-0 pl-2 w-270">
        {!collapsed && children}
      </p>
    </div>
  );
};

export default CollapsedHelpText;
