import { useState } from 'react';

const useModal = (initialState = false): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState);
  const show = () => setIsOpen(true);
  const hide = () => setIsOpen(false);
  return [isOpen, show, hide];
};

export default useModal;
