import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import { fetchCurricula } from '../../../services/curricula-services';

const CurriculumInputSelect = ({ params, submitOnChange, ...props }) => {
  const { submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions params={params} fetchOptions={fetchCurricula}>
      {({ options, isLoading }) => (
        <InputSelect
          name="curriculum"
          label="Currículo"
          placeholder="Selecionar"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          onChange={() => submitOnChange && submitForm()}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

CurriculumInputSelect.propTypes = {
  params: PropTypes.objectOf(PropTypes.node),
  submitOnChange: PropTypes.bool,
};

CurriculumInputSelect.defaultProps = {
  params: {},
  submitOnChange: false,
};

export default CurriculumInputSelect;
