import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const MakeUpExamTicketFormRedirectPage = lazy(() =>
  retry(() => import('./MakeUpExamTicketFormRedirectPage'))
);
const StudentMakeUpExamTicketFormPage = lazy(() =>
  retry(() => import('./StudentMakeUpExamTicketFormPage'))
);
const AcademicMakeUpExamTicketFormPage = lazy(() =>
  retry(() => import('./AcademicMakeUpExamTicketFormPage'))
);
const MakeUpExamPage = lazy(() => retry(() => import('./MakeUpExamPage')));
const ActivityGradesReleaseListPage = lazy(() =>
  retry(() => import('./ActivityGradesReleaseListPage'))
);
const ActivityGradesReleasePage = lazy(() =>
  retry(() => import('./ActivityGradesReleasePage'))
);
const CriterionsPage = lazy(() => retry(() => import('./CriterionsPage')));
const CriterionActivitiesPage = lazy(() =>
  retry(() => import('./CriterionActivitiesPage'))
);
const StudentGradesReportPage = lazy(() =>
  retry(() => import('./StudentGradesReportPage'))
);
const ChooseStudentGradesReportPage = lazy(() =>
  retry(() => import('./ChooseStudentGradesReportPage'))
);
const GradeReviewPage = lazy(() => retry(() => import('./GradeReviewPage')));
const GradesListPage = lazy(() => retry(() => import('./GradesListPage')));
const GradeReviewFormRedirectPage = lazy(() =>
  retry(() => import('./GradeReviewFormRedirectPage'))
);
const AcademicDepartmentGradeReviewFormPage = lazy(() =>
  retry(() => import('./AcademicDepartmentGradeReviewFormPage'))
);
const StudentGradeReviewFormPage = lazy(() =>
  retry(() => import('./StudentGradeReviewFormPage'))
);
const ActivityListPage = lazy(() => retry(() => import('./ActivityListPage')));
const TeacherGradeReviewFormPage = lazy(() =>
  retry(() => import('./TeacherGradeReviewFormPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/atividades/calendario"
      permission="activities.view_activity"
      component={ActivityGradesReleaseListPage}
    />
    <PrivateRoute
      exact
      path="/atividades"
      permission="activities.view_activity"
      component={ActivityListPage}
    />
    <PrivateRoute
      exact
      path="/atividades/:id"
      permission="activities.view_activity"
      component={ActivityGradesReleasePage}
    />
    <PrivateRoute
      exact
      path="/criterios"
      permission="activities.view_criterion"
      component={CriterionsPage}
    />
    <PrivateRoute
      exact
      path="/criterios/:id/atividades"
      permission="activities.add_activity"
      component={CriterionActivitiesPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-nota/new"
      permission="activities.add_review"
      component={GradeReviewFormRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-nota/new/controle-academico"
      permission={[
        'activities.add_review',
        'tickets.add_ticket_as_academic_department',
      ]}
      component={AcademicDepartmentGradeReviewFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-nota/new/aluno"
      permission={['activities.add_review', 'tickets.add_ticket_as_student']}
      component={StudentGradeReviewFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-nota/new/professor"
      permission={['activities.add_review', 'tickets.add_ticket_as_teacher']}
      component={TeacherGradeReviewFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/retificacao-nota/:id"
      permission="activities.view_review"
      component={GradeReviewPage}
    />
    <PrivateRoute
      exact
      path="/extrato-notas"
      component={() => <Redirect to="/extrato-notas/disciplinas-atuais" />}
    />
    <PrivateRoute
      exact
      path="/extrato-notas/:disciplineValidity(disciplinas-anteriores|disciplinas-atuais)"
      permission="activities.view_own_grades"
      component={StudentGradesReportPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/segunda-chamada/new"
      permission="activities.add_makeupexamticket"
      component={MakeUpExamTicketFormRedirectPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/segunda-chamada/new/aluno"
      permission="activities.add_makeupexamticket_as_student"
      component={StudentMakeUpExamTicketFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/segunda-chamada/new/controle-academico"
      permission={[
        'activities.add_makeupexamticket_as_academic_department',
        'tickets.add_ticket_as_academic_department',
      ]}
      component={AcademicMakeUpExamTicketFormPage}
    />
    <PrivateRoute
      exact
      path="/protocolos/segunda-chamada/:id"
      permission="activities.view_makeupexamticket"
      component={MakeUpExamPage}
    />
    <PrivateRoute
      exact
      path="/extrato-notas/aluno"
      permission="activities.view_any_student_grade"
      component={ChooseStudentGradesReportPage}
    />
    <PrivateRoute
      exact
      path="/notas"
      permission="activities.view_grade"
      component={GradesListPage}
    />
  </Switch>
);
