import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Lancamento = ({ operacao, valor, descricao, canExclude, onExclude }) => {
  const textClassName = classnames('text-right font-weight-600', {
    danger: operacao === 'D',
    success: operacao === 'C',
    brown: operacao === '*',
  });

  return (
    <div
      className="d-grid mt-1 mb-1 lancamento-details-row"
      style={{
        gridTemplateColumns: '50% 30% 10% 10%',
      }}
    >
      <span className="font-weight-500">{descricao}</span>
      <span className={textClassName}>{valor}</span>
      <span className={textClassName}>{operacao}</span>
      {canExclude && (
        <span onClick={onExclude} className="text-right">
          <FontAwesomeIcon icon="times" />
        </span>
      )}
    </div>
  );
};

Lancamento.propTypes = {
  operacao: PropTypes.oneOf(['C', 'D', '*']).isRequired,
  valor: PropTypes.string,
  descricao: PropTypes.string.isRequired,
  canExclude: PropTypes.bool,
  onExclude: PropTypes.func,
};

Lancamento.defaultProps = {
  valor: '',
};

export default Lancamento;
