import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const BibliographyPage = lazy(() => retry(() => import('./BibliographyPage')));
const SpecificCompetenciesPage = lazy(() =>
  retry(() => import('./SpecificCompetenciesPage'))
);
const EvaluationCriterionPage = lazy(() =>
  retry(() => import('./EvaluationCriterionPage'))
);
const TeachingPlanViewPage = lazy(() =>
  retry(() => import('./TeachingPlanViewPage'))
);
const TeachingPlanGeneralCompetenciesPage = lazy(() =>
  retry(() => import('./TeachingPlanGeneralCompetenciesPage'))
);
const TeachingPlansPage = lazy(() =>
  retry(() => import('./TeachingPlansPage'))
);
const TeachingPlanProgramContentPage = lazy(() =>
  retry(() => import('./TeachingPlanProgramContentPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/planos-ensino/:id/conteudo-programatico"
      permission="teaching_plans.view_teachingplan"
      component={TeachingPlanProgramContentPage}
    />
    <PrivateRoute
      exact
      path="/planos-ensino/:id/bibliografias"
      permission="teaching_plans.view_teachingplan"
      component={BibliographyPage}
    />
    <PrivateRoute
      exact
      path="/planos-ensino/:id/competencias-gerais"
      permission="teaching_plans.view_teachingplan"
      component={TeachingPlanGeneralCompetenciesPage}
    />
    <PrivateRoute
      exact
      path="/planos-ensino"
      permission="teaching_plans.view_teachingplan"
      component={TeachingPlansPage}
    />
    <PrivateRoute
      exact
      path="/planos-ensino/:id/competencias-especificas"
      permission="teaching_plans.view_teachingplan"
      component={SpecificCompetenciesPage}
    />
    <PrivateRoute
      exact
      path="/planos-ensino/:id/criterios-avaliacao"
      permission="teaching_plans.view_teachingplan"
      component={EvaluationCriterionPage}
    />
    <PrivateRoute
      exact
      path="/planos-ensino/:id"
      permission="teaching_plans.view_teachingplan"
      component={TeachingPlanViewPage}
    />
  </Switch>
);
