import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import withDownloadButton from '../../hoc/withDownloadButton';
import Card from '../Card';
import DataForm from '../formik_inputs/DataForm';
import Input from '../formik_inputs/Input';
import InputCheckbox from '../formik_inputs/InputCheckbox';
import Pagination from '../Pagination';
import LancamentosCardDeck from './LancamentosCardDeck';
import RemessaDetailsFileSection from './RemessaDetailsFileSection';
import RemessaHeader from './RemessaHeader';
import RemessaDetailsTitle from './RemessaDetailsTitle';

const RemessaDetails = ({
  remessa,
  loadingLancamento,
  allLancamentos,
  lancamentosToShow,
  onFilterChange,
  pages,
  page,
  onPageChange,
  fetchingRemessa,
}) => {
  const renderCardTitle = () => {
    if (fetchingRemessa) {
      return <Skeleton width={200} />;
    }

    return remessa.titulo;
  };

  return (
    <Card title={renderCardTitle()}>
      <div className="remessa-detail">
        <RemessaHeader
          fetchingRemessa={fetchingRemessa}
          remessa={remessa}
          lancamentos={allLancamentos}
        />
        <hr />
        <RemessaDetailsFileSection
          fetchingRemessa={fetchingRemessa}
          remessa={remessa}
        />
        <hr />
        <RemessaDetailsTitle>Lançamentos</RemessaDetailsTitle>
        <DataForm submitOnChange onSubmit={onFilterChange}>
          <div className="form-grid-1">
            <Input
              placeholder="Pesquisar por aluno..."
              name="name"
              className="mt-0"
            />
            <InputCheckbox
              label="Somente alunos que apresentaram problemas"
              name="error"
            />
          </div>
        </DataForm>
        <div className="cards-selector mt-3">
          <LancamentosCardDeck
            loading={loadingLancamento}
            lancamentos={lancamentosToShow}
          />
          <Pagination
            pageCount={pages}
            forcePage={page}
            onPageChange={onPageChange}
          />
        </div>
        <div className="btn-group">
          <Link to="/remessas" className="btn btn-link brown">
            Voltar
          </Link>
        </div>
      </div>
    </Card>
  );
};

RemessaDetails.propTypes = {
  loadingLancamento: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  remessa: PropTypes.shape({
    titulo: PropTypes.string,
    created_at: PropTypes.string,
    text: PropTypes.string,
    tipo_remessa: PropTypes.string,
  }),
  allLancamentos: PropTypes.arrayOf(PropTypes.object).isRequired,
  lancamentosToShow: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  fetchingRemessa: PropTypes.bool.isRequired,
};

RemessaDetails.defaultProps = {
  remessa: {},
};

export default withDownloadButton(RemessaDetails);
