import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';

const NewSemesterRetakePage = lazy(() =>
  retry(() => import('./NewSemesterRetakePage'))
);
const SemesterRetakePage = lazy(() =>
  retry(() => import('./SemesterRetakePage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      permission="semester_retakes.add_semesterretake"
      path="/protocolos/dependencia/new"
      component={NewSemesterRetakePage}
    />
    <PrivateRoute
      exact
      permission="semester_retakes.view_semesterretake"
      path="/protocolos/dependencia/:id"
      component={SemesterRetakePage}
    />
  </Switch>
);
