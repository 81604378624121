import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBuilding as farBuilding,
  faCalendar as farCalendar,
  faClock as farClock,
  faCopy as farCopy,
  faFileExcel as farFileExcel,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faAsterisk,
  faBan,
  faBars,
  faBell,
  faBook,
  faBookOpen,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClipboardList,
  faClock,
  faComment,
  faCopy,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileContract,
  faFileDownload,
  faFileExcel,
  faFileImport,
  faFileInvoiceDollar,
  faFileSignature,
  faGraduationCap,
  faGlasses,
  faHome,
  faHandshake,
  faInfoCircle,
  faList,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faMinus,
  faMinusCircle,
  faMobileAlt,
  faPaperclip,
  faPhoneVolume,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestionCircle,
  faRedo,
  faSearch,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faCog,
  faUserGraduate,
  faPlane,
  faHospital,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarExclamation,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faFileDownload,
  faEnvelope,
  faChalkboardTeacher,
  faMobileAlt,
  faGraduationCap,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faBell,
  faChevronUp,
  faChevronDown,
  faClock,
  faCircle,
  faSignOutAlt,
  faEdit,
  faPlusCircle,
  faTimesCircle,
  faEye,
  faTimes,
  faMinusCircle,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faSpinner,
  faAsterisk,
  faBook,
  faBars,
  faBookOpen,
  faHome,
  faPlus,
  faExclamationCircle,
  faFile,
  faFileInvoiceDollar,
  faPaperclip,
  faCheckCircle,
  faTrashAlt,
  faDownload,
  faCalendar,
  faCalendarAlt,
  faAngleDown,
  faAngleUp,
  faLongArrowAltRight,
  farClock,
  farCalendar,
  faCircleNotch,
  faFileAlt,
  faUser,
  faDollarSign,
  faPhoneVolume,
  faFileSignature,
  faEllipsisV,
  farFileExcel,
  faPrint,
  faCopy,
  faMinus,
  faBuilding,
  farBuilding,
  faMapMarkerAlt,
  faQuestionCircle,
  faRedo,
  faBan,
  faEyeSlash,
  faClipboardList,
  farCopy,
  faFileContract,
  faFileImport,
  faSlidersH,
  faSearch,
  faFileImport,
  faFileExcel,
  faTrash,
  faExchangeAlt,
  faList,
  faComment,
  faCalendarExclamation,
  faCog,
  faUserGraduate,
  faGlasses,
  faHandshake,
  faPlane,
  faHospital
);
