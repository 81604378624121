import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import retry from '../../retry';
import PrivateRoute from '../../components/PrivateRoute';

const ConciliationBBPage = lazy(() =>
  retry(() => import('./ConciliationBBPage'))
);
const ReturnFileCBR643Page = lazy(() =>
  retry(() => import('./cbr643/ReturnFilePage'))
);
const DefaultReturnFileCBR643Page = lazy(() =>
  retry(() => import('./cbr643/DefaultReturnFilePage'))
);

const ReturnFileRetornoBradescoPage = lazy(() =>
  retry(() => import('./retorno_bradesco/ReturnFilePage'))
);
const DefaultReturnFileBradescoPage = lazy(() =>
  retry(() => import('./retorno_bradesco/DefaultReturnFilePage'))
);

const RetornoSicoobListPage = lazy(() =>
  retry(() => import('./retorno_sicoob/RetornoSicoobListPage'))
);
const RetornoSicoobPage = lazy(() =>
  retry(() => import('./retorno_sicoob/RetornoSicoobPage'))
);

export default () => (
  <Switch>
    <PrivateRoute
      exact
      path="/conciliacao"
      permission="menu.view_conciliacao"
      component={ConciliationBBPage}
    />

    <PrivateRoute
      exact
      path="/conciliacao/cbr643/arquivos"
      permission="menu.view_conciliacao"
      component={ReturnFileCBR643Page}
    />
    <PrivateRoute
      exact
      path="/conciliacao/cbr643/arquivos/:id"
      permission="menu.view_conciliacao"
      component={DefaultReturnFileCBR643Page}
    />
    <PrivateRoute
      exact
      path="/conciliacao/cbr643/arquivos/:id/:records(conciliados|importados)"
      component={DefaultReturnFileCBR643Page}
    />
    <PrivateRoute
      exact
      path="/conciliacao/cbr643/arquivos/:id/:records(conciliados|importados)"
      component={DefaultReturnFileCBR643Page}
    />

    <PrivateRoute
      exact
      path="/conciliacao/bradesco_retorno/arquivos"
      permission="menu.view_conciliacao"
      component={ReturnFileRetornoBradescoPage}
    />
    <PrivateRoute
      exact
      path="/conciliacao/bradesco_retorno/arquivos/:id"
      permission="menu.view_conciliacao"
      component={DefaultReturnFileBradescoPage}
    />
    <PrivateRoute
      exact
      path="/conciliacao/bradesco_retorno/arquivos/:id/:records(conciliados|importados)"
      component={DefaultReturnFileBradescoPage}
    />

    <PrivateRoute
      exact
      path="/conciliacao/sicoob_240/arquivos"
      permission="menu.view_conciliacao"
      component={RetornoSicoobListPage}
    />
    <PrivateRoute
      exact
      path="/conciliacao/sicoob_240/arquivos/:id"
      permission="menu.view_conciliacao"
      component={RetornoSicoobPage}
    />
    <PrivateRoute
      exact
      path="/conciliacao/sicoob_240/arquivos/:id/:records(conciliados|importados)"
      permission="menu.view_conciliacao"
      component={RetornoSicoobPage}
    />
  </Switch>
);
