import React from 'react';
import InputMask from '../InputMask';

const PhoneInputMask = ({ ...props }) => (
  <InputMask
    name="phone"
    label="Telefone"
    mask={(val) =>
      val.replace(/\D/g, '').length > 10
        ? [
            '(',
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]
        : [
            '(',
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]
    }
    {...props}
  />
);

export default PhoneInputMask;
