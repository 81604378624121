import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';

const CopyToClipboardButton = ({ tooltip, content }) => {
  const [copied, setCopied] = useState(false);
  const [id] = useState(shortid.generate());

  const copyToClibboard = () => {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', content);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  const handleClick = () => {
    copyToClibboard();
    setCopied(true);
  };

  const getTooltip = () =>
    copied ? (
      <>
        <FontAwesomeIcon icon="check-circle" /> {tooltip} copiado!
      </>
    ) : (
      `copiar ${tooltip}`
    );

  return (
    <>
      <ReactTooltip
        id={id}
        getContent={getTooltip}
        className="no-arrow-tooltip"
      />
      <FontAwesomeIcon
        data-for={id}
        data-tip
        icon={['far', 'copy']}
        onClick={handleClick}
        onMouseLeave={() => setCopied(false)}
        className="font-20"
      />
    </>
  );
};

export default CopyToClipboardButton;
