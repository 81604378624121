import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = ({ error, label, className, icon, wrapperProps, ...props }) => (
  <div className={`input-type-style ${className}`} {...wrapperProps}>
    <div className={error ? 'input-error' : 'input-style'}>
      <input {...props} />
    </div>
    <label className={`input-label-style ${error ? 'red' : 'dim'}`}>
      {label}
    </label>
    {error && <span className="input-helper red">{error}</span>}
    {icon && <FontAwesomeIcon className="input-icon" icon={icon} />}
  </div>
);

Input.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Input.defaultProps = {
  error: null,
  label: null,
  className: null,
  icon: null,
};

export default Input;
