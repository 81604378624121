/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_HOST } from '../../consts';
import debounce from 'debounce-promise';

export const fetchContasBancarias = (params) =>
  axios
    .get(`${API_HOST}/banco/contas_bancarias`, { params })
    .then((res) => res.data);

export const fetchBanckOptions = debounce((params) =>
  axios
    .get(`${API_HOST}/banco/bancos`, { params })
    .then((res) => res.data.results)
);
