import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Button from '../common/buttons/Button';

const PopUp = ({ popUps, title, onConfirm, confirmText }) => {
  const [isDialogOpen, setDialogState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [popUpId, setPopUpId] = useState(null);

  const handleDialogState = useCallback(() => {
    setLoading(true);
    if (popUps.length) {
      setDialogState(true);
      setLoading(false);
    } else {
      setDialogState(false);
    }
  }, [popUps]);

  const handleChangePage = useCallback(
    (id) => {
      if (id) {
        setPopUpId(id);
        setLoading(false);
      } else if (popUps[0]) {
        setPopUpId(popUps[0].id);
        setLoading(false);
      }
    },
    [popUps]
  );

  useEffect(() => {
    handleDialogState();
    handleChangePage();
  }, [popUps, handleChangePage, handleDialogState]);

  const handleConfirm = () => {
    setLoading(true);
    setPopUpId(null);
    onConfirm(popUpId);
  };

  const handleHide = () => {
    setDialogState(false);
  };

  const renderPopUpNavigation = () => {
    if (loading) {
      return (
        <div className="w-100">
          <Skeleton height="50px" />
        </div>
      );
    }
    return (
      <nav>
        <ul className="pop-up-navigation m-0">
          {popUps.slice(0, 1).map((popUp) => (
            <li
              className={`pop-up-navigation-item ${
                popUp.id === popUpId ? 'active' : ''
              }`}
              key={popUp.id}
            >
              <button
                className="pop-up-navigation-item-button"
                type="button"
                onClick={() => handleChangePage(popUp.id)}
              >
                {popUp.title}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  const renderPopUpContent = () => {
    if (popUpId) {
      const selectedPopUp = popUps.find((popUp) => popUp.id === popUpId);
      return (
        <span dangerouslySetInnerHTML={{ __html: selectedPopUp.content }} />
      );
    }
    return null;
  };

  const renderPopUpFooter = () => {
    return (
      <div className="d-flex justify-flex-end p-dialog-footer">
        <Button action="add" onClick={handleHide} disabled={!popUpId}>
          Ignorar
        </Button>
        <Button action="link" onClick={handleConfirm} disabled={!popUpId}>
          {confirmText}
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      modal
      className="pop-up"
      header={title}
      visible={isDialogOpen}
      onHide={handleHide}
      dismissableMask
    >
      <div className="d-flex flex-direction-column justify-space-between h-100">
        {renderPopUpNavigation()}
        <div className="pop-up-content h-100 mb-2 mt-2">
          {renderPopUpContent()}
        </div>
        {renderPopUpFooter()}
      </div>
    </Dialog>
  );
};

PopUp.propTypes = {
  popUps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      author: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
};

PopUp.defaultProps = {
  popUps: [],
  onConfirm: () => {},
};

export default PopUp;
