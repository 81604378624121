const RemessaDetailsPageReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_LANCAMENTOS':
      return {
        ...state,
        lancamentos: payload,
        loadingLancamento: false,
      };
    case 'SET_MODAL':
      return {
        ...state,
        openModal: payload,
      };
    case 'SET_VALUES':
      return {
        ...state,
        values: { ...payload },
      };
    case 'FETCH_REMESSA':
      return {
        ...state,
        fetchingRemessa: true,
        remessaError: false,
      };
    case 'SET_REMESSA':
      return {
        ...state,
        remessa: payload,
        remessaError: true,
        fetchingRemessa: false,
      };
    case 'SET_REMESSA_ERROR':
      return {
        ...state,
        remessaError: payload,
        fetchingRemessa: false,
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: payload.selected,
      };
    case 'SET_LOADING_LANCAMENTO':
      return {
        ...state,
        loadingLancamento: payload,
      };
    case 'FETCH_REMESSA_FILE_VALIDATION':
      return {
        ...state,
        fetchingRemessaFileValidation: true,
        remessaFileValidationError: true,
      };
    case 'FETCH_REMESSA_FILE_VALIDATION_SUCCESS':
      return {
        ...state,
        remessaFileValidation: payload,
        fetchingRemessaFileValidation: false,
        remessaFileValidationError: false,
      };
    case 'FETCH_REMESSA_FILE_VALIDATION_ERROR':
      return {
        ...state,
        fetchingRemessaFileValidation: false,
        remessaFileValidationError: true,
      };
    case 'CANCELAR_REMESSA':
      return {
        ...state,
        cancelandoRemessa: true,
        cancelarRemessaError: false,
      };
    case 'CANCELAR_REMESSA_SUCCESS':
      return {
        ...state,
        cancelandoRemessa: false,
        cancelarRemessaError: false,
        openModal: null,
      };
    case 'CANCELAR_REMESSA_ERROR':
      return {
        ...state,
        cancelandoRemessa: false,
        cancelarRemessaError: true,
        openModal: null,
      };
    case 'ENVIAR_EMAILS':
      return {
        ...state,
        enviandoEmails: true,
        enviarEmailsError: false,
      };
    case 'ENVIAR_EMAILS_SUCCESS':
      return {
        ...state,
        enviandoEmails: false,
        enviarEmailsError: false,
        openModal: null,
      };
    case 'ENVIAR_EMAILS_ERROR':
      return {
        ...state,
        enviandoEmails: false,
        enviarEmailsError: true,
        openModal: null,
      };
    default:
      return state;
  }
};

export default RemessaDetailsPageReducer;
