import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Header from './Header';
import { Sidebar } from 'common/sidebar';
import LoadingIndicator from './LoadingIndicator';
import NotFound from '../pages/errors/NotFound';
import { MenuContext } from '../hoc/withMenu';
import { PermissionContext } from '../hoc/withPermission';
import { UserContext } from '../hoc/withUser';
import WhatsAppChat from './chat/WhatsAppChat';

class PrivateRouteWrapper extends Component {
  state = {
    collapsed: false,
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;

    this.setState({
      collapsed: width <= mediumSizeScreen,
    });
  };

  handleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  render() {
    const { navbar, sidebar, permission, children, sizingWidth } = this.props;
    const { collapsed } = this.state;
    const width = window.innerWidth;
    const mediumSizeScreen = 1200;

    return (
      <PermissionContext.Consumer>
        {({ error, fetchingPermissions, hasPermission }) => {
          if (error) {
            const params = get(
              this.props,
              'children.props.computedMatch.url',
              ''
            );
            return <Redirect to={`/login/?next=${params}`} />;
          }

          if (fetchingPermissions) {
            return (
              <div className="center-spinner">
                <LoadingIndicator />
              </div>
            );
          }

          const checkPermission = () => {
            if (Array.isArray(permission)) {
              return permission.every(hasPermission);
            }
            return hasPermission(permission);
          };

          if (permission && !checkPermission()) {
            return <NotFound />;
          }

          return (
            <MenuContext.Consumer>
              {(menu) => (
                <>
                  {navbar && (
                    <UserContext.Consumer>
                      {(user) => <Header {...user} />}
                    </UserContext.Consumer>
                  )}
                  <div
                    className={`${sidebar ? 'content' : ''} ${
                      collapsed && width >= mediumSizeScreen ? 'pl-5' : ''
                    }`}
                  >
                    {sidebar && (
                      <Sidebar menu={menu} onCollapse={this.handleCollapse} />
                    )}
                    <div className={`${sidebar ? sizingWidth : ''}`}>
                      <div className={`${sidebar ? 'container' : ''}`}>
                        {children}
                      </div>
                    </div>
                    <WhatsAppChat />
                  </div>
                </>
              )}
            </MenuContext.Consumer>
          );
        }}
      </PermissionContext.Consumer>
    );
  }
}

PrivateRouteWrapper.propTypes = {
  sidebar: PropTypes.bool,
  navbar: PropTypes.bool,
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.node,
  sizingWidth: PropTypes.string,
};

PrivateRouteWrapper.defaultProps = {
  sidebar: true,
  navbar: true,
  permission: null,
  children: null,
  sizingWidth: 'w-100',
};

export default PrivateRouteWrapper;
