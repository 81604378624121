import React from 'react';
import PropTypes from 'prop-types';

const DropdownMenu = ({ children }) => (
  <ul className="dropdown-menu">{children}</ul>
);

DropdownMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default DropdownMenu;
