import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import retry from '../../retry';

const FinalPapersPage = lazy(() => retry(() => import('./FinalPapersPage')));
const FinalPaperEditPage = lazy(() =>
  retry(() => import('./FinalPaperEditPage'))
);
export default () => (
  <Switch>
    <PrivateRoute exact path="/final-papers" component={FinalPapersPage} />
    <PrivateRoute
      exact
      path="/final-papers/new"
      // TODO PERMISSIONS
      component={FinalPaperEditPage}
    />
    <PrivateRoute
      exact
      path="/final-papers/:id"
      // TODO PERMISSIONS
      component={FinalPaperEditPage}
    />
  </Switch>
);
