import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

const WarningMessage = ({ children, className }) => (
  <div className={`font-13 d-flex align-items-center ${className}`}>
    <FontAwesomeIcon icon="info-circle" className="primary mr-1 font-14" />
    {children}
  </div>
);

WarningMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

WarningMessage.defaultProps = {
  children: null,
  className: '',
};

export default WarningMessage;
