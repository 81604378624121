import { get } from 'lodash';

export default class AttendanceReviewSerializer {
  constructor(ticket, ticketInfo) {
    this.ticket = ticket;
    this.ticketInfo = ticketInfo;
  }

  getAttendances() {
    return this.ticketInfo.attendances.map((d) => ({
      key: d.lesson.time,
      value:
        d.attending === true ? 'PRESENTE -> AUSENTE' : 'AUSENTE -> PRESENTE',
    }));
  }

  getDetails() {
    const { course, clss, discipline } = this.ticketInfo;
    const details = [
      {
        key: 'Frequências para retificação',
        value: `${course || '-'} - ${clss || '-'} - ${discipline || '-'}`,
      },
      { key: 'Aluno', value: get(this.ticketInfo, 'student') },
      { key: 'Professor', value: get(this.ticketInfo, 'teacher') },
    ];

    const ticketStatusId = get(this.ticket, 'status.id');
    if (ticketStatusId === 3) {
      return [...details, ...this.getAttendances()];
    }
    return details;
  }
}
