export const API_HOST =
  process.env.REACT_APP_API_HOST || 'http://localhost:8000';
export const PDF_EXPORT_API_HOST =
  process.env.PDF_EXPORT_API_HOST || 'http://localhost:5001/api';
export const BOLETOS_SERVICE_HOST =
  process.env.REACT_APP_BOLETOS_SERVICE_HOST || 'http://127.0.0.1:8001';
export const MOODLE_HOST =
  process.env.REACT_APP_MOODLE_HOST || 'http://localhost';
export const ACTIVITY_GRADE_DECIMAL_PLACES =
  +process.env.REACT_APP_ACTIVITY_GRADE_DECIMAL_PLACES || 2;
export const CURRENT_PLACE = {
  name: process.env.REACT_APP_CURRENT_PLACE_NAME,
  address: process.env.REACT_APP_CURRENT_PLACE_ADDRESS,
};
export const CHAT_ID = process.env.REACT_APP_CHAT_ID;
export const SUITE_SHARE_ID = process.env.REACT_APP_SUITE_SHARE_ID;
export const WHATS_APP_PHONE = process.env.REACT_APP_WHATS_APP_PHONE;
export const REACT_APP_LABEL_DIPLOMA =
  process.env.REACT_APP_LABEL_DIPLOMA || 'DIPLOMADO';

if (Number.isNaN(ACTIVITY_GRADE_DECIMAL_PLACES)) {
  throw Error(
    '`ACTIVITY_GRADE_DECIMAL_PLACES` setting should be a valid integer.'
  );
}

export const LESSON_STATUS_COLORS = {
  1: '#BABFBF',
  2: '#B77474',
  3: '#6CAA64',
  4: '#CC943C',
  5: '#969292',
};

export const LANCAMENTO_STATUS_COLORS = {
  1: '#da8a38',
  2: '#ACACAE',
  3: '#ACACAE',
  4: '#ACACAE',
  5: '#6caa63',
  6: '#b77474',
  7: '#a39161',
  11: '#6caa63',
  12: '#FF0000',
  14: '#6caa63',
  15: '#6caa63',
  16: '#6caa63',
  17: '#6caa63',
  21: '#FF0000',
  22: '#6caa63',
  25: '#6caa63',
  32: '#b77474',
};

export const TEACHING_PLAN_STATUS_COLORS = {
  1: '#B77474', // Não preenchido
  2: '#23689B', // Preenchendo
  3: '#039F97', // Preenchido
  4: '#DA8A38', // Proposta de melhoria
  5: '#A39161', // Validado CN
  6: '#6CAA63', // Aprovado
  7: '#9B9B9B', // Não verificado
};

export const FEATURES = {
  ACTIVITY_LIST: ['alunos_presentes_no_card_atividades'],
  CRONOGRAMA_AULAS: ['material_didatico_no_cronograma'],
};

export const TICKET_CATEGORY_URLS = {
  1: 'retificacao-frequencia',
  2: 'retificacao-nota',
  3: 'troca-turma',
  4: 'DIV',
  5: 'DEC',
  6: 'HIS',
  7: 'UNI',
  8: 'segunda-chamada',
  9: 'frequencia-fora-do-prazo',
  10: 'disciplina-informada/tipo/1',
  11: 'dependencia',
  12: 'comunicado-ausencia-atividades-docentes',
};

export const TICKET_CATEGORY_ENUMS = {
  RETIFICACAO_FREQUENCIA: 1,
  RETIFICACAO_NOTA: 2,
  TROCA_TURMA: 3,
  SEGUNDA_CHAMADA: 8,
  FREQUENCIA_FORA_DO_PRAZO: 9,
  DISCIPLINA_INFORMADA_TIPO_1: 10,
};

export const ACTIVE_CURRICULUM_STATUS = 13;

export const LESSON_TIMES = [
  {
    start_time: '07:00',
    end_time: '07:50',
  },
  {
    start_time: '07:50',
    end_time: '08:40',
  },
  {
    start_time: '08:55',
    end_time: '09:45',
  },
  {
    start_time: '09:45',
    end_time: '10:35',
  },
  {
    start_time: '10:50',
    end_time: '11:40',
  },
  {
    start_time: '11:40',
    end_time: '12:30',
  },
  {
    start_time: '13:30',
    end_time: '14:20',
  },
  {
    start_time: '14:20',
    end_time: '15:10',
  },
  {
    start_time: '15:25',
    end_time: '16:15',
  },
  {
    start_time: '16:15',
    end_time: '17:05',
  },
  {
    start_time: '17:20',
    end_time: '18:10',
  },
  {
    start_time: '18:10',
    end_time: '19:00',
  },
  {
    start_time: '19:00',
    end_time: '19:50',
  },
  {
    start_time: '19:50',
    end_time: '20:40',
  },
  {
    start_time: '20:55',
    end_time: '21:45',
  },
  {
    start_time: '21:45',
    end_time: '22:35',
  },
];

export const WEEKDAYS = [
  {
    name: 'Segunda-feira',
    abbreviation: 'SEG',
    code: 0,
  },
  {
    name: 'Terça-feira',
    abbreviation: 'TER',
    code: 1,
  },
  {
    name: 'Quarta-feira',
    abbreviation: 'QUA',
    code: 2,
  },
  {
    name: 'Quinta-feira',
    abbreviation: 'QUI',
    code: 3,
  },
  {
    name: 'Sexta-feira',
    abbreviation: 'SEX',
    code: 4,
  },
  {
    name: 'Sábado',
    abbreviation: 'SAB',
    code: 5,
  },
];

export const OFFER_TYPES = {
  HALF_YEARLY: 1,
  YEARLY: 2,
};

export const STATUS_LANCAMENTO = {
  PAGO: 5,
};

export const devices = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export default API_HOST;
