import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import { fetchTurno } from '../../../services/censo_services';

const TurnoInputSelect = ({ params, submitOnChange, ...props }) => {
  const { submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions params={params} fetchOptions={fetchTurno}>
      {({ options, isLoading }) => (
        <InputSelect
          name="turno"
          label="Turno"
          placeholder="Selecionar"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.nome}
          onChange={() => submitOnChange && submitForm()}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

TurnoInputSelect.propTypes = {
  params: PropTypes.objectOf(PropTypes.node),
  submitOnChange: PropTypes.bool,
};

TurnoInputSelect.defaultProps = {
  params: {},
  submitOnChange: false,
};

export default TurnoInputSelect;
