import PropTypes from 'prop-types';
import { Component } from 'react';

class FetchInputSelectOptions extends Component {
  state = {
    isLoading: false,
    options: [],
  };

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props;
    const paramChanged = Object.keys(params).some(
      (param) => params[param] !== prevProps.params[param]
    );
    if (paramChanged) {
      this.fetchOptions();
    }
  }

  fetchOptions = () => {
    const { params, fetchOptions } = this.props;
    this.setState({ isLoading: true });
    fetchOptions(params)
      .then((options) => this.setState({ isLoading: false, options }))
      .catch(() => this.setState({ isLoading: false }));
  };

  render() {
    const { children } = this.props;
    const { options, isLoading } = this.state;
    return children({ options, isLoading });
  }
}

FetchInputSelectOptions.propTypes = {
  fetchOptions: PropTypes.func.isRequired,
  params: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.node),
    PropTypes.number,
  ]),
  children: PropTypes.func.isRequired,
};

FetchInputSelectOptions.defaultProps = {
  params: {},
};

export default FetchInputSelectOptions;
