/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useMemo } from 'react';
import { get } from 'lodash';
import { Form, FieldArray } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Title from '../../common/data-display/Title';
import Card from '../../components/Card';
import CourseInputSelect from '../../components/formik_inputs/custom-inputs/CourseInputSelect';
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '../../components/common/buttons/Button';
import {
  DataForm,
  InputCheckboxWithValue,
} from '../../components/formik_inputs';
import withTable, { TableContext } from '../../hoc/withTable';
import { fetchPaginatedCurricula } from '../../services/curricula-services';
import Table from '../../components/Table';

import { API_HOST } from '../../consts';
import useFetch from '../../hooks/useFetch';
import useFeature from '../../hooks/useFeature';

const NewPromotionPage = ({ onFilterChange }) => {
  const history = useHistory();

  const [validations, isFetchingValidations] = useFetch(
    `${API_HOST}/promotion/validation`
  );

  const [promoteMethod] = useFeature('promotion__promote_method');
  const showValidation = promoteMethod === 'Graduation';

  const formikInitialValues = useMemo(() => {
    if (!validations) return {};

    return {
      curricula: [],
      validations: validations.reduce((mappedValues, curr) => {
        mappedValues.push(curr.id);
        return mappedValues;
      }, []),
    };
  }, [validations]);

  const submitPromotions = async (formData) => {
    const data = {
      curricula: get(formData, 'curricula', [])
        .filter((d) => d)
        .join(),
      validations: get(formData, 'validations', [])
        .filter((d) => d)
        .join(),
    };
    try {
      await axios.post(`${API_HOST}/promotion/`, data);
      toast.success('Promoções criadas com sucesso!');
      history.push(`/promocao`);
    } catch (err) {
      toast.error('Ocorreu um erro ao criar as promoções, tente novamente.');
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        sortable: false,
        className: 'break-line',
        width: 60,
        Cell: ({ index, original: { id } }) => (
          <InputCheckboxWithValue name={`curricula.${index}`} value={id} />
        ),
      },
      {
        Header: 'Nome',
        sortable: false,
        className: 'break-line',
        Cell: ({ original }) => {
          return <p>{original.name}</p>;
        },
      },
    ];
  }, []);

  const handleFilterChange = (filters) => {
    const data = {
      course: get(filters, 'course.id'),
    };
    onFilterChange(data);
  };

  if (isFetchingValidations) {
    return (
      <div className="center-spinner">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <Card title="Processo de matrícula de veterano">
      <DataForm onSubmit={handleFilterChange} submitOnChange>
        <CourseInputSelect submitOnChange className="mb-2" />
      </DataForm>
      <DataForm
        autoSetErrors
        initialValues={formikInitialValues}
        onSubmit={submitPromotions}
      >
        {(formik) => (
          <Form>
            <TableContext.Consumer>
              {(props) => {
                return (
                  <FieldArray
                    name="curricula"
                    render={() => <Table {...props} columns={columns} />}
                  />
                );
              }}
            </TableContext.Consumer>
            {showValidation && (
              <>
                <Title className="mt-2" title="Selecione as validações" />
                <div>
                  <FieldArray
                    name="validations"
                    render={() => (
                      <div>
                        {validations.map((validation, index) => (
                          <div className="mb-1">
                            <InputCheckboxWithValue
                              key={validation.id}
                              label={validation.description}
                              name={`validations.${index}`}
                              value={validation.id}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
              </>
            )}
            <div className="d-flex align-items-center justify-flex-end mt-2">
              <Link to="/promocao" className="btn btn-link brown">
                CANCELAR
              </Link>
              <Button
                disabled={formik.isSubmitting}
                action="primary"
                type="submit"
              >
                {formik.isSubmitting ? 'Salvando...' : 'Criar promoção'}
              </Button>
            </div>
          </Form>
        )}
      </DataForm>
    </Card>
  );
};

export default withTable(fetchPaginatedCurricula)(NewPromotionPage);
