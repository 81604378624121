import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import shortid from 'shortid';
import { uploadFile, fetchFiles, getFileLink } from '../../s3';
import ConfirmationModal from '../ConfirmationModal';
import Circle from '../../common/data-display/Circle';
import FileDisplay from '../FileDisplay';
import ContentModal from '../common/ContentModal';

class InputFile extends Component {
  state = {
    fileNames: [],
    fileKeys: [],
    loading: true,
    isModalOpen: false,
    filesToDelete: [],
    isPreviewModalOpen: false,
    selectedFile: {},
    loadingPreview: false,
  };

  componentDidMount() {
    const { prefix, module } = this.props;

    if (prefix) {
      this.prefix = prefix;
      this.loadFiles();
    } else {
      this.prefix = `${module}/${uuidv1()}`;
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { prefix, fetchOnUpdate } = this.props;
    if (prevProps.prefix !== prefix) {
      this.prefix = prefix;
      if (fetchOnUpdate) {
        this.loadFiles();
      }
    }
  }

  handleFileChange = (e) => {
    const { fileName } = this.props;
    const { files } = e.target;
    if (!files[0]) {
      return;
    }

    const filesArray = Array.from(files);
    const fileNames = filesArray.map((file, index) => {
      const sameNames = this.state.fileNames.filter((fileData) => {
        const originalName = fileData.name.split('.')[0].slice(0, -4);
        const originalExtension = fileData.name.split('.')[1];
        const originalFileName = `${originalName}.${originalExtension}`;
        return fileData.name === file.name || originalFileName === file.name;
      });
      let { name } = file;
      if (sameNames.length > 0 || fileName) {
        const _fileName = file.name.split('.')[0];
        const fileExtension = file.name.split('.')[1];
        const file_name = !!!fileName
          ? `${_fileName} (${sameNames.length})`
          : `${fileName}_${index + 1}`;
        name = `${file_name}.${fileExtension}`;
      }

      return {
        file,
        key: `${this.prefix}/${name}`,
        name,
        uploading: true,
        progress: 0,
      };
    });
    this.setState(
      (prevState) => ({
        fileNames: { ...prevState }.fileNames.concat(fileNames),
      }),
      () => this.handleUploadFiles()
    );
  };

  handleUploadFiles = () => {
    const { module } = this.props;
    const { fileNames } = this.state;
    const newFileNames = fileNames.slice();
    const filesNotUploaded = newFileNames.filter(
      (file) => file.uploading === true
    );
    fileNames
      .filter((file) => file.uploading === true)
      .forEach((fileData, i) => {
        uploadFile(fileData.file, fileData.key, module, (progressEvent) => {
          filesNotUploaded[i].progress =
            (progressEvent.loaded / progressEvent.total) * 100;
          this.setState({
            fileNames: newFileNames,
          });
        })
          .then(() => {
            const { fileKeys } = this.state;
            filesNotUploaded[i].uploading = false;
            this.setState(
              {
                fileNames: newFileNames,
                fileKeys: [...fileKeys, fileData.key],
              },
              () => this.props.onUploadComplete(this.prefix)
            );
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      });
  };

  handleResetFilesConfirmation = () => {
    const { fileKeys } = this.state;
    this.setState({ isModalOpen: true, filesToDelete: fileKeys });
  };

  handleDeleteConfirmation = (fileKey) => {
    const { showDeleteConfirmation } = this.props;
    if (showDeleteConfirmation) {
      this.setState({ isModalOpen: true, filesToDelete: [fileKey] });
    } else {
      this.setState({ filesToDelete: [fileKey] }, this.handleDeleteFiles);
    }
  };

  handleDeleteFiles = () => {
    const { filesToDelete, fileNames, fileKeys } = this.state;
    const { onDeleteFile } = this.props;

    onDeleteFile(filesToDelete);
    this.setState(
      {
        isModalOpen: false,
        fileNames: fileNames.filter(
          (file) => !filesToDelete.includes(file.key)
        ),
        fileKeys: fileKeys.filter((key) => !filesToDelete.includes(key)),
      },
      () => this.props.onUploadComplete(null)
    );
  };

  resetFiles = () => {
    this.setState({ fileNames: [], fileKeys: [] }, () =>
      this.props.onUploadComplete(this.prefix)
    );
  };

  deleteFile = (fileKey) => {
    const { fileNames, fileKeys } = this.state;
    this.setState(
      {
        fileNames: fileNames.filter((file) => file.key !== fileKey),
        fileKeys: fileKeys.filter((key) => key !== fileKey),
      },
      () => this.props.onUploadComplete(this.prefix)
    );
  };

  loadFiles() {
    fetchFiles(this.prefix).then((response) => {
      const fileNames = response.map((file) => ({
        key: file.key,
        name: file.key.split('/').slice(-1)[0],
        size: file.size,
        uploading: false,
        progress: 100,
      }));
      const fileKeys = response.map((file) => file.key);
      this.setState({ loading: false, fileNames, fileKeys });
    });
  }

  handlePreview(file) {
    this.setState({
      isPreviewModalOpen: false,
      selectedFile: {},
      selectedSrc: null,
      loadingPreview: true,
    });
    getFileLink(file.key)
      .then(({ url }) => {
        this.setState({
          isPreviewModalOpen: true,
          selectedFile: file,
          selectedSrc: url,
          loadingPreview: false,
        });
      })
      .catch(() => {
        this.setState({ loadingPreview: false });
      });
  }

  renderFiles = () => {
    const { fileNames, loadingPreview } = this.state;
    const { withPreview } = this.props;
    return (
      <>
        <p className="font-12 ml-1x">
          {fileNames.length}{' '}
          {fileNames.length === 1
            ? 'arquivo selecionado'
            : 'arquivos selecionados'}
        </p>
        {fileNames.map((file) => (
          <FileDisplay
            key={file.key}
            file={file}
            onDeleteConfirmation={(key) => this.handleDeleteConfirmation(key)}
            showDownloadButton
            withPreview={withPreview}
            onPreview={() => this.handlePreview(file)}
            loading={file.uploading || loadingPreview}
          />
        ))}
        <button
          onClick={this.handleResetFilesConfirmation}
          className="btn-link clickable bg-transparent"
          type="button"
        >
          Cancelar
        </button>
      </>
    );
  };

  renderRequiredIndicator() {
    const { required } = this.props;
    return required && <span className="danger">*</span>;
  }

  render() {
    const {
      fileNames,
      loading,
      isModalOpen,
      selectedFile,
      isPreviewModalOpen,
      selectedSrc,
    } = this.state;
    const { multiple, error, label, textFuncaoInput, iconVisible } = this.props;
    const fieldId = shortid.generate();

    if (loading) {
      return <FontAwesomeIcon color="#7d9bd4" icon="spinner" spin />;
    }

    return (
      <div className="input-type-style input-file">
        {label && (
          <label className="input-label-style" htmlFor={fieldId}>
            {label}
            {this.renderRequiredIndicator()}
          </label>
        )}
        <input
          multiple={multiple}
          className="hide"
          type="file"
          id={fieldId}
          onChange={this.handleFileChange}
        />
        <div
          className={classnames('d-flex align-items-center flex-wrap', {
            'mt-1': !!label,
          })}
        >
          <label className="clickable mr-2" htmlFor={fieldId}>
            <div className="d-flex align-items-center">
              {iconVisible ? (
                <Circle color={fileNames.length !== 0 ? '#039F97' : '#707070'}>
                  <FontAwesomeIcon icon="paperclip" />
                </Circle>
              ) : (
                ''
              )}
              {fileNames.length === 0 && (
                <p className="font-12 ml-1">
                  {textFuncaoInput}
                  {!label && this.renderRequiredIndicator()}
                </p>
              )}
            </div>
          </label>
          {fileNames.length !== 0 && this.renderFiles()}
        </div>
        {error && <span className="input-helper red">{error}</span>}
        <ConfirmationModal
          visible={isModalOpen}
          onHide={() =>
            this.setState({ isModalOpen: false, filesToDelete: [] })
          }
          onConfirm={this.handleDeleteFiles}
        >
          <p>Tem certeza que deseja remover este arquivo?</p>
        </ConfirmationModal>
        <ContentModal
          visible={isPreviewModalOpen}
          header={get(selectedFile, 'name')}
          onHide={() =>
            this.setState({
              isPreviewModalOpen: false,
              selectedFile: {},
              selectedSrc: null,
            })
          }
        >
          <iframe
            src={selectedSrc}
            name={get(selectedFile, 'name')}
            title={get(selectedFile, 'name')}
            frameBorder="0"
            scrolling="yes"
            width="100%"
            height="100%"
          />
        </ContentModal>
      </div>
    );
  }
}

InputFile.propTypes = {
  onUploadComplete: PropTypes.func,
  onDeleteFile: PropTypes.func,
  multiple: PropTypes.bool,
  prefix: PropTypes.string,
  fetchOnUpdate: PropTypes.bool,
  module: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  showDeleteConfirmation: PropTypes.bool,
  withPreview: PropTypes.bool,
  textFuncaoInput: PropTypes.string,
  fileName: PropTypes.string,
  iconVisible: PropTypes.bool,
};

InputFile.defaultProps = {
  onUploadComplete: () => {},
  onDeleteFile: () => {},
  multiple: true,
  prefix: '',
  fetchOnUpdate: false,
  error: '',
  label: '',
  required: false,
  showDeleteConfirmation: true,
  withPreview: false,
  textFuncaoInput: 'Anexar arquivos',
  fileName: '',
  iconVisible: true,
};

export default InputFile;
