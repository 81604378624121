import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import ContaReducer from '../../reducers/conta/ContaReducer';
import { SET_CONTA, SET_CONTA_LOADING } from '../../reducers/conta/types';
import {
  cancelarLancamentos,
  fetchConta,
} from '../../services/financeiros/financeiros_services';
import ContaContext from './ContaContext';

const ContaState = ({ contaId, children }) => {
  const initialState = {
    conta: {},
    contaLoading: false,
  };

  const [state, dispatch] = useReducer(ContaReducer, initialState);
  const { conta, contaLoading } = state;

  const getConta = useCallback(async () => {
    if (contaId) {
      dispatch({ type: SET_CONTA_LOADING, payload: true });
      try {
        const data = await fetchConta(contaId);
        dispatch({ type: SET_CONTA, payload: data });
        dispatch({ type: SET_CONTA_LOADING, payload: false });
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar as informações da conta.');
        dispatch({ type: SET_CONTA_LOADING, payload: false });
      }
    }
  }, [contaId]);

  const onCancelarLancamentos = (filters, data) =>
    cancelarLancamentos(data, { params: filters });

  useEffect(() => {
    getConta();
  }, [contaId, getConta]);

  return (
    <ContaContext.Provider
      value={{
        conta,
        contaLoading,
        getConta,
        onCancelarLancamentos,
      }}
    >
      {children}
    </ContaContext.Provider>
  );
};

ContaState.propTypes = {
  children: PropTypes.node.isRequired,
  contaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ContaState;
