import React from 'react';
import { withRouter } from 'react-router-dom';
import AbsenceNoticeTicket from '../../components/tickets/AbsenceNoticeTicket';
import withTicket from '../../hoc/withTicket';
import { API_HOST } from '../../consts';
import { fetchAbsenceNoticeTicket } from '../../services/retrieve_services';

const AbsenceNoticeTicketPage = ({
  ticket,
  onSubmit,
  history,
  ticketInfo,
  submitting,
  renderForwardings,
}) => {
  const handleCancel = () => {
    history.push('/protocolos');
  };

  return (
    <AbsenceNoticeTicket
      ticket={ticket}
      ticketInfo={ticketInfo}
      onCancel={handleCancel}
      onSubmit={onSubmit}
      submitting={submitting}
      renderForwardings={renderForwardings}
    />
  );
};

const submitUrl = (ticketId) =>
  `${API_HOST}/attendances/absence_notice_ticket/ticket/${ticketId}/resolve`;
export default withTicket({
  fetchTicketInfo: fetchAbsenceNoticeTicket,
  submitUrl,
})(withRouter(AbsenceNoticeTicketPage));
