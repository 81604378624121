import React from 'react';
import { Switch } from 'react-router';
import PrivateRoute from '../../components/PrivateRoute.tsx';
import NewPromotionPage from './NewPromotionPage';
import PromotionListPage from './PromotionListPage';
import StudentPromotionListPage from './StudentPromotionListPage';

export default () => (
  <Switch>
    <PrivateRoute
      exact
      permission="promotion.add_promotion"
      path="/promocao/new"
      component={NewPromotionPage}
    />
    <PrivateRoute
      exact
      permission="promotion.add_promotion"
      path="/promocao"
      component={PromotionListPage}
    />
    <PrivateRoute
      exact
      permission="promotion.add_promotion"
      path="/promocao/:id"
      component={StudentPromotionListPage}
    />
  </Switch>
);
