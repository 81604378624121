import React from 'react';
import InputMask from '../InputMask';

const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const CNPJInputMask = ({ ...props }) => (
  <InputMask label="CNPJ" name="cnpj" mask={cnpjMask} {...props} />
);

export default CNPJInputMask;
