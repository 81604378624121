import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  fetchLancamento,
  fetchLancamentoLogs,
  fetchDiscountLogs,
} from '../../services/financeiros/financeiros_services';
import LancamentoContext from './LancamentoContext';
import LancamentoReducer from '../../reducers/lancamento/LancamentoReducer';
import {
  SET_LANCAMENTO_LOADING,
  SET_LANCAMENTO,
  SET_LOGS,
  SET_LOGS_LOADING,
  SET_DISCOUT_LOGS_LOADING,
  SET_DISCOUNT_LOGS,
} from '../../reducers/lancamento/types';

const initialState = {
  lancamento: {
    tipo: {
      id: null,
      nome: null,
    },
    mes_competencia: null,
    ano_competencia: null,
    valor_bruto: null,
    descontos: [],
    acrescimos: [],
    valor_pago: null,
    permissions: {},
  },
  lancamentoLoading: false,
  logs: [],
  loadingDiscountLogs: false,
  discountLogs: [],
  logsLoading: false,
};

const LancamentoState = ({ children, id }) => {
  const [state, dispatch] = useReducer(LancamentoReducer, initialState);
  const {
    lancamento,
    lancamentoLoading,
    logs,
    discountLogs,
    loadingDiscountLogs,
  } = state;

  const getLancamento = useCallback(async () => {
    if (id) {
      dispatch({ type: SET_LANCAMENTO_LOADING, payload: true });
      try {
        const data = await fetchLancamento(id);
        dispatch({ type: SET_LANCAMENTO, payload: data });
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao carregar as informações da lancamento.'
        );
        dispatch({ type: SET_LANCAMENTO_LOADING, payload: false });
      }
    } else {
      dispatch({
        type: SET_LANCAMENTO,
        payload: { ...initialState.lancamento },
      });
    }
  }, [id]);

  const getLogs = useCallback(async () => {
    if (id) {
      dispatch({ type: SET_LOGS_LOADING, payload: true });
      try {
        const data = await fetchLancamentoLogs(id, { field: 'status' });
        dispatch({ type: SET_LOGS, payload: data });
      } catch (error) {
        dispatch({ type: SET_LOGS_LOADING, payload: false });
      }
    } else {
      dispatch({ type: SET_LOGS, payload: [] });
    }
  }, [id]);

  const getDiscountLogs = useCallback(async () => {
    if (id) {
      dispatch({ type: SET_DISCOUT_LOGS_LOADING, payload: true });
      try {
        const data = await fetchDiscountLogs({ lancamento: id });
        dispatch({ type: SET_DISCOUNT_LOGS, payload: data });
      } catch (error) {
        dispatch({ type: SET_DISCOUT_LOGS_LOADING, payload: false });
      }
    } else {
      dispatch({ type: SET_DISCOUNT_LOGS, payload: [] });
    }
  }, [id]);

  useEffect(() => {
    getLancamento();
    getLogs();
    getDiscountLogs();
  }, [id, getLancamento, getLogs, getDiscountLogs]);

  return (
    <LancamentoContext.Provider
      value={{
        lancamento,
        lancamentoLoading,
        getLancamento,
        logs,
        discountLogs,
        loadingDiscountLogs,
      }}
    >
      {children}
    </LancamentoContext.Provider>
  );
};

LancamentoState.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
};

export default LancamentoState;
