import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FetchInputSelectOptions from '../../FetchInputSelectOptions';
import InputSelect from '../InputSelect';
import fetchStates from '../../../services/state_services';

const StateInputSelect = ({ submitOnChange, label, ...props }) => {
  const { submitForm } = useFormikContext();

  return (
    <FetchInputSelectOptions fetchOptions={fetchStates}>
      {({ options, isLoading }) => (
        <InputSelect
          name="state"
          label={label}
          placeholder="Selecione"
          options={options}
          isLoading={isLoading}
          getOptionValue={(d) => d.id}
          getOptionLabel={(d) => d.name}
          onChange={() => submitOnChange && submitForm()}
          {...props}
        />
      )}
    </FetchInputSelectOptions>
  );
};

StateInputSelect.propTypes = {
  submitOnChange: PropTypes.bool,
  label: PropTypes.string,
};

StateInputSelect.defaultProps = {
  submitOnChange: false,
  label: 'Estado',
};

export default StateInputSelect;
