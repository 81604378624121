import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Login from '../../components/Login';
import { API_HOST } from '../../consts';
import queryString from 'query-string';

class LoginPage extends React.Component {
  state = {
    error: null,
    submitting: false,
  };

  handleSubmit = () => {
    const { history } = this.props;
    const { values } = this.formApi.getState();
    const params = queryString.parse(this.props.location.search);

    this.setState({ error: null, submitting: true });

    axios
      .post(`${API_HOST}/login`, values)
      .then((response) => {
        const { token } = response.data;
        localStorage.setItem('token', token);

        if (params.next) {
          history.push(params.next);
        } else {
          history.push('/');
        }
      })
      .catch((error) => {
        const badRequest = error.response && error.response.status === 400;
        const errorMessage = badRequest
          ? get(error, 'response.data.non_field_errors', [
              'Preencha corretamente os campos.',
            ])
          : ['Ocorreu um erro inesperado. Por favor, tente novamente.'];
        this.setState({ error: errorMessage[0], submitting: false });
      });
  };

  render() {
    const { error, submitting } = this.state;
    return (
      <Form
        getApi={(api) => {
          this.formApi = api;
        }}
        className="login-card shadow"
      >
        <Login
          onSubmit={this.handleSubmit}
          error={error}
          submitting={submitting}
        />
      </Form>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LoginPage);
