import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

const Pagination = (props) => (
  <ReactPaginate
    previousLabel={<FontAwesomeIcon icon="arrow-left" />}
    nextLabel={<FontAwesomeIcon icon="arrow-right" />}
    breakLabel="..."
    breakClassName="break-me"
    marginPagesDisplayed={3}
    pageRangeDisplayed={5}
    containerClassName="pagination"
    subContainerClassName="page"
    activeClassName="active"
    {...props}
  />
);

export default Pagination;
