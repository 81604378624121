import { size } from 'lodash';
import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fetchRemessaTotalValue } from 'services/remessas/remessas-services';
import RemessaContext from '../../contexts/Remessa/RemessaContext';
import CurrencyLabel from '../common/CurrencyLabel';
import RemessaDetailsActions from './RemessaDetailsActions';
import RemessaDetailsTitle from './RemessaDetailsTitle';
import StatusRemessa from './StatusRemessa';

const RemessaHeader = () => {
  const { fetchingRemessa, remessa, lancamentos } = useContext(RemessaContext);

  const [remessaTotalValue, setRemessaTotalValue] = useState();

  if (!!!remessa.valor_total && remessa.id) {
    fetchRemessaTotalValue({ remessa_id: remessa.id }).then((data) =>
      setRemessaTotalValue(data.valor_total)
    );
  }

  if (fetchingRemessa) {
    return <Skeleton width="100%" height={110} />;
  }

  const renderNumLancamentoSuccess = () => {
    const numSuccess = size(lancamentos) - size(remessa.sicoob240logs_set);
    return (
      <p className="m-0">
        <strong>
          {numSuccess} aluno{numSuccess === 1 ? '' : 's'}
        </strong>{' '}
        adicionados à remessa.
      </p>
    );
  };

  const renderNumLancamentoError = () => {
    const numError = size(remessa.sicoob240logs_set);

    if (!numError) {
      return null;
    }

    return (
      <p className="m-0 text-danger semi-bold">
        {numError} aluno{numError === 1 ? '' : 's'} com problema.
      </p>
    );
  };

  const renderValorTotalRemessa = () => (
    <p>
      Valor total da remessa:
      <strong>
        {' '}
        <CurrencyLabel
          currency={
            remessa.valor_total ? remessa.valor_total : remessaTotalValue
          }
        />
      </strong>
    </p>
  );

  const renderRemessa = () => {
    const statuses = {
      generate_with_error() {
        return (
          <p className="danger semi-bold">
            Ocorreu um erro ao gerar a remessa.
          </p>
        );
      },
      closed() {
        return (
          <div className="remessa-detail-header">
            <div className="">
              {renderNumLancamentoSuccess()}
              {renderNumLancamentoError()}
              {renderValorTotalRemessa()}
            </div>
          </div>
        );
      },
      processing() {
        return null;
      },
    };
    const status = statuses[remessa.status] || statuses.processing;
    return status();
  };

  return (
    <section>
      <div className="w-100-mobile">
        <RemessaDetailsTitle>
          Remessa {remessa.status && <StatusRemessa status={remessa.status} />}
        </RemessaDetailsTitle>
        {renderRemessa()}
      </div>
      <RemessaDetailsActions remessa={remessa} />
    </section>
  );
};

export default RemessaHeader;
