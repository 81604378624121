import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ChatBubble from './ChatBubble';
import ChatLineInfo from './ChatLineInfo';

const ChatLine = ({
  isAuthor,
  author,
  timestamp,
  message,
  privateMessage,
  children,
  admissionMethod,
  className,
  canSeePrivate,
}) => (
  <div className={className}>
    <div>
      <ChatLineInfo
        author={author}
        timestamp={timestamp}
        admissionMethod={admissionMethod}
      />
    </div>
    <div className="d-flex">
      <ChatBubble isAuthor={isAuthor}>
        <pre>{message || <i>Nenhuma mensagem</i>}</pre>
        {children}
      </ChatBubble>
    </div>
    {canSeePrivate && privateMessage && (
      <div className="d-flex">
        <ChatBubble isAuthor={isAuthor}>
          <pre>{privateMessage || <i>Nenhuma observação</i>}</pre>
          {children}
        </ChatBubble>
      </div>
    )}
  </div>
);

ChatLine.propTypes = {
  isAuthor: PropTypes.bool,
  author: PropTypes.string,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  admissionMethod: PropTypes.shape({
    charge_ticket: PropTypes.bool,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
};

ChatLine.defaultProps = {
  isAuthor: false,
  children: null,
  message: '',
  author: '',
  className: '',
  timestamp: null,
  admissionMethod: {
    charge_ticket: true,
  },
};

export default styled(ChatLine)`
  display: flex;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 5px;
  }
`;
