import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as PrimeReactCalendar } from 'primereact/calendar';
import Circle from '../common/data-display/Circle';

const locale = {
  firstDayOfWeek: 0,
  today: 'Hoje',
  clear: 'Limpar',
  dayNames: [
    'domingo',
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
  ],
  dayNamesShort: ['dom', 'Seg.', 'Ter.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.'],
  dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
};

// On the badges prop, badge.month is 1-indexed, but date.month from dateTemplate(month) is 0-indexed
const isSameDate = (a) => (b) =>
  +a.day === +b.day && +a.month + 1 === +b.month && +a.year === +b.year;

class Calendar extends React.Component {
  get dateTemplate() {
    const { badges } = this.props;
    return (date) => {
      const cmp = isSameDate(date);
      const badge = badges.find(cmp);
      if (badge) {
        return <Circle color={badge.color}>{date.day}</Circle>;
      }
      return <Circle invisible>{date.day}</Circle>;
    };
  }

  render() {
    const { loading, error, className } = this.props;

    return (
      <>
        <PrimeReactCalendar
          locale={locale}
          dateFormat="dd/mm/yy"
          dateTemplate={this.dateTemplate}
          disabledDates={this.disabledDates}
          {...this.props}
          className={loading ? `loading-calendar ${className}` : className}
        />
        {error && <span className="input-helper red">{error}</span>}
      </>
    );
  }
}

Calendar.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
};

Calendar.defaultProps = {
  badges: [],
  loading: false,
  error: '',
  className: '',
};

export default Calendar;
