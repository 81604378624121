/* eslint-disable jsx-a11y/label-has-associated-control */
import classnames from 'classnames';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Calendar from '../Calendar';

const InputDate = ({
  className,
  error,
  label,
  onChange,
  required,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className={`input-type-style ${className}`}>
      <Calendar
        placeholder=""
        className={error ? 'input-error' : 'input-style'}
        readOnlyInput
        {...field}
        {...props}
        onChange={(value) => {
          setFieldValue(field.name, value ? value.value : null);
          if (onChange) {
            onChange(value);
          }
        }}
      />
      <label
        htmlFor={field.name}
        className={classnames('input-label-style', {
          red: meta.error && meta.touched,
          dim: !meta.error,
          'input-required': required,
        })}
      >
        {label}
      </label>
      {meta.error && meta.touched && (
        <span className="input-helper red">{meta.error}</span>
      )}
    </div>
  );
};

InputDate.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  label: PropTypes.string,
  submitOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputDate.defaultProps = {
  className: 'w-100',
  error: '',
  label: '',
  submitOnChange: false,
  required: false,
  onChange: () => {},
};

export default InputDate;
